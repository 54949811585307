import { GLOBAL_VARS } from "helpers/global";
import { RootState } from "redux/reducers";

/**
 * @description used to map path inside public stats of a theme.
 */
export const getStatsPath = (themeURL: RootState["Wizard"]["themeURL"], statsPath: string) => {
  const themePath = (() => {
    const url = new URL(themeURL);
    return {
      full: `${url.origin}${url.pathname}`,
      pathname: url.pathname
    };
  })();

  let path = "";
  if (statsPath === "") {
    path = statsPath;
  } else if (GLOBAL_VARS.validaton.regex.anyUrl.test(statsPath)) {
    path = statsPath;
  } else if (/base64/g.test(statsPath)) {
    path = statsPath;
  } else {
    /**
     * Make sure path always start with / to be merged with themePath url
     */
    const parsePath = /^(\/|\.\/)/.test(statsPath) ? statsPath : `/${statsPath}`;

    /**
     * Merge path with theme url.
     */
    path = `${themePath.full.replace(/(\/)$/, "")}/${parsePath
      .replace(themePath.pathname, "")
      .replace(/^(\.\/|\/)/, "")}`;
  }

  return {
    path: path
  };
};
