/**
 * @description find window menu elements.
 */
export const queryWindowMenuElementsById = (id: string) => {
  const element = document.querySelector<HTMLDivElement>(`[data-wm-id="${id}"]`);

  return {
    container: element?.querySelector(`[data-wm-container="true"]`),
    header: element?.querySelector(`[data-wm-header="true"]`)
  };
};
