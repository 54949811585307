import { Column, Row } from "@soltivo/draw-a-line";
import { FC } from "react";
import PluginList from "blocks/plugin.list/plugin.list";
import ContentHeader from "components/content.header/content.header";
import styles from "./styles.module.scss";

interface PluginsProps {}

const Plugins: FC<React.PropsWithChildren<PluginsProps>> = () => {
  return (
    <Row className={styles.component}>
      <Column size={"12"}>
        <ContentHeader title="Plugins"></ContentHeader>
      </Column>
      <Column size={"12"}>
        <PluginList />
      </Column>
    </Row>
  );
};

export default Plugins;
