import { WindowMenuObject } from "../context";

/**
 *
 * @description resize constants
 */
export const resize: () => Record<WindowMenuObject["size"], Pick<WindowMenuObject, "width" | "maxHeight">> = () => ({
  small: {
    width: 300,
    maxHeight: 400
  },
  medium: {
    width: 500,
    maxHeight: 600
  },
  big: {
    width: 768,
    maxHeight: 768
  }
});
