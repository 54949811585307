import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";
import { ErrorApp } from "helpers/classes/ErrorApp";

/**
 * @description change font of a theme.
 */
export default function* update({
  payload
}: ReturnType<(typeof theme)["font"]["actions"]["update"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const { fontName } = payload;
    if (!fontName) return;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: cloneDeep(WebsiteWizardReducer.website),
      configuration: WebsiteWizardReducer.configuration
    });

    const { configuration }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    let { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);

    const newFont = configuration?.fonts?.find((font) => font.name === fontName);
    if (!newFont) return;

    website = modelWebsite.update(modelWebsite, { font: newFont });

    yield put(
      theme.font.actions.update.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.font.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
