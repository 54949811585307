import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import {
  ValidateTypeBoolean,
  ValidateTypeEmail,
  ValidateTypeImage,
  ValidateTypeNumber,
  ValidateTypePhoneNumber,
  ValidateTypeString,
  ValidateTypeUrl
} from "../../typeof";

export const ValidateClassObject = (validation: ValidateClassObject, payload: WebsiteObject) => {
  const attributesKeys = Object.keys(validation.attributes);
  for (let i = 0; i < attributesKeys.length; i++) {
    const key = attributesKeys[i];
    const attributeValidation = validation.attributes[key];
    // change to unknown
    const attributeValue: any = payload[key];
    try {
      const types = GLOBAL_VARS.validaton.types;
      if (!types.includes(attributeValidation.typeof)) {
        throw `Validation typeof "${attributeValidation.typeof}" is unknown.`;
      } else if (attributeValidation.typeof === "string") {
        ValidateTypeString(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "number") {
        ValidateTypeNumber(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "email") {
        ValidateTypeEmail(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "phone-number") {
        ValidateTypePhoneNumber(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "url") {
        ValidateTypeUrl(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "boolean") {
        ValidateTypeBoolean(attributeValidation, attributeValue);
      } else if (attributeValidation.typeof === "image") {
        ValidateTypeImage(attributeValidation, attributeValue);
      }
    } catch (e) {
      if (typeof e === "string") {
        throw createErrorApp(e);
      }
    }
  }
};
