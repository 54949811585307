/**
 * @description set/get iframe resolution based on device resolution.
 */
export const DOMIframeWebsiteResolution = (
  iframeWebsite: HTMLIFrameElement | undefined,
  resolution: {
    width: number;
    height: number;
  },
  options: {
    /**
     * @description update iframeWebsite styles with transform & left css.
     */
    updateDOM: boolean;
    callback?: (payload: {
      iframeWebsiteWrapper: DOMRect;
      x: {
        scaleSizeX: number;
        wrapperSizeX: number;
        scaleX: number;
        percentageX: number;
      };
      y: {
        scaleSizeY: number;
        wrapperSizeY: number;
        percentageY: number;
        scaleY: number;
      };
    }) => void;
  }
) => {
  if (iframeWebsite) {
    const parentElement = iframeWebsite.parentElement;
    if (parentElement) {
      // get max container width posible
      parentElement.style.width = `100%`;
      let iframeWebsiteWrapper = parentElement.getBoundingClientRect();

      //create aspect ratio for height
      const WrapperHeightAR = (resolution.height / resolution.width) * iframeWebsiteWrapper.width;
      parentElement.style.height = `${WrapperHeightAR}px`;
      iframeWebsiteWrapper = parentElement.getBoundingClientRect();

      //create aspect ratio for width from defined height
      const WrapperWidthAR = (resolution.width / resolution.height) * iframeWebsiteWrapper.height;

      //caculate padding X
      // let widthGap = Math.abs(iframeWebsiteWrapper.width - WrapperWidthAR);
      // const paddingX = widthGap / 2;
      // parentElement.style.borderLeft = `${paddingX}px solid #000`;
      // parentElement.style.borderRight = `${paddingX}px solid #000`;
      parentElement.style.width = `${WrapperWidthAR}px`;
      iframeWebsiteWrapper = parentElement.getBoundingClientRect();

      // X
      const scaleSizeX = resolution.width;
      const wrapperSizeX =
        iframeWebsiteWrapper.width > scaleSizeX ? scaleSizeX : Math.abs(scaleSizeX - iframeWebsiteWrapper.width);

      // used to create translate css rules
      const percentageX =
        iframeWebsiteWrapper.width > scaleSizeX
          ? (iframeWebsiteWrapper.width / scaleSizeX) * 100
          : (wrapperSizeX / scaleSizeX) * 100;

      // same as percentage but for scale css rules
      const calcScaleX =
        iframeWebsiteWrapper.width > scaleSizeX
          ? (iframeWebsiteWrapper.width / scaleSizeX) * 1
          : (wrapperSizeX / scaleSizeX) * 1;
      const scaleX = iframeWebsiteWrapper.width > scaleSizeX ? calcScaleX : 1 - calcScaleX;

      // Y
      const scaleSizeY = resolution.height;
      const wrapperSizeY =
        iframeWebsiteWrapper.height > scaleSizeY ? scaleSizeY : Math.abs(scaleSizeY - iframeWebsiteWrapper.height);

      // used to create translate css rules
      const percentageY =
        iframeWebsiteWrapper.height > scaleSizeY
          ? (iframeWebsiteWrapper.height / scaleSizeY) * 100
          : (wrapperSizeY / scaleSizeY) * 100;

      // same as percentage but for scale css rules
      const calcScaleY =
        iframeWebsiteWrapper.height > scaleSizeY
          ? (iframeWebsiteWrapper.height / scaleSizeY) * 1
          : (wrapperSizeY / scaleSizeY) * 1;
      const scaleY = iframeWebsiteWrapper.height > scaleSizeY ? calcScaleY : 1 - calcScaleY;

      /**
       * this option will add css rules to the iframe DOM element to make sure
       * the iframe scaling is will fit the passed scaleSize options X and Y on
       * any screen size.
       */
      if (options?.updateDOM) {
        iframeWebsite.style.position = "absolute";
        iframeWebsite.style.width = `${resolution.width}px`;
        iframeWebsite.style.height = `${resolution.height}px`;
        iframeWebsite.style.transform = `scaleX(${scaleX}) scaleY(${scaleY}) translateX(-${percentageX}%) translateY(-${percentageY}%)`;
        iframeWebsite.style.left = `${percentageX}%`;
        iframeWebsite.style.top = `${percentageY}%`;
      }

      const iframeScale = {
        iframeWebsiteWrapper,
        x: {
          scaleSizeX,
          wrapperSizeX,
          scaleX,
          percentageX
        },
        y: {
          scaleSizeY,
          wrapperSizeY,
          percentageY,
          scaleY
        }
      };

      if (options?.callback) {
        options.callback(iframeScale);
      }

      return iframeScale;
    }
  }
};
