import { useEffect } from "react";
import { RootState } from "../../redux/reducers";

const useRemoveEvents = (
  editor: boolean,
  website: RootState["Wizard"]["website"],
  iframeWebsite: HTMLIFrameElement | undefined
) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const iframeDocument = iframeWebsite?.contentWindow?.document || iframeWebsite?.contentDocument;
      if (iframeDocument) {
        const links = iframeDocument.querySelectorAll("a");

        //remove routes from links
        if (links?.length && editor) {
          for (let i = 0; i < links.length; i++) {
            if (links[i].style.pointerEvents !== "none") {
              links[i].style.pointerEvents = "none";
            }
          }
        }
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, [website, editor, iframeWebsite]);

  useEffect(() => {
    const interval = setInterval(() => {
      const iframeDocument = iframeWebsite?.contentWindow?.document || iframeWebsite?.contentDocument;
      if (iframeDocument) {
        const frames = iframeDocument.querySelectorAll("iframe");
        if (frames?.length && editor) {
          for (let i = 0; i < frames.length; i++) {
            if (frames[i].style.pointerEvents !== "none") {
              frames[i].style.pointerEvents = "none";
            }
          }
        }
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, [website, editor, iframeWebsite]);

  useEffect(() => {
    //remove any submit and action of a form.
    const interval = setInterval(() => {
      const iframeDocument = iframeWebsite?.contentWindow?.document || iframeWebsite?.contentDocument;
      if (iframeDocument) {
        const forms = iframeDocument.querySelectorAll("form");

        for (let i = 0; i < forms.length; i++) {
          forms[i].onsubmit = (e) => {
            e.preventDefault();
            return false;
          };

          const submitButtons = forms[i].querySelectorAll("button");
          for (let i = 0; i < submitButtons.length; i++) {
            if (submitButtons[i].style.pointerEvents !== "none") {
              submitButtons[i].style.pointerEvents = "none";
            }
          }
        }
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [iframeWebsite?.contentDocument?.querySelectorAll("form")]);

  useEffect(() => {
    const interval = setInterval(() => {
      const iframeDocument = iframeWebsite?.contentWindow?.document || iframeWebsite?.contentDocument;

      if (iframeDocument) {
        const buttons = iframeDocument.querySelectorAll<HTMLButtonElement>("button[data-class]");

        if (buttons?.length && editor) {
          for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].style.pointerEvents !== "none") {
              buttons[i].style.pointerEvents = "none";
            }
          }
        }
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, [website, editor, iframeWebsite]);
};

export default useRemoveEvents;
