/**
 * @description check if value is valid type, if not use defaultValue.
 */
export const valueWithDefault = <T>(
  type: "array" | "string" | "number" | "object" | "bigint" | "boolean" | "function" | "symbol" | "undefined",
  value: T,
  defaultValue: T
) => {
  if (type === "array") {
    if (Array.isArray(value)) {
      return value;
    } else {
      return defaultValue;
    }
  } else if (typeof value === type) {
    return value;
  } else {
    return defaultValue;
  }
};
