import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect, call } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { soltivoHelper } from "@soltivo/draw-a-line";
import apiV2 from "../../../../helpers/api/api.v2";
import websiteClass from "../../../../helpers/api/website.class";
import { createErrorApp, ErrorApp } from "../../../../helpers/classes/ErrorApp";
import { ModelPage, ModelSection, ModelWebsite, ModelValidation } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme, wizard } from "redux/actions";

const scaleDownImageError =
  "Failed to upload image, for performance reasons if you want to use this image consider scale it down before add it to your website.";

/**
 * @description creates a new section with updated value.
 */
function createChangedSectionHelper(section: ModelSection, dataPath: string, dataClass: WebsiteDataClass, value: any) {
  return section
    ? section.sectionDataModify({
        section: section,
        dataPath,
        dataClass: dataClass,
        value,
        placeholder: null
      })
    : undefined;
}

function updatePageSection(
  pages: RootState["Wizard"]["pages"],
  pageId: WebsitePage["id"],
  website: RootState["Wizard"]["website"],
  newSection: WebsiteSection
) {
  pages = pages.map((_page) => {
    const page = new ModelPage(_page);
    if (page.id === pageId) {
      // displayed page
      if (website.page) {
        website.page.sections = [
          ...page.sections.map((section) => {
            if (section.name === newSection.name) {
              return new ModelSection(newSection);
            } else {
              return new ModelSection(section);
            }
          })
        ];

        // list of pages
        page.sections = [
          ...page.sections.map((section) => {
            if (section.name === newSection.name) {
              return new ModelSection(newSection);
            } else {
              return new ModelSection(section);
            }
          })
        ];
      }
    }
    return page.object;
  });
  return { pages, website };
}

/**
 * @description update section of a page in a theme..
 */
export default function* update({
  payload
}: ReturnType<(typeof theme)["editable"]["actions"]["update"]["request"]>): Generator<
  CallEffect<Promise<File>> | CallEffect | Promise<string | undefined> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  // oldSection is here if anything bad happens return editable to previous state.
  let oldSection = null;
  let oldWebsite = null;
  try {
    const codeException = "UpdateException";
    let { value } = payload;
    const { dataClass, dataPath } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: cloneDeep(WebsiteWizardReducer.website),
      pages: cloneDeep(WebsiteWizardReducer.pages),
      pageId: WebsiteWizardReducer.pageId,
      validation: WebsiteWizardReducer.validation
    });

    const { pageId, website, validation }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    let { pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);
    const modelValidation = new ModelValidation(validation);

    const editableTypes = dataPath.split("/");

    const _prevSection =
      editableTypes[0] === "global"
        ? undefined
        : modelWebsite.page.findSectionByName(modelWebsite.page.sections, editableTypes[0]);
    const prevSection = editableTypes[0] === "global" ? undefined : new ModelSection(_prevSection);

    //save point old section global variable.
    oldSection = editableTypes[0] === "global" ? undefined : new ModelSection(prevSection);
    oldWebsite = new ModelWebsite(modelWebsite);

    // 🚩 Most likely to be deprecated in the next prs.
    // We do not support create custom EditableElements globally
    // on WebsiteData, which also means there are no more `data-path="global/..."`
    // But this can be helpful for creating sub global changes in the future.
    if (editableTypes[0] === "global") {
      // type GlobalWithData = "menu" | "footer";
      // const globalAttribute = editableTypes[1] as GlobalWithData;
      // modelWebsite.globalDataModify({
      //   website: modelWebsite.object,
      //   dataPath,
      //   dataClass: dataClass,
      //   value
      // });
      // let newProperty = modelWebsite[globalAttribute];
      // //validate data based on validate.website.json
      // modelValidation.startValidation({ newProperty }, validation, {
      //   dataPath,
      //   pageId
      // });
    } else {
      // create new data
      let newSection = prevSection ? createChangedSectionHelper(prevSection, dataPath, dataClass, value) : undefined;

      //validate data based on validate.website.json
      modelValidation.startValidation({ newSection: newSection }, validation, {
        dataPath,
        dataClass,
        pageId
      });

      if (typeof value === "object") {
        const objectKeys = Object.keys(value);
        for (let i = 0; i < objectKeys.length; i++) {
          const objKey = objectKeys[i];
          const objValue = value[objKey];
          if (objValue instanceof File) {
            let image = yield call(soltivoHelper.processImageSize, objValue, {
              dimensions: {
                width: objValue.size > 640 ? 640 : objValue.size,
                fill: true
              },
              type: "image/webp"
            });
            if (Math.round(value.size / 1024) > 2000) {
              throw createErrorApp(scaleDownImageError, { code: codeException });
            }
            image = yield soltivoHelper.fileToBase64(image);
            // create new data
            if (image && prevSection) {
              newSection = createChangedSectionHelper(prevSection, dataPath, dataClass, {
                ...value,
                [objKey]: image
              });
            }
          }
        }
      }
      // create first state.
      if (newSection) {
        const { pages: _pages, website: _website } = updatePageSection(pages, pageId, modelWebsite.object, newSection);
        pages = _pages;
        modelWebsite.update(modelWebsite, _website);
        // website = _website;
      }
    }

    yield put(
      theme.editable.actions.update.success({
        pages: pages,
        website: modelWebsite.object
      })
    );

    if (typeof value === "object") {
      const objectKeys = Object.keys(value);
      for (let i = 0; i < objectKeys.length; i++) {
        const objKey = objectKeys[i];
        let objValue = value[objKey];
        if (objValue instanceof File) {
          //this block of code is only when uploading files.
          // 16/9
          objValue = yield call(soltivoHelper.processImageSize, objValue, {
            dimensions: {
              width: objValue.size > 640 ? 640 : objValue.size,
              fill: true
            },
            type: "image/webp"
          });
          if (Math.round(objValue.size / 1024) > 2000) {
            throw createErrorApp(scaleDownImageError, {
              code: codeException
            });
          }
          //get s3 links
          const { data: assignedUrl } = yield call(websiteClass.websiteUploadFile, objValue);
          const { fileLink } = yield call(apiV2.postAssignedUrl, assignedUrl, objValue);
          // set new value
          value = {
            ...value,
            [objKey]: fileLink
          };

          // 🚩 Most likely to be deprecated in the next prs.
          // We do not support create custom EditableElements globally
          // on WebsiteData, which also means there are no more `data-path="global/..."`
          // But this can be helpful for creating sub global changes in the future.
          if (editableTypes[0] === "global") {
            // type GlobalWithData = "menu" | "footer";
            // const globalAttribute = editableTypes[1] as GlobalWithData;
            // modelWebsite.globalDataModify({
            //   website: modelWebsite.object,
            //   dataPath,
            //   dataClass: dataClass,
            //   value
            // });
          } else {
            // create new data for the section
            const newSection = prevSection
              ? createChangedSectionHelper(prevSection, dataPath, dataClass, value)
              : undefined;
            // modify page with the new section
            if (newSection) {
              const { pages: _pages, website: _website } = updatePageSection(pages, pageId, modelWebsite, newSection);
              pages = _pages;
              modelWebsite.update(modelWebsite, _website);
              // website = _website;
            }
          }
          // update state
          yield put(
            theme.editable.actions.update.success({
              pages: pages,
              website: modelWebsite.object
            })
          );
        }
      }
    }
  } catch (error) {
    //This block of code is a come back update section, if anything bad happens while updating section, the section will be
    //returned to the previous state.

    const { dataPath } = payload;

    const editableTypes = dataPath.split("/");

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: cloneDeep(WebsiteWizardReducer.website),
      pages: cloneDeep(WebsiteWizardReducer.pages),
      pageId: WebsiteWizardReducer.pageId
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;
    let { website, pages }: MapStateToProps = yield select(mapStateToProps);
    const { pageId }: MapStateToProps = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);

    if (editableTypes[0] === "global" && oldWebsite) {
      //merge change to global attribute in website
      website = oldWebsite;
    } else if (oldSection) {
      // undo page to previous state.
      const { pages: _pages, website: _website } = updatePageSection(pages, pageId, modelWebsite, oldSection);
      pages = _pages;
      modelWebsite.update(modelWebsite, _website);
      // website = _website;
    }

    // force undo state.
    yield put(
      wizard.state.actions.change.request({
        pages: pages,
        website: website
      })
    );

    yield put(
      theme.editable.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
