import styles from "./menu.link.module.scss";

interface MenuLinkProps {
  onClick?: () => void;
  active?: boolean;
}

const MenuLink: React.FC<React.PropsWithChildren<MenuLinkProps>> = ({ children, active, onClick }) => {
  return (
    <button
      className={`${styles.menu__link} ${active === true ? styles.active : ""}`}
      onClick={() => (onClick ? onClick() : undefined)}>
      {children}
    </button>
  );
};

export default MenuLink;
