import { Button } from "@soltivo/draw-a-line";
import { useRouteMatch } from "react-router";
import { ReactComponent as SteveSad } from "@soltivo/draw-a-line/core/assets/illutrations/SteveSad.svg";
import useDashboardAuth from "../../helpers/hooks/useDashboardAuth";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

const ErrorPage = () => {
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    error: Wizard.error || App.error
  });
  const { error } = useSelector(mapStateToProps);

  const isUnauthorized = useRouteMatch({
    path: "/401",
    exact: true
  });

  const isForbidden = useRouteMatch({
    path: "/403",
    exact: true
  });

  const isNotFound = useRouteMatch({
    path: "/404",
    exact: true
  });

  const isUnprocessable = useRouteMatch({
    path: "/422",
    exact: true
  });

  const { onOpenLogin } = useDashboardAuth();

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <SteveSad />

        <h2>
          {isUnauthorized
            ? "Authentication failed."
            : isForbidden
            ? "Forbidden."
            : isNotFound
            ? "Theme Not found"
            : isUnprocessable
            ? "Invalid Theme"
            : ""}
        </h2>

        <p>{error?.message ? error.message : ""}</p>

        {isUnauthorized ? (
          <Button padding variant="primary" outline onClick={() => onOpenLogin()}>
            Sign in
          </Button>
        ) : null}

        {isUnprocessable ? (
          <div className={styles.actions}>
            <Button
              padding
              variant="primary"
              outline
              onClick={() => (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/sales/website`)}>
              My Website
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ErrorPage;
