import React, { FC, useContext, useEffect } from "react";
import { Close, FilterNone24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./window.menu.module.scss";
import { DOMGetIframeWebsite } from "helpers/functions/DOMGetIframeWebsite";
import WindowMenu from ".";
import { WindowMenuObject } from "./context";

interface ComponentProps {
  id: string;
}

const Component: FC<React.PropsWithChildren<ComponentProps>> = ({ id }) => {
  const maxTopPosition = 60;
  const { setState, state } = useContext(WindowMenu.Context);

  const windowMenu = WindowMenu.getWindowMenuObject(id, state.windows) as unknown as WindowMenuObject;

  const Body = windowMenu.Component;
  const Footer = windowMenu.Footer;

  useEffect(() => {
    /**
     * On move window menu
     */
    const onMouseMove = (e: MouseEvent) => {
      if (e.buttons === 1 && windowMenu.moving === true) {
        // window size.
        const bodyWidth = document.body.offsetWidth;
        const bodyHeight = document.body.offsetHeight;

        const windowElements = WindowMenu.queryWindowMenuElementsById(windowMenu.id);

        if (!windowElements.container || !windowElements.header) {
          return;
        }

        const containerRect = windowElements.container.getBoundingClientRect();
        const headerRect = windowElements.header.getBoundingClientRect();
        const headerHeight = headerRect.height;

        let mouseTop = e.clientY - headerHeight / 2;
        let mouseLeft = e.clientX - containerRect.width / 2;

        // make sure it does not go outside the viewport window.
        mouseTop =
          mouseTop < maxTopPosition
            ? maxTopPosition
            : mouseTop > bodyHeight - headerHeight
            ? bodyHeight - headerHeight
            : mouseTop;
        mouseLeft =
          mouseLeft < 0 ? 0 : mouseLeft + containerRect.width > bodyWidth ? bodyWidth - containerRect.width : mouseLeft;

        setState((state) => {
          return {
            ...state,
            windows: state.windows.map((item) => {
              if (item.id === windowMenu.id) {
                return {
                  ...item,
                  to: {
                    top: `${mouseTop}px`,
                    left: `${mouseLeft}px`
                  }
                };
              }
              return item;
            })
          };
        });

        // Make sure to update the zIndex on window on move
        onFocusWindow();
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (windowMenu.moving === true) {
        setState((state) => ({
          ...state,
          windows: state.windows.map((item) => {
            if (item.id === windowMenu.id) {
              return {
                ...item,
                moving: false
              };
            }
            return item;
          })
        }));
      }
    };

    const interval = setInterval(() => {
      const { iframeWebsite } = DOMGetIframeWebsite();
      if (!iframeWebsite) {
        return;
      }
      if (windowMenu.moving === true) {
        iframeWebsite.dataset["moving_window"] = "true";
      } else {
        iframeWebsite.dataset["moving_window"] = "false";
      }
    });

    window.addEventListener("mousemove", onMouseMove, true);
    window.addEventListener("mouseup", onMouseUp, true);

    return () => {
      clearInterval(interval);
      window.removeEventListener("mousemove", onMouseMove, true);
      window.removeEventListener("mouseup", onMouseUp, true);
    };
  }, [windowMenu.moving]);

  useEffect(() => {
    const windowElements = WindowMenu.queryWindowMenuElementsById(windowMenu.id);

    // make sure on resize to keep container inside window screen width and height.
    if (windowElements.header) {
      const fixContainer = WindowMenu.fixContainerPosition(windowMenu);
      if (fixContainer.updateCondition) {
        setState((state) => {
          return {
            ...state,
            windows: state.windows.map((item) => {
              if (item.id === windowMenu.id) {
                return {
                  ...item,
                  to: {
                    top: fixContainer.top,
                    left: fixContainer.left
                  }
                };
              }
              return item;
            })
          };
        });
      }
    }
  }, [windowMenu.size]);

  const onCheckAcceptedSizes = () => {
    const { size, acceptSizes, orderSizes } = windowMenu;

    const filterAcceptedSizes = orderSizes.filter((item) => acceptSizes.includes(item));
    const currentSizeAcceptedIndex = filterAcceptedSizes.findIndex((value) => value === size);
    const nextSizeIndex = currentSizeAcceptedIndex >= filterAcceptedSizes.length - 1 ? 0 : currentSizeAcceptedIndex + 1;
    const nextSize = filterAcceptedSizes[nextSizeIndex];

    const result = {
      width: WindowMenu.resize()[nextSize].width,
      maxHeight: WindowMenu.resize()[nextSize].maxHeight,
      size: nextSize
    };

    return result;
  };

  const onFocusWindow = () => {
    const windowMenu = WindowMenu.getWindowMenuObject(id, state.windows);

    if (!windowMenu) {
      return;
    }

    if (windowMenu.zIndex !== state.windows.length) {
      setState((state) => ({
        ...state,
        windows: state.windows.map((item, index) => {
          if (windowMenu.id === item.id) {
            return {
              ...item,
              zIndex: state.windows.length
            };
          }
          return {
            ...item,
            zIndex: item.zIndex === 0 ? 0 : item.zIndex === state.windows.length ? item.zIndex - 1 : item.zIndex
          };
        })
      }));
    }
  };

  const windowPageVisible = windowMenu.pagination.enabled
    ? windowMenu.pagination.pages.find((windowPage) => windowPage.visible === true)
    : undefined;

  return (
    <div
      onClick={onFocusWindow}
      onDoubleClick={onFocusWindow}
      className={`${styles.window__menu} ${styles[windowMenu.size]} ${windowMenu.show ? styles.show : ""} ${
        windowMenu.loading ? styles.loading : ""
      }`}
      style={{
        ...windowMenu.to,
        width: windowMenu.width,
        maxHeight: windowMenu.maxHeight,
        zIndex: windowMenu.zIndex
      }}
      data-wm-container={"true"}>
      <div
        onMouseDown={(e) => {
          if (!windowMenu.moving) {
            setState((state) => ({
              ...state,
              windows: state.windows.map((item) => {
                if (item.id === windowMenu.id) {
                  return {
                    ...item,
                    moving: true
                  };
                }

                return item;
              })
            }));
          }
        }}
        className={styles.header}
        data-wm-header="true">
        <h6>{windowPageVisible?.title ? windowPageVisible.title : windowMenu.title || "Window"}</h6>
        <div
          style={{
            gridTemplateColumns: `repeat(${windowMenu.acceptSizes.length}, 1fr)`
          }}
          className={styles.actions}
          onMouseDown={(e) => e.stopPropagation()}>
          {windowMenu.acceptSizes.length > 1 ? (
            <button
              // ❌ Requires improvement.
              // data-title="Resize"
              // data-title-position="bottom-left"
              onClick={(e) => {
                WindowMenu.changeWindowMenuState(setState, {
                  id: windowMenu.id,
                  value: {
                    ...onCheckAcceptedSizes()
                  }
                });
              }}>
              <FilterNone24Px />
            </button>
          ) : null}

          <button
            // ❌ Requires improvement.
            // data-title="Close"
            // data-title-position="bottom-left"
            onClick={(e) => {
              setState((state) => ({ ...state, windows: state.windows.filter((item) => item.id !== windowMenu.id) }));
            }}>
            <Close />
          </button>
        </div>
      </div>

      <div className={styles.body}>
        <Body />
      </div>

      {Footer ? (
        <div className={styles.footer}>
          <Footer />
        </div>
      ) : null}
    </div>
  );
};

export default Component;
