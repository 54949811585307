import React, { FC } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@soltivo/draw-a-line/core/components/loading/loader/loader";
import { RootState } from "redux/reducers";
import actions from "redux/actions";
import styles from "./loading.wrapper.module.scss";

/**
 * @description website wizard entry point.
 */
const LoadingWrapper: FC<React.PropsWithChildren> = ({ children }) => {
  const mapStateToProps = ({ Wizard, App }: RootState) => {
    return {
      themeURL: Wizard.themeURL,
      loadingOrganization: App.organization.loading,
      loadingCognitoUser: App.cognitorUser.loading,
      loadingUser: App.user.loading,
      loadingThemeData: Wizard.loadingThemeData
    };
  };

  const { themeURL, loadingThemeData, loadingOrganization, loadingCognitoUser, loadingUser } =
    useSelector(mapStateToProps);

  const loading = loadingOrganization || loadingCognitoUser || loadingUser || loadingThemeData || !themeURL;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wizard.app.actions.init.request());
  }, []);

  if (loading) {
    return (
      <div className={styles.component}>
        <div>
          <Loader className={styles.loader} variant="primary" size="lg" />
          <h5 className="text-geyser-900">
            {/*
             * The other of the loaders here matter.
             */}
            {loadingCognitoUser
              ? "Loading authenticated user..."
              : loadingOrganization
              ? "Loading your organization informations..."
              : loadingUser
              ? "Loading user informations..."
              : loadingThemeData
              ? "Loading theme data..."
              : ""}
          </h5>
        </div>
      </div>
    );
  }

  // return null;

  return <>{children}</>;
};

export default LoadingWrapper;
