import apiV2 from "./api.v2";

const _URL = `${process.env.REACT_APP_API_URL}/organizations`;

/**
 * @description organization API calls.
 */
class OrgClass {
  /**
   * @description get organization by id.
   */
  getById({ orgId }: { orgId: string }) {
    return apiV2.getRequest(`${_URL}/${orgId}`);
  }

  // /**
  //  * @description get current organization locations
  //  */
  // getCurrentOrganizationLocations() {
  //   return apiV2.getRequest(`${_URL}/locations`);
  // }
}

export default new OrgClass();
