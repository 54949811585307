import { decode } from "html-entities";
import React, { useRef } from "react";
import Editable, { ContentEditableEvent } from "react-contenteditable";
import styles from "./styles.module.scss";
import useEditableData from "helpers/hooks/useEditableData";

interface EditableTextProps extends EditableProps<"text"> {
  onFocus?: React.FocusEventHandler<HTMLSpanElement>;
  onBlur?: React.FocusEventHandler<HTMLSpanElement>;
  editableObjectValidation: ValidateClassText;
}

const EditableText: React.FC<React.PropsWithChildren<EditableTextProps>> = ({
  iframeWebsite,
  target,
  editableObjectValidation,
  dataClass,
  dataPath,
  onFocus,
  onBlur
}) => {
  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  const editableTextValue = useRef<string>(decode(String(data?.value || "")));

  /**
   * @description gets the new value of a editable DOM element.
   */
  const updateEditableValue = (e: ContentEditableEvent) => {
    if (editableObjectValidation) {
      try {
        if (editableObjectValidation.attributes.value?.rules?.maxLength) {
          if (e.currentTarget.innerText.length > editableObjectValidation.attributes.value?.rules?.maxLength) {
            e.currentTarget.select();
            e.currentTarget.innerText = editableTextValue.current;

            // focus at the end of the text.
          }
        } else if (editableObjectValidation.attributes.value?.rules?.minLength) {
          if (e.currentTarget.innerText.length < editableObjectValidation.attributes.value?.rules?.minLength) {
            e.currentTarget.innerText = editableTextValue.current;
          }
        }

        //This also avoids updation when clicking on ctrl or any other button that's not text.
        if (editableTextValue.current === e.currentTarget.innerText) {
          return;
        }

        editableTextValue.current = e.currentTarget.innerText;

        onUpdate({
          ...data,
          value: e.currentTarget.innerText || "..."
        });
      } catch (error) {
        return;
      }
    }
  };

  /**
   * @description for text editing, when you finish editing and
   * click outside the element
   */
  const onEditorBlur = (e: React.FocusEvent<HTMLSpanElement>) => {
    if (!iframeWebsite) return;
    if (!iframeWebsite.contentDocument) return;
    e.target.textContent = e.target.textContent ? e.target.textContent : e.target.getAttribute("placeholder");
    // if (e.target.textContent?.toLowerCase() !== placeholder.toLowerCase()) {
    //     websiteWizardHelper.changeElementFromTheSameType(iframeWebsite.contentDocument.body, dataClass, dataFor);
    // }

    if (onBlur) onBlur(e);
  };

  if (!data || !validation) {
    return <></>;
  }

  return (
    <span className={`${styles.component}`}>
      <Editable
        onWheel={(e) => {
          //allow scroll inside editable element.
          e.currentTarget.classList.add(styles.scrolling);
        }}
        spellCheck={false}
        tagName="span"
        disabled={false}
        onChange={(e) => {
          updateEditableValue(e);
        }}
        onBlur={(e) => {
          onEditorBlur(e);
        }}
        onFocus={onFocus}
        placeholder={"..."}
        html={decode(String(editableTextValue.current))}
      />
    </span>
  );
};

export default EditableText;
