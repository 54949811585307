import { createRootCssVariables } from "helpers/functions/createRootCssVariables";
import { GLOBAL_VARS } from "helpers/global";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/reducers";

/**
 * @description create style related to website colors and insert inside iframe head.
 */
const useThemeColors = (
  iframeIsLoaded: boolean,
  iframeWebsite: HTMLIFrameElement | undefined,
  website: RootState["Wizard"]["website"]
) => {
  const [state, setState] = useState<{
    elements: {
      style: HTMLStyleElement;
    };
  }>();

  useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("id", GLOBAL_VARS.iframeWebsite.head.colorsStyle.id);
    style.innerHTML = createRootCssVariables(website.colors);
    if (iframeIsLoaded && iframeWebsite) {
      const iframeDocument = iframeWebsite.contentDocument;

      if (iframeDocument?.head) {
        const head = iframeDocument.head;
        const oldStyle = head.querySelector(`#${GLOBAL_VARS.iframeWebsite.head.colorsStyle.id}`);

        if (oldStyle) {
          if (oldStyle.innerHTML !== style.innerHTML) {
            // add new changes on for the style
            head.removeChild(oldStyle);
            head.appendChild(style);
          }
        } else {
          head.appendChild(style);
        }

        setState((state) => {
          return {
            ...state,
            elements: {
              style
            }
          };
        });
      }
    }
  }, [website?.colors, website?.themeId, iframeIsLoaded]);

  return state;
};

export default useThemeColors;
