import { useEffect, useState } from "react";
/**
 * @description allows you to load 3th part libraries
 * into your iframe.
 */
const useAssets = (
  assetList: { id: string; url: string; htmlElement: "script" | "link" }[],
  iframeWebsite: HTMLIFrameElement | undefined
) => {
  const [state, setState] = useState({
    loadedAllAssets: false,
    assets: assetList.map((item) => ({ id: item.id, loading: true }))
  });

  const iframeDocumentHead = (iframeWebsite?.contentDocument || iframeWebsite?.contentWindow?.document)?.head;

  useEffect(() => {
    setState((state) => {
      return {
        ...state,
        assets: assetList.map((item) => ({ id: item.id, loading: true }))
      };
    });
  }, [assetList.length, setState]);

  useEffect(() => {
    if (iframeDocumentHead) {
      for (let i = 0; i < assetList.length; i++) {
        const url = assetList[i];
        const newElement = document.createElement(url.htmlElement);
        newElement.id = `${url.id}`;
        newElement.setAttribute(url.htmlElement === "link" ? "href" : "src", url.url);
        if (url.htmlElement === "link") newElement.setAttribute("rel", "stylesheet");
        newElement.setAttribute("type", url.htmlElement === "link" ? "text/css" : "text/javascript");
        newElement.onload = () => {
          setState((state) => {
            return {
              ...state,
              assets: state.assets.map((item) => {
                if (item.id === url.id) {
                  item.loading = false;
                }
                return item;
              })
            };
          });
        };

        const oldElement = iframeDocumentHead?.querySelector<HTMLLinkElement | HTMLScriptElement>(`#${url.id}`);
        if (oldElement) {
          let shouldUpdate = false;
          if (url.htmlElement === "link") {
            if (oldElement.getAttribute("href") !== newElement.getAttribute("href")) {
              shouldUpdate = true;
            } else if (oldElement.getAttribute("type") !== newElement.getAttribute("type")) {
              shouldUpdate = true;
            }
            // else if (oldElement.onload !== newElement.onload) {
            //   shouldUpdate = true;
            // }

            if (shouldUpdate) {
              iframeDocumentHead?.removeChild(oldElement);
              iframeDocumentHead?.appendChild(newElement);
            }
          } else if (url.htmlElement === "script") {
            if (oldElement.getAttribute("src") !== newElement.getAttribute("src")) {
              shouldUpdate = true;
            } else if (oldElement.getAttribute("type") !== newElement.getAttribute("type")) {
              shouldUpdate = true;
            }
            // else if (oldElement.onload !== newElement.onload) {
            //   shouldUpdate = true;
            // }

            if (shouldUpdate) {
              iframeDocumentHead?.removeChild(oldElement);
              iframeDocumentHead?.appendChild(newElement);
            }
          }
        } else {
          iframeDocumentHead?.appendChild(newElement);
        }
      }
    }
  }, [iframeDocumentHead, assetList, setState]);

  useEffect(() => {
    if (!state.assets.find((item) => item.loading === true)) {
      setState((state) => {
        return {
          ...state,
          loadedAllAssets: true
        };
      });
    }
  }, [state.assets, setState]);

  return state;
};

export default useAssets;
