import { DOMGetIframeWebsite } from "helpers/functions/DOMGetIframeWebsite";
import { getSectionIndexByName } from "helpers/functions/getSectionIndexByName";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import styles from "./manage.sections.module.scss";
import { theme } from "redux/actions";

const ManageSectionsWindow = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    droppable: false,
    /**
     * @description selected indexes inside of group being dragged to a new position
     */
    draggingIndex: -1
  });
  const ulRef = useRef<HTMLUListElement>(null);

  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);

  const getSectionGroups = (website: WebsiteData) => {
    const partOfGroup: string[] = [];
    const sections = [];

    for (let i = 0; i < website.page.sections.length; i++) {
      const section = website.page.sections[i];

      const groupSections = website.page.sections.filter((groupSection) => groupSection.name.startsWith(section.name));

      if (groupSections.length > 1) {
        sections.push(
          groupSections
            /**
             * Make sure the first section name which group other sections with startName is the first index.
             */
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );

        for (let i = 0; i < groupSections.length; i++) {
          partOfGroup.push(groupSections[i].name);
        }
      } else {
        sections.push(section);
      }
    }

    return sections.filter((section) =>
      !Array.isArray(section) ? !partOfGroup.includes(section.name) : Boolean(section)
    );
  };

  const sectionResults = getSectionGroups(website);

  const onMouseMove = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    const { currentTarget, buttons, clientY } = e;
    if (buttons === 1) {
      if (currentTarget instanceof HTMLUListElement) {
        const currentTargetRect = currentTarget.getBoundingClientRect();
        // const liElements = currentTarget.querySelectorAll<HTMLLIElement>('li:not([data-clone="true"])');
        // const firstItem = liElements[0];
        // const firstItemRect = firstItem.getBoundingClientRect();
        // const lastItem = liElements[liElements.length - 1];
        // const lastItemRect = lastItem.getBoundingClientRect();
        // const dragging = currentTarget.querySelector<HTMLLIElement>('li[data-dragging="true"]');
        const cloneDragging = currentTarget.querySelector<HTMLLIElement>('li[data-clone="true"]');

        if (cloneDragging) {
          const cloneDraggingRect = cloneDragging.getBoundingClientRect();
          const dragCentered = cloneDraggingRect.height / 2;
          const top = Math.abs(currentTargetRect.top - clientY) - dragCentered;
          cloneDragging.style.top = `${top}px`;
        }
      }
    }
  };

  /**
   * @description add listener if user moves outside ul and stops pressing mouse buttons 1 (left)
   */
  const onWindowMouseUpCleanup = () => {
    const { current } = ulRef;

    if (current) {
      const draggingClone = current.querySelector<HTMLLIElement>('li[data-clone="true"]');
      if (draggingClone) {
        current.removeChild(draggingClone);
      }
      const dragging = current.querySelector<HTMLLIElement>('li[data-dragging="true"]');
      if (dragging) {
        dragging.dataset["dragging"] = "false";
      }
    }

    setState((state) => ({
      ...state,
      droppable: false
    }));
  };

  const onGrabSection = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const { currentTarget, clientY } = e;
    const { current } = ulRef;

    if (currentTarget instanceof HTMLLIElement && current) {
      const moveToIndexPosition = parseInt(currentTarget.dataset["index"] || "");
      setState((state) => ({
        ...state,
        droppable: true,
        draggingIndex: typeof moveToIndexPosition === "number" && !isNaN(moveToIndexPosition) ? moveToIndexPosition : -1
      }));
      const currentTargetRect = current.getBoundingClientRect();
      const dragging = current.querySelector<HTMLLIElement>(`li[data-index="${currentTarget.dataset["index"]}"]`);

      if (dragging) {
        /**
         * Close dropdown group of section
         */
        const isGroup = dragging.getAttribute("data-section-group");
        if (isGroup === "true") {
          dragging.setAttribute("data-section-dropped", "false");
        }

        dragging.dataset["dragging"] = "true";
        const cloneDragging = dragging.cloneNode(true) as HTMLLIElement;
        if (cloneDragging instanceof HTMLLIElement) {
          cloneDragging.dataset["dragging"] = "false";
          cloneDragging.dataset["clone"] = "true";
          const cloneDraggingRect = cloneDragging.getBoundingClientRect();
          const dragCentered = cloneDraggingRect.height / 2;
          const top = Math.abs(currentTargetRect.top - clientY) - dragCentered;
          cloneDragging.style.top = `${top}px`;

          current.appendChild(cloneDragging);
        }
      }
    }
  };

  const onDrop = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const { currentTarget } = e;
    if (currentTarget instanceof HTMLLIElement) {
      // index dropped at
      const moveToIndexPosition = parseInt(currentTarget.dataset["index"] || "");
      if (typeof moveToIndexPosition === "number" && !isNaN(moveToIndexPosition)) {
        const sectionResultTo = sectionResults[moveToIndexPosition];

        const sectionResultFrom = sectionResults[state.draggingIndex];

        if (sectionResultTo && sectionResultFrom) {
          // section index to move to.
          dispatch(
            theme.section.actions.move.request({
              from: Array.isArray(sectionResultFrom)
                ? sectionResultFrom.map((item) => getSectionIndexByName(website.page.sections, item.name))
                : [getSectionIndexByName(website.page.sections, sectionResultFrom.name)],
              to: Array.isArray(sectionResultTo)
                ? sectionResultTo.map((item) => getSectionIndexByName(website.page.sections, item.name))
                : [getSectionIndexByName(website.page.sections, sectionResultTo.name)]
            })
          );

          onWindowMouseUpCleanup();
          setState((state) => ({
            ...state,
            droppable: false,
            draggingIndex: -1
          }));

          currentTarget.setAttribute("data-dragging", "false");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", onWindowMouseUpCleanup, true);

    // if user move mouse on top of iframe.
    const interval = setInterval(() => {
      const { iframeWebsite } = DOMGetIframeWebsite();
      if (!iframeWebsite) {
        return;
      }
      if (state.droppable === true) {
        iframeWebsite.dataset["moving_section"] = "true";
      } else {
        iframeWebsite.dataset["moving_section"] = "false";
      }
    });

    return () => {
      clearInterval(interval);
      window.removeEventListener("mouseup", onWindowMouseUpCleanup, true);
    };
  }, [state.droppable, state.draggingIndex]);

  // const [sectionOrder, setSectionOrder] = useState(website.page.sections.map((item) => item.name));
  // useEffect(() => {
  //   const currentOrder = website.page.sections.map((item) => item.name);
  //   if (sectionOrder !== currentOrder) {
  //     console.log(sectionOrder);
  //     dispatch(
  //       websiteChangeState({
  //         website: {
  //           ...website,
  //           page: {
  //             ...website.page,
  //             sections: sectionResults.flatMap((item) => item)
  //           }
  //         }
  //       })
  //     );
  //   } else {
  //     setSectionOrder(currentOrder);
  //   }
  // }, [sectionOrder]);

  return (
    <div className={`${styles.sections}`}>
      <ul onMouseMove={onMouseMove} ref={ulRef} data-droppable={state.droppable}>
        {sectionResults.map((sectionResult, sectionResultIndex) => {
          if (Array.isArray(sectionResult)) {
            /**
             * First section it's the section name which construct all the other section
             * using the start name.
             */
            const groupName = sectionResult[0].name.replace(/(-|_)/g, " ");

            return (
              <li
                key={sectionResultIndex}
                data-section-group={true}
                data-section-dropped={false}
                data-index={sectionResultIndex}
                onMouseDown={(e) => onGrabSection(e)}
                onMouseUp={onDrop}
                className={`${styles.option}`}>
                <span>
                  <span className="material-symbols-outlined size-24 weight-400">drag_handle</span>
                  {groupName}
                </span>

                <button
                  onMouseUp={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    const liEl = e.currentTarget.parentElement;

                    if (liEl) {
                      const currentDropped = liEl.getAttribute("data-section-dropped");
                      liEl.setAttribute("data-section-dropped", currentDropped === "true" ? "false" : "true");
                    }
                  }}>
                  <span className={`${styles.arrow__icon} material-symbols-outlined size-24 weight-200`}>
                    arrow_drop_up
                  </span>
                </button>

                {/* List of available sections in the group */}
                <ul>
                  {sectionResult.map((section, sectionIndex) => {
                    const sectionName = section.name.replace(/(-|_)/g, " ");

                    return (
                      <li key={sectionIndex} className={`${styles.option}`}>
                        <span>{sectionName === groupName ? "default" : sectionName}</span>{" "}
                        <button
                          // data-title-position="bottom-left"
                          // data-title={section.default === true ? "Default sections cannot \n be removed." : undefined}
                          // className={`${section.default === true ? styles.default : undefined}`}
                          onMouseUp={(e) => {
                            e.stopPropagation();
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            /**
                             * Make all section of the same group invisible and
                             * toggle visible/invisible the selected one.
                             */
                            dispatch(
                              theme.section.actions.toggle.request({
                                pageId: website.page.id,
                                sections: sectionResult.map((item) => {
                                  if (item.name === section.name) {
                                    return {
                                      sectionName: item.name,
                                      action: section.selected ? "disable" : "enable"
                                    };
                                  } else {
                                    return {
                                      sectionName: item.name,
                                      action: "disable"
                                    };
                                  }
                                })
                              })
                            );
                          }}>
                          {
                            // section.default === true ? (
                            //   <span className="material-symbols-outlined size-24 weight-200">lock</span>
                            // ) :
                            section.selected === true ? (
                              <span className="material-symbols-outlined size-24 weight-200">visibility</span>
                            ) : (
                              <span className="material-symbols-outlined size-24 weight-200">visibility_off</span>
                            )
                          }
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          } else {
            return (
              <li
                key={sectionResultIndex}
                data-index={sectionResultIndex}
                onMouseDown={(e) => onGrabSection(e)}
                onMouseUp={onDrop}>
                <span>
                  <span className="material-symbols-outlined size-24 weight-400">drag_handle</span>
                  {sectionResult.name.replace(/(-|_)/g, " ")}
                </span>{" "}
                <button
                  // data-title-position="bottom-left"
                  // data-title={sectionResult.default === true ? "Default sections cannot \n be removed." : undefined}
                  // className={`${sectionResult.default === true ? styles.default : undefined}`}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    dispatch(
                      theme.section.actions.toggle.request({
                        pageId: website.page.id,
                        sections: [
                          {
                            sectionName: sectionResult.name,
                            action: sectionResult.selected ? "disable" : "enable"
                          }
                        ]
                      })
                    );
                  }}>
                  {
                    // sectionResult.default === true ? (
                    //   <span className="material-symbols-outlined size-24 weight-200">lock</span>
                    // ) :
                    sectionResult.selected === true ? (
                      <span className="material-symbols-outlined size-24 weight-200">visibility</span>
                    ) : (
                      <span className="material-symbols-outlined size-24 weight-200">visibility_off</span>
                    )
                  }
                </button>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default ManageSectionsWindow;
