import { matchPath } from "react-router-dom";

export const checkOrganizationRoute = () => {
  const isOrganization = matchPath(window.location.pathname, {
    exact: true,
    path: "/:themeId/organization"
  });

  return Boolean(isOrganization?.isExact);
};
