import React from "react";
import styles from "./styles.module.scss";
import { Button } from "@soltivo/draw-a-line";
import FooterWindow from "./footer.window/footer.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface EditableFooterProps {
  dataClass: ModifyConfig["dataClass"];
  editable?: HTMLElement;
  iframeWebsite?: HTMLIFrameElement;
}

const EditableFooter: React.FC<React.PropsWithChildren<EditableFooterProps>> = ({
  editable,
  dataClass,
  iframeWebsite
}) => {
  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.key,
      event: e,
      Component: () => <FooterWindow />,
      title: "Footer manager",
      size: "big",
      acceptSizes: ["big"],
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_LOGO,
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SITEMAP,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_BUTTON,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_COPYRIGHT,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SM,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_CONTACTS,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_TEXT,
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  return (
    <div className={styles.component}>
      <div className={styles.actions}>
        <Button variant="elm-500" onClick={onOpenWindow}>
          Footer Manager
        </Button>
      </div>
    </div>
  );
};

export default EditableFooter;
