import { WizardState } from "redux/reducers/wizard/reducer";
const types = {
  WEBSITE_GET_DOMAIN_INFO_REQUEST: "WEBSITE_GET_DOMAIN_INFO_REQUEST",
  WEBSITE_GET_DOMAIN_INFO_SUCCESS: "WEBSITE_GET_DOMAIN_INFO_SUCCESS",
  WEBSITE_GET_DOMAIN_INFO_FAILURE: "WEBSITE_GET_DOMAIN_INFO_FAILURE"
};

const actions = {
  /**
   * @description get organization domain
   */
  get: {
    request: () => ({
      type: types.WEBSITE_GET_DOMAIN_INFO_REQUEST,
      payload: undefined
    }),
    success: (payload: Pick<WizardState, "domainInfo">) => ({
      type: types.WEBSITE_GET_DOMAIN_INFO_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_GET_DOMAIN_INFO_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
