import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { delay } from "redux-saga/effects";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import { ModelWebsite } from "helpers/models";
import actions from "redux/actions";

/**
 * @description update contact social media
 */
export default function* changeSocialMediaAttribute({
  payload
}: ReturnType<(typeof actions.theme)["contact"]["actions"]["changeSocialMediaAttribute"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any> | Promise<string>,
  void,
  any
> {
  try {
    yield delay(1000);
    const { id, name, value } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website
    });

    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    let _value = value;

    // social media validation
    if (name === "name") {
      if (typeof _value !== "string") {
        throw new Error("Social media name must be typeof string.");
      } else if (!_value) {
        throw new Error("Social media name cannot be empty.");
      }
    } else if (name === "href") {
      if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(String(_value))) {
        throw new Error("Social media link is invalid.");
      }
    } else if (name === "svgHTML") {
      if (!(_value instanceof File)) {
        throw new Error("Social media svg file is required.");
      } else if (_value.type !== "image/svg+xml") {
        throw new Error("Social media file type must be svg.");
      }

      // Get svg as string.
      const textHTML = yield _value.text();

      const div = document.createElement("div");
      div.innerHTML = textHTML;
      const svg = div.querySelector("svg");

      if (!svg) {
        throw new Error("Social media file type must be svg.");
      }

      svg.setAttribute("width", "1em");
      svg.setAttribute("height", "1em");
      svg.setAttribute("fill", "currentColor");

      _value = div.innerHTML;
    } else {
      // do nothing if someone tries to update another field.
      return;
    }

    modelWebsite.update(modelWebsite, {
      contact: {
        ...modelWebsite.contact,
        socialMedia: modelWebsite.contact.socialMedia.map((sm) => {
          if (sm.id === id) {
            sm = {
              ...sm,
              [name]: _value
            };
          }
          return sm;
        })
      }
    });

    yield put(
      actions.theme.contact.actions.changeSocialMediaAttribute.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      actions.theme.contact.actions.changeSocialMediaAttribute.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
