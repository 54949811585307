import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_CHANGE_THEME_COLOR_REQUEST: "WEBSITE_CHANGE_THEME_COLOR_REQUEST",
  WEBSITE_CHANGE_THEME_COLOR_SUCCESS: "WEBSITE_CHANGE_THEME_COLOR_SUCCESS",
  WEBSITE_CHANGE_THEME_COLOR_FAILURE: "WEBSITE_CHANGE_THEME_COLOR_FAILURE",

  WEBSITE_SAVE_PREVIOUS_COLOR_REQUEST: "WEBSITE_SAVE_PREVIOUS_COLOR_REQUEST"
};

const actions = {
  /**
   * @description change theme colors.
   */
  update: {
    request: (payload: { color: string; inputName: string }) => ({
      type: types.WEBSITE_CHANGE_THEME_COLOR_REQUEST,
      payload
    }),
    success: (payload: Pick<WizardState, "website">) => ({
      type: types.WEBSITE_CHANGE_THEME_COLOR_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_CHANGE_THEME_COLOR_FAILURE,
      payload
    })
  },
  /**
   * @descrition update prevColors list
   */
  updatePrevColors: {
    request: (payload: Pick<WizardState, "prevColors">) => ({
      type: types.WEBSITE_SAVE_PREVIOUS_COLOR_REQUEST,
      payload
    })
  }
};

export default {
  types,
  actions
};
