import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_OPEN_PAGE_REQUEST: "WEBSITE_REDIRECT_PAGE_REQUEST",
  WEBSITE_OPEN_PAGE_SUCCESS: "WEBSITE_REDIRECT_PAGE_SUCCESS",
  WEBSITE_OPEN_PAGE_FAILURE: "WEBSITE_REDIRECT_PAGE_FAILURE",

  WEBSITE_TOGGLE_PAGE_REQUEST: "WEBSITE_TOGGLE_PAGE_REQUEST",
  WEBSITE_TOGGLE_PAGE_SUCCESS: "WEBSITE_TOGGLE_PAGE_SUCCESS",
  WEBSITE_TOGGLE_PAGE_FAILURE: "WEBSITE_TOGGLE_PAGE_FAILURE",

  WEBSITE_UPDATE_PAGE_SEO_REQUEST: "WEBSITE_UPDATE_PAGE_SEO_REQUEST",
  WEBSITE_UPDATE_PAGE_SEO_SUCCESS: "WEBSITE_UPDATE_PAGE_SEO_SUCCESS",
  WEBSITE_UPDATE_PAGE_SEO_FAILURE: "WEBSITE_UPDATE_PAGE_SEO_FAILURE"
};

const actions = {
  /**
   * @description open page specific page in the builder view.
   */
  open: {
    request: (payload: { pageId: WebsitePage["id"] }) => ({
      type: types.WEBSITE_OPEN_PAGE_REQUEST,
      payload
    }),
    success: (payload: { pageId: WizardState["pageId"]; website: WizardState["website"] }) => ({
      type: types.WEBSITE_OPEN_PAGE_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_OPEN_PAGE_FAILURE,
      payload
    })
  },
  /**
   * @descrition switch on/off (selected => true/false) page by name.
   */
  toggle: {
    request: (payload: { pageName: WebsitePage["name"]; action: "enable" | "disable" }) => ({
      type: types.WEBSITE_TOGGLE_PAGE_REQUEST,
      payload
    }),
    success: (payload: { pages: WizardState["pages"]; website: WizardState["website"] }) => ({
      type: types.WEBSITE_TOGGLE_PAGE_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_TOGGLE_PAGE_FAILURE,
      payload
    })
  },
  /**
   * @description update seo attributes
   */
  updateSEO: {
    request: (payload: {
      seo: {
        title?: string;
        description?: string;
        thumbnail?: string | File;
      };
    }) => ({
      type: types.WEBSITE_UPDATE_PAGE_SEO_REQUEST,
      payload
    }),
    success: (payload: { pages: WizardState["pages"]; website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_PAGE_SEO_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_PAGE_SEO_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
