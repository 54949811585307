import { FC } from "react";
import MenuLink from "components/menu.link/menu.link";
import styles from "./styles.module.scss";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import FormWindowView from "./views";

export interface FormWindowProps extends EditableWindowProps<"form"> {}

const FormWindow: FC<React.PropsWithChildren<FormWindowProps>> = (props) => {
  const { windowMenuState, onMoveToPage } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((item) => item.id === WINDOW_MENU_KEYS.EDITABLE_FORM.key);
  const currentPage = windowMenu?.pagination.pages.find((page) => page.visible === true);

  if (!currentPage || !windowMenu) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <div className={styles.sidebar}>
        <ul>
          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_INPUTS
                });
              }}
              active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_INPUTS}>
              Inputs
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_BUTTONS
                });
              }}
              active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_BUTTONS}>
              Buttons (CTA)
            </MenuLink>
          </li>
        </ul>
      </div>

      <div className={styles.content}>
        {(() => {
          switch (currentPage.id) {
            case WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_INPUTS:
              return <FormWindowView.Inputs {...props} />;

            case WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_BUTTONS:
              return <FormWindowView.Buttons {...props} />;

            default:
              return <></>;
          }
        })()}
      </div>
    </div>
  );
};

export default FormWindow;
