import { Column, Row } from "@soltivo/draw-a-line";
import { FC } from "react";
import LogosComponent from "blocks/logos/logos";
import ContentHeader from "components/content.header/content.header";
import styles from "./styles.module.scss";

interface LogosProps {}

const Logos: FC<React.PropsWithChildren<LogosProps>> = () => {
  return (
    <Row className={styles.component}>
      <Column size={"12"}>
        <ContentHeader title="Logos"></ContentHeader>
      </Column>
      <Column size={"12"}>
        <LogosComponent />
      </Column>
    </Row>
  );
};

export default Logos;
