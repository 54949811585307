import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import Customization from "./views/customization/customization";
import FlowEnd from "./views/flow.end/flow.end";
import InformationOne from "./views/information.one/information.one";
import InformationTwo from "./views/information.two/information.two";
import Welcome from "./views/welcome/welcome";

const Flow = () => {
  const { windowMenuState } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((item) => item.id === WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key);
  const currentPage = windowMenu?.pagination.pages.find((page) => page.visible === true);

  switch (currentPage?.id) {
    case WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_WELCOME: {
      return <Welcome />;
    }
    case WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_1: {
      return <InformationOne />;
    }
    case WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_2: {
      return <InformationTwo />;
    }
    case WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_CUSTOMIZATION: {
      return <Customization />;
    }
    case WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_END: {
      return <FlowEnd />;
    }
    default:
      return <></>;
  }
};

export default Flow;
