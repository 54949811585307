import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_MENU_REQUEST: "WEBSITE_UPDATE_MENU_REQUEST",
  WEBSITE_UPDATE_MENU_SUCCESS: "WEBSITE_UPDATE_MENU_SUCCESS",
  WEBSITE_UPDATE_MENU_FAILURE: "WEBSITE_UPDATE_MENU_FAILURE"
};

const actions = {
  /**
   * @description update root menu object
   */
  update: {
    request: (payload: { menu: Partial<WebsiteMenu["data"]> }) => ({
      type: types.WEBSITE_UPDATE_MENU_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_MENU_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_MENU_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
