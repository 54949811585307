import { useEffect } from "react";

/**
 * @description sets scrollbar for mobile device.
 */
const useDeviceScrollbar = (
  isThemeReadyToLoad: boolean,
  device: Device,
  _themeURL: string,
  iframeIsLoaded: boolean,
  iframeWebsite: HTMLIFrameElement | undefined
) => {
  const css =
    device.type === "mobile"
      ? `
      html {
        scrollbar-width: thin;
      }
      
      html ::-webkit-scrollbar {
        width: 5px;
      }
      
      html ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #eee;
        border-radius: 0px;
      }
      
      html ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 0px;
      }
      
      html ::-webkit-scrollbar-thumb:hover {
        background: #aaa;
      }`
      : `
      html ::-webkit-scrollbar {
        width: 10px;
      }
      html ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #eee;
        border-radius: 10px;
      }
      html ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 10px;
      }
      html ::-webkit-scrollbar-thumb:hover {
        background: #aaa;
      }
      `;

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isThemeReadyToLoad) {
      if (iframeIsLoaded) {
        interval = setInterval(() => {
          const iframeWindow = iframeWebsite?.contentWindow;
          if (iframeWindow) {
            const head = iframeWindow.document.head;
            const style = document.createElement("style");
            style.innerHTML = css;
            style.setAttribute("rel", "stylesheet");
            style.setAttribute("id", "BUILDER_DEVICE_SCROLL");
            head.appendChild(style);
            if (interval) {
              clearInterval(interval);
            }
          }
        });
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [device, _themeURL, iframeIsLoaded]);

  return {
    css
  };
};

export default useDeviceScrollbar;
