import apiV2 from "./api.v2";
const API_URL = `${process.env.REACT_APP_API_URL}/builder`;
const API_BOOKING = `${process.env.REACT_APP_API_URL}/public-bookings`;
const API_SERVICES = `${process.env.REACT_APP_API_URL}/services`;
const API_BUILDER = `${process.env.REACT_APP_BUILDER_API}`;
const API_DOMAIN = `${process.env.REACT_APP_API_URL}/domain`;

export class WebsiteClass {
  /**
   * @description get information about specific theme
   * - latestVersion
   * - description
   * - name
   * - industry
   */
  public async getThemeById(payload: { themeId: string }) {
    return apiV2.getRequest(`${API_URL}/themes/${payload.themeId}`);
  }

  /**
   * @description get theme public json.
   */
  public async websiteGetJson(payload: {
    themeId: string;
    fileName: "validation.website.json" | "global.website.json" | "config.website.json" | "pages.website.json";
    version: string;
  }) {
    try {
      const url = `${
        process.env.NODE_ENV === "development" ? process.env.PUBLIC_URL : process.env.REACT_APP_WEBSITE_CDN
      }/themes/${payload.themeId}/${payload.version}/assets/data/${payload.fileName}`;

      const response = await fetch(url, {
        method: "GET"
      });
      return await response.json();
    } catch (error) {
      if (error instanceof Error && error.name === "SyntaxError") {
        throw new Error("Theme data was not found.");
      } else {
        throw error;
      }
    }
  }

  /**
   * @description get domain info
   */
  public websiteGetDomainInfo() {
    return apiV2.getRequest(`${API_DOMAIN}`);
  }

  /**
   * @description get website global data. or global.website.json
   */
  public async websiteGetGlobal(payload: { orgId: string }) {
    const textResponse = await apiV2.getRequest(`${API_BUILDER}/builder/global?id=${payload.orgId}`, {
      useToken: false,
      useOrg: false,
      useResponseType: "text"
    });
    return JSON.parse(textResponse);
  }

  /**
   * @description get website pages data. or pages.website.json
   */
  public async websiteGetPages(payload: { orgId: string }) {
    const textResponse = await apiV2.getRequest(`${API_BUILDER}/builder/pages?id=${payload.orgId}`, {
      useToken: false,
      useOrg: false,
      useResponseType: "text"
    });
    return JSON.parse(textResponse);
  }

  /**
   * @description get photos pagination.
   */
  public async websiteGetUnsplashPhotos({
    page,
    per_page
  }: {
    page: number;
    per_page: number;
  }): Promise<UnsplashPhotos[]> {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`https://api.unsplash.com/photos?page=${page}&per_page=${per_page}`, {
        headers: {
          Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
        }
      });

      if (response.ok && response.status === 200) {
        resolve(response.json());
      } else {
        reject(new Error("Failed to get photos from unsplash, try again later."));
      }
    });
  }

  /**
   * @description search photos pagination.
   */
  public async websiteSearchUnsplashPhotos({
    page,
    per_page,
    query
  }: {
    page: number;
    per_page: number;
    query: string;
  }): Promise<UnplashPhotosSearch> {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(
        `https://api.unsplash.com/search/photos?query=${query}&page=${page}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          }
        }
      );

      if (response.ok && response.status === 200) {
        resolve(response.json());
      } else {
        reject(new Error("Failed to search photos from unsplash, try again later."));
      }
    });
  }

  /**
   * @description upload image
   */
  public websiteUploadFile(file: File) {
    return apiV2.getRequest(`${API_URL}/themes/policy?contentType=${file.type}&cacheControl=max-age=300,public`);
  }

  /**
   * @description publish website
   */
  public websitePublish({ global, pages }: { global: WebsiteData; pages: WebsitePage[] }) {
    return apiV2.putRequest(`${API_URL}/themes`, {
      data: JSON.stringify({
        global,
        pages
      }),
      useToken: "accessToken",
      useOrg: true
    });
  }

  /**
   * @description get all service categories
   */
  public websiteGetAllServiceCategories() {
    return apiV2.getRequest(`${API_SERVICES}/categories`);
  }

  /**
   * @description get all services
   */
  public websiteGetAllServices() {
    return apiV2.getRequest(`${API_SERVICES}`);
  }

  /**
   * @description get PUBLIC booking categories
   */
  public websiteGetBookingCategories() {
    return apiV2.getRequest(`${API_BOOKING}/categories`);
  }

  /**
   * @description get services from a specific category
   */
  public websiteGetBookingCategoryServices(payload: { categoryId: string }) {
    return apiV2.getRequest(`${API_BOOKING}/services/category/${payload.categoryId}`);
  }

  /**
   * @description update category of services.
   */
  public websiteUpdateBookingCategory({
    categoryId,
    ...restPayload
  }: {
    categoryId: string;
    name: string;
    description: string;
  }) {
    return apiV2.putRequest(`${process.env.REACT_APP_API_URL}/services/categories/${categoryId}`, {
      data: JSON.stringify(restPayload),
      useToken: "accessToken"
    });
  }

  /**
   * @description upload image before create service.
   */
  public websiteUploadServiceImage(payload: { file: File }) {
    return apiV2.getRequest(
      `${process.env.REACT_APP_API_URL}/services/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`,
      {
        useToken: "accessToken"
      }
    );
  }

  /**
   * @description update a service
   */
  public websiteUpdateService(payload: {
    serviceId: string;
    service: {
      title: string;
      // currency: string;
      // color: string;
      description: string;
      price: number;
      category: string;
      image: string;
    };
  }) {
    const { serviceId, service } = payload;
    return apiV2.putRequest(`${process.env.REACT_APP_API_URL}/services/v2/${serviceId}`, {
      useToken: "accessToken",
      data: JSON.stringify(service),
      headers: {
        "content-type": "application/json"
      }
    });
  }
}

export default new WebsiteClass();
