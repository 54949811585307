import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import uniqBy from "lodash.uniqby";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";

/**
 * @description undo or redo website object.
 */
export default function* recovery({
  payload
}: ReturnType<(typeof theme)["data"]["actions"]["recovery"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const { action } = payload;
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      editor: WebsiteWizardReducer.editor,
      undos: cloneDeep(WebsiteWizardReducer.undos),
      website: cloneDeep(WebsiteWizardReducer.website),
      pages: cloneDeep(WebsiteWizardReducer.pages)
    });

    const { editor, undos, website, pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);

    if (!editor) return;

    let websiteRecal = null;

    if (action.toLowerCase() === "undo" && undos.prev.length) {
      websiteRecal = undos.prev.pop();
      undos.next = uniqBy([...undos.next, { website: modelWebsite.object, pages }], (data) => {
        return JSON.stringify(data);
      });
    } else if (action.toLowerCase() === "redo" && undos.next.length) {
      websiteRecal = undos.next.pop();
      undos.prev = uniqBy([...undos.prev, { website: modelWebsite.object, pages }], (data) => {
        return JSON.stringify(data);
      });
    }

    if (websiteRecal) {
      yield put(
        theme.data.actions.recovery.success({
          website: websiteRecal.website,
          pages: websiteRecal.pages,
          undos: undos
        })
      );
    }
  } catch (error) {
    yield put(
      theme.data.actions.recovery.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
