import { InputText } from "@soltivo/draw-a-line";
import { FC, useState } from "react";
import ContentHeader from "components/content.header/content.header";
import styles from "./styles.module.scss";
import { FormWindowProps } from "../../form.window";
import useEditableData from "helpers/hooks/useEditableData";

type ButtonsViewProps = FormWindowProps;

const ButtonsView: FC<React.PropsWithChildren<ButtonsViewProps>> = (props) => {
  const editable = useEditableData({
    dataClass: props.dataClass,
    dataPath: props.dataPath
  });

  const INITIAL_STATE = {
    buttonName: ""
  };

  const [state, setState] = useState(INITIAL_STATE);

  // /**
  //  * @description create a new input
  //  */
  // const onCreateInput = (payload: { value: string }) => {};

  /**
   * @description on change button values.
   */
  const onChangeButton = (payload: { name: string; value: string | boolean }) => {
    if (!props.dataClass || !props.dataPath || !editable.data) {
      return;
    }

    const editableData = {
      ...editable.data,
      buttons: {
        ...editable.data.buttons,
        [state.buttonName]: {
          ...editable.data.buttons[state.buttonName],
          [payload.name]: payload.value
        }
      }
    } as WebsiteForm;

    editable.onUpdate(editableData);
  };

  /**
   * @description current open button.
   */
  const currentButton = state.buttonName !== "" ? editable.data?.buttons[state.buttonName] : undefined;

  if (!editable.data) {
    return null;
  }
  return (
    <div className={styles.component}>
      <ContentHeader
        title={currentButton ? `${currentButton.value}` : "Buttons"}
        onBack={
          currentButton
            ? // when update currentButton opened
              () => {
                setState(INITIAL_STATE);
              }
            : undefined
        }></ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div style={{ display: "grid", gridColumn: "1/3" }}>
          {
            // change input
            currentButton ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 20
                }}>
                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="value">Text</label>
                  <InputText
                    id={"value"}
                    name="value"
                    onChange={(e) => {
                      onChangeButton({
                        name: e.target.name,
                        value: e.target.value
                      });
                    }}
                    value={currentButton.value}
                    placeholder="Text content"
                  />
                </div>
              </div>
            ) : (
              // list of inputs
              <ul className={styles.links}>
                {Object.keys(editable.data.buttons).map((buttonKey, index) => {
                  if (!editable.data) {
                    return null;
                  }
                  const button = editable.data.buttons[buttonKey];
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setState((state) => ({
                          ...state,
                          buttonName: buttonKey
                        }));
                      }}>
                      {button.value || "No value"}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 14L12 10L8 6L8 14Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                  );
                })}
              </ul>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ButtonsView;
