const environment = process.env?.REACT_APP_ENVIRONMENT?.toLowerCase() || "";
const https = process.env.REACT_APP_HTTPS;
const authConfig = {
  Auth: {
    //identityPoolId: ,
    region: process.env.REACT_APP_USER_POOL_REGION,
    madatorySignIn: true,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "mysoltivo.local",
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "strict",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: environment === "development" ? (https ? true : false) : true
    }
  }
};

export default authConfig;
