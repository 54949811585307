export const WINDOW_MENU_KEYS = {
  CONFIGURATION_FLOW: {
    key: "CONFIGURATION_FLOW",
    pageKeys: {
      CONFIGURATION_FLOW_WELCOME: "CONFIGURATION_FLOW_WELCOME",
      CONFIGURATION_FLOW_INFORMATION_STEP_1: "CONFIGURATION_FLOW_INFORMATION_STEP_1",
      CONFIGURATION_FLOW_INFORMATION_STEP_2: "CONFIGURATION_FLOW_INFORMATION_STEP_2",
      CONFIGURATION_FLOW_CUSTOMIZATION: "CONFIGURATION_FLOW_CUSTOMIZATION",
      CONFIGURATION_FLOW_END: "CONFIGURATION_FLOW_END"
    }
  },
  CONFIGURATION_MENU: {
    key: "CONFIGURATION_MENU",
    pageKeys: {
      CONFIGURATION_MENU_BASICS: "CONFIGURATION_MENU_BASICS",
      CONFIGURATION_MENU_LOGOS: "CONFIGURATION_MENU_LOGOS",
      CONFIGURATION_MENU_COLORS: "CONFIGURATION_MENU_COLORS",
      CONFIGURATION_MENU_FONTS: "CONFIGURATION_MENU_FONTS",
      CONFIGURATION_MENU_CONTACT_INFO: "CONFIGURATION_MENU_CONTACT_INFO",
      CONFIGURATION_MENU_PLUGINS: "CONFIGURATION_MENU_PLUGINS"
    }
  },
  SOCIAL_MEDIA: {
    key: "SOCIAL_MEDIA",
    pageKeys: {}
  },
  META_PIXEL: {
    key: "META_PIXEL",
    pageKeys: {}
  },
  UNSPLASH: {
    key: "UNSPLASH",
    pageKeys: {}
  },
  COLOR_PICKER: {
    key: "COLOR_PICKER",
    pageKeys: {}
  },
  SAVE_INDEXEDDB_LOCALLY: {
    key: "SAVE_INDEXEDDB_LOCALLY",
    pageKeys: {}
  },
  MANAGE_SECTIONS: {
    key: "MANAGE_SECTIONS",
    pageKeys: {}
  },
  PAGE_SEO: {
    key: "PAGE_SEO",
    pageKeys: {
      PAGE_SEO_INPUTS: "PAGE_SEO_INPUTS",
      PAGE_SEO_UNSPLASH: "PAGE_SEO_UNSPLASH"
    }
  },
  EDITABLE_AUDIO: {
    key: "EDITABLE_AUDIO",
    pageKeys: {}
  },
  EDITABLE_BACKGROUND_IMAGE: {
    key: "EDITABLE_BACKGROUND_IMAGE",
    pageKeys: {}
  },
  EDITABLE_BACKGROUND_VIDEO: {
    key: "EDITABLE_BACKGROUND_VIDEO",
    pageKeys: {}
  },
  EDITABLE_BUTTON: {
    key: "EDITABLE_BUTTON",
    pageKeys: {}
  },
  EDITABLE_FORM: {
    key: "EDITABLE_FORM",
    pageKeys: {
      EDITABLE_FORM_INPUTS: "EDITABLE_FORM_INPUTS",
      EDITABLE_FORM_BUTTONS: "EDITABLE_FORM_BUTTONS"
    }
  },
  EDITABLE_FOOTER: {
    key: "EDITABLE_FOOTER",
    pageKeys: {
      EDITABLE_FOOTER_LOGO: "EDITABLE_FOOTER_LOGO",
      EDITABLE_FOOTER_SITEMAP: "EDITABLE_FOOTER_SITEMAP",
      EDITABLE_FOOTER_BUTTON: "EDITABLE_FOOTER_BUTTON",
      EDITABLE_FOOTER_COPYRIGHT: "EDITABLE_FOOTER_COPYRIGHT",
      EDITABLE_FOOTER_SM: "EDITABLE_FOOTER_SM",
      EDITABLE_FOOTER_CONTACTS: "EDITABLE_FOOTER_CONTACTS",
      EDITABLE_FOOTER_TEXT: "EDITABLE_FOOTER_TEXT"
    }
  },
  EDITABLE_HEADER: {
    key: "EDITABLE_HEADER",
    pageKeys: {
      EDITABLE_HEADER_LOGO: "EDITABLE_HEADER_LOGO",
      EDITABLE_HEADER_MENU: "EDITABLE_HEADER_MENU",
      EDITABLE_HEADER_BUTTON: "EDITABLE_HEADER_BUTTON"
    }
  },
  /**
   * BEST PAGE APPROACH.
   */
  EDITABLE_ICON: {
    key: "EDITABLE_ICON",
    pageKeys: {
      EDITABLE_ICON_INPUTS: "EDITABLE_ICON_INPUTS"
    }
  },
  EDITABLE_IMAGE: {
    key: "EDITABLE_IMAGE",
    pageKeys: {}
  },
  EDITABLE_LINK: {
    key: "EDITABLE_LINK",
    pageKeys: {}
  },
  EDITABLE_MAP: {
    key: "EDITABLE_MAP",
    pageKeys: {}
  },
  EDITABLE_OBJECT: {
    key: "EDITABLE_OBJECT",
    pageKeys: {}
  },
  EDITABLE_PLUGIN: {
    key: "EDITABLE_PLUGIN",
    pageKeys: {
      BOOKING_CATEGORIES: "BOOKING_CATEGORIES",
      BOOKING_CATEGORY: "BOOKING_CATEGORY",
      BOOKING_SERVICES: "BOOKING_SERVICES",
      BOOKING_SERVICE: "BOOKING_SERVICE"
    }
  },
  EDITABLE_VIDEO: {
    key: "EDITABLE_VIDEO",
    pageKeys: {}
  }
};
