import { Button, Column, FormControl, InputSwitch, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import InputImage from "components/input.image/input.image";
import { FC } from "react";
import Unsplash from "../../../../components/unsplash/unsplash";
import SelectLink from "components/select.link/select.link";
import SelectIcon from "components/select.icon/select.icon";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface ObjectWindowProps extends EditableWindowProps<"object"> {}

const ObjectWindow: FC<React.PropsWithChildren<ObjectWindowProps>> = ({ dataClass, dataPath }) => {
  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_OBJECT.key);

  if (!windowMenu || !data || !validation) {
    return null;
  }

  return (
    <Row>
      {Object.keys(data).map((key, index) => {
        if (key === "id") return null;

        /**
         * @description input information
         */
        const validateType = validation.attributes[key];
        // If no validation found we should not show an input for it.
        if (!validateType) return null;

        const value = data[key];

        switch (validateType.typeof) {
          case "string":
            return (
              <Column key={index} size={"12"}>
                {" "}
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <Textarea
                    name={key}
                    value={String(value)}
                    onChange={(e) => {
                      onUpdate({
                        ...data,
                        [key]: e.target.value
                      });
                    }}
                    placeholder={validateType.label}
                  />
                </FormControl>
              </Column>
            );
          case "phone-number":
            return (
              <Column key={index} size={"12"}>
                {" "}
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <InputText
                    type="text"
                    value={value as string}
                    onChange={(e) => {
                      onUpdate({
                        ...data,
                        [key]: e.target.value
                      });
                    }}
                    placeholder={validateType.label}
                  />
                </FormControl>
              </Column>
            );

          case "number":
            return (
              <Column key={index} size={"12"}>
                {" "}
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <InputText
                    name={key}
                    value={String(value)}
                    type="number"
                    onChange={(e) => {
                      onUpdate({
                        ...data,
                        [key]: e.target.value
                      });
                    }}
                    placeholder={validateType.label}
                  />
                </FormControl>
              </Column>
            );

          case "email":
            return (
              <Column key={index} size={"12"}>
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <InputText
                    name={key}
                    value={String(value)}
                    onChange={(e) => {
                      onUpdate({
                        ...data,
                        [key]: e.target.value
                      });
                    }}
                    type="email"
                    placeholder={validateType.label}
                  />
                </FormControl>
              </Column>
            );

          case "url":
            return (
              <Column key={index} size={"12"}>
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <SelectLink
                    onChange={({ name, value }) => {
                      onUpdate({
                        ...data,
                        [key]: value
                      });
                    }}
                    disabled={false}
                    url={String(value)}
                    name={key}
                  />
                </FormControl>
              </Column>
            );

          case "icon": {
            const parsedValue: WebsiteIcon | undefined = (() => {
              try {
                return typeof value === "string" ? JSON.parse(value) : undefined;
              } catch (e) {
                return undefined;
              }
            })();

            if (typeof parsedValue !== "object") {
              return null;
            }

            return (
              <Column key={index} size={"12"}>
                <Row>
                  <Column size={"10"}>
                    <FormControl
                      labelProps={{
                        value: validateType.label
                      }}>
                      <SelectIcon
                        onSelect={(payload) => {
                          const change = {
                            ...data,
                            [key]: JSON.stringify({
                              ...parsedValue,
                              name: payload.value.name
                            } as WebsiteIcon)
                          } as WebsiteObject;

                          onUpdate({
                            ...data,
                            ...change
                          });
                        }}
                        name={key}
                        value={parsedValue}
                      />
                    </FormControl>
                  </Column>

                  <Column size={"2"}>
                    <FormControl
                      labelProps={{
                        value: "Fill"
                      }}>
                      <div data-title={"Fill/Outlined"} data-title-position="bottom-left">
                        <Button
                          onClick={() => {
                            const change = {
                              ...data,
                              [key]: JSON.stringify({
                                ...parsedValue,
                                fill: parsedValue.fill === 1 ? 0 : 1
                              } as WebsiteIcon)
                            } as WebsiteObject;

                            onUpdate({
                              ...data,
                              ...change
                            });
                          }}
                          outline
                          variant="primary"
                          padding={false}
                          style={{
                            margin: "8px auto 0 auto",
                            fontVariationSettings: `'FILL' ${parsedValue.fill}`
                          }}
                          className={` material-symbols-${parsedValue.format || "outlined"}`}>
                          format_color_reset
                        </Button>
                      </div>
                    </FormControl>
                  </Column>
                </Row>
              </Column>
            );
          }
        }
      })}

      {Object.keys(data).map((key, index) => {
        if (key === "id") return null;

        /**
         * @description input information
         */
        const validateType = validation.attributes[key];
        // If no validation found we should not show an input for it.
        if (!validateType) return null;
        const value = data[key];

        switch (validateType.typeof) {
          case "boolean":
            return (
              <Column key={index} size={windowMenu.size === "medium" ? "4" : "6"}>
                {" "}
                <FormControl
                  labelProps={{
                    value: key
                  }}>
                  <div>
                    <InputSwitch
                      checked={value as boolean}
                      onChange={(e) => {
                        onUpdate({
                          ...data,
                          [key]: e.target.checked
                        });
                      }}
                      placeholder={key}
                    />
                  </div>
                </FormControl>
              </Column>
            );
        }
      })}

      {Object.keys(data).map((key, index) => {
        if (key === "id" || key === "label") return null;

        /**
         * @description input information
         */
        const validateType = validation.attributes[key];
        // If no validation found we should not show an input for it.
        if (!validateType) return null;

        const value = data[key];

        switch (validateType.typeof) {
          case "image":
            return (
              <Column key={index} size={"12"}>
                {" "}
                <FormControl
                  labelProps={{
                    value: validateType.label
                  }}>
                  <InputImage
                    name="src"
                    style={{
                      maxWidth: "100%",
                      height: windowMenu.size === "medium" ? "240px" : "140px",
                      maxHeight: windowMenu.size === "medium" ? "240px" : "140px"
                    }}
                    value={value as string | File}
                    onLibrary={(e) => {
                      onStartWindowMenu({
                        id: WINDOW_MENU_KEYS.UNSPLASH.key,
                        event: e,
                        size: "medium",
                        acceptSizes: ["medium"],
                        title: "Select an image",
                        Component: () => {
                          return (
                            <Unsplash
                              columns={2}
                              menu={true}
                              onSelectImage={(imageUrl) => {
                                onUpdate({
                                  ...data,
                                  [key]: imageUrl
                                });
                              }}
                            />
                          );
                        }
                      });
                    }}
                    accept={"image/png,image/jpeg,image/jpg,image/webp"}
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file instanceof File)
                        onUpdate({
                          ...data,
                          [key]: file
                        });
                    }}
                  />
                </FormControl>
              </Column>
            );
        }
      })}

      {Object.keys(data).map((key, index) => {
        // REST NOT WORKING OR BUTTONS.
        if (key === "id") return null;

        /**
         * @description input information
         */
        const validateType = validation.attributes[key];
        // If no validation found we should not show an input for it.
        if (!validateType) return null;
        // const value = data[key];

        switch (validateType.typeof) {
          // case "array":
          //     return (
          //         <Column key={index} size={"12"}>
          //             <FormControl
          //                 labelProps={{
          //                     value: validation.label,
          //                 }}>
          //                 <Button variant="primary" outline padding={false} border={false}>
          //                     {validation.label}
          //                 </Button>
          //             </FormControl>
          //         </Column>
          //     );

          // case "object":
          //     return (
          //         <Column key={index} size={"12"}>
          //             <FormControl
          //                 labelProps={{
          //                     value: validation.label,
          //                 }}>
          //                 <Button variant="primary" outline padding={false} border={false}>
          //                     {validation.label}
          //                 </Button>
          //             </FormControl>
          //         </Column>
          //     );
          // case "video":
          //     return null;

          // case "file":
          //     return null;

          default:
            return null;
        }
      })}
    </Row>
  );
};

export default ObjectWindow;
