import { WizardState } from "../../../reducers/wizard/reducer";

const types = {
  WEBSITE_CHANGE_STATE: "WEBSITE_CHANGE_STATE",
  WEBSITE_WIZARD_EDITOR_REQUEST: "WEBSITE_WIZARD_EDITOR_REQUEST"
};

const actions = {
  change: {
    /**
     * @description change global state.
     */
    request: (payload: Partial<WizardState>) => ({
      type: types.WEBSITE_CHANGE_STATE,
      payload
    })
  },
  /**
   * @description enable|disable website wizard editor.
   */
  toggleEditor: {
    request: (payload: { editor: boolean }) => ({
      type: types.WEBSITE_WIZARD_EDITOR_REQUEST,
      payload
    })
  }
};

export default {
  types,
  actions
};
