import sanitazeHTML from "sanitize-html";

import { decode } from "html-entities";

/**
 * @description sets the correct value for a editor type.
 */
export const setValueInDataClass = (dataClass: WebsiteDataClass, payload: any) => {
  try {
    if (dataClass === "text") {
      let newValue = sanitazeHTML(payload.value ? payload.value : "...", {
        allowedTags: [],
        allowedAttributes: undefined
      });
      newValue = decode(newValue) === "null" ? "..." : decode(newValue);

      return {
        ...payload,
        value: newValue
      };
    } else {
      return payload;
    }
  } catch (error) {
    console.error(error);
  }
};
