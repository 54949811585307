import { queryWindowMenuElementsById } from "./queryWindowMenuElementsById";
import { WindowMenuObject } from "../context";

export const fixContainerPosition = (windowMenu: WindowMenuObject) => {
  const maxTopPosition = 60;

  const windowMenuElements = queryWindowMenuElementsById(windowMenu.id);
  if (!windowMenuElements.container || !windowMenuElements.header) {
    return {
      fixContainer: false,
      left: `${0}px`,
      top: `${0}px`
    };
  }
  const containerRect = windowMenuElements.container.getBoundingClientRect();
  const headerRect = windowMenuElements.header.getBoundingClientRect();
  const headerHeight = headerRect.height;
  // window size.
  const bodyWidth = document.body.offsetWidth;
  const bodyHeight = document.body.offsetHeight;

  let mouseTop = containerRect.top;
  let mouseLeft = containerRect.left;
  // make sure it does not go outside the viewport window.
  mouseTop =
    containerRect.top < maxTopPosition
      ? maxTopPosition
      : containerRect.top > bodyHeight - headerHeight
      ? bodyHeight - headerHeight
      : containerRect.top;
  mouseLeft =
    containerRect.left < 0
      ? 0
      : containerRect.left + containerRect.width > bodyWidth
      ? bodyWidth - containerRect.width
      : containerRect.left;

  const updateCondition =
    containerRect.top < 0 ||
    containerRect.top > bodyHeight - headerHeight ||
    containerRect.left < 0 ||
    containerRect.left + containerRect.width > bodyWidth;

  return {
    updateCondition: updateCondition,
    left: `${mouseLeft}px`,
    top: `${mouseTop}px`
  };
};
