import React from "react";
import { AddImage } from "@soltivo/draw-a-line/core/components/icons";
import Loader from "@soltivo/draw-a-line/core/components/loading/loader/loader";
import styles from "./styles.module.scss";
import ImageBackgroundWindow from "./image.background.window/image.background.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface EditableBackgroundImageProps extends EditableProps<"background-image"> {}

const EditableBackgroundImage: React.FC<React.PropsWithChildren<EditableBackgroundImageProps>> = ({
  dataPath,
  dataClass
}) => {
  const editable = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_BACKGROUND_IMAGE.key,
      event: e,
      title: "Background",
      Component: () => <ImageBackgroundWindow dataPath={dataPath} dataClass={dataClass} />
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  const isBlob = /^(blob)/.test(editable.data.src);

  return (
    <div className={styles.component}>
      <div className={styles.actions}>
        <button onClick={onOpenWindow}>{isBlob ? <Loader size="md" variant="secondary" /> : <AddImage />}</button>
      </div>
    </div>
  );
};

export default EditableBackgroundImage;
