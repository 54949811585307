import { AddImage } from "@soltivo/draw-a-line/core/components/icons";
import React, { FC, useRef } from "react";
import styles from "./input.image.module.scss";

export interface InputImageProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value?: string | File;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLibrary?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  CustomIcon?: JSX.Element;
  className?: string;
  accept?: string;
}
const InputImage: FC<React.PropsWithChildren<InputImageProps>> = ({
  accept = "image/*",
  value,
  name,
  onChange,
  onLibrary,
  disabled,
  CustomIcon,
  className,
  style,
  ...rest
}) => {
  const imageRef = useRef<HTMLInputElement>(null);

  const defaultSize = {
    minWidth: style?.maxWidth || "260px",
    maxWidth: style?.minWidth || "140px",
    minHeight: style?.minHeight || "140px",
    maxHeight: style?.maxHeight || "140px"
  };
  return (
    <div
      {...rest}
      className={`${styles.input__image} ${!value ? styles.empty : ""} ${disabled ? styles.disabled : ""} ${
        className ? className : ""
      }`}
      tabIndex={1}
      style={{
        ...style,
        ...defaultSize
      }}>
      {value ? <img src={value instanceof File ? URL.createObjectURL(value) : value} alt="Upload a file" /> : null}

      <input accept={accept} type="file" name={name} onChange={disabled ? undefined : onChange} ref={imageRef} />

      <div className={styles.actions}>
        {onLibrary ? (
          <button
            onClick={(e) => {
              if (onLibrary && !disabled) onLibrary(e);
            }}>
            <AddImage /> Image from library
          </button>
        ) : null}

        {onChange ? (
          <button
            onKeyDown={(e) => {
              if (e.key === "Enter" && !disabled) {
                imageRef.current?.click();
              }
            }}
            onClick={() => {
              imageRef.current?.click();
            }}>
            Upload from your computer
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default InputImage;
