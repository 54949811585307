import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_GENERAL_REQUEST: "WEBSITE_UPDATE_GENERAL_REQUEST",
  WEBSITE_UPDATE_GENERAL_SUCCESS: "WEBSITE_UPDATE_GENERAL_SUCCESS",
  WEBSITE_UPDATE_GENERAL_FAILURE: "WEBSITE_UPDATE_GENERAL_FAILURE"
};

const actions = {
  /**
   * @description update root general object
   */
  update: {
    request: (
      payload: { value: unknown; attribute: string },
      options?: Partial<{
        delay: number;
      }>
    ) => ({
      type: types.WEBSITE_UPDATE_GENERAL_REQUEST,
      payload,
      options
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_GENERAL_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_GENERAL_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
