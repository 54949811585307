import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { RootState } from "redux/reducers";
import {
  Button,
  Color,
  Column,
  FormControl,
  InputPhone,
  InputSwitch,
  InputText,
  Row,
  Textarea
} from "@soltivo/draw-a-line";
import { useDispatch, useSelector } from "react-redux";
import InputImage from "components/input.image/input.image";
import { GLOBAL_VARS } from "helpers/global";
import Unsplash from "components/unsplash/unsplash";
import SelectIcon from "components/select.icon/select.icon";
import actions from "redux/actions";
import { formatPhoneNumber } from "helpers";

const General = () => {
  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard }: RootState) => ({
    websiteName: Wizard.website.name,
    generalValidation: Wizard.validation.general,
    general: Wizard.website.general
  });

  const { websiteName, generalValidation, general } = useSelector(mapStateToProps);

  /**
   * @description change inputs on general
   */
  const onChange = (name: string, value: unknown, delay: boolean) => {
    const validation = generalValidation.attributes[name];
    if (!validation) return;

    dispatch(
      actions.theme.general.actions.update.request(
        {
          attribute: name,
          value: value instanceof FileList ? value[0] : value
        },
        {
          delay: delay ? 1000 : undefined
        }
      )
    );
  };

  const openUnsplash = (e: React.MouseEvent<HTMLElement, MouseEvent>, name: string) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.UNSPLASH.key,
      event: e,
      size: "medium",
      title: "Select an image",
      acceptSizes: ["medium", "big"],
      Component: () => {
        const { onClose } = WindowMenu.useWindowMenu();

        return (
          <Unsplash
            style={{
              width: "100%",
              minHeight: "300px"
            }}
            columns={2}
            sidebar={false}
            menu={true}
            onSelectImage={(image) => {
              onChange(name, image, true);
              onClose(WINDOW_MENU_KEYS.UNSPLASH.key);
            }}
          />
        );
      }
    });
  };

  return (
    <Row>
      {/* Website name */}
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Website name"
          }}>
          <InputText
            onChange={(e) => {
              dispatch(
                actions.theme.data.actions.updateName.request({
                  name: e.target.value
                })
              );
            }}
            value={websiteName || ""}
            placeholder="Website name"
          />
        </FormControl>
      </Column>

      {/* Create all inputs by validation type */}
      {general
        ? Object.keys(general).map((key, index) => {
            if (key === "id" || key === "label") return null;
            /**
             * @description input information
             */
            const validation = generalValidation.attributes[key];

            // If no validation found we should not show an input for it.
            if (!validation) return null;

            const value = general[key];

            switch (validation.typeof) {
              case "string":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <Textarea
                        name={key}
                        defaultValue={String(value)}
                        onChange={(e) => {
                          onChange(key, e.target.value, true);
                        }}
                        placeholder={validation.label}
                      />
                    </FormControl>
                  </Column>
                );
              case "phone-number":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <InputPhone
                        styling="default"
                        autoFormat
                        inputStyle={{
                          width: "100%"
                        }}
                        inputProps={{
                          name: key
                        }}
                        value={String(value)}
                        onChange={(value) => {
                          value = formatPhoneNumber(value);

                          onChange(key, value, true);
                        }}
                        placeholder={validation.label}
                      />
                    </FormControl>
                  </Column>
                );

              case "email":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <InputText
                        name={key}
                        defaultValue={String(value)}
                        onChange={(e) => {
                          onChange(key, e.target.value, true);
                        }}
                        type="email"
                        placeholder={validation.label}
                      />
                    </FormControl>
                  </Column>
                );

              case "url":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <InputText
                        name={key}
                        defaultValue={String(value)}
                        onDoubleClick={() => {
                          if (GLOBAL_VARS.validaton.regex.httpsUrl.test(String(value))) {
                            window.open(String(value), "_blank");
                          }
                        }}
                        onClick={(e) => {
                          e.currentTarget.select();
                        }}
                        style={{
                          color: Color["bluemine"]["bluemine-500"],
                          textDecoration: "underline"
                        }}
                        onChange={(e) => {
                          onChange(key, e.target.value, true);
                        }}
                        type="url"
                        placeholder={validation.label}
                      />
                    </FormControl>
                  </Column>
                );

              case "icon": {
                const parsedValue: WebsiteIcon | undefined = (() => {
                  try {
                    return typeof value === "string" ? JSON.parse(value) : undefined;
                  } catch (e) {
                    return undefined;
                  }
                })();

                if (typeof parsedValue !== "object") {
                  return null;
                }

                return (
                  <Column key={index} size={"12"}>
                    <Row>
                      <Column size={"10"}>
                        <FormControl
                          labelProps={{
                            value: validation.label
                          }}>
                          <SelectIcon
                            onSelect={(payload) => {
                              onChange(
                                key,
                                JSON.stringify({
                                  ...parsedValue,
                                  name: payload.value.name
                                } as WebsiteIcon),
                                false
                              );
                            }}
                            name={key}
                            value={parsedValue}
                          />
                        </FormControl>
                      </Column>

                      <Column size={"2"}>
                        <FormControl
                          labelProps={{
                            value: "Fill"
                          }}>
                          <div data-title={"Fill/Outlined"} data-title-position="bottom-left">
                            <Button
                              onClick={() => {
                                onChange(
                                  key,
                                  JSON.stringify({
                                    ...parsedValue,
                                    fill: parsedValue.fill === 1 ? 0 : 1
                                  } as WebsiteIcon),
                                  false
                                );
                              }}
                              outline
                              variant="primary"
                              padding={false}
                              style={{
                                margin: "8px auto 0 auto",
                                fontVariationSettings: `'FILL' ${parsedValue.fill}`
                              }}
                              className={` material-symbols-${parsedValue.format || "outlined"}`}>
                              format_color_reset
                            </Button>
                          </div>
                        </FormControl>
                      </Column>
                    </Row>
                  </Column>
                );
              }
            }
          })
        : null}

      {general
        ? Object.keys(general).map((key, index) => {
            if (key === "id" || key === "label") return null;
            /**
             *  input information
             */
            const validation = generalValidation.attributes[key];
            // If no validation found we should not show an input for it.
            if (!validation) return null;

            const value = general[key];

            switch (validation.typeof) {
              case "boolean":
                return (
                  <Column key={index} size={"6"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <div>
                        <InputSwitch
                          checked={value as boolean}
                          onChange={(e) => {
                            onChange(key, e.target.checked, false);
                          }}
                          placeholder={validation.label}
                        />
                      </div>
                    </FormControl>
                  </Column>
                );
            }
          })
        : null}

      {general
        ? Object.keys(general).map((key, index) => {
            if (key === "id" || key === "label") return null;
            /**
             * @description input information
             */
            const validation = generalValidation.attributes[key];
            // If no validation found we should not show an input for it.
            if (!validation) return null;

            const value = general[key];
            switch (validation.typeof) {
              case "image":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: validation.label
                      }}>
                      <InputImage
                        name={key}
                        style={{
                          maxWidth: "100%",
                          height: 100,
                          maxHeight: 100
                          // height: windowMenuState.size === "medium" ? "240px" : "140px",
                          // maxHeight: windowMenuState.size === "medium" ? "240px" : "140px",
                        }}
                        value={value as string | File}
                        onLibrary={(e) => {
                          openUnsplash(e, key);
                        }}
                        onChange={(e) => {
                          onChange(key, e.target.files, false);
                        }}
                      />
                    </FormControl>
                  </Column>
                );
            }
          })
        : null}
    </Row>
  );
};

export default General;
