import React from "react";
import { resize } from "./functions/resize";

export type WindowMenuObject = {
  id: string;
  zIndex: number;
  show: boolean;
  moving: boolean;
  Component: () => JSX.Element;
  to: Record<"top" | "left", string>;
  title: string;
  width: number;
  maxHeight: number;
  /**
   * @description current window size.
   * @default "small"
   */
  size: "small" | "medium" | "big";
  /**
   * @description accepted sizes for the window.
   * @default ["small", "medium"]
   */
  acceptSizes: WindowMenuObject["size"][];
  /**
   * @description order of resizing.
   */
  orderSizes: readonly WindowMenuObject["size"][];
  Footer: (() => JSX.Element) | undefined;
  loading: boolean;
  pagination: {
    enabled: boolean;
    pages: {
      id: string;
      visible: boolean;
      title?: string;
      buttons?: {
        value: string;
        onClick: (e: React.MouseEvent<HTMLElement>, pagination: WindowMenuObject["pagination"]) => void;
      }[];
    }[];
    style: "fixed__bottom";
  };
};

export interface WindowMenuContextState {
  state: {
    windows: WindowMenuObject[];
  };
  setState: React.Dispatch<React.SetStateAction<WindowMenuContextState["state"]>>;
}

export const INITIAL_WINDOW_STATE: WindowMenuObject = {
  id: "",
  zIndex: 0,
  show: false,
  moving: false,
  loading: false,
  Component: () => <></>,
  to: {
    top: "0px",
    left: "0px"
  },
  title: "",
  width: resize().small.width,
  maxHeight: resize().small.maxHeight,
  size: "small",
  acceptSizes: ["small", "medium"],
  orderSizes: ["small", "medium", "big"],
  pagination: {
    enabled: false,
    style: "fixed__bottom",
    pages: []
  },
  Footer: undefined
};

export const INITIAL_STATE: WindowMenuContextState = {
  state: {
    windows: []
  },
  setState: () => undefined
};

export const WindowMenuContext = React.createContext<WindowMenuContextState>(INITIAL_STATE);
