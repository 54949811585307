import _get from "lodash.get";
import { createObjectAttributePath } from "./createObjectAttributePath";

/**
 * @description get last item of a data type in the website, it could be a section, nav, footer.. any object that has data inside.
 * if last attribute is defined in options it will stop look if found attribute name.
 */
export const getLastDataValue = (
  data: { data?: any; [index: string]: any },
  dataPath: string,
  options?: {
    isValidation?: boolean;
  }
): any | undefined => {
  try {
    const startFromDataAttribute = Object.keys(data).includes("data");
    let test = "";

    test = dataPath
      // remove parent ${parent}/rest/rest
      .replace(/[a-z_-]+\//, "")
      // if it's validation we always have object with attributes "items"
      // else transform to [index]
      .replace(/\/([0-9]+)/g, options?.isValidation ? ".items" : "[$1]")
      .replace(/\//g, ".");

    test = startFromDataAttribute ? `data.${test}` : test;

    test = createObjectAttributePath(data, test);
    return _get(data, test);
  } catch (error) {
    return undefined;
  }
};
