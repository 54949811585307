import React from "react";
import { AddImage } from "@soltivo/draw-a-line/core/components/icons";
import Loader from "@soltivo/draw-a-line/core/components/loading/loader/loader";
import styles from "./styles.module.scss";
import ImageWindow from "./image.window/image.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface EditableImageProps extends EditableProps<"image"> {}

const EditableImage: React.FC<React.PropsWithChildren<EditableImageProps>> = ({
  wrapperProps,
  viewPosition,
  dataPath,
  dataClass
}) => {
  const editable = useEditableData({ dataClass, dataPath });

  const createEditablePositionCSSClasses = (viewPosition: EditableViewPosition) => {
    let classNames = "";
    Object.entries(viewPosition).map((values) => {
      if (values[1]) {
        classNames += ` to__${values[0]}`;
      }
    });

    return classNames;
  };

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();
  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_IMAGE.key,
      event: e,
      title: "Image",
      Component: () => <ImageWindow dataPath={dataPath} dataClass={dataClass} />
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  const isBlob = /^(blob)/.test(editable.data.src);

  return (
    <div
      className={`${styles.component} ${
        wrapperProps.editableStyles?.size ? `${styles[`editable__image-${wrapperProps.editableStyles?.size}`]} ` : ""
      } ${createEditablePositionCSSClasses(viewPosition)}`}>
      <div className={styles.actions}>
        <button onClick={onOpenWindow}>{isBlob ? <Loader size="md" variant="secondary" /> : <AddImage />}</button>
      </div>
    </div>
  );
};

export default EditableImage;
