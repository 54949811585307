import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { createErrorApp, ErrorApp } from "../../../../helpers/classes/ErrorApp";
import { RootState } from "redux/reducers";
import { GLOBAL_VARS } from "../../../../helpers/global";
import { ModelWebsite, ModelValidation } from "helpers/models";
import { ValidateClassFooter } from "helpers/validations/classof";
import { theme } from "redux/actions";
/**
 * @description update website footer
 */
export default function* update({
  payload
}: ReturnType<(typeof theme)["footer"]["actions"]["update"]["request"]>): Generator<
  CallEffect<Promise<File>> | CallEffect | Promise<string | undefined> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const exceptionCode = "footerException";
    const { footer: footerData } = payload;
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      validation: WebsiteWizardReducer.validation
    });

    const { website, validation }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const modelValidation = new ModelValidation(validation);
    if (!website.footer) return;

    const errors: string[] = [];

    if (typeof footerData.logo !== "undefined") {
      // website.footer.data.logo = footerData.logo;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            logo: footerData.logo
          }
        }
      });
    }

    if (typeof footerData.siteMap !== "undefined" && website.footer.data.siteMap) {
      if (typeof footerData.siteMap.visible !== "boolean") {
        errors.push(`Menu visible attribute must type of boolean.`);
      }
      // website.footer.data.siteMap = footerData.siteMap;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            siteMap: footerData.siteMap
          }
        }
      });
    }

    if (typeof footerData.links !== "undefined" && website.footer.data.links) {
      for (let i = 0; i < footerData.links.items.length; i++) {
        const item = footerData.links.items[i];

        if (typeof item.value !== "string") {
          errors.push(`Link value must be type of string.`);
        } else if (typeof item.href !== "string") {
          errors.push(`Link must be type of string.`);
        } else if (
          !GLOBAL_VARS.validaton.regex.httpsUrl.test(item.href) &&
          !item.href.startsWith("/") &&
          item.href !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
        ) {
          errors.push(`Link is invalid, select a page, section or add a custom url.`);
        } else if (item.target && !GLOBAL_VARS.editables.link.targets.includes(item.target)) {
          errors.push(
            `Link behavior is invalid, available options are ${GLOBAL_VARS.editables.link.targets.join(", ")}.`
          );
        }
      }
      // website.footer.data.links = footerData.links;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            links: footerData.links
          }
        }
      });
    }

    if (typeof footerData.copyright !== "undefined") {
      if (typeof footerData.copyright.value !== "string") {
        errors.push(`Copyright value must be string.`);
      }

      // website.footer.data.copyright = footerData.copyright;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            copyright: footerData.copyright
          }
        }
      });
    }

    if (typeof footerData.contactInformation !== "undefined" && website.footer.data.contactInformation) {
      if (typeof footerData.contactInformation.visible !== "boolean") {
        errors.push(`Contact information visible attribute must be type of boolean.`);
      }

      // website.footer.data.contactInformation = footerData.contactInformation;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            contactInformation: footerData.contactInformation
          }
        }
      });
    }

    if (typeof footerData.socialMedia !== "undefined" && website.footer.data.socialMedia) {
      if (typeof footerData.socialMedia.visible !== "boolean") {
        errors.push(`Social media visible attribute must be type of boolean.`);
      }

      // website.footer.data.socialMedia = footerData.socialMedia;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            socialMedia: footerData.socialMedia
          }
        }
      });
    }

    if (typeof footerData.text !== "undefined" && website.footer.data.text) {
      if (typeof footerData.text.visible !== "boolean") {
        errors.push(`Text visible attribute must be a boolean.`);
      } else if (typeof footerData.text.value !== "string") {
        errors.push(`Text value must be a string.`);
      }

      // website.footer.data.text = footerData.text;
      modelWebsite.update(modelWebsite, {
        footer: {
          ...modelWebsite.footer,
          data: {
            ...modelWebsite.footer.data,
            text: footerData.text
          }
        }
      });
    }

    ValidateClassFooter(modelValidation.footer, modelWebsite.footer);

    if (errors.length) {
      throw createErrorApp(errors[0], {
        code: exceptionCode
      });
    }

    yield put(
      theme.footer.actions.update.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.footer.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
