import { FC } from "react";
import { RootState } from "redux/reducers";
import { useSelector } from "react-redux";
import { GLOBAL_VARS } from "helpers/global";
import styles from "./styles.module.scss";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import MetaPixel from "blocks/meta.pixel/meta.pixel";

interface PluginListProps {}
const PluginList: FC<React.PropsWithChildren<PluginListProps>> = () => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();
  return (
    <div className={styles.component}>
      {Object.keys(website.plugins).map((key, index) => {
        return (
          <span
            role="button"
            onClick={(e) => {
              onStartWindowMenu({
                id: WINDOW_MENU_KEYS.META_PIXEL.key,
                event: e,
                title: "Meta Pixel",
                size: "medium",
                acceptSizes: ["medium"],
                Component: () => {
                  return <MetaPixel pluginKey={key as WebsitePluginName} />;
                }
              });
              // setConfigurationState((state) => ({
              //   ...state,
              //   Component: () => {
              //     return <MetaPixel pluginKey={key as WebsitePluginName} />;
              //   }
              // }));
              // sidebarUpdate({
              //   title: GLOBAL_VARS.plugins[key as WebsitePluginName].label,
              //   footer: () => {
              //     return (
              //       <Button
              //         padding={false}
              //         outline
              //         customButton={{
              //           backgroundColor: "white",
              //           textColor: "primary"
              //         }}
              //         onClick={() => {
              //           setConfigurationState((state) => ({
              //             ...state,
              //             Component: undefined
              //           }));
              //           sidebarUpdate({ title: "Configuration", footer: undefined });
              //         }}>
              //         Back
              //       </Button>
              //     );
              //   }
              // });
            }}
            key={index}
            className={`${styles.item}`}>
            {GLOBAL_VARS.plugins[key as WebsitePluginName].label}
          </span>
        );
      })}
    </div>
  );
};

export default PluginList;
