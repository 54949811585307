export const ValidateTypeString = (validation: ValidateTypeString, payload: string) => {
  if (typeof payload !== "string") {
    throw `Value should be type of string.`;
  } else if (validation.rules?.maxLength) {
    if (payload.length > validation.rules.maxLength) {
      throw `String cannot have more than ${validation.rules.maxLength} characters.`;
    }
  } else if (validation.rules?.minLength) {
    if (payload.length < validation.rules.minLength) {
      throw `String cannot have less than ${validation.rules.minLength} characters.`;
    }
  }
};
