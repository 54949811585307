import { ReactComponent as Dailymotion } from "assets/icons/dailymotion.svg";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as Mixcloud } from "assets/icons/mixcloud.svg";
import { ReactComponent as Soundcloud } from "assets/icons/soundcloud.svg";
import { ReactComponent as Twitch } from "assets/icons/twitch.svg";
import { ReactComponent as Vimeo } from "assets/icons/vimeo.svg";
import { ReactComponent as Wistia } from "assets/icons/wistia.svg";
import { ReactComponent as Youtube } from "assets/icons/youtube.svg";
import { nanoid } from "nanoid";

/**
 * @description global defined attributes for the whole website builder.
 */
export const GLOBAL_VARS = {
  api: {
    defaultErrorString: "Something went wrong."
  },
  devices: {
    desktop: {
      type: "desktop",
      height: 1080,
      width: 1920
    },
    tablet: {
      type: "tablet",
      height: 1024,
      width: 768
    },
    mobile: {
      type: "mobile",
      height: 844,
      width: 390
    }
  } as Record<Device["type"], Device>,
  iframeWebsite: {
    id: "iframe-website",
    name: "iframe-website",
    head: {
      colorsStyle: {
        id: "SOLTIVO_THEME_COLORS"
      },
      fontsStyle: {
        id: "FONT_THEME_STYLE"
      },
      fontsLink: {
        id: "FONT_THEME_LINK"
      }
    }
  },
  styles: {
    /**
     * @description main header sizing.
     */
    headerSize: 56,
    /**
     * @description gap between header and website/iframe.
     */
    headerGap: 52,
    /**
     * @description height of header of  website/iframe for domain and manage sections.
     */
    containerHeaderHeight: 48,
    /**
     * @description css index position elements like text, image etc show on the screen.
     */
    editableElementIndex: 499,
    /**
     * @description offset of both sides for the website/iframe.
     */
    iframeOffset: 95,

    WINDOW_MENU_INDEX: 1000
  },
  /**
   * @description 5MB in binary.
   */
  defaultFileSize: 5242880,
  /**
   * @description 2MB in binary.
   */
  secondaryFileSize: 2097152,
  BOOKING_URL_PLACEHOLDER: "ORGANIZATION__BOOKING__URL",
  editables: {
    link: {
      rels: [
        "alternate",
        "author",
        "bookmark",
        "external",
        "help",
        "license",
        "next",
        "nofollow",
        "noopener",
        "noreferrer",
        "opener",
        "prev",
        "search",
        "tag"
      ] as WebsiteLinkRel[],
      targets: ["_self", "_blank"] as WebsiteLinkTarget[],
      /**
       * @description labels for link.targets based on index.
       */
      targetLabels: ["Open in the current tab", "Open in new tab"]
    },
    button: {
      types: ["button", "reset", "submit"] as WebsiteButtonType[],
      actions: ["window_open", "internal"] as WebsiteButtonAction[],
      actionLabels: ["Open link", "Internal"]
    },
    form: {
      inputs: {
        requiredAttributes: ["firstName", "lastName", "email", "message"],
        types: [
          { type: "email", label: "Email" },
          { type: "text", label: "Text" },
          { type: "tel", label: "Phone number" }
        ] as { type: WebsiteFormInputType; label: string }[],
        tags: [
          { tag: "input", label: "Basic text" },
          { tag: "textarea", label: "Big text" }
        ] as { tag: WebsiteFormInputTag; label: string }[]
      }
    },
    logo: {
      fileTypes: ["image/jpeg", "image/png", "image/webp"]
    },
    plugins: {
      availablePlugins: ["booking/category", "booking/service"] as WebsiteDataPlugin[]
    },
    icon: {
      formats: ["outlined", "sharp", "rounded"] as WebsiteIcon["format"][]
    },
    audio: {
      /**
       * @description attributes which must be defined.
       */
      requiredAttributes: [
        "controls",
        "description",
        "light",
        "loop",
        "muted",
        "playing",
        "src",
        "thumbnail",
        "title",
        "volume"
      ] as (keyof WebsiteAudio)[],
      availableSources: {
        Youtube: {
          icon: Youtube,
          name: "Youtube",
          url: "https://www.youtube.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(youtu\.?be(\.com)?)/
        },

        SoundCloud: {
          icon: Soundcloud,
          name: "SoundCloud",
          url: "https://soundcloud.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(soundcloud\.com)/
        },
        MixCloud: {
          icon: Mixcloud,
          name: "MixCloud",
          url: "https://www.mixcloud.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(mixcloud\.com)/
        }
      }
    },
    video: {
      /**
       * @description attributes which must be defined.
       */
      requiredAttributes: [
        "controls",
        "description",
        "light",
        "loop",
        "muted",
        "playing",
        "src",
        "thumbnail",
        "title",
        "volume"
      ] as (keyof WebsiteVideo)[],
      availableSources: {
        Youtube: {
          icon: Youtube,
          name: "Youtube",
          url: "https://www.youtube.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(youtu\.?be(\.com)?)/
        },
        Vimeo: {
          icon: Vimeo,
          name: "Vimeo",
          url: "https://vimeo.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(vimeo\.com)/
        },
        Facebook: {
          icon: Facebook,
          name: "Facebook",
          url: "https://www.facebook.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(facebook\.com)/
        },
        Twitch: {
          icon: Twitch,
          name: "Twitch",
          url: "https://www.twitch.tv/",
          regexUrl: /^(https?:\/\/)?(www\.)?(twitch\.tv)/
        },
        Wistia: {
          icon: Wistia,
          name: "Wistia",
          url: "https://wistia.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(.+)?(wistia\.com)/
        },
        DailyMotion: {
          icon: Dailymotion,
          name: "DailyMotion",
          url: "https://www.dailymotion.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(dailymotion\.com)/
        }
      }
    },
    "background-video": {
      /**
       * @description attributes which must be defined.
       */
      requiredAttributes: [
        "controls",
        "description",
        "light",
        "loop",
        "muted",
        "playing",
        "src",
        "thumbnail",
        "title",
        "volume"
      ] as (keyof WebsiteVideo)[],
      availableSources: {
        Youtube: {
          icon: Youtube,
          name: "Youtube",
          url: "https://www.youtube.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(youtu\.?be(\.com)?)/
        },
        Vimeo: {
          icon: Vimeo,
          name: "Vimeo",
          url: "https://vimeo.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(vimeo\.com)/
        },
        Facebook: {
          icon: Facebook,
          name: "Facebook",
          url: "https://www.facebook.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(facebook\.com)/
        },
        Twitch: {
          icon: Twitch,
          name: "Twitch",
          url: "https://www.twitch.tv/",
          regexUrl: /^(https?:\/\/)?(www\.)?(twitch\.tv)/
        },
        Wistia: {
          icon: Wistia,
          name: "Wistia",
          url: "https://wistia.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(.+)?(wistia\.com)/
        },
        DailyMotion: {
          icon: Dailymotion,
          name: "DailyMotion",
          url: "https://www.dailymotion.com/",
          regexUrl: /^(https?:\/\/)?(www\.)?(dailymotion\.com)/
        }
      }
    }
  },
  validaton: {
    types: [
      "boolean",
      "currency",
      "email",
      "file",
      "icon",
      "image",
      "number",
      "object",
      "phone-number",
      "string",
      "url"
    ] as ValidateTypeof[],
    classes: [
      "audio",
      "background-image",
      "background-video",
      "button",
      "form",
      "group",
      "icon",
      "icon",
      "image",
      "link",
      "map",
      "object",
      "plugin",
      "text",
      "video"
    ] as ValidateClassof[],
    videoKeys: [
      "controls",
      "description",
      "light",
      "loop",
      "muted",
      "playing",
      "src",
      "thumbnail",
      "title",
      "volume"
    ] as (keyof WebsiteVideo)[],
    regex: {
      publicUrl: /^(\.?\/.+)/,
      anyUrl:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      httpsUrl: /(https:\/\/)([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
      extractFromJson: (attributeName: string, attributeValue: string) => {
        return new RegExp(`(("${attributeName}"):("${attributeValue}"))`, "g");
      },
      phoneNumber: /^([+0-9\s]{0,5})?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
      email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      file: {
        type: {
          initImage: /(jpeg|jpg|png|webp)/g
        }
      },
      color: {
        hex: /(#[0-9A-Za-z]{3,8})/
      },
      icon: {
        hexadecimal: /^&#([a-zA-Z0-9]+);$/
      }
    }
  },
  unsplash: {
    /**
     * @description number of images requested per page.
     */
    perPage: 20
  },
  undo: {
    /**
     * @description number allowed to hold undos in array.
     */
    hold: 20
  },
  socialMedia: {
    sample: () => ({
      id: nanoid(),
      name: "Google",
      svgHTML: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em">
                        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                        <path fill="currentColor" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                        <path fill="currentColor" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                        <path fill="currentColor" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                        <path fill="currentColor" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                        </g>
                    </svg>`,
      href: "https://google.com"
    })
  },
  plugins: {
    metaPixel: {
      label: "Meta Pixel",
      learnMore: "https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
    }
  } as Record<
    WebsitePluginName,
    {
      label: string;
      learnMore: string;
    }
  >
};

export type GlobalVars = ReturnType<() => typeof GLOBAL_VARS>;
