import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { createErrorApp, ErrorApp } from "../../../../helpers/classes/ErrorApp";
import { RootState } from "redux/reducers";
import { GLOBAL_VARS } from "../../../../helpers/global";
import { ModelWebsite, ModelValidation } from "helpers/models";
import { ValidateClassMenu } from "helpers/validations/classof";
import { theme } from "redux/actions";

/**
 * @description update website menu
 */
export default function* update({
  payload
}: ReturnType<(typeof theme)["menu"]["actions"]["update"]["request"]>): Generator<
  CallEffect<Promise<File>> | CallEffect | Promise<string | undefined> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const exceptionCode = "menuException";
    const { menu: menuData } = payload;
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      validation: WebsiteWizardReducer.validation
    });

    const { website, validation }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const modelValidation = new ModelValidation(validation);
    const errors: string[] = [];

    if (menuData.logo) {
      // website.menu.data.logo = menuData.logo;
      modelWebsite.update(modelWebsite, {
        menu: {
          ...modelWebsite.menu,
          data: {
            ...modelWebsite.menu.data,
            logo: menuData.logo
          }
        }
      });
    }

    if (menuData.siteMap) {
      if (typeof menuData.siteMap.visible !== "boolean") {
        errors.push(`Site map visible attribute must be type of boolean.`);
      }
      // website.menu.data.siteMap = menuData.siteMap;
      modelWebsite.update(modelWebsite, {
        menu: {
          ...modelWebsite.menu,
          data: {
            ...modelWebsite.menu.data,
            siteMap: menuData.siteMap
          }
        }
      });
    }

    if (menuData.links) {
      for (let i = 0; i < menuData.links.items.length; i++) {
        const item = menuData.links.items[i];

        if (typeof item.value !== "string") {
          errors.push(`Link value must be type of string.`);
        } else if (typeof item.href !== "string") {
          errors.push(`Link must be typeof string.`);
        } else if (
          !GLOBAL_VARS.validaton.regex.httpsUrl.test(item.href) &&
          !item.href.startsWith("/") &&
          item.href !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
        ) {
          errors.push(`Link is invalid, select a page, section or add a custom url.`);
        } else if (item.target && !GLOBAL_VARS.editables.link.targets.includes(item.target)) {
          errors.push(
            `Link behavior is invalid, available options are ${GLOBAL_VARS.editables.link.targets.join(", ")}.`
          );
        }
      }
      // website.menu.data.links = menuData.links;
      modelWebsite.update(modelWebsite, {
        menu: {
          ...modelWebsite.menu,
          data: {
            ...modelWebsite.menu.data,
            links: menuData.links
          }
        }
      });
    }

    if (errors.length) {
      throw createErrorApp(errors[0], {
        code: exceptionCode
      });
    }

    ValidateClassMenu(modelValidation.menu, modelWebsite.menu);

    yield put(
      theme.menu.actions.update.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.menu.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
