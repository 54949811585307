export const createPrevColors = (colors: WebsiteData["colors"]) => {
  const prevColors: PrevColors = {};
  Object.keys(colors).forEach((key) => {
    prevColors[key] = [
      colors[key],
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF"
    ];
  });
  return prevColors;
};
