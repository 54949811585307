import { FC } from "react";
import { Button, Column, Row } from "@soltivo/draw-a-line";
import { useDispatch } from "react-redux";
import actions from "redux/actions";
import ContentHeader from "components/content.header/content.header";
import ContactInformation from "blocks/contact.information/contact.information";
import SocialMediaList from "blocks/social.media.list/social.media.list";

interface ContactProps {}
const Contact: FC<React.PropsWithChildren<ContactProps>> = () => {
  const dispatch = useDispatch();

  return (
    <Row>
      <Column size={"12"}>
        <ContentHeader title="Contact information"></ContentHeader>
      </Column>

      {/* Contact component */}
      <ContactInformation />

      <Column size={"12"}>
        <ContentHeader title="Social media">
          <Button
            onClick={() => {
              dispatch(actions.theme.contact.actions.createSocialMedia.request());
            }}
            variant="primary"
            outline
            padding={false}>
            Add <span className="material-symbols-outlined">add</span>
          </Button>
        </ContentHeader>
      </Column>

      {/* Social Media List component */}
      <SocialMediaList />
    </Row>
  );
};

export default Contact;
