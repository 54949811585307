import Flow from "blocks/configuration/flow/flow";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { checkOrganizationRoute } from "helpers/functions/checkOrganizationRoute";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useBuilderIndexedDB } from "../useBuilderIndexedDB/useBuilderIndexedDB";

export const useInitialConfiguration = () => {
  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    prevColors: Wizard.prevColors,
    pages: Wizard.pages,
    undos: Wizard.undos
  });
  const { website } = useSelector(mapStateToProps);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const indexedDB = useBuilderIndexedDB({ storeKey: "theme", version: 1 });

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const previousChange = await indexedDB.get(website.themeId);
      const isOrganizationRoute = checkOrganizationRoute();

      if (!isOrganizationRoute && !previousChange?.website) {
        onStartWindowMenu({
          id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
          title: "Welcome to your new website!",
          startPosition: "center",
          Component: Flow,
          size: "big",
          acceptSizes: ["big"],
          pagination: {
            enabled: true,
            style: "fixed__bottom",
            pages: [
              {
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_WELCOME,
                title: "Welcome to your new website!",
                visible: true
              },
              {
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_1,
                title: "Website information",
                visible: false
              },
              {
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_2,
                title: "Website information",
                visible: false
              },
              {
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_CUSTOMIZATION,
                title: "Customization",
                visible: false
              },
              {
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_END,
                title: "Your webite is configured!",
                visible: false
              }
            ]
          }
        });
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};
