import { combineReducers } from "redux";
import AppReducer from "./app/AppReducer";
import Wizard from "./wizard/reducer";

const reducers = combineReducers({
  Wizard: Wizard,
  App: AppReducer
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
