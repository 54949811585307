import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassAudio = (validation: ValidateClassAudio, payload: WebsiteAudio) => {
  const requiredKeys = GLOBAL_VARS.editables.audio.requiredAttributes;
  if (typeof payload !== "object") {
    throw createErrorApp(`Audio should be type of object.`);
  }

  const payloadKeys = Object.keys(payload);

  // Required attributes
  for (let i = 0; i < requiredKeys.length; i++) {
    const key = requiredKeys[i] as keyof WebsiteAudio;

    if (!payloadKeys.includes(key)) {
      throw createErrorApp(`Audio requires "${key}" attribute.`);
    } else if (typeof payload[key] === "undefined") {
      throw createErrorApp(`Audio requires "${key}" attribute.`);
    }
  }

  // invalid attributes
  for (let i = 0; i < payloadKeys.length; i++) {
    const key = payloadKeys[i] as keyof WebsiteAudio;

    if (!requiredKeys.includes(key)) {
      throw createErrorApp(`Audio cannot update with invalid attribute "${key}".`);
    }
  }

  const srcRegexes = Object.values(GLOBAL_VARS.editables["audio"].availableSources).map((i) => i.regexUrl);
  const findSource = srcRegexes.find((rex) => rex.test(payload.src));

  if (!findSource) {
    throw createErrorApp(
      `Available sources: ${Object.values(GLOBAL_VARS.editables["audio"].availableSources)
        .map((source) => source.name)
        .join(", ")}.`
    );
  }

  if (typeof payload.thumbnail === "string") {
    if (!new RegExp(GLOBAL_VARS.validaton.regex.httpsUrl).test(payload.thumbnail)) {
      throw createErrorApp(`Audio thumbnail must be a valid url.`);
    }
  }
  if ((payload.thumbnail as unknown as File) instanceof File) {
    const thumbnail = payload.thumbnail as unknown as File;

    if (!thumbnail.type.startsWith("image")) {
      throw createErrorApp(`Audio thumbnail must be a image.`);
    }

    if (!new RegExp(GLOBAL_VARS.validaton.regex.file.type.initImage).test(thumbnail.type.toLowerCase())) {
      throw createErrorApp(`Audio thumbnail must have jpeg, jpg, png or webp extension.`);
    }

    if (thumbnail.size > GLOBAL_VARS.defaultFileSize) {
      throw createErrorApp(`Audio thumbnail file cannot be greater than 5MB.`);
    }
  }
  if (typeof payload.volume !== "number") {
    throw createErrorApp(`Audio volume must be a number.`);
  }
  if (payload.volume > 2) {
    throw createErrorApp(`Audio max volume reached.`);
  }
  if (payload.volume < 0) {
    throw createErrorApp(`Audio min volume reached.`);
  }

  if (validation.attributes.description?.rules.maxLength) {
    if (payload.description.length > validation.attributes.description?.rules.maxLength) {
      throw createErrorApp(
        `Audio description length cannot have more than ${validation.attributes.description?.rules.maxLength} caracters.`
      );
    }
  }

  if (validation.attributes.description?.rules.minLength) {
    if (payload.description.length < validation.attributes.description?.rules.minLength) {
      throw createErrorApp(
        `Audio description length cannot less more than ${validation.attributes.description?.rules.minLength} caracters.`
      );
    }
  }

  if (validation.attributes.title?.rules.maxLength) {
    if (payload.title.length > validation.attributes.title?.rules.maxLength) {
      throw createErrorApp(
        `Audio title length cannot have more than ${validation.attributes.title?.rules.maxLength} caracters.`
      );
    }
  }

  if (typeof validation.attributes.title?.rules.minLength === "number") {
    if (payload.title.length < validation.attributes.title.rules.minLength) {
      throw createErrorApp(
        `Audio title length cannot less more than ${validation.attributes.title.rules.minLength} caracters.`
      );
    }
  }
};
