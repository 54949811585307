import { GLOBAL_VARS } from "helpers/global";

export const ValidateTypePhoneNumber = (validation: ValidateTypePhoneNumber, payload: string) => {
  if (typeof payload !== "string") {
    throw `Phone number should be typeof string.`;
  } else if (typeof validation.rules.regexp === "undefined") {
    if (!GLOBAL_VARS.validaton.regex.phoneNumber.test(payload)) {
      throw `Phone number is invalid.`;
    }
  } else {
    const regex = new RegExp(validation.rules.regexp.content, validation.rules.regexp.flag);
    if (!regex.test(payload)) {
      throw validation.rules.regexp.message;
    }
  }
};
