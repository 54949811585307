import * as wizard from "./wizard";
import * as theme from "./theme";
import * as api from "./api";

export {
  /**
   * @deprecated
   */
  api,
  /**
   * @deprecated
   */
  theme,
  /**
   * @deprecated
   */
  wizard
};

const actions = {
  wizard,
  theme,
  api
};

export default actions;
