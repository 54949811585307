import { FC } from "react";
import { RootState } from "redux/reducers";
import { Column, Row } from "@soltivo/draw-a-line";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import SocialMedia from "blocks/social.media/social.media";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface SocialMediaListProps {}
const SocialMediaList: FC<React.PropsWithChildren<SocialMediaListProps>> = () => {
  const mapStateToProps = ({ Wizard }: RootState) => ({
    validation: Wizard.validation,
    website: Wizard.website
  });

  const { website } = useSelector(mapStateToProps);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  return (
    <Row className={styles.component}>
      {/* Website social media */}
      <Column size={"12"}>
        <Row>
          {website.contact.socialMedia?.map((sm) => {
            return (
              <Column key={sm.id} size={"6"}>
                <div
                  className={styles.media}
                  onClick={(e) => {
                    onStartWindowMenu({
                      id: WINDOW_MENU_KEYS.SOCIAL_MEDIA.key,
                      event: e,
                      Component: () => <SocialMedia media={sm} />,
                      acceptSizes: ["medium"],
                      size: "medium",
                      title: "Social Media"
                    });
                  }}>
                  <span
                    className={styles.brand}
                    dangerouslySetInnerHTML={{
                      __html: sm.svgHTML
                    }}
                  />
                  <p>{sm.name}</p>
                </div>
              </Column>
            );
          })}
        </Row>
      </Column>
    </Row>
  );
};

export default SocialMediaList;
