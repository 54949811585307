import React from "react";
import styles from "./styles.module.scss";
import { Button } from "@soltivo/draw-a-line";
import HeaderWindow from "./header.window/header.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface EditableHeaderProps {
  dataClass: ModifyConfig["dataClass"];
  editable?: HTMLElement;
  iframeWebsite?: HTMLIFrameElement;
}

const EditableHeader: React.FC<React.PropsWithChildren<EditableHeaderProps>> = ({
  editable,
  dataClass,
  iframeWebsite
}) => {
  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const openManagerPopup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_HEADER.key,
      event: e,
      Component: () => <HeaderWindow />,
      title: "Header manager",
      size: "big",
      acceptSizes: ["big"],
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_LOGO,
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_MENU,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_BUTTON,
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  return (
    <div className={styles.component}>
      <div className={styles.actions}>
        <Button variant="elm-500" onClick={openManagerPopup}>
          Header Manager
        </Button>
      </div>
    </div>
  );
};

export default EditableHeader;
