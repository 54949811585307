/**
 * @description get width and height of a File.
 * @warning it may not work if File is not image.
 */
export const getImageDimensions = (file: File): Promise<{ w: number; h: number }> => {
  return new Promise((resolve, reject) => {
    if (!window.FileReader) reject("Browser could not fetch image dimensions.");
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      const result = e.target?.result?.toString();
      image.src = result || "";
      image.onload = () => {
        resolve({
          w: image.width || 0,
          h: image.height || 0
        });
      };
    };
    reader.readAsDataURL(file);
  });
};
