import { Column, FormControl, Row } from "@soltivo/draw-a-line";
import InputLogo from "components/input.logo/input.logo";
import { getStatsPath } from "helpers/functions/getStatsPath";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";

interface LogosProps {}

const Logos: FC<React.PropsWithChildren<LogosProps>> = () => {
  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    themeURL: Wizard.themeURL
  });

  const { website, themeURL } = useSelector(mapStateToProps);

  const logos = {
    favicon: website.logo?.favicon.src || "",
    primary: website.logo?.primary.src || "",
    secondary: website.logo?.secondary.src || ""
  };

  const themePath = (() => {
    const url = new URL(themeURL);
    return `${url.origin}${url.pathname}`;
  })();

  const dispatch = useDispatch();

  const onChange = (name: keyof WebsiteData["logo"], value: File) => {
    const change = {
      ...logos,
      [name]: value
    };

    dispatch(
      actions.theme.logo.actions.update.request({
        logo: change
      })
    );

    return change;
  };

  return (
    <div className="logos">
      <Row>
        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Favicon"
            }}
            footerProps={{
              value: "Recommended size: 32 x 32 pixels"
            }}>
            <InputLogo
              accept="image/*"
              inputStyleType="inline"
              name="favicon"
              value={typeof logos.favicon === "string" ? getStatsPath(themePath, logos.favicon).path : logos.favicon}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) onChange("favicon", file);
              }}
            />
          </FormControl>
        </Column>

        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Primary logo"
            }}
            footerProps={{
              value: "Recommended size: 512 x 512 pixels"
            }}>
            <InputLogo
              accept="image/*"
              inputStyleType="inline"
              name="primary"
              value={typeof logos.primary === "string" ? getStatsPath(themePath, logos.primary).path : logos.primary}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) onChange("primary", file);
              }}
            />
          </FormControl>
        </Column>

        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Secondary logo"
            }}
            footerProps={{
              value: "Recommended size: 512 x 512 pixels"
            }}>
            <InputLogo
              accept="image/*"
              inputStyleType="inline"
              name="secondary"
              value={
                typeof logos.secondary === "string" ? getStatsPath(themePath, logos.secondary).path : logos.secondary
              }
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) onChange("secondary", file);
              }}
            />
          </FormControl>
        </Column>
      </Row>
    </div>
  );
};

export default Logos;
