import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import reducers from "./reducers";
import actions from "./actions";

const sagaMiddleware = createSagaMiddleware();

const configStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload"]
      }
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV === "development"
});
const initialStoreState = configStore.getState();

const store = {
  ...configStore,
  reset: () => configStore.dispatch(actions.wizard.state.actions.change.request(initialStoreState.Wizard))
};

sagaMiddleware.run(sagas);

export default store;
