import React from "react";
import { Settings24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./styles.module.scss";
import IconWindow from "./icon.window/icon.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

type EditableIconProps = Pick<EditableProps<"icon">, "dataClass" | "dataPath">;

const EditableIcon: React.FC<React.PropsWithChildren<EditableIconProps>> = ({ dataClass, dataPath }) => {
  const editable = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_ICON.key,
      event: e,
      title: "Icon",
      Component: () => <IconWindow dataClass={dataClass} dataPath={dataPath} />,
      size: "small",
      acceptSizes: ["small", "medium"]
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  return (
    <div className={`${styles.component} ${styles["left"]} ${styles["bottom"]}`}>
      <div className={styles.content}>
        <div className={styles.actions}>
          <React.Fragment>
            <button onClick={onOpenWindow}>
              <Settings24Px />
            </button>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default EditableIcon;
