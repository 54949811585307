import React, { useRef, useState, useEffect } from "react";
import "./unsplash.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Button, Loader, Image, InputSearch, useSimpleScrollPagination } from "@soltivo/draw-a-line";
import { ArrowBack24Px } from "@soltivo/draw-a-line/core/components/icons";
import { GLOBAL_VARS } from "helpers/global";
import { api } from "redux/actions";

export interface UnsplashProps {
  onSelectImage?: (image: string) => void;
  onBackward?: () => void;
  className?: string;
  sidebar?: boolean;
  params?: {
    h?: number;
    w: number;
    fit?: string; //https://docs.imgix.com/apis/rendering/size/fit
  };
  menu?: boolean;
  columns?: 1 | 2 | 3;
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
}

/**
 * @description component for wizard to allow users to choose a photo from Unsplash library.
 */
const Unsplash: React.FC<React.PropsWithChildren<UnsplashProps>> = ({
  columns = 3,
  onSelectImage,
  onBackward,
  className,
  sidebar,
  menu,
  params,
  style
}) => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    unsplashPhotos: WebsiteWizardReducer.unsplashPhotos,
    unsplashSearch: WebsiteWizardReducer.unsplashSearch
  });

  const { unsplashPhotos, unsplashSearch } = useSelector(mapStateToProps);

  const dispatch = useDispatch();
  const unsplashComponentRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState(unsplashSearch.query || "");
  const [queries, setQueries] = useState({
    /**
     * @description current page
     */
    page:
      (unsplashSearch.query.length
        ? Math.round(unsplashSearch.results.length / GLOBAL_VARS.unsplash.perPage)
        : Math.round(unsplashPhotos.results.length / GLOBAL_VARS.unsplash.perPage)) || 1,
    /**
     * @description how many photos should be requested per page
     */
    per_page: GLOBAL_VARS.unsplash.perPage
  });

  /**
   * @description current api being called.
   */
  const currentAPI = search.length ? "search" : "all";

  const resetQueries = (currentAPI: "all" | "search") => {
    setQueries((queries) => ({
      ...queries,
      page: currentAPI === "all" ? unsplashPhotos.results.length / queries.per_page : 1,
      per_page: queries.per_page
    }));
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetQueries(e.target.value.length === 0 ? "all" : "search");
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search.length && queries.page === 1)
      dispatch(
        api.unsplash.actions.searchPhotos.request({
          page: queries.page,
          query: search
        })
      );
  }, [search]);

  /**
   * @description transform given list to an array of columns.
   */
  const transformArrayToColumns = <T extends UnsplashPhotos[0] | UnplashPhotosSearch["results"][0]>(
    list: T[] = [],
    columnsCount = 3
  ) => {
    // const spliceTo = Math.ceil(list.length / columnsCount);
    let x = 0;
    try {
      const variantList = [...list];
      const transformColumn: T[][] = [];
      for (let i = 0; i < columnsCount; i++) {
        transformColumn.push([]);
      }

      variantList.forEach((variant) => {
        transformColumn[x].push(variant);
        x++;
        if (x === columnsCount) x = 0;
      });

      return transformColumn;
    } catch (error) {
      console.error(error);
    }
  };

  const columnsSearch = transformArrayToColumns(unsplashSearch.results, columns);
  const columnsAll = transformArrayToColumns(unsplashPhotos.results, columns);

  const onSelectImageSubmit = (item: any) => {
    if (!onSelectImage) return;

    //with defined sizes
    if (params) {
      const {
        urls: { raw }
      } = item;
      onSelectImage(
        `${raw}${params.w ? `&w=${params.w}` : ""}${params.h ? `&h=${params.h}` : ""}${
          params.fit ? `&fit=${params.fit}` : ""
        }`
      );
      return;
    }
    // else regular images.
    const {
      urls: { regular }
    } = item;

    onSelectImage(regular);
  };

  const { loading, lastItemObserverRef } = useSimpleScrollPagination({
    // for all we always set as has more because the api does not contain this information.
    hasMore: currentAPI === "search" ? unsplashSearch.page < unsplashSearch.total_pages : true,
    next: () => {
      setQueries((queries) => {
        return { ...queries, page: queries.page + 1 };
      });
    }
  });

  useEffect(() => {
    //search hook.
    if (!search.length) return;
    if (queries.page === Math.round(unsplashSearch.results.length / GLOBAL_VARS.unsplash.perPage)) return;

    dispatch(
      api.unsplash.actions.searchPhotos.request({
        page: queries.page,
        query: search
      })
    );
  }, [unsplashPhotos.results.length, search, queries]);

  useEffect(() => {
    //initial photos hook
    if (search.length) return;
    if (queries.page === unsplashPhotos.results.length / GLOBAL_VARS.unsplash.perPage) return;
    dispatch(
      api.unsplash.actions.getPhotos.request({
        page: queries.page,
        per_page: queries.per_page
      })
    );
  }, [unsplashPhotos.results.length, search, queries]);

  return (
    <div
      ref={unsplashComponentRef}
      className={`unsplash-component ${className ? className : ""} ${`columns_${columns}`} ${
        sidebar ? "sidebar" : menu ? "menu" : ""
      }`}
      // onScroll={onScroll}
      style={style}>
      <div className="unsplash--backwards-action">
        {onBackward && (
          <Button padding={false} variant="secondary" outline border={false} onClick={onBackward}>
            <ArrowBack24Px />
          </Button>
        )}
      </div>
      {/* Search enginer */}
      <div className="unsplash-component--search w-100">
        <fieldset className="w-100">
          <InputSearch
            className="unsplashed__search-engine"
            isSearching={unsplashSearch.loading}
            value={search}
            hidden={false}
            onChange={onChangeSearch}
            placeholder="Search high-resolution photos"
          />

          {search.length && !unsplashSearch.loading ? (
            <label>
              {unsplashSearch.results.length && unsplashSearch.total
                ? `Found ${unsplashSearch.total} results.`
                : `No photos with name "${search}" found.`}
            </label>
          ) : null}
        </fieldset>
      </div>
      <div className={`unsplash-component-items ${unsplashSearch.loading ? "loading" : ""}`}>
        {currentAPI === "all"
          ? columnsAll?.map((column, columnKey) => {
              return (
                <div className="unsplash-component-items--col" key={columnKey}>
                  {column.map((item, index: number) => {
                    const placeholder = new URL(item.urls.thumb);
                    placeholder.searchParams.set("w", "10");
                    if (columnKey === columnsAll?.length - 1 && index === column.length - 1) {
                      return (
                        <div ref={lastItemObserverRef} key={index} className="unsplash-component-item">
                          <Image
                            onClick={() => onSelectImageSubmit(item)}
                            placeholder={placeholder.href}
                            className="image"
                            src={item.urls.thumb}
                            title={item.alt_description || ""}
                            alt={item.alt_description || ""}
                          />

                          <div className="credit">
                            <span>
                              Photo by{" "}
                              <a href={item.user.links.html} target="_blank" rel="noreferrer">
                                {item.user.name}
                              </a>
                            </span>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div key={index} className="unsplash-component-item">
                        <Image
                          onClick={() => onSelectImageSubmit(item)}
                          placeholder={placeholder.href}
                          className="image"
                          src={item.urls.thumb}
                          title={item.alt_description || ""}
                          alt={item.alt_description || ""}
                        />

                        <div className="credit">
                          <span>
                            Photo by{" "}
                            <a href={item.user.links.html} target="_blank" rel="noreferrer">
                              {item.user.name}
                            </a>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : currentAPI === "search"
          ? columnsSearch?.map((column, columnKey) => {
              return (
                <div className="unsplash-component-items--col" key={columnKey}>
                  {column.map((item, index: number) => {
                    const placeholder = new URL(item.urls.thumb);
                    placeholder.searchParams.set("w", "10");
                    if (columnKey === columnsSearch?.length - 1 && index === column.length - 1) {
                      return (
                        <div ref={lastItemObserverRef} key={index} className="unsplash-component-item">
                          <Image
                            onClick={() => onSelectImageSubmit(item)}
                            placeholder={placeholder.href}
                            className="image"
                            src={item.urls.thumb}
                            title={item.alt_description || ""}
                            alt={item.alt_description || ""}
                          />

                          <div className="credit">
                            <span>
                              Photo by{" "}
                              <a href={item.user.links.html} target="_blank" rel="noreferrer">
                                {item.user.name}
                              </a>
                            </span>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div key={index} className="unsplash-component-item">
                        <Image
                          onClick={() => onSelectImageSubmit(item)}
                          placeholder={placeholder.href}
                          className="image"
                          src={item.urls.thumb}
                          title={item.alt_description || ""}
                          alt={item.alt_description || ""}
                        />

                        <div className="credit">
                          <span>
                            Photo by{" "}
                            <a href={item.user.links.html} target="_blank" rel="noreferrer">
                              {item.user.name}
                            </a>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : null}

        {loading ? <Loader size="md" variant="primary" className="popup-unsplash--loading-photos" /> : null}
      </div>

      <div className="unplash__powered">
        <p>
          Powered by{" "}
          <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
            Unsplash
          </a>
        </p>
      </div>

      {/* {!disableScrollUp ? (
        <Button onClick={onScrollTop} variant="secondary" className="unsplash-component--scroll-up">
          <ArrowUpward24Px />
        </Button>
      ) : null} */}
    </div>
  );
};

export default Unsplash;
