import React, { FC } from "react";
import { Column, FormControl, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import useEditableData from "helpers/hooks/useEditableData";

interface MapWindowProps extends EditableWindowProps<"map"> {}
const MapWindow: FC<React.PropsWithChildren<MapWindowProps>> = ({ dataClass, dataPath }) => {
  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  if (!data || !validation) {
    return null;
  }

  return (
    <Row>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Location address"
          }}>
          <Textarea
            name="value"
            value={data.address}
            onChange={(e) => {
              onUpdate({ ...data, address: e.target.value });
            }}
            placeholder="Location address"
          />
        </FormControl>
      </Column>

      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Zoom"
          }}>
          <InputText
            type="number"
            name="value"
            value={data.zoom}
            onChange={(e) => {
              const zoom = parseFloat(e.target.value);
              onUpdate({ ...data, zoom: isNaN(zoom) ? 15 : zoom });
            }}
            placeholder="value"
            disabled={validation.attributes.zoom?.rules.disabled}
          />
        </FormControl>
      </Column>
    </Row>
  );
};

export default MapWindow;
