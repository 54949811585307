import { FC } from "react";
import { RootState } from "redux/reducers";
import { Color, Column, FormControl, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_VARS } from "helpers/global";
import actions from "redux/actions";
import styles from "./styles.module.scss";

interface ContactInformationProps {}
const ContactInformation: FC<React.PropsWithChildren<ContactInformationProps>> = () => {
  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard }: RootState) => ({
    validation: Wizard.validation,
    website: Wizard.website
  });

  const { validation, website } = useSelector(mapStateToProps);

  return (
    <Row className={styles.component}>
      {/* Create all inputs by validation type */}
      {website.contact.information
        ? Object.keys(website.contact.information).map((key, index) => {
            if (key === "id" || key === "label") return null;
            /**
             * @description input information
             */
            const _validation = validation.contact.attributes.information.attributes[key];
            // If no validation found we should not show an input for it.
            if (!_validation) return null;

            const value = website.contact.information[key];

            switch (_validation.typeof) {
              case "string":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: _validation.label
                      }}>
                      <Textarea
                        name={key}
                        defaultValue={String(value)}
                        onChange={(e) => {
                          dispatch(
                            actions.theme.contact.actions.changeInformationAttribute.request({
                              name: key,
                              value: e.target.value
                            })
                          );
                        }}
                        placeholder={_validation.label}
                      />
                    </FormControl>
                  </Column>
                );
              case "phone-number":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: _validation.label
                      }}>
                      <InputText
                        type="text"
                        defaultValue={String(value)}
                        onChange={(e) => {
                          dispatch(
                            actions.theme.contact.actions.changeInformationAttribute.request({
                              name: key,
                              value: e.target.value
                            })
                          );
                        }}
                        placeholder={_validation.label}
                      />
                    </FormControl>
                  </Column>
                );

              case "email":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: _validation.label
                      }}>
                      <InputText
                        name={key}
                        defaultValue={String(value)}
                        onChange={(e) => {
                          dispatch(
                            actions.theme.contact.actions.changeInformationAttribute.request({
                              name: key,
                              value: e.target.value
                            })
                          );
                        }}
                        type="email"
                        placeholder={_validation.label}
                      />
                    </FormControl>
                  </Column>
                );

              case "url":
                return (
                  <Column key={index} size={"12"}>
                    <FormControl
                      labelProps={{
                        value: _validation.label
                      }}>
                      <InputText
                        name={key}
                        defaultValue={String(value)}
                        onDoubleClick={() => {
                          if (GLOBAL_VARS.validaton.regex.httpsUrl.test(String(value))) {
                            window.open(String(value), "_blank");
                          }
                        }}
                        style={{
                          color: Color["bluemine"]["bluemine-500"],
                          textDecoration: "underline"
                        }}
                        onClick={(e) => {
                          e.currentTarget.select();
                        }}
                        onChange={(e) => {
                          dispatch(
                            actions.theme.contact.actions.changeInformationAttribute.request({
                              name: key,
                              value: e.target.value
                            })
                          );
                        }}
                        type="url"
                        placeholder={_validation.label}
                      />
                    </FormControl>
                  </Column>
                );
            }
          })
        : null}
    </Row>
  );
};

export default ContactInformation;
