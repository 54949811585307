import { soltivoHelper } from "@soltivo/draw-a-line";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateTypeImage = (validation: ValidateTypeImage, payload: string | File) => {
  if (payload instanceof File) {
    // jest doesn't understand clean RegExp for some reason.
    const testImageType = new RegExp(GLOBAL_VARS.validaton.regex.file.type.initImage);
    if (!payload.type.startsWith("image/")) {
      throw `File type ${payload.type} is not a valid image type.`;
    } else if (validation.rules?.mimes) {
      if (!(validation.rules?.mimes as string[]).includes(payload.type.replace("image/", "").toLowerCase())) {
        throw `File type of ${
          payload.type
        } is not allowed, validation extentions allowed: ${validation.rules?.mimes.join(", ")}.`;
      }
    } else if (!testImageType.test(payload.type)) {
      throw `File type of ${payload.type} is not allowed, we only accept JPEG, JPG, PNG and WEBP.`;
    }

    if (validation.rules?.size) {
      if (payload.size > validation.rules.size) {
        throw `File size cannot be greater than ${soltivoHelper.formatBytes(validation.rules.size)}.`;
      }
    } else if (payload.size > GLOBAL_VARS.defaultFileSize) {
      throw `File size cannot be greater than ${soltivoHelper.formatBytes(GLOBAL_VARS.defaultFileSize)}.`;
    }
  } else {
    if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(payload) && !GLOBAL_VARS.validaton.regex.publicUrl.test(payload)) {
      throw `Image must be either a file image or an image url.`;
    } else if (!/unsplash/g.test(payload) && !GLOBAL_VARS.validaton.regex.publicUrl.test(payload)) {
      throw `Image url should be from unsplash library.`;
    }
  }
};
