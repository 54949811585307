/**
 * @description organize sections
 */
export const createSectionGroups = (page: WebsiteData["page"]) => {
  const partOfGroup: string[] = [];
  const sections = [];

  for (let i = 0; i < page.sections.length; i++) {
    const section = page.sections[i];

    const groupSections = page.sections.filter((groupSection) => groupSection.name.startsWith(section.name));

    if (groupSections.length > 1) {
      sections.push(
        groupSections
          /**
           * Make sure the first section name which group other sections with startName is the first index.
           */
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      );

      for (let i = 0; i < groupSections.length; i++) {
        partOfGroup.push(groupSections[i].name);
      }
    } else {
      sections.push(section);
    }
  }

  return sections.filter((section) =>
    !Array.isArray(section) ? !partOfGroup.includes(section.name) : Boolean(section)
  );
};
