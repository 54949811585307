import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";
import styles from "./styles.module.scss";

interface FontsProps {}

const Fonts: FC<React.PropsWithChildren<FontsProps>> = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    font: WebsiteWizardReducer.website.font,
    configuration: WebsiteWizardReducer.configuration
  });

  const { configuration, font } = useSelector(mapStateToProps);

  return (
    <div className={styles.component}>
      {configuration.fonts?.map((fontItem: WebsiteFont, index: number) => (
        <span
          key={index}
          style={{
            fontFamily: fontItem.cssRule.replace(/;/g, "")
          }}
          className={`${styles.item} ${font?.name === fontItem.name ? styles.actived : ""}`}
          role="button"
          onClick={() => dispatch(actions.theme.font.actions.update.request({ fontName: fontItem.name }))}>
          {fontItem.name}
        </span>
      ))}
    </div>
  );
};

export default Fonts;
