import { ModelSection } from "../ModelSection/ModelSection";
import _cloneDeep from "lodash.clonedeep";
import cloneDeep from "lodash.clonedeep";
import { pushToArrayPosition } from "helpers";

class ModelPage implements WebsitePage {
  constructor(page?: WebsitePage) {
    if (page) {
      const _page = _cloneDeep(page);
      this.description = _page.description;
      this.hidden = _page.hidden;
      this.id = _page.id;
      this.name = _page.name;
      this.sections = page.sections.map((section) => {
        return new ModelSection(section);
      });
      this.thumbnail = _page.thumbnail;
      this.title = _page.title;
    }
  }

  description = "";
  hidden?: boolean | undefined = undefined;
  id = "";
  name = "";
  sections: ModelSection[] = [];
  thumbnail = "";
  title = "";

  get object(): WebsitePage {
    return {
      description: this.description,
      hidden: this.hidden,
      id: this.id,
      name: this.name,
      sections: this.sections.map((section) => {
        return new ModelSection(section).object;
      }),
      thumbnail: this.thumbnail,
      title: this.title
    };
  }

  /**
   * @description checks if name has "-" or "_" and replace it with space.
   */
  public purifyName(name: string) {
    return name.replace(/(-|_)/g, " ").toLowerCase();
  }

  /**
   * @description find page/validation by id
   */
  public findPageById<T extends WebsitePage | Validation["pages"][0] = WebsitePage>(pages: T[], pageId: T["id"]) {
    return pages.find((page) => page.id === pageId);
  }

  /**
   * @description finds a section by id.
   */
  public findSectionById<T extends WebsiteSection | Validation["pages"][0]["sections"][0] = WebsiteSection>(
    sections: T[],
    sectionId: T["id"]
  ) {
    return sections.find((section) => section.id === sectionId);
  }

  /**
   * @description finds a section by name.
   */
  public findSectionByName<T extends WebsiteSection | Validation["pages"][0]["sections"][0] = WebsiteSection>(
    sections: T[],
    name: T["name"]
  ) {
    return sections.find((section) => section.name.toLowerCase() === name.toLowerCase());
  }

  /**
   * @description get the index of a section
   */
  public getSectionIndex(sections: { name: string }[], sectionName: string) {
    return sections.findIndex((section) => section.name === sectionName);
  }

  /**
   * @description update sections list.
   */
  public updateSections(sections: WebsiteSection[]) {
    this.sections = sections.map((section) => {
      return new ModelSection(section);
    });
    return this.object;
  }

  /**
   * @description replace the "from" index with "to" and the "to" to "from".
   */
  public moveSectionIndex(sections: WebsiteSection[], from: number, to: number) {
    try {
      const cloneSectons = cloneDeep(sections);
      const copy = new ModelSection(cloneSectons[from]).object;
      const copyTo = new ModelSection(cloneSectons[to]).object;
      cloneSectons[to] = copy;
      cloneSectons[from] = copyTo;
      this.updateSections(cloneSectons);
      return cloneSectons;
    } catch (error) {
      console.error(error);
      return sections;
    }
  }

  public moveSectionIndexes(
    sections: WebsiteSection[],
    fromSectionNameList: WebsiteSection["name"][],
    toSectionNameList: WebsiteSection["name"][]
  ) {
    try {
      if (JSON.stringify(fromSectionNameList) === JSON.stringify(toSectionNameList)) {
        return sections;
      }
      const cloneSections = cloneDeep(sections);

      if (fromSectionNameList.length === 1 && toSectionNameList.length === 1) {
        const fromIndex = this.getSectionIndex(sections, fromSectionNameList[0]);
        const toIndex = this.getSectionIndex(sections, toSectionNameList[0]);
        const copyFrom = new ModelSection(cloneSections[fromIndex]).object;
        const copyTo = new ModelSection(cloneSections[toIndex]).object;
        cloneSections[fromIndex] = copyTo;
        cloneSections[toIndex] = copyFrom;

        this.updateSections(cloneSections);
        return cloneSections;
      } else {
        const fromIndex = this.getSectionIndex(sections, fromSectionNameList[0]);
        const toIndex = this.getSectionIndex(sections, toSectionNameList[0]);
        const copyFrom = new ModelSection(cloneSections[fromIndex]).object;
        const copyTo = new ModelSection(cloneSections[toIndex]).object;
        cloneSections[fromIndex] = copyTo;
        cloneSections[toIndex] = copyFrom;

        const firstFromSectionList = fromSectionNameList.shift() || "";
        const firstToSectionList = toSectionNameList.shift() || "";

        let filtered = cloneSections.filter((section) => {
          return !fromSectionNameList.includes(section.name) && !toSectionNameList.includes(section.name);
        });

        const fromSections = sections.filter((section) => fromSectionNameList.includes(section.name));
        const toSections = sections.filter((section) => toSectionNameList.includes(section.name));

        const firstToIndex = this.getSectionIndex(filtered, firstToSectionList);

        for (let i = 0; i < toSections.length; i++) {
          filtered = pushToArrayPosition(filtered, firstToIndex + 1, toSections[i]);
        }

        const firstFromIndex = this.getSectionIndex(filtered, firstFromSectionList);

        for (let i = 0; i < fromSections.length; i++) {
          filtered = pushToArrayPosition(filtered, firstFromIndex + 1, fromSections[i]);
        }

        this.updateSections(filtered);
        return filtered;
      }
    } catch (error) {
      console.error(error);
      return sections;
    }
  }
}

export { ModelPage };
