import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";
import { createSectionGroups } from "../../../../helpers/functions/createSectionGroups";

/**
 * @description organize website page sections.
 */

export default function* organize({
  payload: { website: payloadWebsite }
}: ReturnType<(typeof theme)["section"]["actions"]["organize"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      pages: cloneDeep(WebsiteWizardReducer.pages)
    });
    const website = cloneDeep(payloadWebsite);
    let { pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const sectionResults = createSectionGroups(website.page);
    modelWebsite.page.updateSections(sectionResults.flatMap((section) => section));
    pages = pages.map((page) => {
      const sectionResults = createSectionGroups(page);
      page.sections = sectionResults.flatMap((section) => section);
      return page;
    });

    yield put(
      theme.section.actions.organize.success({
        pages: pages,
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.section.actions.organize.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
