import color from "./color";
import contact from "./contact";
import data from "./data";
import editable from "./editable";
import font from "./font";
import footer from "./footer";
import general from "./general";
import logo from "./logo";
import menu from "./menu";
import page from "./page";
import route from "./route";
import section from "./section";

const types = {
  ...color.types,
  ...font.types,
  ...data.types,
  ...section.types,
  ...page.types,
  ...editable.types,
  ...contact.types,
  ...footer.types,
  ...menu.types,
  ...general.types,
  ...logo.types,
  ...route.types
};

/**
 * @description all actions related to the theme.
 */
export { color, font, data, section, page, editable, contact, footer, menu, general, logo, route, types };
