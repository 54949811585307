import { ModelPage } from "../ModelPage/ModelPage";
import _cloneDeep from "lodash.clonedeep";
import { RootState } from "redux/reducers";
import { createObjectAttributePath, getLastDataValue, setValueInDataClass } from "helpers";
import _updateWith from "lodash.updatewith";
import get from "lodash.get";
import { valueWithDefault } from "helpers/functions/valueWithDefault";

class ModelWebsite implements WebsiteData {
  constructor(website?: WebsiteData) {
    if (website) {
      const _website = _cloneDeep(website);
      this.version = _website.version || this.version;
      this.colors = _website.colors;
      this.contact = _website.contact;
      this.font = _website.font;
      this.footer = _website.footer;
      this.general = _website.general;
      this.id = _website.id;
      this.logo = _website.logo;
      this.menu = _website.menu;
      this.name = _website.name;
      this.page = new ModelPage(_website.page);
      // this.policies = _website.policies;
      this.routes = _website.routes;
      this.themeId = _website.themeId;
      this.plugins = _website.plugins;
    }
  }

  public version: Exclude<WebsiteData["version"], undefined> = "0.1.0";
  public colors: WebsiteData["colors"] = {
    accent: "",
    background: ""
  };
  public contact: WebsiteData["contact"] = {
    information: {},
    socialMedia: []
  };
  public font: WebsiteData["font"] = {
    cssRule: "",
    href: "",
    id: "",
    name: ""
  };
  public footer: WebsiteData["footer"] = {
    data: {}
  };
  public general: WebsiteData["general"] = {};
  public id?: WebsiteData["id"] = undefined;
  public logo: WebsiteData["logo"] = {
    favicon: {
      alt: "",
      src: ""
    },
    primary: {
      alt: "",
      src: ""
    },
    secondary: {
      alt: "",
      src: ""
    }
  };
  public menu: WebsiteData["menu"] = {
    data: {}
  };
  public name: WebsiteData["name"] = "";
  public page: ModelPage = new ModelPage();
  // public policies: WebsiteData["policies"] = [];
  public routes: WebsiteData["routes"] = {
    list: [],
    siteMap: { groups: [] }
  };
  public themeId: WebsiteData["themeId"] = "";
  public plugins: WebsiteData["plugins"] = {
    metaPixel: {
      enabled: true,
      attributes: {
        pixelId: ""
      }
    }
  };

  get object(): WebsiteData {
    return {
      colors: this.colors,
      contact: this.contact,
      font: this.font,
      footer: this.footer,
      general: this.general,
      id: this.id,
      logo: this.logo,
      menu: this.menu,
      name: this.name,
      page: new ModelPage(this.page).object,
      // policies: this.policies,
      routes: this.routes,
      themeId: this.themeId,
      plugins: this.plugins,
      version: this.version
    };
  }

  /**
   * @description checks if name has "-" or "_" and replace it with space.
   */
  public purifyName(name: string) {
    return name.replace(/(-|_)/g, " ").toLowerCase();
  }

  /**
   * @description update website.
   */
  public update(website: WebsiteData, change: Partial<WebsiteData>) {
    const update = new ModelWebsite({ ...website, ...change });
    this.colors = update.colors;
    this.contact = update.contact;
    this.font = update.font;
    this.footer = update.footer;
    this.general = update.general;
    this.id = update.id;
    this.logo = update.logo;
    this.menu = update.menu;
    this.name = update.name;
    this.page = update.page;
    this.routes = update.routes;
    this.themeId = update.themeId;
    this.plugins = update.plugins;
    this.version = update.version;

    return this;
  }

  /**
   * @description prefill meta pixel plugin
   */
  public prefillMetaPixel(website: WebsiteData) {
    return this.update(website, {
      plugins: {
        ...website.plugins,
        metaPixel: {
          ...website.plugins?.metaPixel,
          enabled: valueWithDefault("boolean", website.plugins?.metaPixel?.enabled, false),
          attributes: {
            ...website.plugins?.metaPixel?.attributes,
            pixelId: valueWithDefault("string", website.plugins?.metaPixel?.attributes?.pixelId, "")
          }
        }
      }
    });
  }

  /**
   * @description order pages using routes path, homepage will be always the 0 index.
   */
  public orderPagesByRoutes(website: RootState["Wizard"]["website"], pages: WebsitePage[]) {
    return pages.sort((a, b) => {
      const routeA = website.routes.list.find((route) => route.pageName === a.name);
      const routeB = website.routes.list.find((route) => route.pageName === b.name);

      if (routeA && routeB) {
        if (routeA.path > routeB.path) {
          return 1;
        }
      }

      return -1;
    });
  }

  /**
   * @description modify the data property of a global property & returns back the single property from the data property
   * the user has modified.
   */
  public updateRootPath = (options: UpdateRootPathOptions) => {
    try {
      const { website, dataPath, dataClass, value } = options;
      const copyWebsite = new ModelWebsite(website).object;
      let test = dataPath
        .replace(/global\//, "")
        .replace(/\/([0-9]+)/g, "[$1]")
        .replace(/\//g, ".");

      test = createObjectAttributePath(copyWebsite, test);

      const objectExists = typeof get(copyWebsite, test) !== "undefined";

      if (!objectExists) {
        throw new Error(`Attempt to update WebsiteData failed, dataPath ${dataPath} doesn't exist.`);
      }

      const result = _updateWith(copyWebsite, test, (oldVaue) => {
        const sanitazedValue = dataClass ? setValueInDataClass(dataClass, value) : value;
        return sanitazedValue;
      });
      return this.update(website, result);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description get data for editable elements data objects like WebsiteText, WebsiteImage, WebsiteVideo etc...
   * @example <div data-path="(section)/example/id:48/example/{last_property}></div>"
   */
  getEditableData(payload: { website: RootState["Wizard"]["website"]; dataPath: WebsiteDataPath }) {
    try {
      const { dataPath, website } = payload;
      const dataPathSplit = dataPath.split("/");
      const sectionName = dataPathSplit[0];
      const section = this.page.findSectionByName(website.page.sections, sectionName);

      if (!section) {
        throw new Error(`Unable to find section with data-path="${dataPath}" inside data.`);
      }

      const result = getLastDataValue(section, dataPath);

      return result;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description get validation of editable elements inside the body.
   * @example <div data-path="(section)/example/id:48/example/{last_property}></div>"
   */
  getEditableValidation<T extends WebsiteDataValidation>(payload: {
    pageId: WebsitePage["id"];
    validation: RootState["Wizard"]["validation"];
    dataPath: WebsiteDataPath;
    dataClass: WebsiteDataClass;
  }) {
    const { dataPath, pageId, validation } = payload;
    try {
      const pageValidation = this.page.findPageById(validation.pages, pageId);
      const pageValidationIndex = validation.pages.findIndex((page) => page.id === pageId);
      const pageValidationSections = pageValidation?.sections;
      const dataPathSplit = dataPath.split("/");
      const sectionName = dataPathSplit[0];
      const sectionValidationIndex = pageValidationSections
        ? this.page.getSectionIndex(pageValidationSections, sectionName)
        : -1;

      if (pageValidationIndex === -1) {
        throw new Error(`Unable to find page with data-path="${dataPath}" inside validation.`);
      }

      if (sectionValidationIndex === -1) {
        throw new Error(`Unable to find section with data-path="${dataPath}" inside validation.`);
      }

      const path = `pages.[${pageValidationIndex}].sections.[${sectionValidationIndex}]`;
      const sectionValidationObject = get(validation, path);
      const result: T = getLastDataValue(sectionValidationObject, dataPath, { isValidation: true });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description parse string website to object WebsiteData.
   */
  public parseJSON(website: string) {
    const data: WebsiteData | undefined = JSON.parse(website);
    return data;
  }

  /**
   * @description parse website to json string.
   */
  public toJSON(website: WebsiteData) {
    const _website: WebsiteData = _cloneDeep(website);
    return JSON.stringify({
      ..._website
    });
  }
}

export { ModelWebsite };
