/**
 * @description transform object with string values to root css variables.
 */
export const createRootCssVariables = (object: Record<string, string>) => {
  const rootVariables = Object.keys(object)
    .map((name) => {
      return `\n  --${name}: ${object[name]};\n`;
    })
    .join(",")
    .replace(/(,)/g, "");
  return `:root {\n${rootVariables}\n}`;
};
