import ButtonView from "./button/button.view";
import Logo from "./logo/logo";
import MenuView from "./menu/menu";

const HeaderWindowView = {
  Button: ButtonView,
  Logo: Logo,
  Menu: MenuView
};

export default HeaderWindowView;
