import * as idb from "idb";

export type IDBDatabaseInitial = Record<
  string,
  Record<
    string,
    {
      value: any;
      key: string;
      indexes: Record<string, IDBValidKey>;
    }
  >
>;

class IndexedDB {
  constructor(payload: { databaseName: string; storeKey: string; version?: number }) {
    if (!global.indexedDB || !window.indexedDB) {
      console.warn("IndexedDB not supported");
      return;
    }

    this.storeKey = payload.storeKey;
    this.databaseName = payload.databaseName;
    this.version = typeof payload?.version === "number" ? payload.version : 1;
    this.database = idb.openDB<IDBDatabaseInitial>(`${this.databaseName}`, this.version, {
      upgrade: (idb) => {
        idb.createObjectStore(this.storeKey);
      }
    });
  }

  public databaseName = "";
  public storeKey = "";
  public version = 1;
  public database: Promise<idb.IDBPDatabase<IDBDatabaseInitial>> | undefined = undefined;

  /**
   * @description get all values in database key.
   */
  public async all(
    /**
     * @description query/search for specifics
     */
    query?: string
  ) {
    if (!this.database) {
      return;
    }
    return (await this.database).getAll(this.storeKey, query);
  }
  public async get(key: string) {
    if (!this.database) {
      return;
    }
    return (await this.database).get(this.storeKey, key);
  }
  public async set(key: string, val: any) {
    if (!this.database) {
      return;
    }
    return (await this.database).put(this.storeKey, val, key);
  }
  public async del(key: string) {
    if (!this.database) {
      return;
    }
    return (await this.database).delete(this.storeKey, key);
  }

  /**
   * @description deletes all records in the store
   */
  public async clear() {
    if (!this.database) {
      return;
    }
    return (await this.database).clear(this.storeKey);
  }
  /**
   * @description get all keys in database key.
   */
  public async keys(
    /**
     * @description query/search for specifics
     */
    query?: string
  ) {
    if (!this.database) {
      return;
    }
    return (await this.database).getAllKeys(this.storeKey, query);
  }
}

export { IndexedDB };
