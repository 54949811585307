import { useEffect, useState } from "react";

/**
 * @description request user to login in dashboard using a popup window.
 */
const useDashboardAuth = () => {
  const [state, setState] = useState<{
    loginWindow: Window | null;
    sendMessageInterval: NodeJS.Timeout | undefined;
  }>({
    loginWindow: null,
    sendMessageInterval: undefined
  });

  const onOpenLogin = () => {
    setState((state) => {
      if (state.loginWindow) {
        state.loginWindow.close();
      }

      const windowOpened = window.open(process.env.REACT_APP_DASHBOARD_URL, "dashboard");
      if (state.sendMessageInterval) {
        clearInterval(state.sendMessageInterval);
      }

      const onListenAnswerFromMysoltivo = () => {
        windowOpened?.postMessage(
          {
            SOLTIVO_BUILDER: {
              login: "REQUEST"
            }
          },
          `${process.env.REACT_APP_DASHBOARD_URL}`
        );
      };

      const interval = setInterval(onListenAnswerFromMysoltivo, 1000);
      return {
        ...state,
        loginWindow: windowOpened,
        sendMessageInterval: interval
      };
    });
  };
  useEffect(() => {
    const onMessage = (e: MessageEvent<any>) => {
      if (e.origin === process.env.REACT_APP_DASHBOARD_URL) {
        const hasSetOrg = e.data?.SOLTIVO_BUILDER?.hasSetOrg;

        if (hasSetOrg) {
          state.loginWindow?.close();
          window.history.back();
        }
      }
    };
    window.addEventListener("message", onMessage, true);

    return () => {
      if (state.sendMessageInterval) {
        clearInterval(state.sendMessageInterval);
      }
      window.removeEventListener("message", onMessage, true);
    };
  }, [state.loginWindow]);

  return {
    ...state,
    onOpenLogin
  };
};

export default useDashboardAuth;
