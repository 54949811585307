import { CallEffect, delay, put, PutEffect, select, SelectEffect, TakeEffect, call } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import websiteClass from "../../../../helpers/api/website.class";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import actions from "redux/actions";

/**
 * @description get unsplash photos.
 */
export default function* getPhotos({
  payload
}: ReturnType<(typeof actions.api)["unsplash"]["actions"]["getPhotos"]["request"]>): Generator<
  CallEffect | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    yield delay(3000);

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      unsplashPhotos: WebsiteWizardReducer.unsplashPhotos
    });

    const { unsplashPhotos }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const data: UnsplashPhotos = yield call(websiteClass.websiteGetUnsplashPhotos, {
      page: payload.page,
      per_page: payload.per_page ? payload.per_page : GLOBAL_VARS.unsplash.perPage
    });

    yield put(
      actions.api.unsplash.actions.getPhotos.success({
        unsplashPhotos: {
          ...unsplashPhotos,
          loading: false,
          page: payload.page,
          results: payload.page === 1 ? data : [...unsplashPhotos.results, ...data]
        }
      })
    );
  } catch (error) {
    yield put(
      actions.api.unsplash.actions.getPhotos.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
