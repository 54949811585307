import { WindowMenuObject } from "../context";

/**
 * @description get window menu object from windows.
 */
export const getWindowMenuObject = (id: string, windowMenuList: WindowMenuObject[]) => {
  const windowMenu = windowMenuList.find((wm) => wm.id === id);

  if (!windowMenu) {
    return;
  }

  return windowMenu;
};
