import apiV2 from "./api.v2";

const _URL = `${process.env.REACT_APP_API_URL}/profile`;

/**
 * @description Authentication API calls.
 */
export class AdminClass {
  /**
   * @description api to get the status of user's profile
   */
  public async profileInfo() {
    return await apiV2.getRequest(`${_URL}`, {
      useToken: "accessToken"
    });
  }
}

export default new AdminClass();
