import { put, call } from "@redux-saga/core/effects";
import websiteClass from "../../../../helpers/api/website.class";
import { Service, ServiceCategory } from "@soltivo/types";
import { Color } from "@soltivo/draw-a-line";
import actions from "redux/actions";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { valueWithDefault } from "helpers/functions/valueWithDefault";

/**
 * @description get booking category service
 */
export default function* getAll({
  payload
}: ReturnType<(typeof actions.api)["booking"]["actions"]["getAll"]["request"]>): Generator<unknown, void, any> {
  try {
    const { data: serviceCategories }: { data: ServiceCategory[] } = yield call(
      websiteClass.websiteGetAllServiceCategories
    );
    const { data: servicesData }: { data: Service[] } = yield call(websiteClass.websiteGetAllServices);

    const services: WebsitePluginService[] = servicesData.map((service) => ({
      id: valueWithDefault("string", service.id, ""),
      title: valueWithDefault("string", service.title, ""),
      currency: valueWithDefault("string", service.currency, "usd"),
      color: valueWithDefault("string", service.color, Color["bluemine"]["bluemine-500"]),
      description: valueWithDefault("string", service.description, ""),
      price: valueWithDefault("number", service.price, 0),
      categoryId: valueWithDefault("string", service.category, ""),
      image: valueWithDefault("string", service.image, ""),
      duration: valueWithDefault("number", service.duration, 0),
      bufferTime: valueWithDefault("number", service.bufferTime, 0)
    }));

    const categories: WebsitePluginServiceCategory[] = serviceCategories.map((category) => ({
      color: valueWithDefault("string", category.color, Color["bluemine"]["bluemine-500"]),
      description: valueWithDefault("string", category.description, ""),
      id: valueWithDefault("string", category.id, ""),
      name: valueWithDefault("string", category.name, ""),
      // @ts-ignore: This api contains lowestPrice and the type is wrong.
      lowestPrice: valueWithDefault("number", category.lowestPrice as number, 0)
    }));

    yield put(
      actions.api.booking.actions.getAll.success({
        categories: categories,
        services: services
      })
    );
  } catch (error) {
    yield put(
      actions.api.booking.actions.getAll.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
