import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_REQUEST: "WEBSITE_UPDATE_REQUEST",
  WEBSITE_UPDATE_SUCCESS: "WEBSITE_UPDATE_SUCCESS",
  WEBSITE_UPDATE_FAILURE: "WEBSITE_UPDATE_FAILURE",

  WEBSITE_PLUGIN_STORE_REQUEST: "WEBSITE_PLUGIN_STORE_REQUEST",
  WEBSITE_PLUGIN_STORE_SUCCESS: "WEBSITE_PLUGIN_STORE_SUCCESS",
  WEBSITE_PLUGIN_STORE_FAILURE: "WEBSITE_PLUGIN_STORE_FAILURE"
};

const actions = {
  /**
   * @description update editable element (any)
   */
  update: {
    request: <T extends WebsiteDataClass>(payload: {
      dataClass: T;
      dataPath: WebsiteDataPath;
      value?: any;
      options?: T extends "plugin"
        ? {
            updatePluginApi?: true;
          }
        : undefined;
    }) => ({
      type: types.WEBSITE_UPDATE_REQUEST,
      payload
    }),
    success: (payload: { pages: WizardState["pages"]; website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_FAILURE,
      payload
    })
  },
  /**
   * @description store changes the user wants to keep and save in the api
   * when publish the theme.
   */
  pluginStore: {
    request: <T extends WebsiteDataPlugin>(payload: {
      type: T;
      value: T extends "booking/service"
        ? Omit<WebsitePlugin<T>, "image"> & { image: string | File }
        : WebsitePlugin<T>;
    }) => ({
      type: types.WEBSITE_PLUGIN_STORE_REQUEST,
      payload
    }),
    success: (payload: { plugins: WizardState["plugins"] }) => ({
      type: types.WEBSITE_PLUGIN_STORE_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_PLUGIN_STORE_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
