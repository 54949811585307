import { call, CallEffect, delay, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import websiteClass from "../../../../helpers/api/website.class";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import actions from "redux/actions";

/**
 * @description search unsplash photos.
 */
export default function* searchPhotos({
  payload
}: ReturnType<(typeof actions.api)["unsplash"]["actions"]["searchPhotos"]["request"]>): Generator<
  CallEffect | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    yield delay(3000);

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      unsplashSearch: WebsiteWizardReducer.unsplashSearch
    });

    const { unsplashSearch }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    if (!String(payload.query).length) return;

    const data: {
      results: UnplashPhotosSearch["results"];
      total: number;
      total_pages: number;
    } = yield call(websiteClass.websiteSearchUnsplashPhotos, {
      page: payload.page,
      per_page: payload.per_page ? payload.per_page : GLOBAL_VARS.unsplash.perPage,
      query: payload.query
    });

    yield put(
      actions.api.unsplash.actions.searchPhotos.success({
        unsplashSearch: {
          ...unsplashSearch,
          results: payload.page === 1 ? data.results : [...unsplashSearch.results, ...data.results],
          loading: false,
          query: payload.query,
          total: data.total,
          total_pages: data.total_pages,
          page: payload.page
        }
      })
    );
  } catch (error) {
    yield put(
      actions.api.unsplash.actions.searchPhotos.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
