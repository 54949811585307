import { GLOBAL_VARS } from "helpers/global";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/reducers";

/**
 * @description create link & style related to website fonts and insert inside iframe head.
 */
const useThemeFonts = (
  iframeIsLoaded: boolean,
  iframeWebsite: HTMLIFrameElement | undefined,
  website: RootState["Wizard"]["website"]
) => {
  const [state, setState] = useState<{
    elements: {
      style: HTMLStyleElement;
      link: HTMLLinkElement;
    };
  }>();

  useEffect(() => {
    const style = document.createElement("style");
    const link = document.createElement("link");

    style.setAttribute("id", GLOBAL_VARS.iframeWebsite.head.fontsStyle.id);
    style.innerHTML = `:root {\n --font-family-default: '${website.font.name}', sans-serif; \n}`;

    link.setAttribute("rel", "stylesheet");
    link.setAttribute("href", website.font.href);
    link.setAttribute("id", GLOBAL_VARS.iframeWebsite.head.fontsLink.id);

    if (iframeIsLoaded && iframeWebsite) {
      const iframeDocument = iframeWebsite.contentDocument;

      if (iframeDocument?.head) {
        const head = iframeDocument.head;
        //remove previous style.
        const oldStyle = head.querySelector(`#${GLOBAL_VARS.iframeWebsite.head.fontsStyle.id}`);
        const oldLink = head.querySelector(`#${GLOBAL_VARS.iframeWebsite.head.fontsLink.id}`);

        if (oldLink) {
          if (oldLink.getAttribute("href") !== link.getAttribute("href")) {
            // add new changes on for the link
            head.removeChild(oldLink);
            head.appendChild(link);
          }
        } else {
          head.appendChild(link);
        }
        if (oldStyle) {
          if (oldStyle.innerHTML !== style.innerHTML) {
            // add new changes on for the styles
            head.removeChild(oldStyle);
            head.appendChild(style);
          }
        } else {
          head.appendChild(style);
        }
      }
    }

    setState((state) => {
      return {
        ...state,
        elements: {
          style,
          link
        }
      };
    });
  }, [iframeWebsite?.contentDocument?.readyState, website?.font, website?.themeId, iframeIsLoaded]);

  return state;
};

export default useThemeFonts;
