import { InputSelectHybrid } from "@soltivo/draw-a-line";
import { Info24Px } from "@soltivo/draw-a-line/core/components/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ContentHeader from "components/content.header/content.header";
import VisibleSwitch from "components/visible.switch/visible.switch";
import styles from "./styles.module.scss";
import actions from "redux/actions";
import { getStatsPath } from "helpers/functions/getStatsPath";

const Logo = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    themeURL: Wizard.themeURL
  });

  const { website, themeURL } = useSelector(mapStateToProps);

  const onChange = (payload: { name: string; value: unknown }) => {
    if (!website.menu.data.logo) {
      return;
    }
    dispatch(
      actions.theme.menu.actions.update.request({
        menu: { logo: { ...website.menu.data.logo, [payload.name]: payload.value as WebsiteLogoKey } }
      })
    );
  };

  if (!website.menu.data.logo) {
    return null;
  }

  return (
    <div className={styles.component}>
      <ContentHeader title="Logo parameters">
        <VisibleSwitch
          checked={website.menu.data.logo.visible}
          onChange={(e) => {
            onChange({ name: "visible", value: e.target.checked });
          }}
        />
      </ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div style={{ display: "grid", gridColumn: "1/2" }}>
          <label htmlFor="type">
            Type{" "}
            <Info24Px
              data-title={`Choose which logo defined \n on configurations you want \n to use in your header.`}
              data-title-position="bottom-right"
            />
          </label>

          <InputSelectHybrid
            selectProps={{
              disabled: !website.menu.data.logo?.visible,
              name: "logoKey",
              value: website.menu.data.logo?.logoKey,
              onChange: (e) => {
                onChange({ name: "logoKey", value: e.target.value });
              },
              placeholder: "Select a logo"
            }}>
            {website.logo?.primary.src && <option value={"primary"}>Primary logo</option>}
            {website.logo?.secondary.src && <option value={"secondary"}>Secondary logo</option>}
            <option value={"text"}>Website name</option>
          </InputSelectHybrid>
        </div>

        <div style={{ display: "grid", gridColumn: "1/3" }}>
          <label htmlFor="test">Overview</label>
          <div className={`${styles.overview} ${!website.menu.data.logo?.visible ? styles.disabled : ""} `}>
            {website.menu.data.logo.logoKey !== "text" ? (
              <img
                src={getStatsPath(themeURL, website.logo[website.menu.data.logo.logoKey].src).path || ""}
                alt={website.logo[website.menu.data.logo.logoKey].alt || ""}
              />
            ) : website.menu.data.logo.logoKey === "text" ? (
              <span style={{ fontFamily: `"${website.font.name}", sans-serif` }}>{website.name}</span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
