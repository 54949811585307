import { Button, Column, Row } from "@soltivo/draw-a-line";
import Colors from "blocks/colors/colors";
import Fonts from "blocks/fonts/fonts";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useEffect } from "react";
import styles from "./styles.module.scss";
const Customization = () => {
  const { onFooterUpdate, onMoveToPage } = WindowMenu.useWindowMenu();

  useEffect(() => {
    onFooterUpdate(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key, () => {
      return (
        <>
          <Button
            variant="primary"
            onClick={() => {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
                pageId: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_END
              });
            }}>
            Next <span className="material-symbols-outlined">navigate_next</span>
          </Button>
        </>
      );
    });
  }, []);

  return (
    <div className={styles.component}>
      <Row>
        <Column size={"6"}>
          <p className={styles.label}>Colors</p>
          <Colors />
        </Column>

        <Column size={"6"}>
          <p className={styles.label}>Fonts</p>
          <Fonts />
        </Column>
      </Row>
    </div>
  );
};

export default Customization;
