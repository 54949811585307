import { DOMIframeWebsiteResolution } from "helpers/functions/DOMIframeWebsiteResolution";
import { GLOBAL_VARS } from "helpers/global";
import React, { FC, useEffect, useRef, useState } from "react";
import { RootState } from "redux/reducers";
import styles from "./styles.module.scss";

export interface EditableWrapperProps {
  iframeWebsite: HTMLIFrameElement;
  device: RootState["Wizard"]["device"];
  target: HTMLElement;
  dataClass: WebsiteDataClass;
  dataPath?: WebsiteDataPath;
  children: (props: {
    refs: {
      parent: React.RefObject<HTMLDivElement>;
      child: React.RefObject<HTMLDivElement>;
    };
    editableStyles:
      | {
          size: EditableSize;
          editableCssText: string;
        }
      | undefined;
  }) => JSX.Element;
}

const EditableWrapper: FC<EditableWrapperProps> = ({
  iframeWebsite,
  target,
  dataClass,
  dataPath,
  children,
  device
}) => {
  const parentElRef = useRef<HTMLDivElement>(null);
  const childElRef = useRef<HTMLDivElement>(null);
  const [editableStyles, setEditableStyles] = useState<ReturnType<typeof onHandleStyles>>();
  // const [update] = useState<number>(0);

  /**
   * @description set styling and position for editableElement on parent window.
   */
  const onHandleStyles = (
    iframeWebsite: HTMLIFrameElement,
    dataClass: ModifyConfig["dataClass"],
    target: HTMLElement,
    elements: {
      parentEl: HTMLElement;
      childEl?: HTMLElement;
    }
  ) => {
    const { parentEl, childEl } = elements;

    const iframeRect = iframeWebsite.getBoundingClientRect();
    const iframeBodyRect = iframeWebsite.contentDocument?.body.getBoundingClientRect();

    if (!iframeBodyRect || !iframeWebsite?.contentWindow || !iframeWebsite?.contentDocument) {
      return;
    }

    // Get current scaled iframe scale.
    const iframeScale = DOMIframeWebsiteResolution(
      iframeWebsite,
      { width: device.width, height: device.height },
      { updateDOM: false }
    );
    if (!iframeScale) {
      return;
    }

    const index = GLOBAL_VARS.styles.editableElementIndex;
    const targetRect = target.getBoundingClientRect();

    // console.log(targetRect, iframeRect);
    // targetRect.top = targetRect.top - (targetRect.top * iframeScale.y.percentageY) / 100;
    // targetRect.left = targetRect.left - (targetRect.left * iframeScale.x.percentageX) / 100;
    // fix border to move a bit more to the right or bottom.
    const fixer = 0;

    // iframe scollbar width
    const iframeScrollWidth =
      iframeWebsite?.contentWindow.innerWidth - iframeWebsite.contentDocument.documentElement.clientWidth + fixer;

    // adjust if element is on greater than top postion.
    const POSITION_TOP = targetRect.top + iframeRect.top;
    let positionTop = POSITION_TOP <= iframeRect.top ? iframeRect.top + 1 : POSITION_TOP;

    // adjust if element is hidden with position left/right.
    const POSITION_LEFT = targetRect.left + iframeRect.left;
    let positionLeft = POSITION_LEFT <= iframeRect.left ? iframeRect.left + fixer : POSITION_LEFT;

    if (dataClass === "text") {
      positionTop = POSITION_TOP;
    }

    let calcY = targetRect.top - (targetRect.top * iframeScale.y.percentageY) / 100;
    if (iframeScale.y.percentageY >= 100) {
      // overide because the scaling is incressing the size of the frame.
      calcY = (targetRect.top * iframeScale.y.percentageY) / 100;
    }

    let calcX = targetRect.left - (targetRect.left * iframeScale.x.percentageX) / 100;
    if (iframeScale.x.percentageX >= 100) {
      // overide because the scaling is incressing the size of the frame.
      calcX = (targetRect.left * iframeScale.x.percentageX) / 100;
    }

    //transformation position based on scale.
    positionTop = iframeRect.top + calcY;
    positionLeft = iframeRect.left + calcX;

    // This css rule is only for elements which doesn't have defined width & height, or may have dinamic
    // sizing like text.
    const transformStyle = `
    transform: scaleX(${iframeScale.x.scaleX}) scaleY(${iframeScale.y.scaleY});
    -ms-transform: scaleX(${iframeScale.x.scaleX}) scaleY(${iframeScale.y.scaleY});
    -webkit-transform: scaleX(${iframeScale.x.scaleX}) scaleY(${iframeScale.y.scaleY});
    -o-transform: scaleX(${iframeScale.x.scaleX}) scaleY(${iframeScale.y.scaleY});
    -moz-transform: scaleX(${iframeScale.x.scaleX}) scaleY(${iframeScale.y.scaleY});
    `;

    //adjust if element is bigger than body of iframe left/right.
    let elWidth =
      targetRect.width < iframeRect.width - 1 ? targetRect.width : iframeScale.x.scaleSizeX - iframeScrollWidth;

    //adjust if element is bigger than body of iframe top/bottom.
    let elHeight =
      POSITION_TOP < iframeRect.top ? targetRect.height - (iframeRect.top - POSITION_TOP) : targetRect.height;

    // tranformation based on scale.
    let calcWidth = targetRect.width - (targetRect.width * iframeScale.x.percentageX) / 100;

    if (iframeScale.x.percentageX >= 100) {
      // overide because the scaling is incressing the size of the frame.
      calcWidth = (targetRect.width * iframeScale.x.percentageX) / 100;
    }

    let calcHeight = targetRect.height - (targetRect.height * iframeScale.y.percentageY) / 100;
    if (iframeScale.y.percentageY >= 100) {
      // overide because the scaling is incressing the size of the frame.
      calcHeight = (targetRect.height * iframeScale.y.percentageY) / 100;
    }

    elWidth = calcWidth;
    elHeight = calcHeight;

    const sizes = {
      xsm: elWidth < 150 || elHeight < 150,
      sm: elWidth < 200 || elHeight < 200,
      md: elWidth >= 100 || elHeight >= 200
    };

    const elSize = Object.keys(sizes).find(
      (s: string) => (sizes as { [index: string]: boolean })[s] === true
    ) as EditableSize;
    const styleComputed = iframeWebsite?.contentWindow?.getComputedStyle(target, null);

    switch (dataClass) {
      case "text":
        if (styleComputed) {
          const childProps: (keyof CSSStyleDeclaration)[] = [
            "color",
            "display",
            "font",
            "fontFamily",
            "fontSize",
            "fontStyle",
            "fontWeight",
            "letterSpacing",
            "lineHeight",
            "textAlign",
            "textIndent",
            "wordSpacing",
            "textTransform",
            "whiteSpace",
            "wordBreak",
            "wordSpacing",
            "wordWrap"
          ];

          const parentProps: (keyof CSSStyleDeclaration)[] = [
            "backgroundColor",
            "boxSizing",
            "borderRadius",
            "borderTopLeftRadius",
            "borderTopRightRadius",
            "borderBottomLeftRadius",
            "borderBottomRightRadius",
            "display",
            "letterSpacing",
            "lineHeight",
            "padding",
            "paddingLeft",
            "paddingRight",
            "paddingTop",
            "paddingBottom",
            "textAlign",
            "textIndent",
            "wordSpacing",
            "textTransform",
            "whiteSpace",
            "wordBreak",
            "wordSpacing",
            "wordWrap",
            "width",
            "height"
          ];

          if (childEl) {
            for (let i = 0; i < childProps.length; i++) {
              const key = childProps[i];
              const propertyFormat = key
                .toString()
                .replace(/([A-Z])/g, "-$1")
                .toLowerCase();
              childEl.style.setProperty(propertyFormat, `${styleComputed[key]}`);
            }
          }

          for (let i = 0; i < parentProps.length; i++) {
            const key = parentProps[i];
            const propertyFormat = key
              .toString()
              .replace(/([A-Z])/g, "-$1")
              .toLowerCase();
            parentEl.style.setProperty(propertyFormat, `${styleComputed[key]}`);
          }
        }

        // detect if is auto sizing.
        if (parentEl.dataset["resizing"] !== "true") {
          // adding data set resizing to the parent to make sure we don't over do this
          // same task on the browser.
          parentEl.dataset["resizing"] = "true";
          const node = target.cloneNode(true) as HTMLElement;
          document.body.appendChild(node);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (node instanceof iframeWebsite.contentWindow?.["HTMLElement"]) {
            const initValueWidth = iframeWebsite.contentWindow?.getComputedStyle(node).width;
            node.innerText = node.innerText + "testing width";
            const lastValueWidth = iframeWebsite.contentWindow?.getComputedStyle(node).width;

            if (initValueWidth !== lastValueWidth) {
              parentEl.style.setProperty("width", "auto");
            }
          }
          document.body.removeChild(node);
        }

        parentEl.setAttribute(
          "style",
          `
          ${parentEl.style.cssText};
          position: fixed !important; 
          top: ${positionTop}px !important; 
          left:${positionLeft}px !important; 
          z-index:${index} !important; 
          pointer-events: none;
          ${transformStyle}
          `
        );

        if (!target.classList.contains("editable-hidden")) {
          target.classList.add("editable-hidden");
        }

        break;

      case "link":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "button":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "map":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "icon":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "image":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "background-image":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "video":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "audio":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "background-video":
        parentEl.setAttribute(
          "style",
          `
          position:fixed;
          display:flex;
          align-items:center;
          justify-content:center;
          width:${elWidth}px;
          height:${elHeight}px;
          top: ${positionTop}px;
          left:${positionLeft}px;
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "form":
        parentEl.setAttribute(
          "style",
          `
          position:fixed;
          display:flex;
          align-items:center;
          justify-content:center;
          width:${elWidth}px;
          height:${elHeight}px;
          top: ${positionTop}px;
          left:${positionLeft}px;
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "object":
        parentEl.setAttribute(
          "style",
          `
          position:fixed;
          width:${elWidth}px;
          height:${elHeight}px;
          top: ${positionTop}px;
          left:${positionLeft}px;
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "plugin":
        parentEl.setAttribute(
          "style",
          `
          position:fixed;
          width:${elWidth}px;
          height:${elHeight}px;
          top: ${positionTop}px;
          left:${positionLeft}px;
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "menu":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      case "footer":
        parentEl.setAttribute(
          "style",
          `
          position:fixed; 
          display:flex; 
          align-items:center; 
          justify-content:center; 
          width:${elWidth}px; 
          height:${elHeight}px; 
          top: ${positionTop}px; 
          left:${positionLeft}px; 
          z-index:${index};
          pointer-events: none;
          `
        );
        break;

      default:
        break;
    }

    return {
      size: elSize,
      editableCssText: parentEl.style.cssText
    };
  };

  const findTarget = (
    iframeWebsite: HTMLIFrameElement,
    options: {
      dataClass: WebsiteDataClass;
      dataPath?: WebsiteDataPath;
    }
  ) => {
    const globals: WebsiteDataClass[] = ["menu", "footer"];
    const { dataClass, dataPath } = options;
    let target: HTMLElement | undefined;

    if (globals.includes(dataClass)) {
      const element = iframeWebsite.contentDocument?.querySelector<HTMLElement>(`[data-class="${dataClass}"]`);
      if (element !== null) {
        target = element;
      }
    } else {
      const classTargets = iframeWebsite.contentDocument?.querySelectorAll<HTMLElement>(`[data-class="${dataClass}"]`);

      if (classTargets !== undefined) {
        if (classTargets.length > 1 && dataPath) {
          for (let i = 0; i < classTargets.length; i++) {
            const classTarget = classTargets[i];
            if (classTarget.dataset["path"] === dataPath) {
              target = classTarget;
              break;
            }
          }
        } else {
          target = classTargets[0];
        }
      }
    }

    return target;
  };

  useEffect(() => {
    if (!parentElRef.current) {
      return;
    }
    const _target = iframeWebsite.contentDocument?.contains(target)
      ? target
      : findTarget(iframeWebsite, { dataClass, dataPath });
    if (!_target) {
      return;
    }

    switch (dataClass) {
      case "text":
        if (!childElRef.current) return;

        setEditableStyles(
          onHandleStyles(iframeWebsite, "text", _target, {
            parentEl: parentElRef.current,
            childEl: childElRef.current
          })
        );

        // ✅ Another way to watch for changes on elements.

        // const observer = new MutationObserver((entries) => {
        //     if (!parentElRef.current || !childElRef.current) return;

        //     setEditableStyle(iframeWebsite, "text", target, {
        //         parentEl: parentElRef.current,
        //         childEl: childElRef.current,
        //     });
        // });

        // observer.observe(target, {
        //     attributeFilter: ["innerText"],
        //     subtree: true,
        //     attributeOldValue: true,
        //     attributes: true,
        //     characterData: true,
        //     characterDataOldValue: true,
        //     childList: true,
        // });

        break;

      case "link":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "link", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "button":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "button", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "image":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "image", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "background-image":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "background-image", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "video":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "video", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "audio":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "audio", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "background-video":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "background-video", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "map":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "map", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "icon":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "icon", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "form":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "form", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "object":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "image", _target, {
            parentEl: parentElRef.current
          })
        );

        break;

      case "plugin":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "image", _target, {
            parentEl: parentElRef.current
          })
        );

        break;

      case "menu":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "menu", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      case "footer":
        setEditableStyles(
          onHandleStyles(iframeWebsite, "footer", _target, {
            parentEl: parentElRef.current
          })
        );
        break;

      default:
        break;
    }
  }, [parentElRef.current]);

  // useEffect(() => {
  //     // Watch innerHTML changes to update builder highlight.
  //     const innerHTML = target.innerHTML;

  //     let i = setInterval(() => {
  //         if (innerHTML !== target.innerHTML) {
  //             setUpdate((state) => state + 1);
  //         }
  //     }, 10);

  //     return () => {
  //         clearInterval(i);
  //     };
  // }, [target.innerHTML]);

  useEffect(() => {
    const parentEl = parentElRef.current;
    const childEl = childElRef.current;
    if (!parentEl) return;
    const interval = setInterval(() => {
      const _target = iframeWebsite.contentDocument?.contains(target)
        ? target
        : findTarget(iframeWebsite, { dataClass, dataPath });
      if (!_target) {
        return;
      }

      onHandleStyles(iframeWebsite, dataClass, _target, {
        parentEl: parentEl,
        childEl: childEl || undefined
      });
    });

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div
      data-editable-label={dataClass}
      className={styles.component}
      style={{
        position: "fixed"
      }}
      ref={parentElRef}>
      {dataClass === "text" ? (
        <span data-editable-label={dataClass}>
          <span ref={childElRef}>
            {children({
              refs: {
                child: childElRef,
                parent: parentElRef
              },
              editableStyles
            })}
          </span>
        </span>
      ) : (
        children({
          refs: {
            child: childElRef,
            parent: parentElRef
          },
          editableStyles
        })
      )}
    </div>
  );
};

export default EditableWrapper;
