import { call, CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { toastHelper } from "@soltivo/draw-a-line";
import websiteClass from "../../../../helpers/api/website.class";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { validateWebsiteData } from "helpers/functions/validateWebsiteData";
import actions from "redux/actions";

/**
 * @description publish website
 */
export default function* publish({
  type
}: ReturnType<(typeof actions.theme)["data"]["actions"]["publish"]["request"]>): Generator<
  CallEffect | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      pages: WebsiteWizardReducer.pages
    });

    const { pages, website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const checkWebsite = validateWebsiteData({
        ...website,
        page: page
      });

      if (!checkWebsite.valid) {
        throw checkWebsite.errors[0];
      }
    }

    yield call(websiteClass.websitePublish, { global: website, pages: pages });

    yield put(actions.theme.data.actions.publish.success());

    /**
     * update changes with plugins in the database. (if the user requested.)
     */
    yield put(actions.api.booking.actions.update.request());

    toastHelper.toastStartContent("success", "Website published successfully.");
  } catch (error) {
    yield put(
      actions.theme.data.actions.publish.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
