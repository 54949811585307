import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { ModelWebsite } from "helpers/models";
import actions from "redux/actions";

interface UseGetClassDataProps<T extends WebsiteDataClass> {
  dataPath: WebsiteDataPath;
  dataClass: T;
}

const useEditableData = <T extends WebsiteDataClass>({ dataClass, dataPath }: UseGetClassDataProps<T>) => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    validation: Wizard.validation,
    pageId: Wizard.pageId
  });

  const { website, validation, pageId } = useSelector(mapStateToProps);
  const modelWebsite = new ModelWebsite(website);

  const editableData: EditableClassMap[T] | undefined = modelWebsite.getEditableData({
    dataPath: dataPath,
    website: modelWebsite.object
  });

  const editableValidation = modelWebsite.getEditableValidation({
    pageId: pageId,
    validation: validation,
    dataPath: dataPath,
    dataClass: dataClass
  }) as unknown as EditableValidationClassMap[T] | undefined;

  /**
   * @description update editable element object
   */
  const onUpdate = (payload: Record<string, any>) => {
    if (!editableData) {
      console.error(new Error(`[Editable Update Error]: Missing data of ${dataClass} for ${dataPath} path.`));
      return;
    }

    if (!editableValidation) {
      console.error(new Error(`[Editable Update Error]: Missing validation of ${dataClass} for ${dataPath} path.`));
      return;
    }

    dispatch(
      actions.theme.editable.actions.update.request({
        dataPath: dataPath,
        dataClass: dataClass,
        value: {
          ...(editableData as unknown as Record<string, any>),
          ...payload
        }
      })
    );
  };

  return {
    data: editableData,
    validation: editableValidation,
    onUpdate
  };
};

export default useEditableData;
