import React from "react";
import "./not.found.styles.scss";
import { ReactComponent as SteveSadSVG } from "@soltivo/draw-a-line/core/assets/illutrations/SteveSad.svg";

const NotFound = () => {
  return (
    <div id="not-found">
      <div className="container">
        <SteveSadSVG />
        <h2>Page not found.</h2>
        <p>Looks like you are trying to access a page which does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
