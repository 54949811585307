import types from "../../actions/types";
import { CognitoUser } from "amazon-cognito-identity-js";
import { User, Organization } from "@soltivo/types";
import { ErrorApp } from "helpers/classes/ErrorApp";

export interface AppState {
  organization: {
    loading: boolean;
    data: Organization;
  };
  cognitorUser: {
    loading: boolean;
    data: CognitoUser;
  };
  user: {
    loading: boolean;
    data: User;
  };
  /**
   * @description errors in the builder.
   */
  error: ErrorApp | undefined;
}

const INITIAL_STATE: AppState = {
  organization: {
    loading: true,
    data: undefined as any // checked when app loads.
  },
  cognitorUser: {
    loading: true,
    data: undefined as any // checked when app loads.
  },
  user: {
    loading: true,
    data: undefined as any // checked when app loads.
  },
  error: undefined
};

const AppReducer = (
  state = INITIAL_STATE,
  action: {
    payload?: any;
    type: string;
  }
): AppState => {
  switch (action.type) {
    case types.APP_GET_CREDENTIALS_REQUEST: {
      return INITIAL_STATE;
    }

    case types.APP_GET_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        cognitorUser: action.payload.cognitorUser,
        organization: action.payload.organization,
        user: action.payload.user,
        error: undefined
      };
    }

    case types.APP_GET_CREDENTIALS_PARTIAL_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case types.APP_GET_CREDENTIALS_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }

    default:
      return state;
  }
};

export default AppReducer;
