import { GLOBAL_VARS } from "helpers/global";
import { RootState } from "redux/reducers";
import { errorWizard, createErrorApp } from "helpers/classes/ErrorApp";
import { createObjectAttributePath, getLastDataValue } from "helpers";
import _get from "lodash.get";
import {
  ValidateTypeBoolean,
  ValidateTypeCurrency,
  ValidateTypeEmail,
  ValidateTypeImage,
  ValidateTypeNumber,
  ValidateTypePhoneNumber,
  ValidateTypeString,
  ValidateTypeUrl
} from "helpers/validations/typeof";
import {
  ValidateClassAudio,
  ValidateClassBackgroundImage,
  ValidateClassBackgroundVideo,
  ValidateClassButton,
  ValidateClassGroup,
  ValidateClassIcon,
  ValidateClassImage,
  ValidateClassLink,
  ValidateClassMap,
  ValidateClassObject,
  ValidateClassPlugin,
  ValidateClassText,
  ValidateClassVideo
} from "helpers/validations/classof";
import { ModelPage } from "../ModelPage/ModelPage";
import { ValidateClassForm } from "helpers/validations/classof/form/form";
import { ValidateTypeIcon } from "helpers/validations/typeof/icon/icon";

class ModelValidation implements Validation {
  constructor(validation?: Validation) {
    if (validation) {
      this.contact = validation.contact;
      this.footer = validation.footer;
      this.general = validation.general;
      this.menu = validation.menu;
      this.pages = validation.pages;
      this.routes = validation.routes;
    }
  }

  public readonly contact: Validation["contact"] = {
    classof: "object",
    attributes: {
      information: {
        typeof: "object",
        attributes: {}
      },
      socialMedia: {
        typeof: "array",
        items: {
          typeof: "object",
          rules: {},
          attributes: {}
        }
      }
    }
  };
  public readonly footer: Validation["footer"] = {
    classof: "footer",
    attributes: {}
  };
  public readonly general: Validation["general"] = {
    classof: "object",
    attributes: {}
  };
  public readonly menu: Validation["menu"] = {
    classof: "menu",
    attributes: {}
  };
  public readonly pages: Validation["pages"] = [];
  public readonly routes: Validation["routes"] = {
    classof: "object",
    attributes: {
      list: {
        typeof: "array",
        items: {
          typeof: "object",
          rules: {},
          attributes: {}
        }
      }
    }
  };

  get object(): Validation {
    return {
      contact: this.contact,
      footer: this.footer,
      general: this.general,
      menu: this.menu,
      pages: this.pages,
      routes: this.routes
    };
  }

  findPageById = new ModelPage().findPageById;

  getSectionIndex = new ModelPage().getSectionIndex;

  /**
   * @description get validation of editable elements inside the body.
   * @example <div data-path="(section)/example/id:48/example/{last_property}></div>"
   */
  getEditableValidation<T extends WebsiteDataValidation>(payload: {
    pageId: WebsitePage["id"];
    validation: RootState["Wizard"]["validation"];
    dataPath: WebsiteDataPath;
    dataClass: WebsiteDataClass;
  }) {
    const { dataPath, pageId, validation } = payload;
    try {
      const pageValidation = this.findPageById(validation.pages, pageId);
      const pageValidationIndex = validation.pages.findIndex((page) => page.id === pageId);
      const pageValidationSections = pageValidation?.sections;
      const dataPathSplit = dataPath.split("/");
      const sectionName = dataPathSplit[0];
      const sectionValidationIndex = pageValidationSections
        ? this.getSectionIndex(pageValidationSections, sectionName)
        : -1;

      if (pageValidationIndex === -1) {
        throw new Error(`Unable to find page with data-path="${dataPath}" inside validation.`);
      }

      if (sectionValidationIndex === -1) {
        throw new Error(`Unable to find section with data-path="${dataPath}" inside validation.`);
      }

      const path = `pages.[${pageValidationIndex}].sections.[${sectionValidationIndex}]`;
      const sectionValidationObject = _get(validation, path);
      const result: T = getLastDataValue(sectionValidationObject, dataPath, { isValidation: true });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description finds the last property of a editable element.
   * @observation if first items of data type is "global" this function will look at the global of the validation
   * otherwise it will look for a section.name inside the actual page.
   * @example <div data-path="(section | "global")/example/id:48/example/{last_property}></div>"
   */
  filterPropertyInValidation(payload: {
    pageId: WebsitePage["id"];
    validation: RootState["Wizard"]["validation"];
    dataPath: string;
  }) {
    const { dataPath, pageId, validation } = payload;
    try {
      const page = validation.pages?.find((p) => p.id === pageId);
      const sections = page?.sections;
      const dataPathSplit = dataPath.split("/");
      const parent = dataPathSplit[0];
      const section = sections?.find((s) => s.name === parent);
      let actualValue: any = section?.data;
      if (!actualValue) return;
      // console.log(actualValueV2);
      dataPathSplit.shift();

      if (!actualValue) return;

      if (parent !== "global") {
        // class of first level.
        actualValue = actualValue[dataPathSplit[0]];
        dataPathSplit.shift();

        // if it's an array or object it will loop through
        for (let i = 0; i < dataPathSplit.length; i++) {
          const attribute = dataPathSplit[i];

          if (/(:)/g.test(attribute) && (actualValue.classof === "group" || actualValue.typeof === "array")) {
            /**
             * @description splited dataPath object type
             * @example
             * "photos/id:10" = ["id", "10"]
             */
            // const objectIndexes = attribute.split(":");

            if (actualValue.items) {
              actualValue = actualValue["items"];
            } else {
              errorWizard(`Failed to retrive validation for "${dataPath}".`);
            }
          } else if (
            /([0-9]+)/g.test(attribute) &&
            (actualValue.classof === "group" || actualValue.typeof === "array")
          ) {
            // In case of array index number, all items which is the definition unique for an array.

            if (actualValue.items) {
              actualValue = actualValue["items"];
            } else {
              errorWizard(`Failed to retrive validation for "${dataPath}".`);
            }
          }
        }
      } else {
        actualValue = actualValue[dataPathSplit[0]];
        dataPathSplit.shift();

        // if it's an array or object it will loop through
        for (let i = 0; i < dataPathSplit.length; i++) {
          const attribute = dataPathSplit[i];

          if (/(:)/g.test(attribute) && (actualValue.classof === "group" || actualValue.typeof === "array")) {
            /**
             * @description splited dataPath object type
             * @example
             * "photos/id:10" = ["id", "10"]
             */
            const objectIndexes = attribute.split(":");

            if (actualValue.items) {
              actualValue = actualValue["items"][objectIndexes[1]];
            } else {
              errorWizard(`Failed to retrive validation for "${dataPath}".`);
            }
          } else if (
            /([0-9]+)/g.test(attribute) &&
            (actualValue.classof === "group" || actualValue.typeof === "array")
          ) {
            // In case of array index number, all items which is the definition unique for an array.

            if (actualValue.items) {
              actualValue = actualValue["items"];
            } else {
              errorWizard(`Failed to retrive validation for "${dataPath}".`);
            }
          }
        }
      }

      return actualValue;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description validate global.website.json pages using validation.website.json
   */
  public validate(
    validation: any, // WebsiteDataValidation | WebsiteDataValidationType
    payload: any
  ) {
    const classes = GLOBAL_VARS.validaton.classes;
    if (typeof validation.typeof !== "undefined") {
      try {
        const types = GLOBAL_VARS.validaton.types;
        if (!types.includes(validation.typeof)) {
          throw `Validation typeof "${validation.typeof}" is unknown.`;
        } else if (validation.typeof === "string") {
          ValidateTypeString(validation, payload);
        } else if (validation.typeof === "number") {
          ValidateTypeNumber(validation, payload);
        } else if (validation.typeof === "currency") {
          ValidateTypeCurrency(validation, payload);
        } else if (validation.typeof === "email") {
          ValidateTypeEmail(validation, payload);
        } else if (validation.typeof === "phone-number") {
          ValidateTypePhoneNumber(validation, payload);
        } else if (validation.typeof === "url") {
          ValidateTypeUrl(validation, payload);
        } else if (validation.typeof === "boolean") {
          ValidateTypeBoolean(validation, payload);
        } else if (validation.typeof === "image") {
          ValidateTypeImage(validation, payload);
        } else if (validation.typeof === "icon") {
          ValidateTypeIcon(validation, payload);
        }
      } catch (e) {
        if (typeof e === "string") throw createErrorApp(e);
      }
    } else if (typeof validation.classof !== "undefined") {
      if (!classes.includes(validation.classof)) {
        throw createErrorApp(`Validation classof or typeof "${validation.typeof}" is unknown.`);
      } else if (Array.isArray(payload) && validation.classof !== "group") {
        throw `Array should have group validation, received ${validation.classof}.`;
      } else if (validation.classof === "group") {
        ValidateClassGroup(validation, payload);
      } else if (validation.classof === "form") {
        ValidateClassForm(validation, payload);
      } else if (validation.classof === "object") {
        ValidateClassObject(validation, payload);
      } else if (validation.classof === "plugin") {
        ValidateClassPlugin(validation, payload);
      } else if (validation.classof === "image") {
        ValidateClassImage(validation, payload);
      } else if (validation.classof === "background-image") {
        ValidateClassBackgroundImage(validation, payload);
      } else if (validation.classof === "video") {
        ValidateClassVideo(validation, payload);
      } else if (validation.classof === "background-video") {
        ValidateClassBackgroundVideo(validation, payload);
      } else if (validation.classof === "audio") {
        ValidateClassAudio(validation, payload);
      } else if (validation.classof === "button") {
        ValidateClassButton(validation, payload);
      } else if (validation.classof === "link") {
        ValidateClassLink(validation, payload);
      } else if (validation.classof === "text") {
        ValidateClassText(validation, payload);
      } else if (validation.classof === "map") {
        ValidateClassMap(validation, payload);
      } else if (validation.classof === "icon") {
        ValidateClassIcon(validation, payload);
      }
    }
  }

  /**
   * @description start validation
   */
  public startValidation(
    parentObject: {
      newSection?: WebsiteSection;
      newProperty?: any; // any global property of website that has data attribute inside, like nav.
    },
    validation: RootState["Wizard"]["validation"],
    options: {
      pageId: WebsitePage["id"];
      dataPath: WebsiteDataPath;
      dataClass: WebsiteDataClass;
    }
  ) {
    const { newSection, newProperty } = parentObject;
    const { dataPath, dataClass, pageId } = options;
    const dataPathSplit = dataPath.split("/");
    dataPathSplit.shift(); // remove global or section name
    if (newSection) {
      //validation of a page
      // const vPage = validation?.pages.find((p: WebsitePage) => p.id === pageId);
      //validation of a section
      // const vSection = findSectionByName(vPage?.sections, newSection.name);
      //first value always data.
      const sectionData = newSection.data;
      // let validationData = vSection?.data;
      let test = dataPath
        .replace(/[a-z_-]+\//, "")
        .replace(/\/([0-9]+)/g, "[$1]")
        .replace(/\//g, ".");
      test = `${test}`;
      const testData = createObjectAttributePath(sectionData, test);

      const dataResult = _get(sectionData, testData);
      const validationResult = this.getEditableValidation({
        pageId: pageId,
        dataPath: dataPath,
        dataClass: dataClass,
        validation: validation
      });

      if (validationResult) {
        this.validate(validationResult, dataResult);
      } else {
        throw createErrorApp(`Unable to update element without validation.`);
      }
    } else if (newProperty) {
      //website global validation
      const vWebsite = validation;
      //global property of the validation
      const vProperty = vWebsite[dataPathSplit[0] as ReturnType<() => keyof typeof vWebsite>];
      //first value always data.
      const globalData = newProperty;
      // let validationData = vProperty?.data;
      const validationData = vProperty;
      let test = dataPath
        .replace(/global\//, "")
        .replace(/([a-z0-9]+\/)/, "")
        .replace(/\/([0-9]+)/g, "[$1]")
        .replace(/\//g, ".");
      test = `${test}`;
      const testData = createObjectAttributePath(globalData, test);
      const testValidation = createObjectAttributePath(validationData, test);
      // console.log(testData, testValidation);
      const dataResult = _get(globalData, testData);
      const validationResult = _get(validationData, testValidation.replace(/(\[undefined\])/g, "[0]"));
      this.validate(validationResult, dataResult);
    }
  }
}

export { ModelValidation };
