import { CallEffect, put, PutEffect, select, call, SelectEffect, TakeEffect, delay } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { soltivoHelper, toastHelper } from "@soltivo/draw-a-line";
import websiteClass from "../../../../helpers/api/website.class";
import apiV2 from "../../../../helpers/api/api.v2";
import { createErrorApp, ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import { getImageDimensions, processImageSize } from "helpers";
import { ModelWebsite } from "helpers/models";
import { theme } from "redux/actions";

/**
 * @description update logo attributes of a theme
 */
export default function* update({
  payload
}: ReturnType<(typeof theme)["logo"]["actions"]["update"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  const codeException = "updateLogoException";

  try {
    yield delay(1000);
    const { logo } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website
    });

    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    if (logo.favicon && logo.favicon instanceof File) {
      const res: {
        w: number;
        h: number;
      } = yield call(getImageDimensions, logo.favicon);

      if (res.h < 32 || res.w < 32) {
        throw createErrorApp("Failed to process favicon image, resolution must be greater or equal to 32x32.", {
          code: codeException
        });
      } else if (!GLOBAL_VARS.editables.logo.fileTypes.includes(logo.favicon.type.toLowerCase())) {
        throw createErrorApp(
          `Failed to process favicon image, it cannot be type of ${logo.favicon.type.split(/\//g)[1]}.`,
          {
            code: codeException
          }
        );
      }

      const processedImage = yield processImageSize(logo.favicon, {
        dimensions: {
          width: 32,
          height: 32,
          fill: true
        }
      });

      const image: string | undefined = yield call(soltivoHelper.fileToBase64, processedImage);
      if (image) {
        // website.logo.favicon.src = image;

        modelWebsite.update(modelWebsite, {
          logo: {
            ...modelWebsite.logo,
            favicon: {
              ...modelWebsite.logo.favicon,
              src: image
            }
          }
        });
      } else {
        throw createErrorApp("Failed to process favicon image, verify your file and try again.", {
          code: codeException
        });
      }
    }

    const logoKeys = Object.keys(logo);
    for (let i = 0; i < logoKeys.length; i++) {
      const key = logoKeys[i] as keyof WebsiteData["logo"];
      if (key !== "favicon") {
        const file = logo[key];
        if (file && file instanceof File) {
          const res: {
            w: number;
            h: number;
          } = yield call(getImageDimensions, file);

          if (res.h < 512 || res.w < 512) {
            throw createErrorApp(`Failed to process ${key} image, resolution must be greater or equal to 512x512.`, {
              code: codeException
            });
          } else if (!GLOBAL_VARS.editables.logo.fileTypes.includes(file.type.toLowerCase())) {
            throw createErrorApp(`Failed to process ${key} image, it cannot be type of ${file.type.split(/\//g)[1]}.`, {
              code: codeException
            });
          }

          const processedImage: File = yield processImageSize(file, {
            dimensions: {
              width: 512,
              height: 512,
              fit: true
            }
          });

          // let image: string = yield call(soltivoHelper.fileToBase64, processedImage);

          toastHelper.toastStartContent("info", `Uploading ${key} logo...`);
          const { data: assignedUrl } = yield call(websiteClass.websiteUploadFile, processedImage);
          const { fileLink } = yield call(apiV2.postAssignedUrl, assignedUrl, processedImage);
          toastHelper.toastStartContent("success", `Saved ${key} logo changes.`);

          // if (website.logo[key].src !== "") {
          //     let data = JSON.stringify(website);
          //     data = data.replace(new RegExp(`${website.logo[key].src}`, "g"), fileLink);
          //     website = JSON.parse(data);
          // }
          // website.logo[key] = { ...website.logo[key], src: fileLink, alt: `${website.name || "logo."}` };

          modelWebsite.update(modelWebsite, {
            logo: {
              ...modelWebsite.logo,
              [key]: {
                ...modelWebsite.logo[key],
                src: fileLink,
                alt: `${modelWebsite.name || "logo."}`
              }
            }
          });
        }
      }
    }

    // Update current everywhere else.
    yield put(
      theme.logo.actions.update.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.logo.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
