import React from "react";
import styles from "./option.module.scss";

export interface OptionProps {
  key?: string | number;
  option: {
    value: string | JSX.Element;
    option: any;
    icon?: {
      svg: JSX.Element;
      onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    };
  };
  onClick?: (option: {
    e: React.MouseEvent<HTMLLIElement, MouseEvent>;
    option: Omit<OptionProps["option"], "icon">;
  }) => void;
  disabled?: boolean;
  dragOptions?: Pick<
    React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>,
    // events for dragging.
    "onDragStart" | "onDragEnter" | "onDragLeave" | "onDragOver" | "onDrop"
  >;
  className?: string;
}

type OptionComponent = (props: OptionProps) => JSX.Element;

const Option: OptionComponent = ({ onClick, option, disabled, dragOptions, className }) => {
  const Icon = option.icon?.svg || null;
  return (
    <li
      draggable={dragOptions ? true : false}
      onDragStart={(e) => {
        if (dragOptions?.onDragStart) {
          dragOptions?.onDragStart(e);
        }
      }}
      onDragEnter={(e) => {
        if (dragOptions?.onDragEnter) {
          dragOptions?.onDragEnter(e);
        }
      }}
      onDragLeave={(e) => {
        if (dragOptions?.onDragLeave) {
          dragOptions?.onDragLeave(e);
        }
      }}
      onDragOver={(e) => {
        if (dragOptions?.onDragOver) {
          dragOptions?.onDragOver(e);
        }
      }}
      onDrop={(e) => {
        if (dragOptions?.onDrop) {
          dragOptions?.onDrop(e);
        }
      }}
      className={`${styles.option} ${className ? className : ""} ${disabled ? styles.disabled : ""}`}
      onClick={
        onClick
          ? (e) => {
              if (disabled) return;
              onClick({ e, option });
            }
          : undefined
      }>
      <span>{option.value}</span>{" "}
      <button
        onClick={
          option.icon?.onClick
            ? (e) => {
                e.stopPropagation();
                if (disabled) return;
                if (option.icon?.onClick) option.icon.onClick(e);
              }
            : undefined
        }>
        {Icon}
      </button>
    </li>
  );
};

export default Option;
