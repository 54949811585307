import { AppState } from "redux/reducers/app/AppReducer";

const types = {
  APP_GET_CREDENTIALS_REQUEST: "APP_GET_CREDENTIALS_REQUEST",
  APP_GET_CREDENTIALS_SUCCESS: "APP_GET_CREDENTIALS_SUCCESS",
  APP_GET_CREDENTIALS_PARTIAL_SUCCESS: "APP_GET_CREDENTIALS_PARTIAL_SUCCESS",
  APP_GET_CREDENTIALS_FAILURE: "APP_GET_CREDENTIALS_FAILURE"
};

const actions = {
  /**
   * @description get initial informations needed to load the builder
   * - Authetication
   * - organization id
   */
  init: {
    request: () => ({
      type: types.APP_GET_CREDENTIALS_REQUEST,
      payload: undefined
    }),
    partialSuccess: (
      payload: Partial<{
        cognitorUser: AppState["cognitorUser"];
        organization: AppState["organization"];
        user: AppState["user"];
      }>
    ) => ({
      type: types.APP_GET_CREDENTIALS_PARTIAL_SUCCESS,
      payload
    }),
    success: (payload: {
      cognitorUser: AppState["cognitorUser"];
      organization: AppState["organization"];
      user: AppState["user"];
    }) => ({
      type: types.APP_GET_CREDENTIALS_SUCCESS,
      payload
    }),
    failure: (payload: Pick<AppState, "error">) => ({
      type: types.APP_GET_CREDENTIALS_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
