import { WindowMenuContextState, WindowMenuObject } from "../context";

/**
 * @description change window menu global state context.
 */
export const changeWindowMenuState = (
  setState: WindowMenuContextState["setState"],
  payload: {
    id: string;
    value: Partial<WindowMenuObject> | ((windowMenu: WindowMenuObject) => Partial<WindowMenuObject>);
  }
) => {
  setState((state) => ({
    ...state,
    windows: state.windows.map((item) => {
      if (item.id === payload.id) {
        if (typeof payload.value === "function") {
          return {
            ...item,
            ...payload.value(item)
          };
        }
        return {
          ...item,
          ...payload.value
        };
      }
      return item;
    })
  }));
};
