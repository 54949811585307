import { InputText } from "@soltivo/draw-a-line";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";
import ContentHeader from "../../../../../../components/content.header/content.header";

const Copyright = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);

  const onChange = (payload: { name: string; value: unknown }) => {
    if (!website.footer.data.copyright) {
      return;
    }
    dispatch(
      actions.theme.footer.actions.update.request({
        footer: { copyright: { ...website.footer.data.copyright, [payload.name]: payload.value as WebsiteLogoKey } }
      })
    );
  };

  if (!website.footer.data.copyright) {
    return null;
  }
  return (
    <div>
      <ContentHeader title="Copyright"></ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div
          style={{
            display: "grid"
          }}>
          <label htmlFor="value">Copyright</label>
          <InputText
            id={"value"}
            name="value"
            disabled={false}
            onChange={(e) => {
              onChange({ name: e.target.name, value: e.target.value });
            }}
            value={website.footer.data.copyright.value}
            placeholder="2022 John Doe inc. All right reserved."
          />
        </div>
      </div>
    </div>
  );
};

export default Copyright;
