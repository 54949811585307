/**
 * @description check if object contains the correct data format, not less or more attributes.
 */

export const validateObjectKeys = <TObject extends object>(
  object: TObject,
  options: {
    requiredKeys: (keyof TObject)[];
    validKeys: (keyof TObject)[];
  }
) => {
  const { validKeys, requiredKeys } = options;
  const allKeys = [...validKeys, ...requiredKeys] as string[];
  const objectKeys = Object.keys(object);
  const notFoundKeys = requiredKeys.filter((item) => !objectKeys.includes(item.toString()));
  const invalidKeys = objectKeys.filter((item) => !allKeys.includes(item));

  return {
    /**
     * @description required keys not found.
     */
    notFoundKeys,
    /**
     * @description keys which shouldn't exists inside object.
     */
    invalidKeys
  };
};
