import { createErrorApp } from "helpers/classes/ErrorApp";

export const ValidateClassText = (validation: ValidateClassText, payload: WebsiteText) => {
  if (typeof payload.value !== "string") {
    throw createErrorApp(`Text value is requird.`);
  }

  if (payload.value === "") {
    throw createErrorApp(`Text value cannot be empty.`);
  }

  if (validation.attributes.value?.rules.maxLength) {
    if (payload.value.length > validation.attributes.value?.rules.maxLength) {
      throw createErrorApp(`Text length cannot be greater than ${validation.attributes.value?.rules.maxLength}.`);
    }
  } else if (validation.attributes.value?.rules.minLength) {
    if (payload.value.length < validation.attributes.value?.rules.minLength) {
      throw createErrorApp(`Text length cannot be less than ${validation.attributes.value?.rules.minLength}.`);
    }
  }
};
