import React, { useContext, useEffect, useState } from "react";
import { WindowMenuObject } from "../context";
import WindowMenu from "..";

export const useWindowMenu = () => {
  const maxTopPosition = 60;
  const [startTimeOut, setStartTimeOut] = useState<NodeJS.Timeout>();
  const { setState, state } = useContext(WindowMenu.Context);

  /**
   * Display window menu
   */
  const onStartWindowMenu = (
    options: Pick<WindowMenuObject, "id" | "Component"> &
      Partial<Pick<WindowMenuObject, "width" | "maxHeight" | "pagination" | "title" | "size" | "acceptSizes">> &
      Partial<{
        startPosition: "center";
        event: React.MouseEvent<HTMLElement, MouseEvent>;
      }>
  ) => {
    try {
      // clear previous creation of window if any exists.
      if (startTimeOut) {
        clearTimeout(startTimeOut);
      }

      const windowMenu: WindowMenuObject = {
        ...WindowMenu.INITIAL_WINDOW_STATE,
        ...options,
        width: options.size ? WindowMenu.resize()[options.size].width : WindowMenu.INITIAL_WINDOW_STATE.width,
        maxHeight: options.size
          ? WindowMenu.resize()[options.size].maxHeight
          : WindowMenu.INITIAL_WINDOW_STATE.maxHeight,
        show: false
      };

      // We initiate the window here to create the parent element DOM.
      setState((state) => {
        return {
          ...state,
          windows: WindowMenu.exists(windowMenu.id, state.windows) ? state.windows : [...state.windows, windowMenu]
        };
      });

      // we need this so we know what will be the height of the window menu.
      setStartTimeOut(
        setTimeout(() => {
          const target = options?.event?.target;

          const windowElements = WindowMenu.queryWindowMenuElementsById(windowMenu.id); // parent container of window menu.

          if (!windowElements.container) {
            throw new Error("[Window Menu]: Container not found.");
          }

          if (
            typeof options.startPosition === "undefined" &&
            !(target instanceof HTMLElement) &&
            !(target instanceof SVGElement)
          ) {
            throw new Error("[Window Menu]: Target is not html element.");
          }

          const targetEl = target as HTMLElement | SVGElement; // element clicked on.

          // window size.
          const bodyWidth = document.body.offsetWidth;
          const bodyHeight = document.body.offsetHeight;

          const containerRect = windowElements.container.getBoundingClientRect();
          // rect (position) attributes.
          const targetRect =
            options.startPosition === "center"
              ? {
                  top: Math.abs(bodyHeight / 2 - containerRect.height / 2),
                  left: Math.abs(bodyWidth / 2 - containerRect.width / 2),
                  height: 0,
                  width: 0
                }
              : targetEl.getBoundingClientRect();

          //where on the window it starts to be visible. (X & Y)
          const targetPosition =
            options.startPosition === "center"
              ? {
                  topStart: targetRect.top + containerRect.height,
                  leftStart: targetRect.left + containerRect.width
                }
              : {
                  topStart: targetRect.top + targetRect.height + containerRect.height,
                  leftStart: targetRect.left + targetRect.width + containerRect.width
                };

          let to = {
            top: 0,
            left: 0
          };

          // DECIDE POSITION TO DISPLAY THE CONTAINER BASED ON CONTAINER POSITION, HEIGHT & WIDTH.
          const outerHeight = bodyHeight <= targetPosition.topStart; // greater than window height screen.
          const outerWidth = bodyWidth <= targetPosition.leftStart; // greater than window width screen.

          if (outerHeight) {
            to = {
              ...to,
              top: Math.abs(targetRect.top - containerRect.height)
            };
          } else {
            to = {
              ...to,
              top: Number(targetRect.top + targetRect.height)
            };
          }

          if (outerWidth) {
            to = {
              ...to,
              left: Math.abs(targetRect.left - containerRect.width)
            };
          } else {
            to = {
              ...to,
              left: Number(targetRect.left + targetRect.width)
            };
          }

          if (to.top < maxTopPosition) {
            to.top = maxTopPosition;
          }

          // setState((state) => ({
          //   ...state,
          //   windows: state.windows.map((item) => ({ ...item, zIndex: 0 }))
          // }));

          setState((state) => ({
            ...state,
            windows: state.windows.map((item, index) => {
              if (windowMenu.id === item.id) {
                return {
                  ...item,
                  ...options,
                  show: true,
                  title: options.title || "",
                  to: {
                    left: `${to.left}px`,
                    top: `${to.top}px`
                  },
                  zIndex: state.windows.length
                };
              }
              return {
                ...item,
                zIndex: item.zIndex === 0 ? 0 : item.zIndex === state.windows.length ? item.zIndex - 1 : item.zIndex
              };
            })
          }));
        }, 100)
      );
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Hide a specific window.
   */
  const onClose = (id: WindowMenuObject["id"]) => {
    const windowMenu = getWindowMenuById(id);
    if (!windowMenu) {
      return;
    }

    setState((state) => ({
      ...state,
      windows: state.windows.filter((item) => item.id !== windowMenu.id)
    }));
  };

  /**
   * Kill all windows from state.
   */
  const onCloseAll = () => {
    setState((state) => ({
      ...state,
      windows: []
    }));
  };

  /**
   * Hook Helper to handle footer updates.
   */
  const useFooter = (props: { id: WindowMenuObject["id"]; component: () => JSX.Element }, deps: any[]) => {
    const windowMenu = WindowMenu.getWindowMenuObject(props.id, state.windows);
    const currentPage = windowMenu?.pagination.pages.find((page) => page.visible);
    useEffect(() => {
      if (!windowMenu) {
        return;
      }
      WindowMenu.changeWindowMenuState(setState, { id: windowMenu.id, value: { Footer: props.component } });
      return () => {
        WindowMenu.changeWindowMenuState(setState, { id: windowMenu.id, value: { Footer: undefined } });
      };
    }, [...deps, currentPage?.id]);
  };

  /**
   * Function helper to handle footer updates.
   */
  const onFooterUpdate = (id: WindowMenuObject["id"], Footer: (() => JSX.Element) | "reset") => {
    const windowMenu = WindowMenu.getWindowMenuObject(id, state.windows);
    if (!windowMenu) {
      return;
    }
    WindowMenu.changeWindowMenuState(setState, {
      id: windowMenu.id,
      value: { Footer: Footer === "reset" ? undefined : Footer }
    });
  };

  /**
   * Change view of window to a specific page.
   */
  const onMoveToPage = (payload: { id: WindowMenuObject["id"]; pageId: string }) => {
    const { pageId, id } = payload;

    const windowMenu = WindowMenu.getWindowMenuObject(id, state.windows);
    if (!windowMenu) {
      return;
    }

    WindowMenu.changeWindowMenuState(setState, {
      id: windowMenu.id,
      value: (item) => ({
        ...item,
        pagination: {
          ...item.pagination,
          pages: item.pagination.pages.map((windowPage) => {
            windowPage.visible = false;
            if (windowPage.id === pageId) {
              windowPage.visible = true;
            }

            return windowPage;
          })
        }
      })
    });
  };

  const getWindowMenuById = (id: string) => {
    const windowMenu = state.windows.find((item) => item.id === id);

    if (!windowMenu) {
      return;
    }

    return windowMenu;
  };

  //
  return {
    windowMenuState: state,
    update: setState,
    onClose,
    onCloseAll,
    onStartWindowMenu,
    onFooterUpdate,
    useFooter,
    onMoveToPage,
    getWindowMenuById
  };
};
