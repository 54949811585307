import { InputText } from "@soltivo/draw-a-line";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ContentHeader from "../../../../../../components/content.header/content.header";
import VisibleSwitch from "components/visible.switch/visible.switch";
import actions from "redux/actions";

const SocialMedia = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);

  const onChange = (payload: { name: string; value: unknown }) => {
    if (!website.footer.data.socialMedia) {
      return;
    }
    dispatch(
      actions.theme.footer.actions.update.request({
        footer: { socialMedia: { ...website.footer.data.socialMedia, [payload.name]: payload.value as WebsiteLogoKey } }
      })
    );
  };

  if (!website.footer.data.socialMedia) {
    return null;
  }
  return (
    <div>
      <ContentHeader title="Social media">
        <VisibleSwitch
          checked={website.footer.data.socialMedia.visible}
          onChange={(e) => {
            onChange({ name: "visible", value: e.target.checked });
          }}
        />
      </ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div
          style={{
            display: "grid"
          }}>
          <label htmlFor="title">Section title</label>
          <InputText
            id={"title"}
            name="title"
            disabled={!website.footer.data.socialMedia.visible}
            onChange={(e) => {
              onChange({ name: e.target.name, value: e.target.value });
            }}
            value={website.footer.data.socialMedia.title}
            placeholder="Section title"
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
