import React, { useEffect } from "react";
import Routes from "./routes/routes";
import ErrorBoundary from "./blocks/error.boundary/error.boundary";
import WindowMenu from "components/window.menu";

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();
        e.returnValue = "";
      });
    }
  }, []);

  return (
    <div className="app">
      <ErrorBoundary>
        <WindowMenu.Wrapper>
          <Routes />
        </WindowMenu.Wrapper>
      </ErrorBoundary>
    </div>
  );
};

export default App;
