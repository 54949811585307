import { soltivoHelper } from "@soltivo/draw-a-line";
import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassImage = (
  validation: ValidateClassImage,
  payload: Omit<WebsiteImage, "src"> & { src: string | File }
) => {
  if (typeof payload.src !== "string" && !(payload.src instanceof File)) {
    throw createErrorApp(`Image must be either a file or an unsplash image url.`);
  }

  if (typeof payload.alt !== "string") {
    throw createErrorApp(`Image description is required.`);
  }

  if (typeof payload.src === "string") {
    if (
      !GLOBAL_VARS.validaton.regex.httpsUrl.test(payload.src) &&
      !new RegExp(GLOBAL_VARS.validaton.regex.publicUrl).test(payload.src)
    ) {
      throw createErrorApp(`Image must be either a file image or an image url.`);
    }
    if (!/unsplash/g.test(payload.src) && !new RegExp(GLOBAL_VARS.validaton.regex.publicUrl).test(payload.src)) {
      throw createErrorApp(`Image url should be from unsplash library.`);
    }
  }

  if (payload.src instanceof File) {
    if (validation.attributes.src?.rules?.mimes) {
      if (
        !(validation.attributes.src?.rules?.mimes as string[]).includes(
          payload.src.type.replace("image/", "").toLowerCase()
        )
      ) {
        throw createErrorApp(
          `Image type of ${
            payload.src.type
          } is not allowed, validation extentions allowed: ${validation.attributes.src?.rules?.mimes.join(", ")}.`
        );
      }
    } else if (!new RegExp(GLOBAL_VARS.validaton.regex.file.type.initImage).test(payload.src.type.toLowerCase())) {
      throw createErrorApp(`Image must have jpeg, jpg, png or webp extension.`);
    }

    if (validation.attributes.src?.rules?.size) {
      if (payload.src.size > validation.attributes.src.rules.size) {
        throw createErrorApp(
          `Image cannot be greater than ${soltivoHelper.formatBytes(validation.attributes.src.rules.size)}.`
        );
      }
    } else if (payload.src.size > GLOBAL_VARS.defaultFileSize) {
      throw createErrorApp(`Image file cannot be greater than 5MB.`);
    }
  }
};
