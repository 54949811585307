import { createErrorApp } from "../classes/ErrorApp";

/**
 * @description resize images to a specific dimension.
 */
export const processImageSize = (
  file: File,
  options: {
    dimensions: {
      width: number;
      height?: number;
      fill?: boolean;
      fit?: boolean;
    };
    type?: string;
  }
): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const {
        dimensions: { width, height, fill, fit },
        type
      } = options;

      if (!file) throw createErrorApp("File not inserted");
      if (!/(image)/g.test(file.type)) throw createErrorApp("File must be a image");
      if (!width) throw createErrorApp("Missing width values.");

      const reader = new FileReader();

      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result?.toString() || "";

        image.onload = (imageEvent) => {
          if (!imageEvent.currentTarget) return;
          const canvas = document.createElement("canvas");

          const scaleSize = width / image.width;
          canvas.width = width;
          canvas.height = height || image.height * scaleSize;

          const ctx = canvas.getContext("2d");

          if (fill) {
            const scale = Math.max(canvas.width / image.width, canvas.height / image.height);

            const x = canvas.width / 2 - (image.width / 2) * scale;
            const y = canvas.height / 2 - (image.height / 2) * scale;

            ctx?.drawImage(image, x, y, image.width * scale, image.height * scale);
          } else if (fit) {
            const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
            const x = canvas.width / 2 - (image.width / 2) * scale;
            const y = canvas.height / 2 - (image.height / 2) * scale;

            ctx?.drawImage(image, x, y, image.width * scale, image.height * scale);
          } else {
            ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
          }

          ctx?.canvas.toBlob((blob) => {
            if (!blob) {
              throw createErrorApp("Attempt to convert image to blob failed.");
            }
            const newFile = new File([blob], file.name, {
              type: type || file.type
            });

            resolve(newFile);
          });
        };
      };

      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};
