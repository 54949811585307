import { CallEffect, delay, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import actions from "redux/actions";

/**
 * @description change theme colors.
 */
export default function* update({
  payload
}: ReturnType<(typeof actions.theme)["color"]["actions"]["update"]["request"]>): Generator<
  CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: cloneDeep(WebsiteWizardReducer.website),
      editor: WebsiteWizardReducer.editor,
      prevColors: cloneDeep(WebsiteWizardReducer.prevColors)
    });

    const { website, editor, prevColors }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    if (!editor) {
      throw new ErrorApp({
        message: `You can only modify your theme with wizard enabled.`,
        exception: "ColorException",
        code: "ColorException"
      });
    }

    const { inputName, color } = payload;

    //check if field actually exists in colors property of website
    const validField = Object.keys(modelWebsite.colors).find((key) => key === inputName);

    if (!validField) {
      throw new ErrorApp({
        message: `Color attribute "${inputName}" is not valid.`,
        exception: "ColorException",
        code: "ColorException"
      });
    }

    //no need to update something equal.
    if (website.colors[inputName] !== color) {
      modelWebsite.update(modelWebsite, { colors: { ...modelWebsite.colors, [inputName]: color } });
    }

    yield put(actions.theme.color.actions.update.success({ website: modelWebsite.object }));

    yield delay(3000);
    //update previous colors
    const prevColor = prevColors[inputName];
    if (prevColor) {
      const found = prevColor.find((c) => c === color);
      if (!found) {
        prevColor.pop();
        prevColor.unshift(modelWebsite.colors[inputName]);
      }
    }

    yield put(actions.theme.color.actions.updatePrevColors.request({ prevColors: prevColors }));
  } catch (error) {
    yield put(actions.theme.color.actions.update.failure({ error: new ErrorApp(error).toObject() }));
  }
}
