import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassLink = (validation: ValidateClassLink, payload: WebsiteLink) => {
  if (typeof payload.value !== "string") {
    throw createErrorApp(`Link value is required.`);
  }

  if (payload.value === "") {
    throw createErrorApp(`Link value cannot be empty.`);
  }

  if (typeof payload.title !== "string") {
    throw createErrorApp(`Link title is required.`);
  }

  if (typeof payload.href !== "string") {
    throw createErrorApp(`Link href is required.`);
  }

  if (
    !GLOBAL_VARS.validaton.regex.httpsUrl.test(payload.href) &&
    !payload.href.startsWith("/") &&
    payload.href !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
  ) {
    throw createErrorApp(`Link href should be a valid https website url or valid page on your website.`);
  }

  if (typeof payload.target !== "string") {
    throw createErrorApp(`Link target is required.`);
  }

  if (payload.target && !GLOBAL_VARS.editables.link.targets.includes(payload.target)) {
    throw createErrorApp(`Link doesn't contain a valid target.`);
  }

  // if (typeof payload.rel !== "string") {
  //   throw throwWizardError(`Link relationship is required.`);
  // }

  // if (!GLOBAL_VARS.editables.link.rels.includes(payload.rel)) {
  //   throw throwWizardError(`Link doesn't contain a valid relationship string.`);
  // }

  const { href, target, title, value } = validation.attributes;
  if (href?.rules.regexp) {
    const regex = new RegExp(href.rules.regexp.content, href.rules.regexp.flag);
    if (!regex.test(payload.href)) {
      throw createErrorApp(href.rules.regexp.message);
    }
  }

  // if (rel?.rules.acceptOnly) {
  //   if (!rel?.rules.acceptOnly.includes(payload.rel)) {
  //     throw throwWizardError(`Link relationship is not allowed.`);
  //   }
  // }

  if (target?.rules.acceptOnly) {
    if (!target?.rules.acceptOnly.includes(payload.target)) {
      throw createErrorApp(`Link target is not allowed.`);
    }
  }

  if (title?.rules.maxLength) {
    if (payload.title.length > title.rules.maxLength) {
      throw createErrorApp(`Link title length cannot have more than ${title?.rules.maxLength} caracters.`);
    }
  }

  if (title?.rules.minLength) {
    if (payload.title.length < title.rules.minLength) {
      throw createErrorApp(`Link title length cannot less more than ${title?.rules.minLength} caracters.`);
    }
  }

  if (value?.rules.maxLength) {
    if (payload.value.length > value.rules.maxLength) {
      throw createErrorApp(`Link value length cannot have more than ${value?.rules.maxLength} caracters.`);
    }
  }

  if (value?.rules.minLength) {
    if (payload.value.length < value.rules.minLength) {
      throw createErrorApp(`Link value length cannot less more than ${value?.rules.minLength} caracters.`);
    }
  }
};
