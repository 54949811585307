import { Button } from "@soltivo/draw-a-line";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useBuilderIndexedDB } from "helpers/hooks/useBuilderIndexedDB/useBuilderIndexedDB";
import { ModelWebsite } from "helpers/models";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";
import styles from "./reset.theme.module.scss";

interface ResetThemeWindowProps {}

const ResetThemeWindow = (props: ResetThemeWindowProps) => {
  const dispatch = useDispatch();
  const { useFooter, onClose } = WindowMenu.useWindowMenu();

  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    prevColors: Wizard.prevColors,
    pages: Wizard.pages,
    undos: Wizard.undos
  });
  const { website } = useSelector(mapStateToProps);

  const indexedDB = useBuilderIndexedDB({ storeKey: "theme", version: 1 });

  const onDeleteStorage = async () => {
    // attemp to load saved indexeddb saved
    if (indexedDB.database) {
      await indexedDB.del(website.themeId);
    }
    onClose(WINDOW_MENU_KEYS.SAVE_INDEXEDDB_LOCALLY.key);
  };

  const onLoadFromStorage = async () => {
    const storedTheme: IDBBuilderDatabase["theme"]["value"] | undefined = await indexedDB.get(website.themeId);
    // attemp to load saved indexeddb saved
    if (storedTheme) {
      const modelWebsite = new ModelWebsite(website);
      modelWebsite.update(modelWebsite.object, storedTheme.website);
      const pages = storedTheme.pages;
      dispatch(
        actions.wizard.state.actions.change.request({
          website: modelWebsite.object,
          pages: pages
        })
      );
    }
    onClose(WINDOW_MENU_KEYS.SAVE_INDEXEDDB_LOCALLY.key);
  };

  useFooter(
    {
      id: WINDOW_MENU_KEYS.SAVE_INDEXEDDB_LOCALLY.key,
      component: () => {
        return (
          <Fragment>
            <Button
              variant="primary"
              outline
              padding={false}
              onClick={() => {
                onDeleteStorage();
              }}>
              No
            </Button>
            <Button
              style={{
                marginLeft: 32
              }}
              variant="primary"
              outline
              padding={false}
              onClick={() => {
                onLoadFromStorage();
              }}>
              Yes
            </Button>
          </Fragment>
        );
      }
    },
    []
  );
  return (
    <div className={`${styles.component}`}>
      <p>Looks like you have been working in this theme previously, would you like to start from where you left off?</p>
    </div>
  );
};

export default ResetThemeWindow;
