import styles from "./styles.module.scss";
import { Button } from "@soltivo/draw-a-line";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { PluginWindowState } from "../../plugin.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface CategoriesViewProps extends EditableWindowProps<"plugin"> {
  state: PluginWindowState;
  setState: React.Dispatch<React.SetStateAction<PluginWindowState>>;
}

const ServicesView = ({ state, setState, dataClass, dataPath }: CategoriesViewProps) => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    plugins: WebsiteWizardReducer.plugins
  });

  const { plugins } = useSelector(mapStateToProps);

  const { data, validation } = useEditableData({ dataClass, dataPath });

  const { useFooter, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

  const currentWindowPage = windowMenu?.pagination.pages.find(
    (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES
  );

  const Footer = () => {
    const { data, validation } = useEditableData({ dataClass, dataPath });

    const { onMoveToPage, getWindowMenuById } = WindowMenu.useWindowMenu();

    const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

    const currentWindowPage = windowMenu?.pagination.pages.find(
      (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES
    );

    if (!windowMenu || !currentWindowPage?.visible || !validation || !data) {
      return <></>;
    }

    const disabledNext = !state.service;

    return (
      <Fragment>
        <Button
          onClick={() => {
            onMoveToPage({
              id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
              pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY
            });
          }}
          outline
          variant="primary">
          Back
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (disabledNext) return;
            onMoveToPage({
              id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
              pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICE
            });
          }}
          disabled={disabledNext}>
          Next
        </Button>
      </Fragment>
    );
  };

  useFooter(
    {
      id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
      component: () => <Footer />
    },
    [state]
  );

  if (!windowMenu || !currentWindowPage?.visible || !data || !validation) {
    return <></>;
  }

  const servicesFilter = plugins.booking.services.data.filter((service) => service.categoryId === state.category?.id);

  return (
    <div className={`${styles.component}`}>
      <ul className={`${styles.items} ${windowMenu.size === "medium" ? styles.medium__size : ""}`}>
        {state.category
          ? servicesFilter.length
            ? servicesFilter.map((service) => {
                if (state.search) {
                  const regex = new RegExp(`(${state.search})`, "g");
                  if (!regex.test(service.title)) {
                    return null;
                  }
                }
                return (
                  <li
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        service: state.service?.id === service.id ? undefined : service
                      }));
                    }}
                    className={`${styles.plugin__item} ${state.service?.id === service.id ? styles.active : ""}`}
                    key={service.id}
                    tabIndex={3}>
                    <span>{service.title}</span>
                  </li>
                );
              })
            : null
          : null}
      </ul>
    </div>
  );
};

export default ServicesView;
