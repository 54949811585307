import { createErrorApp } from "helpers/classes/ErrorApp";

export const ValidateClassMap = (validation: ValidateClassMap, value: WebsiteMap) => {
  if (typeof value.address !== "string") {
    throw createErrorApp(`Map address must be a text string.`);
  }

  if (typeof value.zoom !== "number") {
    throw createErrorApp(`Map zoom must be a number.`);
  }

  if (value.zoom < 0) {
    throw createErrorApp(`Map zoom cannot be less than 0.`);
  }

  if (value.zoom > 25) {
    throw createErrorApp(`Map zoom cannot be greater than 25.`);
  }
};
