import currencies from "@soltivo/draw-a-line/core/assets/json/currencies.json";

export const ValidateTypeCurrency = (validation: ValidateTypeCurrency, payload: WebsiteCurrency | string) => {
  if (validation.rules?.onlyCode) {
    if (typeof payload !== "string") {
      throw `Currency code must be text string.`;
    } else if (!Object.values(currencies).find((currency) => currency.code.toLowerCase() === payload.toLowerCase())) {
      throw `Currency code "${payload}" is not valid.`;
    }
  } else {
    if (typeof payload !== "object") {
      throw `Currency must be a valid currency object.`;
    } else {
      const found = Object.values(currencies).find(
        (currency) => currency.code.toLowerCase() === (payload as WebsiteCurrency).code.toLowerCase()
      );
      if (!found) {
        throw `Currency was not found.`;
      } else if (payload.decimal_digits !== found.decimal_digits) {
        throw `Currency invalid decimal digits.`;
      } else if (payload.name !== found.name) {
        throw `Currency invalid name.`;
      } else if (payload.name_plural !== found.name_plural) {
        throw `Currency invalid plural name.`;
      } else if (payload.rounding !== found.rounding) {
        throw `Currency invalid rounding number.`;
      } else if (payload.symbol !== found.symbol) {
        throw `Currency invalid symbol.`;
      } else if (payload.symbol_native !== found.symbol_native) {
        throw `Currency invalid native symbol.`;
      }
    }
  }
};
