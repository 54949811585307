import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import { theme } from "redux/actions";

/**
 * @description redirect user to the given page by id.
 */
export default function* open({
  payload
}: ReturnType<(typeof theme)["page"]["actions"]["open"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const { pageId } = payload;
    if (!pageId) {
      throw new Error("Cannot open page without its id.");
    }

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      pages: WebsiteWizardReducer.pages,
      website: WebsiteWizardReducer.website
    });

    const { pages, website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    if (!pages?.length) {
      throw new Error("Theme does not have pages.");
    }

    const page = pages.find((page) => page.id == pageId);

    if (!page) {
      throw new Error(`Page with id "${pageId}" was not found.`);
    }

    modelWebsite.update(modelWebsite, { page: page });

    yield put(
      theme.page.actions.open.success({
        pageId: page.id,
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.page.actions.open.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
