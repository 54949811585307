import { ErrorApp } from "helpers/classes/ErrorApp";
import authClass from "./auth.class";

type Method = "GET" | "POST" | "PUT" | "DELETE";
type UseToken = "idToken" | "accessToken" | false;
type Data =
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null
  | undefined;

interface Options {
  method?: Method;
  data?: Data;
  useToken: UseToken;
  useOrg?: boolean;
  useResponseType?: "text" | "json" | "formData" | "blob" | "arrayBuffer";
  headers?: {
    "content-type"?: "application/json" | "application/x-www-form-urlencoded" | "multipart/form-data" | string;
    authorization?: string;
    orgid?: string;
  };
}

const defaultOptions: Options = {
  useToken: "accessToken",
  useOrg: true,
  useResponseType: "json"
};

class ApiV2 {
  private refreshTokenAttempts = 0;

  public async request(
    method: Method,
    url: string,
    options: Options = {
      useToken: "accessToken",
      useOrg: true
    }
  ): Promise<any> {
    try {
      const tokens = {
        accessToken: localStorage.getItem("token"),
        idToken: localStorage.getItem("idToken")
      };
      const orgId: string | null = localStorage.getItem("orgId");
      const { headers, ...restOptions } = options;

      const _options: Options = {
        ...defaultOptions,
        ...restOptions
      };

      const _headers = new Headers();
      _headers.append("content-type", headers?.["content-type"] ? headers["content-type"] : "application/json");

      if (_options.useToken) {
        _headers.append("authorization", `Bearer ${tokens[_options.useToken]}`);

        if (!tokens[_options.useToken]) {
          throw new Error(`${_options.useToken} is not defined.`);
        }
      }

      if (_options.useOrg === true) {
        if (!orgId) {
          throw new Error("organization id not defined.");
        }
        _headers.append("orgid", orgId);
      }

      if (!window.fetch) {
        throw new Error("Your browser does not support fetch.");
      }

      const response = await fetch(url, {
        method: method,
        headers: _headers,
        body: options.data
      });

      if (response.status === 403) {
        const errorResponse = await response.json();
        if (errorResponse?.message === "User is not authorized to access this resource with an explicit deny") {
          window.location.assign(`${process.env.REACT_APP_DASHBOARD_URL}/organization/403`);
        }
        return;
      }

      if (this.refreshTokenAttempts >= 5) {
        this.refreshTokenAttempts = 0;
        throw new Error("Failed to refresh token.");
      }

      if (response.status === 401 && _options.useToken) {
        const errorResponse = await response.json();

        if (
          errorResponse?.message === "Unauthorized" ||
          errorResponse?.message === "Invalid Access Token" ||
          errorResponse?.message === "Access Token has expired"
        ) {
          return await new Promise(async (resolve, reject) => {
            localStorage.removeItem("token");
            localStorage.removeItem("idToken");

            console.log(`%c refreshing token...`, "font-weight:bold; color: rgb(255,0,0);");
            this.refreshTokenAttempts = this.refreshTokenAttempts + 1;
            return await authClass
              .refreshSessionToken(() => {
                console.log(`%c Token refreshed successfully.`, "font-weight:bold; color: rgb(0,255,0);");
                resolve(this.request(method, url, options));
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          throw errorResponse;
        }
      }

      // handle issue if response is not ok by throwing the response as JSON.
      if (!response.ok) {
        const res = await response.json();
        if (res?.deprecated === true) {
          console.warn(`DEPRECATED REQUEST: ${url}`);
        }
        throw res;
      }

      // refresh attempt.
      this.refreshTokenAttempts = 0;
      switch (_options.useResponseType) {
        case "text":
          return await response.text();
        case "arrayBuffer":
          return await response.arrayBuffer();
        case "json": {
          const res = await response.json();
          if (res?.deprecated === true) {
            console.warn(`DEPRECATED REQUEST: ${url}`);
          }
          return res;
        }
        case "blob":
          return await response.blob();
        case "formData":
          return await response.formData();
      }
    } catch (error: unknown) {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
      if (typeof error === "string") {
        throw new ErrorApp({
          exception: "apiException",
          code: "400",
          message: error
        });
      } else if (error instanceof Error) {
        throw new ErrorApp(error);
      } else if (typeof error === "object") {
        throw new ErrorApp(error as any);
      }
      throw new ErrorApp(error as any);
    }
  }

  /**
   * @description get request
   */
  public getRequest(url: string, options?: Options): Promise<any> {
    return this.request("GET", url, options);
  }

  /**
   * @description post request
   */
  public postRequest(url: string, options?: Options): Promise<any> {
    return this.request("POST", url, options);
  }

  /**
   * @description put request
   */
  public putRequest(url: string, options?: Options): Promise<any> {
    return this.request("PUT", url, options);
  }

  /**
   * @description delete request
   */
  public deleteRequest(url: string, options?: Options): Promise<any> {
    return this.request("DELETE", url, options);
  }

  /**
   * @description upload file using assigned url policy
   * @param data data from get assigned url api
   * @param file File to upload
   */
  async postAssignedUrl(data: any, file: File): Promise<{ fileLink: string }> {
    const formData = new FormData();
    Object.keys(data.fields).forEach((key) => {
      formData.append(key, data.fields[key]);
    });

    formData.append("file", file);
    formData.append("Content-Type", file.type);
    formData.append("success_action_status", "200");
    formData.append("Cache-Control", "max-age=300,public");
    const res = await fetch(data.url, {
      method: "POST",
      body: formData
    });

    if (res.ok && res.status >= 200 && res.status <= 299) {
      // set new value
      return {
        fileLink: `${process.env.REACT_APP_SOLTIVO_CDN}/${data.fields.key}`
      };
    } else {
      const json = await res.json();
      throw json;
    }
  }
}

export { ApiV2 };
export default new ApiV2();
