import { Info24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./input.create.link.module.scss";

interface InputCreateLinkProps {
  name: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onEnter?: React.KeyboardEventHandler<HTMLInputElement>;
}

const InputCreateLink: React.FC<React.PropsWithChildren<InputCreateLinkProps>> = ({
  onChange,
  name,
  placeholder,
  onEnter
}) => {
  return (
    <div className={styles.input}>
      <input
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onEnter) {
            onEnter(e);
            e.currentTarget.value = "";
          }
        }}
        autoComplete="off"
      />
      <span data-title-position="top-right" data-title={`Insert menu link text \n and press [enter].`}>
        <Info24Px width={16} height={16} />
      </span>
    </div>
  );
};

export default InputCreateLink;
