import styles from "./styles.module.scss";
import {
  Button,
  Checkbox,
  Column,
  FormControl,
  InputLabel,
  InputSelect,
  InputText,
  InputTime,
  Row,
  Textarea
} from "@soltivo/draw-a-line";
import { Fragment } from "react";
import { PluginWindowState } from "../../plugin.window";
import { useDispatch } from "react-redux";
import InputImage from "components/input.image/input.image";
import Unsplash from "components/unsplash/unsplash";
import websiteClass from "helpers/api/website.class";
import apiV2 from "helpers/api/api.v2";
import { GLOBAL_VARS } from "helpers/global";
import { ErrorApp } from "helpers/classes/ErrorApp";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";
import actions from "redux/actions";

const BUFFER_TIMES = [
  { value: "0 minutes" },
  { value: "5 minutes" },
  { value: "10 minutes" },
  { value: "15 minutes" },
  { value: "30 minutes" },
  { value: "60 minutes" }
];

/**
 * @description convert hours to seconds
 */
const hoursToSeconds = (hours: string, minutes: string): number => {
  return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60;
};

/**
 * @description convert from seconds to hours and minutes
 */
const secondsToHoursSeconds = (seconds: number): { hours: number; minutes: number } => {
  const hours = Math.trunc(seconds / 3600);
  const minutes = (seconds % 3600) / 60;
  return {
    hours,
    minutes
  };
};

interface ServiceViewProps extends EditableWindowProps<"plugin"> {
  state: PluginWindowState;
  setState: React.Dispatch<React.SetStateAction<PluginWindowState>>;
}

const ServiceView = ({ state, setState, dataClass, dataPath }: ServiceViewProps) => {
  const dispatch = useDispatch();

  const { data, validation } = useEditableData({ dataClass, dataPath });

  const { getWindowMenuById, useFooter, onStartWindowMenu } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

  const currentWindowPage = windowMenu?.pagination.pages.find(
    (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICE
  );

  const Footer = () => {
    const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

    const { onMoveToPage, getWindowMenuById, onClose } = WindowMenu.useWindowMenu();

    const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

    const currentWindowPage = windowMenu?.pagination.pages.find(
      (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICE
    );

    if (!windowMenu || !currentWindowPage?.visible || !validation || !data) {
      return <></>;
    }

    const disabledNext = !state.service;

    return (
      <Fragment>
        <Button
          onClick={() => {
            onMoveToPage({
              id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
              pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES
            });
          }}
          outline
          variant="primary">
          Back
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            if (disabledNext) return;
            if (state.service) {
              const image = state.service.image as unknown as string | File;
              if (image instanceof File) {
                try {
                  if (image.size > GLOBAL_VARS.defaultFileSize) {
                    throw new Error("Image cannot be greater than 5MB");
                  }

                  setState((state) => ({
                    ...state,
                    loading: true
                  }));
                  const { data: assignedUrl } = await websiteClass.websiteUploadServiceImage({
                    file: image
                  });

                  const { fileLink } = await apiV2.postAssignedUrl(assignedUrl, image);
                  state.service.image = fileLink;
                } catch (error) {
                  dispatch(
                    actions.wizard.state.actions.change.request({
                      error: new ErrorApp(error as any)
                    })
                  );
                }
              }

              // update editable element
              if (validation.type === "booking/service") {
                onUpdate(state.service);
              }

              // store changes to be saved inside api on publish
              if (state.pluginUpdate) {
                dispatch(
                  actions.theme.editable.actions.pluginStore.request({
                    type: validation.type,
                    value: state.service
                  })
                );
              }

              setState((state) => ({
                ...state,
                loading: false
              }));

              onClose(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);
            }
          }}
          disabled={disabledNext}>
          Insert
        </Button>
      </Fragment>
    );
  };

  useFooter(
    {
      id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
      component: () => <Footer />
    },
    [state]
  );

  if (!windowMenu || !currentWindowPage?.visible || !data || !validation) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <Row>
        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Image"
            }}>
            <InputImage
              name="image"
              placeholder="image"
              accept="image/png,image/jpeg,image/jpg,image/webp"
              onLibrary={(e) => {
                onStartWindowMenu({
                  id: WINDOW_MENU_KEYS.UNSPLASH.key,
                  event: e,
                  size: "medium",
                  acceptSizes: ["medium"],
                  title: "Select an image",
                  Component: () => {
                    return (
                      <Unsplash
                        columns={2}
                        menu={true}
                        onSelectImage={(imageUrl) => {
                          setState((state) => {
                            return {
                              ...state,
                              unsplash: false,
                              service: state.service
                                ? {
                                    ...state.service,
                                    image: imageUrl
                                  }
                                : undefined
                            };
                          });
                        }}
                      />
                    );
                  }
                });
              }}
              style={{
                maxWidth: "100%",
                width: "100%",
                height: "240px",
                maxHeight: "240px"
              }}
              onChange={(e) => {
                const file = e.target.files?.[0];
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        image: file as unknown as string
                      }
                    : undefined
                }));
              }}
              value={state.service?.image || ""}></InputImage>
          </FormControl>
        </Column>

        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Title"
            }}>
            <InputText
              name="name"
              placeholder="Title"
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        title: e.target.value
                      }
                    : undefined
                }))
              }
              value={state.service?.title || ""}></InputText>
          </FormControl>
        </Column>

        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Description"
            }}>
            <Textarea
              name="description"
              placeholder="Description"
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        description: e.target.value
                      }
                    : undefined
                }))
              }
              value={state.service?.description || ""}></Textarea>
          </FormControl>
        </Column>

        <Column size={"12"}>
          <FormControl
            labelProps={{
              value: "Price"
            }}>
            <InputLabel
              type="currency"
              labelPositionX="right"
              label="CAD"
              id="servicePrice"
              name="price"
              max={99999}
              min={0}
              value={state.service?.price || 0}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        price: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value)
                      }
                    : undefined
                }));
              }}
              withLocale={true}
              placeholder="price"
            />
          </FormControl>
        </Column>

        <Column size={"4"}>
          <FormControl
            labelProps={{
              value: "Duration"
            }}>
            <InputTime
              maxHours={23}
              onChange={(e) => {
                const durationSplit = e.value.split(":");
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        duration: hoursToSeconds(durationSplit[0] || "00", durationSplit[1] || "00")
                      }
                    : undefined
                }));
              }}
              value={`${secondsToHoursSeconds(state.service?.duration || 0).hours || "00"}:${
                secondsToHoursSeconds(state.service?.duration || 0).minutes || "00"
              }`}
              name="duration"
            />
          </FormControl>
        </Column>

        <Column size={"8"}>
          <FormControl
            labelProps={{
              value: "Buffer Time"
            }}>
            <InputSelect
              data-testid="bufferTime"
              name="bufferTime"
              placeholder="Select buffer"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  service: state.service
                    ? {
                        ...state.service,
                        bufferTime: parseInt(`${e.value}`.split(" ")[0]) * 60
                      }
                    : undefined
                }));
              }}
              options={BUFFER_TIMES}
              value={
                BUFFER_TIMES.find((bf) => parseInt(bf.value.split(" ")[0]) * 60 === state.service?.bufferTime)?.value ||
                BUFFER_TIMES[0].value
              }
            />
          </FormControl>
        </Column>

        <Column size={"12"}>
          <div className={styles.plugin__update__checkbox}>
            <Checkbox
              name="pluginUpdate"
              checked={state.pluginUpdate}
              onChange={(e) => setState((state) => ({ ...state, pluginUpdate: e.target.checked }))}
            />
            <p>Update the service on my listing</p>
          </div>
        </Column>
      </Row>
    </div>
  );
};

export default ServiceView;
