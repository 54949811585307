import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";

/**
 * @description move section index inside a page
 */
export default function* move({
  payload
}: ReturnType<(typeof theme)["section"]["actions"]["move"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: cloneDeep(WebsiteWizardReducer.website),
      pages: cloneDeep(WebsiteWizardReducer.pages),
      pageId: WebsiteWizardReducer.pageId
    });

    let { pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const { from, to } = payload;

    const modelWebsite = new ModelWebsite(website);

    modelWebsite.page.moveSectionIndexes(
      website.page.sections,
      from.map((item) => website.page.sections[item].name),
      to.map((item) => website.page.sections[item].name)
    );

    pages = pages.map((page) => (page.id === modelWebsite.page.id ? modelWebsite.page.object : page));

    yield put(
      theme.section.actions.move.success({
        pages: pages,
        website: modelWebsite.object
      })
    );

    yield put(theme.section.actions.organize.request({ website: modelWebsite.object }));
  } catch (error) {
    yield put(
      theme.section.actions.move.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
