import { Button } from "@soltivo/draw-a-line";
import { ReactComponent as SteveHiFive } from "@soltivo/draw-a-line/core/assets/illutrations/SteveHiFive.svg";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useEffect } from "react";
import styles from "./styles.module.scss";

const FlowEnd = () => {
  const { onFooterUpdate, onClose } = WindowMenu.useWindowMenu();

  useEffect(() => {
    onFooterUpdate(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key, () => {
      return (
        <>
          <Button
            variant="primary"
            style={{
              marginRight: 24
            }}
            onClick={() => {
              onClose(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key);
            }}>
            Done
          </Button>
        </>
      );
    });
  }, []);

  return (
    <div className={styles.component}>
      <div className={styles.vertical_center}>
        <SteveHiFive width={180} height={180} />
      </div>

      <div className={styles.vertical_center}>
        <p className={styles.description}>
          We are done with the basic configuration of your webiste, you can now edit the text, sections, and images of
          it!
        </p>
      </div>
    </div>
  );
};

export default FlowEnd;
