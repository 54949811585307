import { GLOBAL_VARS } from "helpers/global";
import { ErrorApp } from "../../../helpers/classes/ErrorApp";
import { ModelWebsite, ModelValidation } from "../../../helpers/models";
import types from "../../actions/types";

export interface WizardState {
  /**
   * @description device view.
   */
  device: Device;
  /**
   * @description either or not publishing theme.
   */
  publishing: boolean;
  /**
   * @description searched unsplash photos
   */
  unsplashSearch: {
    query: string;
    /**
     * @description current page
     */
    page: number;
    loading: boolean;
    results: UnplashPhotosSearch["results"];
    total: UnplashPhotosSearch["total"];
    total_pages: UnplashPhotosSearch["total_pages"];
  };
  /**
   * @description get initial unsplash photos
   */
  unsplashPhotos: {
    loading: boolean;
    /**
     * @description current page
     */
    page: number;
    results: UnsplashPhotos;
  }; //unsplash photos
  /**
   * @description theme url
   */
  themeURL: string;
  /**
   * @description theme cdn url for assets.
   */
  themeCDNURL: string;
  /**
   * @description either or not user wants to edit enable editing in the builder.
   */
  editor: boolean;
  /**
   * @description which page user is viewing/editing at the builder.
   */
  pageId: WebsitePage["id"]; //the page the user is inside the wizard.
  /**
   * @description options for industry
   */
  themeOptions: any[];
  /**
   * @description catch change in toggle section
   */
  updatedSection: number;
  /**
   * @description previous 3 colors for available colors within a theme.
   */
  prevColors: Partial<PrevColors>;
  /**
   * @description previous website data.
   */
  undos: {
    next: { website: WebsiteData; pages: WebsitePage[] }[];
    prev: { website: WebsiteData; pages: WebsitePage[] }[];
  };
  /**
   * @description check if theme is loading the data from its source.
   */
  loadingThemeData: boolean;
  /**
   * @description website global data.
   */
  website: WebsiteData;
  /**
   * @description website pages.
   */
  pages: WebsitePage[];
  /**
   * @description website configurations from config.data
   */
  configuration: Partial<WebsiteConfig>;
  /**
   * @description validation of the website.
   */
  validation: Validation;
  /**
   * @description available plugins.
   */
  plugins: {
    booking: {
      loading: boolean;
      categories: {
        loading: boolean;
        data: WebsitePluginServiceCategory[];
        toUpdate: WebsitePluginServiceCategory[];
      };
      services: {
        loading: boolean;
        data: WebsitePluginService[];
        toUpdate: WebsitePluginService[];
      };
    };
  };
  /**
   * @description purchased domain information.
   */
  domainInfo: {
    loading: boolean;
    data: DomainInfo | null;
    /**
     * @description when website.routes.list related to page is under update process.
     */
    updatingPagePath: boolean;
  };
  /**
   * @description errors in the builder.
   */
  error: ErrorApp | undefined;
}

const INITIAL_STATE: WizardState = {
  device: GLOBAL_VARS.devices.desktop,
  publishing: false,
  unsplashSearch: {
    query: "",
    loading: false,
    results: [],
    page: 1,
    total: 0,
    total_pages: 0
  },
  unsplashPhotos: {
    results: [],
    page: 1,
    loading: true
  }, //unsplash photos
  themeURL: "",
  themeCDNURL: "",
  editor: false,
  pageId: "", //the page the user is inside the wizard.
  themeOptions: [], //options for industry
  updatedSection: 0, //catch change in toggle section
  prevColors: {},
  undos: {
    next: [],
    prev: []
  },
  // watchingEditables: false,
  website: new ModelWebsite().object,
  loadingThemeData: true,
  pages: [],
  configuration: {},
  validation: new ModelValidation().object,
  plugins: {
    booking: {
      loading: true,
      categories: { data: [], toUpdate: [], loading: true },
      services: { data: [], toUpdate: [], loading: true }
    }
    // contact: {
    //     fields: [],
    // },
  },
  domainInfo: {
    loading: true,
    data: null,
    updatingPagePath: false
  },
  error: undefined
};
const Wizard = (
  state = INITIAL_STATE,
  action: {
    payload?: any;
    type: string;
  }
): WizardState => {
  switch (action.type) {
    case types.APP_GET_CREDENTIALS_REQUEST: {
      return INITIAL_STATE;
    }

    case types.WEBSITE_CHANGE_STATE:
      return {
        ...state,
        ...action.payload
      };

    case types.WEBSITE_GET_THEME_DATA_SUCCESS:
      return {
        ...state,
        loadingThemeData: false,
        themeURL: action.payload.themeURL,
        themeCDNURL: action.payload.themeCDNURL,
        website: action.payload.website,
        pages: action.payload.pages,
        validation: action.payload.validation,
        configuration: action.payload.configuration,
        error: undefined
      };

    case types.WEBSITE_GET_THEME_DATA_FAILURE:
      return {
        ...state,
        loadingThemeData: false,
        website: INITIAL_STATE.website,
        themeURL: INITIAL_STATE.themeURL,
        themeCDNURL: INITIAL_STATE.themeCDNURL,
        pages: INITIAL_STATE.pages,
        configuration: INITIAL_STATE.configuration,
        validation: INITIAL_STATE.validation,
        prevColors: INITIAL_STATE.prevColors,
        undos: INITIAL_STATE.undos,
        error: action.payload.error
      };

    case types.WEBSITE_GET_DOMAIN_INFO_REQUEST:
      return {
        ...state,
        domainInfo: {
          ...state.domainInfo,
          loading: true
        }
      };

    case types.WEBSITE_GET_DOMAIN_INFO_SUCCESS:
      return {
        ...state,
        domainInfo: action.payload.domainInfo,
        error: undefined
      };

    case types.WEBSITE_GET_DOMAIN_INFO_FAILURE:
      return {
        ...state,
        domainInfo: {
          ...state.domainInfo,
          loading: false,
          data: null
        },
        error: action.payload.error
      };

    case types.WEBSITE_OPEN_PAGE_SUCCESS:
      return {
        ...state,
        pageId: action.payload.pageId,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_OPEN_PAGE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_TOGGLE_PAGE_SUCCESS:
      return {
        ...state,
        updatedSection: state.updatedSection + 1,
        pages: action.payload.pages,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_TOGGLE_PAGE_FAILURE:
      return {
        ...state
      };

    case types.WEBSITE_TOGGLE_PAGE_SECTION_SUCCESS:
      return {
        ...state,
        updatedSection: state.updatedSection + 1,
        pages: action.payload.pages,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_TOGGLE_PAGE_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_RECORD_UNDO_SUCCESS:
      return {
        ...state,
        undos: action.payload.undos,
        error: undefined
      };

    case types.WEBSITE_RECORD_UNDO_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_RECOVER_CHANGE_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        pages: action.payload.pages,
        undos: action.payload.undos,
        error: undefined
      };

    case types.WEBSITE_RECOVER_CHANGE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_WIZARD_EDITOR_REQUEST:
      return {
        ...state,
        editor: action.payload.editor
      };

    case types.WEBSITE_CHANGE_THEME_COLOR_SUCCESS:
      return {
        ...state,
        website: action.payload.website
      };

    case types.WEBSITE_CHANGE_THEME_COLOR_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_SAVE_PREVIOUS_COLOR_REQUEST: {
      return {
        ...state,
        prevColors: { ...action.payload.prevColors },
        error: undefined
      };
    }

    case types.WEBSITE_MOVE_PAGE_SECTION_SUCCESS:
      return {
        ...state,
        pages: [...action.payload.pages],
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_MOVE_PAGE_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_CHANGE_THEME_FONT_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_CHANGE_THEME_FONT_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_GENERAL_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_GENERAL_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_CREATE_CONTACT_SM_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_CREATE_CONTACT_SM_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_DELETE_CONTACT_SM_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_DELETE_CONTACT_SM_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_LOGO_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_NAME_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_NAME_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_PAGE_SEO_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        pages: action.payload.pages,
        error: undefined
      };

    case types.WEBSITE_UPDATE_PAGE_SEO_FAILURE:
      return {
        ...state,
        website: state.website,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_PAGE_ROUTE_REQUEST:
      return {
        ...state,
        domainInfo: {
          ...state.domainInfo,
          updatingPagePath: true
        },
        error: undefined
      };

    case types.WEBSITE_UPDATE_PAGE_ROUTE_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        pages: action.payload.pages,
        domainInfo: {
          ...state.domainInfo,
          updatingPagePath: false
        },
        error: undefined
      };

    case types.WEBSITE_UPDATE_PAGE_ROUTE_FAILURE:
      return {
        ...state,
        website: state.website,
        pages: [...state.pages],
        domainInfo: {
          ...state.domainInfo,
          updatingPagePath: false
        },
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_SUCCESS:
      return {
        ...state,
        pages: action.payload.pages,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_SITE_MAP_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_SITE_MAP_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_MENU_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_MENU_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_UPDATE_FOOTER_SUCCESS:
      return {
        ...state,
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_UPDATE_FOOTER_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_GET_UNSPLASH_PHOTOS_REQUEST:
      return {
        ...state,
        unsplashPhotos: {
          ...state.unsplashPhotos,
          loading: true
        },
        unsplashSearch: INITIAL_STATE.unsplashSearch,
        error: undefined
      };

    case types.WEBSITE_GET_UNSPLASH_PHOTOS_SUCCESS:
      return {
        ...state,
        unsplashPhotos: action.payload.unsplashPhotos,
        unsplashSearch: INITIAL_STATE.unsplashSearch,
        error: undefined
      };

    case types.WEBSITE_GET_UNSPLASH_PHOTOS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_REQUEST:
      return {
        ...state,
        unsplashSearch: {
          ...state.unsplashSearch,
          loading: action.payload.page === 1 ? true : false
        }
      };

    case types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_SUCCESS:
      return {
        ...state,
        unsplashSearch: action.payload.unsplashSearch,
        error: undefined
      };

    case types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_FAILURE:
      return {
        ...state,
        unsplashSearch: INITIAL_STATE.unsplashSearch,
        error: action.payload.error
      };

    case types.WEBSITE_PUBLISH_REQUEST:
      return {
        ...state,
        publishing: true
      };

    case types.WEBSITE_PUBLISH_SUCCESS:
      return {
        ...state,
        publishing: false,
        error: undefined
      };

    case types.WEBSITE_PUBLISH_FAILURE:
      return {
        ...state,
        publishing: false,
        error: action.payload.error
      };

    case types.WEBSITE_GET_BOOKING_REQUEST:
      return {
        ...state,
        plugins: {
          ...state.plugins,
          booking: {
            ...state.plugins.booking,
            loading: true,
            categories: {
              ...state.plugins.booking.categories,
              loading: true
            },
            services: {
              ...state.plugins.booking.services,
              loading: true
            }
          }
        }
      };

    case types.WEBSITE_GET_BOOKING_SUCCESS:
      return {
        ...state,
        plugins: {
          ...state.plugins,
          booking: {
            ...state.plugins.booking,
            loading: false,
            categories: {
              ...state.plugins.booking.categories,
              loading: false,
              data: action.payload.categories
            },
            services: {
              ...state.plugins.booking.services,
              loading: false,
              data: action.payload.services
            }
          }
        },
        error: undefined
      };

    case types.WEBSITE_GET_BOOKING_FAILURE:
      return {
        ...state,
        plugins: {
          ...state.plugins,
          booking: {
            ...state.plugins.booking,
            loading: false,
            categories: {
              ...state.plugins.booking.categories,
              loading: false
            },
            services: {
              ...state.plugins.booking.services,
              loading: false
            }
          }
        },
        error: action.payload.error
      };

    case types.WEBSITE_PLUGIN_STORE_REQUEST:
      return state;

    case types.WEBSITE_PLUGIN_STORE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case types.WEBSITE_PLUGIN_STORE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_PLUGIN_UPDATE_REQUEST:
      return state;

    case types.WEBSITE_PLUGIN_UPDATE_SUCCESS:
      return {
        ...state
      };

    case types.WEBSITE_PLUGIN_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case types.WEBSITE_SECTION_ORGANIZE_ALL_SUCCESS:
      return {
        ...state,
        pages: [...action.payload.pages],
        website: action.payload.website,
        error: undefined
      };

    case types.WEBSITE_SECTION_ORGANIZE_ALL_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    // case types.WEBSITE_THEME_RESET_DEFAULT_SUCCESS:
    //   return {
    //     ...state,
    //     pages: [...action.payload.pages],
    //     website: action.payload.website,
    //     prevColors: action.payload.prevColors,
    //     undos: action.payload.undos,
    //     error: undefined
    //   };

    // case types.WEBSITE_THEME_RESET_DEFAULT_FAILURE:
    //   return {
    //     ...state,
    //     error: action.payload
    //   };

    // case types.WEBSITE_THEME_PUBLISHED_SUCCESS:
    //   return {
    //     ...state,
    //     pages: [...action.payload.pages],
    //     website: action.payload.website,
    //     prevColors: action.payload.prevColors,
    //     undos: action.payload.undos,
    //     error: undefined
    //   };

    // case types.WEBSITE_THEME_PUBLISHED_FAILURE:
    //   return {
    //     ...state,
    //     error: action.payload
    //   };

    default:
      return state;
  }
};

export default Wizard;
