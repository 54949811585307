import { Close24Px } from "@soltivo/draw-a-line/core/components/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import styles from "./messager.module.scss";

const Messager = () => {
  const [messages, setMessages] = useState<{ message: string }[]>([]);

  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    error: WebsiteWizardReducer.error
  });

  const { error } = useSelector(mapStateToProps);

  const onClose = (index?: number) => {
    if (typeof index === "number") {
      setMessages((state) => state.filter((item, i) => i !== index));
    } else {
      setMessages([]);
    }
  };

  useEffect(() => {
    if (error) {
      setMessages([
        {
          message: error.message
        }
      ]);
    }
  }, [error]);

  useEffect(() => {
    const i = setTimeout(() => {
      onClose();
    }, 10000);

    return () => {
      clearTimeout(i);
    };
  }, [error]);

  return (
    <div className={styles.messager}>
      <ul>
        {messages.map((item, index) => {
          return (
            <li key={index}>
              <span>{item.message}</span>

              <button onClick={() => onClose(index)}>
                <Close24Px />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Messager;
