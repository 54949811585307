import React, { useEffect } from "react";
import useAssets from "../../helpers/hooks/useAssets";
import useIframe from "../../helpers/hooks/useIframe";
import useRemoveEvents from "../../helpers/hooks/useRemoveEvents";
import useSendData from "../../helpers/hooks/useSendData";
import useThemeFonts from "../../helpers/hooks/useThemeFonts";
import styles from "./brain.wizard.themes.module.scss";
import Button from "@soltivo/draw-a-line/core/components/buttons/button/button";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers";
import Loader from "@soltivo/draw-a-line/core/components/loading/loader/loader";
import useThemeColors from "../../helpers/hooks/useThemeColors";
import useUndo from "../../helpers/hooks/useUndo";
import { Eye, EyeClosed, Undo, Redo } from "@soltivo/draw-a-line/core/components/icons";
import useEditableV1 from "../../helpers/hooks/useEditable/useEditable";
import Dropdown from "../../components/dropdown/dropdown";
import useDeviceScrollbar from "helpers/hooks/useDeviceScrollbar";
import Messager from "components/messager/messager";
import { GLOBAL_VARS } from "helpers/global";
import { DOMIframeWebsiteResolution } from "helpers/functions/DOMIframeWebsiteResolution";
import PageSeoWindow from "./windows/page.seo/page.seo";
import ManageSectionsWindow from "./windows/manage.sections/manage.sections";
import { useSaveDataLocally } from "helpers/hooks/useSaveDataLocally/useSaveDataLocally";
import { theme, wizard } from "redux/actions";
import { useInitialConfiguration } from "helpers/hooks/useInitialConfiguration/useInitialConfiguration";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import Menu from "blocks/configuration/menu/menu";
import DomainInput from "blocks/domain.input/domain.input";

type BrainWizardThemesProps = MapStateToProps;

/**
 * @description Website wizard render
 */
const BrainWizardThemes: React.FC<React.PropsWithChildren<BrainWizardThemesProps>> = ({
  publishing,
  device,
  themeURL,
  website,
  pages,
  loadingThemeData,
  configuration,
  validation,
  updatedSection,
  pageId,
  undos,
  // watchingEditables,
  editor,
  prevColors,
  plugins,
  domainInfo,
  error
}) => {
  const dispatch = useDispatch();
  useInitialConfiguration();
  const { loading: savingDataLocally } = useSaveDataLocally();
  const { iframeWebsite, iframeIsLoaded } = useIframe();
  useThemeFonts(iframeIsLoaded, iframeWebsite, website);
  useThemeColors(iframeIsLoaded, iframeWebsite, website);
  useRemoveEvents(editor, website, iframeWebsite);
  // Loading libraries and assets required for editor.
  useAssets(
    [
      {
        id: "SOLTIVO_THEME_EDITOR",
        url: `${process.env.REACT_APP_URL}/website/styles/IBS__.css`,
        htmlElement: "link"
      }
      // ...(configuration.assets?.css.external || [])?.map((item) => ({
      //   id: "SOLTIVO_THEME_EDITOR",
      //   url: `${process.env.REACT_APP_URL}/website/styles/IBS___.css`,
      //   htmlElement: "link" as any
      // }))
    ],
    iframeWebsite
  );

  /**
   * @description check if theme is able to be loaded.
   */
  const isThemeReadyToLoad = Boolean(website.themeId && pageId && themeURL);

  useDeviceScrollbar(isThemeReadyToLoad, device, themeURL, iframeIsLoaded, iframeWebsite);

  const { sentFirstMessage } = useSendData({
    isThemeReadyToLoad,
    iframeIsLoaded,
    iframeWebsite,
    themeURL,
    website,
    pages
  });

  // const _INITIAL_DROPS =
  //     pages: false,
  //     sections: false,
  // };

  const { EditableElements } = useEditableV1({
    iframeWebsite: iframeWebsite,
    website: website,
    validation: validation,
    pageId: pageId,
    plugins: plugins,
    error: error,
    device: device
  });

  // const { setState: createWindowDataSetState, state: createWindowDataState } = useCreateWindowData();

  //create undos
  useUndo(isThemeReadyToLoad);

  // const [
  //     ,
  //     // drop
  //     setDrop,
  // ] = useState(_INITIAL_DROPS);

  // const onDropdownSections = (show: boolean) => setDrop({ ..._INITIAL_DROPS, sections: show });

  /**
   * @description selects a page to be displayed on the screen only if
   * is selected or default.
   * @param {number} pageId
   */
  const onSelectPage = (pageId?: WebsitePage["id"]) => {
    if (!pageId) return;
    dispatch(theme.page.actions.open.request({ pageId: pageId }));
  };

  //enables page
  const enablePage = (pageName?: WebsitePage["name"]) => {
    if (!pageName) return;
    dispatch(theme.page.actions.toggle.request({ pageName: pageName, action: "enable" }));
  };

  //disables page
  const disablePage = (pageName?: WebsitePage["name"]) => {
    if (!pageName) return;
    dispatch(theme.page.actions.toggle.request({ pageName: pageName, action: "disable" }));
  };

  /**
   * @description on undo change in WebsiteWizardReducer[website].
   */
  const onUndo = () => {
    dispatch(theme.data.actions.recovery.request({ action: "undo" }));
  };

  /**
   * @description on redo change in WebsiteWizardReducer[website].
   */
  const onRedo = () => {
    dispatch(theme.data.actions.recovery.request({ action: "redo" }));
  };

  const onPublish = () => {
    if (publishing) return;
    // popupHelper.popupStart(PopupPublish);
    dispatch(theme.data.actions.publish.request());
    //publishWebsite(WebsiteWizardReducer);
  };

  useEffect(() => {
    dispatch(wizard.state.actions.toggleEditor.request({ editor: true }));
  }, [dispatch]);

  const onOpenWebsiteConfiguration = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onStartWindowMenu({
      Component: () => <Menu />,
      id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.key,
      title: "Configuration",
      size: "big",
      acceptSizes: ["big"],
      event: e,
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_BASICS,
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_LOGOS,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_COLORS,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_FONTS,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_CONTACT_INFO,
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_PLUGINS,
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  useEffect(() => {
    // get fonts links in the builder
    if (configuration.fonts) {
      configuration.fonts.forEach((font: WebsiteFont) => {
        const link =
          document.head.querySelector(`#BUILDER_LINK-${font.name.replace(/\s/g, "_")}`) ||
          document.createElement("link");
        link.id = `BUILDER_LINK-${font.name.replace(/\s/g, "_")}`;
        link.setAttribute("href", font.href);
        link.setAttribute("rel", "stylesheet");
        document.head.appendChild(link);
      });
    }
  }, [configuration?.fonts]);

  // useEffect(() => {
  //     if (website.footer && validation) {
  //         // const x = dataInputGenerator.createDataPath(website.footer as WebsiteFooter, {
  //         //     removeData: true,
  //         // });
  //         // console.log(x);
  //         dataInputGenerator.process({
  //             obj: website.footer as WebsiteFooter,
  //             validation: validation as Validation,
  //             dataPathStart: "footer",
  //         });
  //     }
  // }, [validation, website]);

  useEffect(() => {
    const interval = setInterval(() => {
      DOMIframeWebsiteResolution(
        iframeWebsite,
        { width: device.width, height: device.height },
        {
          updateDOM: true,
          callback: (payload) => {
            clearInterval(interval);
          }
        }
      );
    });

    return () => {
      clearInterval(interval);
    };
  }, [iframeWebsite, iframeIsLoaded, isThemeReadyToLoad, window.innerHeight, window.innerWidth, device]);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenPageSeoWindow = (e: React.MouseEvent<HTMLButtonElement>) => {
    onStartWindowMenu({
      event: e,
      id: WINDOW_MENU_KEYS.PAGE_SEO.key,
      Component: () => <PageSeoWindow />,
      title: "Page Settings",
      size: "big",
      acceptSizes: ["big", "medium"],
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_INPUTS,
            title: "Page settings",
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_UNSPLASH,
            title: "Thumbnail",
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  const openManageSectionsWindow = (e: React.MouseEvent<HTMLButtonElement>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.MANAGE_SECTIONS.key,
      event: e,
      Component: () => <ManageSectionsWindow />,
      title: "Manage Sections",
      size: "medium",
      acceptSizes: ["medium"]
    });
  };

  // const openResetDefault = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   onStart(e, {
  //     Component: () => <ResetThemeWindow />,
  //     title: "Reset theme",
  //     size: "big",
  //     acceptSizes: ["big"]
  //   });
  // };

  useEffect(() => {
    // make sure window is always at top and left 0 scroll position.
    const interval = setInterval(() => {
      if (window.scrollY !== 0 || window.scrollX !== 0) {
        window.scrollTo({ top: 0, left: 0 });
      }
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.brain__wizard__themes} data-theme={localStorage.getItem("BUILDER_THEME")}>
      {isThemeReadyToLoad ? (
        <>
          <header>
            <div className={styles.left}>
              <div className={styles.page}>
                <label>Page :</label>
                {pages.length > 1 ? (
                  <Dropdown
                    valueProps={{
                      value: website.page.name.replace(/(-|_)/g, " ")
                    }}
                    optionsProps={{
                      options: pages
                        .filter((page) => !page.hidden)
                        .map((page) => {
                          let visibility = {
                            default: false,
                            selected: false
                          };

                          const routesList = website.routes.list;

                          const pageRoute = routesList.find((p) => p.pageName === page.name);

                          if (typeof pageRoute?.default === "boolean" && typeof pageRoute?.selected === "boolean") {
                            visibility = {
                              default: pageRoute.default,
                              selected: pageRoute.selected
                            };
                          }

                          return {
                            option: {
                              option: page,
                              value: page.name.replace(/(-|_)/g, " "),
                              icon: visibility.default
                                ? undefined
                                : {
                                    svg: visibility.selected ? <Eye /> : <EyeClosed />,
                                    onClick: () =>
                                      visibility.selected || visibility.default
                                        ? disablePage(page.name)
                                        : enablePage(page.name)
                                  }
                            },
                            key: page.id,
                            onClick: () => onSelectPage(page.id)
                          };
                        })
                    }}
                  />
                ) : (
                  <span>{website.page.name.replace(/(-|_)/g, " ") || ""}</span>
                )}
              </div>
            </div>

            <div className={styles.right}>
              {/* Display devices. */}
              <div className={styles.devices}>
                <span
                  onClick={() => {
                    if (device.type !== "desktop")
                      dispatch(wizard.state.actions.change.request({ device: GLOBAL_VARS.devices.desktop }));
                  }}
                  className={`material-symbols-outlined ${device.type === "desktop" ? styles.active : ""}`}
                  role="button">
                  desktop_windows
                </span>

                <span
                  onClick={() => {
                    if (device.type !== "tablet")
                      dispatch(wizard.state.actions.change.request({ device: GLOBAL_VARS.devices.tablet }));
                  }}
                  className={`material-symbols-outlined ${device.type === "tablet" ? styles.active : ""}`}
                  role="button">
                  tablet_android
                </span>

                <span
                  onClick={() => {
                    if (device.type !== "mobile")
                      dispatch(wizard.state.actions.change.request({ device: GLOBAL_VARS.devices.mobile }));
                  }}
                  className={`material-symbols-outlined ${device.type === "mobile" ? styles.active : ""}`}
                  role="button">
                  smartphone
                </span>
              </div>

              <div className={styles.painel}>
                <Button
                  data-title="Undo previous changes"
                  data-title-position="bottom-left"
                  variant="geyser-500"
                  outline
                  border={false}
                  disabled={!undos.prev.length}
                  onClick={onUndo}
                  padding={false}>
                  <Undo />{" "}
                </Button>

                <Button
                  data-title="Redo undone changes"
                  data-title-position="bottom-left"
                  variant="geyser-500"
                  outline
                  border={false}
                  disabled={!undos.next.length}
                  onClick={onRedo}
                  padding={false}>
                  <Redo />{" "}
                </Button>

                {/* <hr />

                <Button
                  onClick={(e) => {
                    openResetDefault(e);
                  }}
                  data-title="Delete all changes you have done in the theme view."
                  data-title-position="bottom-left"
                  outline
                  variant="geyser-500"
                  padding={false}>
                  Reset default
                </Button> */}

                <hr />

                <Button onClick={onOpenWebsiteConfiguration} outline variant="geyser-500" padding={false}>
                  Configuration
                </Button>
              </div>

              <div className={styles.publish}>
                <button onClick={savingDataLocally ? undefined : onPublish} disabled={publishing}>
                  {publishing ? "Publishing" : "Publish"}
                </button>
              </div>
            </div>
          </header>

          <div
            style={{
              margin: loadingThemeData && !sentFirstMessage ? "auto" : "0 auto"
            }}
            className={`${styles.container} ${styles[device.type || "desktop"] || ""}`}>
            {!loadingThemeData && sentFirstMessage ? null : (
              <div className={styles.loading}>
                <h6>Rendering theme</h6>
                <Loader size="sm" variant="primary" />
              </div>
            )}

            <header className={styles.header}>
              <div className={styles.header__content}>
                <DomainInput
                  website={website}
                  domainInfo={domainInfo}
                  page={website.page}
                  error={error}
                  validation={validation}
                />

                <div className={styles.page__panel}>
                  <Button onClick={onOpenPageSeoWindow} outline variant="geyser-500" padding={false}>
                    Page settings
                  </Button>

                  <span>|</span>

                  <Button onClick={openManageSectionsWindow} outline variant="geyser-500" padding={false}>
                    Manage Sections
                  </Button>
                </div>
              </div>
            </header>

            <div className={styles.iframe__wrapper}>
              <iframe
                /* Do not remove id from iframe because it's important to fetch it in some places/helpers/components 
                        manually using querySelector or getDocumentById */
                id={GLOBAL_VARS.iframeWebsite.id}
                name={GLOBAL_VARS.iframeWebsite.name}
                className={`${!loadingThemeData && sentFirstMessage ? "" : styles.hidden}`}
                src={`${themeURL}`}
              />
            </div>
          </div>

          {EditableElements ? <EditableElements /> : null}

          <Messager />
        </>
      ) : (
        <div className="starting-wizard">
          <div>
            <Loader variant="primary" size="lg" />
            <h2> </h2>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => {
  return {
    publishing: WebsiteWizardReducer.publishing,
    themeURL: WebsiteWizardReducer.themeURL,
    website: WebsiteWizardReducer.website,
    pages: WebsiteWizardReducer.pages,
    loadingThemeData: WebsiteWizardReducer.loadingThemeData,
    configuration: WebsiteWizardReducer.configuration,
    validation: WebsiteWizardReducer.validation,
    updatedSection: WebsiteWizardReducer.updatedSection,
    pageId: WebsiteWizardReducer.pageId,
    undos: WebsiteWizardReducer.undos,
    editor: WebsiteWizardReducer.editor,
    prevColors: WebsiteWizardReducer.prevColors,
    device: WebsiteWizardReducer.device,
    // fromLocalStorage: WebsiteWizardReducer.fromLocalStorage,
    plugins: WebsiteWizardReducer.plugins,
    domainInfo: WebsiteWizardReducer.domainInfo,
    error: WebsiteWizardReducer.error
  };
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(BrainWizardThemes);
