import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ResetThemeWindow from "pages/brain.wizard.themes/windows/reset.theme/reset.theme";
import { useBuilderIndexedDB } from "../useBuilderIndexedDB/useBuilderIndexedDB";

export const useSaveDataLocally = () => {
  const [state, setState] = useState({
    loading: false,
    openedWindow: false
  });

  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website,
    prevColors: Wizard.prevColors,
    pages: Wizard.pages,
    undos: Wizard.undos
  });
  const { pages, prevColors, undos, website } = useSelector(mapStateToProps);

  const indexedDB = useBuilderIndexedDB({ storeKey: "theme", version: 1 });

  const { getWindowMenuById } = WindowMenu.useWindowMenu();
  const windowMenuIDB = getWindowMenuById(WINDOW_MENU_KEYS.SAVE_INDEXEDDB_LOCALLY.key);

  // open window to restore
  useEffect(() => {
    const timeout = setTimeout(async () => {
      const storedTheme: IDBBuilderDatabase["theme"]["value"] | undefined = await indexedDB.get(website.themeId);

      if (!storedTheme || state.openedWindow) {
        clearTimeout(timeout);
        return;
      }

      setState((state) => ({ ...state, openedWindow: true }));

      onStartWindowMenu({
        startPosition: "center",
        Component: () => {
          return <ResetThemeWindow />;
        },
        id: WINDOW_MENU_KEYS.SAVE_INDEXEDDB_LOCALLY.key,
        title: "Previous changes",
        size: "medium",
        acceptSizes: ["medium"]
      });
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // store every 2 seconds
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (windowMenuIDB?.show) {
        clearTimeout(timeout);
        setState((state) => ({ ...state, loading: false }));
        return;
      }

      const current = await indexedDB?.get(website.themeId);
      const next = {
        website: website,
        pages: pages,
        undos: undos,
        prevColors: prevColors
      };

      if (JSON.stringify(current) !== JSON.stringify(next)) {
        setState((state) => ({
          ...state,
          loading: true
        }));

        await indexedDB?.set(website.themeId, next);
      }

      setState((state) => ({ ...state, loading: false }));
    }, 2000);

    return () => {
      clearTimeout(timeout);
      setState((state) => ({ ...state, loading: false }));
    };
  }, [website, undos, pages, prevColors, windowMenuIDB?.show]);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  return state;
};
