import React, { FC } from "react";
import { Button, Column, FormControl, Row } from "@soltivo/draw-a-line";
import useEditableData from "helpers/hooks/useEditableData";
import SelectIcon from "components/select.icon/select.icon";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface IconWindowProps extends EditableWindowProps<"icon"> {}

const IconWindow: FC<React.PropsWithChildren<IconWindowProps>> = ({ dataClass, dataPath }) => {
  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  const { getWindowMenuById } = WindowMenu.useWindowMenu();

  const onSelect = (icon: WebsiteIcon) => {
    if (!data || !validation) {
      return;
    }

    onUpdate(icon);
  };

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_ICON.key);

  if (!data || !validation || !windowMenu) {
    return null;
  }

  return (
    <Row>
      <Column size={"12"}>
        <Row>
          <Column size={"10"}>
            <FormControl
              labelProps={{
                value: "Icon"
              }}>
              <SelectIcon
                onSelect={(payload) => {
                  onSelect(payload.value);
                }}
                name={"icon"}
                value={data}
              />
            </FormControl>
          </Column>

          <Column size={"2"}>
            <FormControl
              labelProps={{
                value: "Fill"
              }}>
              <div data-title={"Fill/Outlined"} data-title-position="bottom-left">
                <Button
                  onClick={() => {
                    if (!data) {
                      return;
                    }
                    onSelect({
                      ...data,
                      fill: data?.fill === 1 ? 0 : 1
                    });
                  }}
                  outline
                  variant="primary"
                  padding={false}
                  style={{
                    margin: "8px auto 0 auto",
                    fontVariationSettings: `'FILL' ${data.fill}`
                  }}
                  className={` material-symbols-${data.format || "outlined"}`}>
                  format_color_reset
                </Button>
              </div>
            </FormControl>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

export default IconWindow;
