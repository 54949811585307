import React from "react";
import { Settings24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./styles.module.scss";
import MapWindow from "./map.window/map.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface EditableMapProps extends EditableProps<"map"> {}

const EditableMap: React.FC<React.PropsWithChildren<EditableMapProps>> = ({
  viewPositionString,
  dataClass,
  dataPath
}) => {
  const editable = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_MAP.key,
      event: e,
      title: "Map",
      Component: () => <MapWindow dataPath={dataPath} dataClass={dataClass} />
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  return (
    <div className={`${styles.component} ${styles[viewPositionString.x]} ${styles[viewPositionString.y]}`}>
      <div className={styles.content}>
        <div className={styles.actions}>
          <React.Fragment>
            <button onClick={onOpenWindow}>
              <Settings24Px />
            </button>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default EditableMap;
