import { forwardRef } from "react";
import Option, { OptionProps } from "./option/option";
import styles from "./options.module.scss";

export interface OptionsProps {
  options: OptionProps[];
  className?: string;
}

const Options = forwardRef<HTMLUListElement, OptionsProps>(({ options, className }, ref) => {
  return (
    <ul className={`${styles.options} ${className ? className : ""}`} ref={ref}>
      {options.map((opt, index) => {
        return <Option {...opt} key={opt.key || index} />;
      })}
    </ul>
  );
});

export default Options;
