import { DOMGetIframeWebsite } from "helpers/functions/DOMGetIframeWebsite";
import { useState, useEffect } from "react";

/**
 * @description get the element iframe if is available (rendered) & also gets the data from
 * the theme script in the head.
 */
const useIframe = (
  options: {
    device: DeviceTypes;
  } = {
    device: "desktop"
  }
) => {
  const { device } = options;
  const [state, setState] = useState<{
    iframeWebsite: HTMLIFrameElement | undefined;
    iframeIsLoaded: boolean;
  }>({
    iframeWebsite: undefined,
    iframeIsLoaded: false
  });

  const onLoadIframe = (iframeWebsite: HTMLIFrameElement) => {
    const interval = setInterval(() => {
      const iframeDocument = iframeWebsite?.contentDocument;
      if (!iframeDocument) {
        clearInterval(interval);
        return;
      }

      //remove right click inside iframe.
      if (process.env.NODE_ENV === "production") {
        if (iframeWebsite.contentWindow) {
          const onPreventDefalt = (e: MouseEvent) => {
            e.preventDefault();
          };
          iframeWebsite.contentWindow.removeEventListener("contextmenu", onPreventDefalt);
          iframeWebsite.contentWindow.addEventListener("contextmenu", onPreventDefalt);
        }
      }

      if (iframeDocument.readyState === "complete") {
        setState((state) => ({
          ...state,
          iframeWebsite: iframeWebsite,
          iframeIsLoaded: true
        }));
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    // gets the iframe dom element
    const intervalIframe = setInterval(() => {
      const { iframeWebsite } = DOMGetIframeWebsite();
      if (iframeWebsite) {
        onLoadIframe(iframeWebsite);
        clearInterval(intervalIframe);
      }
    });

    return () => {
      clearInterval(intervalIframe);
    };
  }, [device]);

  return state;
};

export default useIframe;
