import { GLOBAL_VARS } from "helpers/global";
import React, { useRef, useState } from "react";
import ReactDom from "react-dom";
import WindowMenu from ".";
import { INITIAL_STATE, WindowMenuContextState } from "./context";

/**
 * Create window menu context and append windows through react portal portal api,
 * it's also responsible to make sure the windows don't have to update if other
 * window is updating.
 */
const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<WindowMenuContextState["state"]>(INITIAL_STATE["state"]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <WindowMenu.Context.Provider
      value={{
        setState: setState,
        state: state
      }}>
      <WindowMenu.Context.Consumer>
        {({ state }) => {
          return (
            <>
              <div
                style={{
                  position: "fixed",
                  zIndex: GLOBAL_VARS.styles.WINDOW_MENU_INDEX,
                  width: "100vw",
                  height: "100vh",
                  pointerEvents: "none"
                }}
                data-wm-wrapper="true"
                ref={wrapperRef}></div>

              {state.windows.map((windowMenu, i) => {
                if (!wrapperRef.current) {
                  return;
                }
                const exists = wrapperRef.current.querySelector(`[data-wm-id="${windowMenu.id}"]`);

                if (!exists) {
                  const element = document.createElement("div");
                  element.setAttribute(`data-wm-id`, windowMenu.id);
                  wrapperRef.current.appendChild(element);
                  return ReactDom.createPortal(
                    <WindowMenu.Component id={windowMenu.id} />,
                    element,
                    `${windowMenu.id}`
                  );
                } else {
                  return ReactDom.createPortal(<WindowMenu.Component id={windowMenu.id} />, exists, `${windowMenu.id}`);
                }
              })}

              {children}
            </>
          );
        }}
      </WindowMenu.Context.Consumer>
    </WindowMenu.Context.Provider>
  );
};

export default Wrapper;
