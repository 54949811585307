import { WizardState } from "redux/reducers/wizard/reducer";
const types = {
  WEBSITE_GET_UNSPLASH_PHOTOS_REQUEST: "WEBSITE_GET_UNSPLASH_PHOTOS_REQUEST",
  WEBSITE_GET_UNSPLASH_PHOTOS_SUCCESS: "WEBSITE_GET_UNSPLASH_PHOTOS_SUCCESS",
  WEBSITE_GET_UNSPLASH_PHOTOS_FAILURE: "WEBSITE_GET_UNSPLASH_PHOTOS_FAILURE",

  WEBSITE_SEARCH_UNSPLASH_PHOTOS_REQUEST: "WEBSITE_SEARCH_UNSPLASH_PHOTOS_REQUEST",
  WEBSITE_SEARCH_UNSPLASH_PHOTOS_SUCCESS: "WEBSITE_SEARCH_UNSPLASH_PHOTOS_SUCCESS",
  WEBSITE_SEARCH_UNSPLASH_PHOTOS_FAILURE: "WEBSITE_SEARCH_UNSPLASH_PHOTOS_FAILURE"
};

const actions = {
  /**
   * @description get unsplash photos.
   */
  getPhotos: {
    request: (payload: { page: number; per_page?: number }) => ({
      type: types.WEBSITE_GET_UNSPLASH_PHOTOS_REQUEST,
      payload
    }),
    success: (payload: { unsplashPhotos: WizardState["unsplashPhotos"] }) => ({
      type: types.WEBSITE_GET_UNSPLASH_PHOTOS_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_GET_UNSPLASH_PHOTOS_FAILURE,
      payload
    })
  },
  /**
   * @description search unsplash photos.
   */
  searchPhotos: {
    request: (payload: { query: string; page: number; per_page?: number }) => ({
      type: types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_REQUEST,
      payload
    }),
    success: (payload: { unsplashSearch: WizardState["unsplashSearch"] }) => ({
      type: types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_SEARCH_UNSPLASH_PHOTOS_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
