import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassPlugin = (
  validation: ValidateClassPlugin,
  payload: WebsitePluginService | WebsitePluginServiceCategory
) => {
  if (validation.type === "booking/category") {
    const value = payload as WebsitePluginServiceCategory;

    if (typeof value.id !== "string") {
      throw createErrorApp(`Category is missing id.`);
    }

    if (typeof value.color !== "string") {
      throw createErrorApp(`Category is missing color.`);
    }

    if (!new RegExp(GLOBAL_VARS.validaton.regex.color.hex).test(value.color)) {
      throw createErrorApp(`Category color value is not HEX color.`);
    }

    if (typeof value.description !== "string") {
      throw createErrorApp(`Category is missing description.`);
    }

    if (typeof value.lowestPrice !== "number") {
      throw createErrorApp(`Category is missing lowest price.`);
    }

    if (typeof value.name !== "string") {
      throw createErrorApp(`Category is missing name.`);
    }
  }

  if (validation.type === "booking/service") {
    const value = payload as WebsitePluginService;

    if (typeof value.id !== "string") {
      throw createErrorApp(`Service is missing id.`);
    }

    if (typeof value.categoryId !== "string") {
      throw createErrorApp(`Service is missing categoryId.`);
    }

    if (typeof value.color !== "string") {
      throw createErrorApp(`Service is missing color.`);
    }

    if (!new RegExp(GLOBAL_VARS.validaton.regex.color.hex).test(value.color)) {
      throw createErrorApp(`Service color value is not HEX color.`);
    }

    if (typeof value.description !== "string") {
      throw createErrorApp(`Service is missing description.`);
    }

    if (typeof value.bufferTime !== "number") {
      throw createErrorApp(`Service is missing bufferTime.`);
    }

    if (typeof value.price !== "number") {
      throw createErrorApp(`Service is missing price.`);
    }

    if (typeof value.title !== "string") {
      throw createErrorApp(`Service is missing title.`);
    }

    if (typeof value.duration !== "number") {
      throw createErrorApp(`Service is missing duration.`);
    }

    if (typeof value.currency !== "string") {
      throw createErrorApp(`Service is missing currency.`);
    }

    if (typeof value.image !== "string") {
      throw createErrorApp(`Service is missing image.`);
    }
  }
};
