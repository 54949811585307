import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { createErrorApp, ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import { theme } from "redux/actions";

/**
 * @description enable/disable selectable page.
 */
export default function* toggle({
  payload
}: ReturnType<(typeof theme)["page"]["actions"]["toggle"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const codeException = "TogglePageException";
    const { pageName, action } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      pages: WebsiteWizardReducer.pages,
      pageId: WebsiteWizardReducer.pageId,
      website: WebsiteWizardReducer.website
    });

    const { pages, website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    const pageSelectedLength = modelWebsite.routes.list.filter((page) => page.selected || page.default).length;
    if (pageSelectedLength === 1 && action.toLowerCase() === "disable") {
      throw createErrorApp("You must have at least one page enabled.", { code: codeException });
    }

    const foundRoute = modelWebsite.routes.list.find((route) => route.pageName === pageName);

    if (foundRoute) {
      // update website.routes.list
      foundRoute.selected = action.toLowerCase() === "enable" ? true : false;
      modelWebsite.update(modelWebsite, {
        routes: {
          ...modelWebsite.routes,
          list:
            modelWebsite.routes.list.map((route) =>
              route.pageName === foundRoute?.pageName && foundRoute ? foundRoute : route
            ) || []
        }
      });
    }

    yield put(
      theme.page.actions.toggle.success({
        pages: pages,
        website: website
      })
    );
  } catch (error) {
    yield put(
      theme.page.actions.toggle.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
