import { Button, Column, FormControl, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import { Color } from "@soltivo/draw-a-line/core/helpers/colors.styles";
import Logos from "blocks/logos/logos";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";
import styles from "./styles.module.scss";

const InformationOne = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website
  });

  const { website } = useSelector(mapStateToProps);

  const { onFooterUpdate, onMoveToPage } = WindowMenu.useWindowMenu();

  useEffect(() => {
    onFooterUpdate(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key, () => {
      return (
        <>
          <Button
            variant="primary"
            onClick={() => {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
                pageId: Object.keys(website.contact.information).length
                  ? WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_2
                  : WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_CUSTOMIZATION
              });
            }}>
            Next <span className="material-symbols-outlined">navigate_next</span>
          </Button>
        </>
      );
    });
  }, []);

  return (
    <form
      className={styles.component}
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <Row>
        <Column size={"6"}>
          <Row>
            <Column size={"12"}>
              <FormControl
                labelProps={{
                  value: "Title"
                }}
                footerProps={{
                  value: `${website.page.title.length}/70 CHARACTERS`,
                  style: {
                    color: website.page.title.length > 50 ? Color["valencia"]["valencia-500"] : undefined
                  }
                }}>
                <InputText
                  defaultValue={website.page.title}
                  onChange={(e) => {
                    dispatch(
                      actions.theme.page.actions.updateSEO.request({
                        seo: {
                          title: e.target.value
                        }
                      })
                    );
                  }}
                  placeholder="Joi Hair Salon - New York"></InputText>
              </FormControl>
            </Column>
            <Column size={"12"}>
              <FormControl
                labelProps={{
                  value: "Description"
                }}
                footerProps={{
                  value: `${website.page.description.length}/160 CHARACTERS`,
                  style: {
                    color: website.page.description.length > 120 ? Color["valencia"]["valencia-500"] : undefined
                  }
                }}>
                <Textarea
                  defaultValue={website.page.description}
                  onChange={(e) => {
                    dispatch(
                      actions.theme.page.actions.updateSEO.request({
                        seo: {
                          description: e.target.value
                        }
                      })
                    );
                  }}
                  placeholder="We offer the best hair cut in New York city"></Textarea>
              </FormControl>
            </Column>
          </Row>
        </Column>
        <Column size={"6"}>
          <Logos />
        </Column>
      </Row>
    </form>
  );
};

export default InformationOne;
