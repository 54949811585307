import _updateWith from "lodash.updatewith";
import _cloneDeep from "lodash.clonedeep";
import { createObjectAttributePath, setValueInDataClass } from "helpers";

class ModelSection implements WebsiteSection {
  constructor(section?: Partial<WebsiteSection>) {
    if (section) {
      const _section = _cloneDeep(section);
      this.data = typeof _section.data === "object" ? _section.data : this.data;
      this.default = typeof _section.default === "boolean" ? _section.default : this.default;
      this.id = typeof _section.id === "string" ? _section.id : this.id;
      this.name = typeof _section.name === "string" ? _section.name : this.name;
      this.selected = typeof _section.selected === "boolean" ? _section.selected : this.selected;
      this.targetId = typeof _section.targetId === "string" ? _section.targetId : this.targetId;
    }
  }

  data: { [index: string]: any } = {};

  default = false;

  id = "";

  name = "";

  selected = false;

  targetId = "";

  get object(): WebsiteSection {
    return {
      data: this.data,
      default: this.default,
      id: this.id,
      name: this.name,
      selected: this.selected,
      targetId: this.targetId
    };
  }

  /**
   * @description checks if name has "-" or "_" and replace it with space.
   */
  public purifyName(name: string) {
    return name.replace(/(-|_)/g, " ").toLowerCase();
  }

  /**
   * @description modify the data property of a section & returns back the single property from the data property
   * the user has modified.
   */
  public sectionDataModify(modifyConfig: ModifyConfig) {
    try {
      const { section, dataPath, dataClass, value } = modifyConfig;
      const copySection = section;

      let test = dataPath
        .replace(/[a-z_-]+\//, "")
        .replace(/\/([0-9]+)/g, "[$1]")
        .replace(/\//g, ".");
      test = `data.${test}`;
      test = createObjectAttributePath(copySection, test);

      const result = _updateWith(copySection, test, (oldVaue) => {
        const sanitazedValue = setValueInDataClass(dataClass, value);
        return sanitazedValue;
      });

      return result;
    } catch (error) {
      console.error(error);
      const { section } = modifyConfig;
      return section;
    }
  }
}

export { ModelSection };
