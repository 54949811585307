import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import actions from "redux/actions";

/**
 * @description create contact social media
 */
export default function* deleteSocialMedia({
  payload
}: ReturnType<(typeof actions.theme)["contact"]["actions"]["deleteSocialMedia"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<any> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  const { id } = payload;
  try {
    const mapStateToProps = ({ Wizard }: RootState) => ({
      website: Wizard.website
    });

    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const socialMedia = modelWebsite.contact.socialMedia.filter((item) => item.id !== id);

    modelWebsite.update(modelWebsite, {
      contact: {
        ...modelWebsite.contact,
        socialMedia: socialMedia
      }
    });

    yield put(
      actions.theme.contact.actions.deleteSocialMedia.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      actions.theme.contact.actions.deleteSocialMedia.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
