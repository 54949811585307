import { Column, Row } from "@soltivo/draw-a-line";
import ContentHeader from "components/content.header/content.header";
import General from "blocks/general/general";
import styles from "./styles.module.scss";

const Basics = () => {
  return (
    <Row className={styles.component}>
      <Column size={"12"}>
        <ContentHeader title="Basic information"></ContentHeader>
      </Column>
      <Column size={"12"}>
        <General />
      </Column>
    </Row>
  );
};

export default Basics;
