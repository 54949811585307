import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import MenuLink from "components/menu.link/menu.link";
import styles from "./styles.module.scss";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import HeaderWindowView from "./views";

const HeaderWindow = () => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website,
    validation: WebsiteWizardReducer.validation
  });
  const { website } = useSelector(mapStateToProps);

  const { windowMenuState, onMoveToPage } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((item) => item.id === WINDOW_MENU_KEYS.EDITABLE_HEADER.key);
  const currentPage = windowMenu?.pagination.pages.find((page) => page.visible === true);

  if (!currentPage || !windowMenu) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <div className={styles.sidebar}>
        <ul>
          {website.menu.data.logo && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_LOGO
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_LOGO}>
                Logo
              </MenuLink>
            </li>
          )}
          {website.menu.data.siteMap && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_MENU
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_MENU}>
                Menu
              </MenuLink>
            </li>
          )}

          {website.menu.data.links && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_BUTTON
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_BUTTON}>
                Button (CTA)
              </MenuLink>
            </li>
          )}
        </ul>
      </div>

      <div className={styles.content}>
        {(() => {
          switch (currentPage.id) {
            case WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_LOGO:
              return <HeaderWindowView.Logo />;

            case WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_MENU:
              return <HeaderWindowView.Menu />;

            case WINDOW_MENU_KEYS.EDITABLE_HEADER.pageKeys.EDITABLE_HEADER_BUTTON:
              return <HeaderWindowView.Button />;

            default:
              return <></>;
          }
        })()}
      </div>
    </div>
  );
};

export default HeaderWindow;
