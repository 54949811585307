import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { delay } from "redux-saga/effects";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { getLastDataValue } from "helpers";
import { ModelWebsite, ModelValidation } from "helpers/models";
import actions from "redux/actions";

/**
 * @description update contact
 */
export default function* changeInformationAttribute({
  payload,
  options
}: ReturnType<(typeof actions.theme)["contact"]["actions"]["changeInformationAttribute"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    if (!options?.noDelay) {
      yield delay(1000);
    }

    const { name, value } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      validation: WebsiteWizardReducer.validation
    });

    const { website, validation }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const modelValidation = new ModelValidation(validation);

    // if contact information attribute does not have validation
    if (!modelValidation.contact.attributes.information.attributes[name]) {
      return;
    }

    const validationAttribute = getLastDataValue(modelValidation.contact.attributes.information.attributes, `${name}`);

    modelValidation.validate(validationAttribute, value);

    modelWebsite.updateRootPath({
      dataClass: undefined,
      dataPath: `global/contact/information/${name}`,
      value: value,
      website: modelWebsite
    });

    yield put(
      actions.theme.contact.actions.changeInformationAttribute.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      actions.theme.contact.actions.changeInformationAttribute.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
