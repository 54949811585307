import { Button } from "@soltivo/draw-a-line";
import { ArrowBack24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./content.header.module.scss";

interface ContentHeaderProps {
  title: string;
  onBack?: () => void;
}

const ContentHeader: React.FC<React.PropsWithChildren<ContentHeaderProps>> = ({ title, children, onBack }) => {
  return (
    <div className={`${styles.content__headder} ${onBack ? styles.back : ""}`}>
      {onBack ? (
        <div>
          <Button outline padding={false} variant="geyser-900" onClick={() => onBack()}>
            <ArrowBack24Px width={24} height={24} />
          </Button>
        </div>
      ) : null}

      <h6>{title}</h6>

      <div>{children}</div>
    </div>
  );
};

export default ContentHeader;
