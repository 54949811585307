import Options, { OptionsProps } from "./options/options";
import Value, { ValueProps } from "./value/value";
import styles from "./dropdown.module.scss";
import { useRef, useState } from "react";
import { useClickOutsideRef } from "@soltivo/draw-a-line";

interface DropdownProps {
  optionsProps: OptionsProps;
  valueProps: Partial<ValueProps>;
  /**
   * @default "right"
   */
  dropTo?: {
    x: "left" | "right";
  };
}

const Dropdown = ({ optionsProps, valueProps, dropTo }: DropdownProps) => {
  const [state, setState] = useState<{
    dropped: boolean;
  }>({
    dropped: false
  });

  const dropdownRef = useRef<HTMLDivElement>(null);

  const onChangeState = (payload: Partial<ReturnType<() => typeof state>>) => {
    setState((state) => ({ ...state, ...payload }));
  };

  const iframeWebsite = document.querySelector<HTMLIFrameElement>("#iframeWebsite")?.contentWindow;
  useClickOutsideRef(
    iframeWebsite ? [window, iframeWebsite] : [window],
    () => {
      setState({
        dropped: false
      });
    },
    dropdownRef
  );

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <Value
        value={valueProps.value || "Empty"}
        dropped={typeof valueProps.dropped !== "undefined" ? valueProps.dropped : state.dropped}
        onClick={(e) => {
          onChangeState({ dropped: !state.dropped });
          if (valueProps?.onClick) valueProps?.onClick(e);
        }}
      />
      {state.dropped ? (
        <Options className={`${styles.options} ${styles[dropTo?.x || "right"]}`} {...optionsProps} />
      ) : null}
    </div>
  );
};

export default Dropdown;
