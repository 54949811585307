import { errorWizard, ErrorApp } from "../classes/ErrorApp";
import { validateWebsiteSection } from "./validateWebsiteSection";
import { validateObjectKeys } from "./validateObjectKeys";

/**
 * @description validate WebsitePage interface structure.
 */

export const validateWebsitePage = (page: WebsitePage) => {
  const code = "WebsitePage";
  const errors: ErrorApp[] = [];
  const requiredKeys: (keyof WebsitePage)[] = ["description", "id", "name", "sections", "thumbnail", "title"];
  const validKeys: (keyof WebsitePage)[] = ["hidden"];
  const { invalidKeys, notFoundKeys } = validateObjectKeys(page, {
    requiredKeys: requiredKeys,
    validKeys: validKeys
  });

  if (invalidKeys.length) {
    const message = `page named ${page.name} contains invalid attributes ${invalidKeys.join("|")}.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }
  if (notFoundKeys.length) {
    const message = `page named ${page.name} is missing required attributes ${notFoundKeys.join("|")}.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.id !== "string") {
    const message = `page named ${page.name} id must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.description !== "string") {
    const message = `page named ${page.name} description must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.title !== "string") {
    const message = `page named ${page.name} title must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.thumbnail !== "string") {
    const message = `page named ${page.name} thumbnail must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.name !== "string") {
    const message = `page named ${page.name} name must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof page.hidden !== "boolean" && typeof page.hidden !== "undefined") {
    const message = `page named ${page.name} hidden must be boolean.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (!Array.isArray(page.sections)) {
    const message = `page named ${page.name} sections must be array.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  } else if (!page.sections.length) {
    const message = `page named ${page.name} must have at least one section.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  } else {
    for (let i = 0; i < page.sections.length; i++) {
      const section = page.sections[i];
      const { errors: sectionErrors } = validateWebsiteSection(section);
      for (let i = 0; i < sectionErrors.length; i++) {
        errors.push(sectionErrors[i]);
      }
    }
  }

  errors.forEach((error) => errorWizard(error.message, { code: error.code }));

  return {
    valid: !errors.length ? true : false,
    errors
  };
};
