import { Column, FormControl, Row, Textarea } from "@soltivo/draw-a-line";
import InputImage from "components/input.image/input.image";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";
import { FC } from "react";
import Unsplash from "../../../../components/unsplash/unsplash";

interface ImageWindowProps extends EditableWindowProps<"image"> {}

const ImageWindow: FC<React.PropsWithChildren<ImageWindowProps>> = ({ dataClass, dataPath }) => {
  const { onStartWindowMenu, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_IMAGE.key);

  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  if (!windowMenu || !data || !validation) {
    return null;
  }

  return (
    <Row>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Image"
          }}>
          <InputImage
            name="src"
            style={{
              maxWidth: "100%",
              height: windowMenu.size === "medium" ? "240px" : "140px",
              maxHeight: windowMenu.size === "medium" ? "240px" : "140px"
            }}
            value={data.src}
            onLibrary={(e) => {
              onStartWindowMenu({
                id: WINDOW_MENU_KEYS.UNSPLASH.key,
                event: e,
                size: "medium",
                acceptSizes: ["medium"],
                title: "Select an image",
                Component: () => {
                  return (
                    <Unsplash
                      columns={2}
                      menu={true}
                      onSelectImage={(imageUrl) => {
                        onUpdate({
                          ...data,
                          src: imageUrl
                        });
                      }}
                    />
                  );
                }
              });
            }}
            accept={
              validation.attributes.src?.rules.mimes
                ? validation.attributes.src?.rules.mimes.map((item) => `image/${item}`).join(",")
                : "image/png,image/jpeg,image/jpg,image/webp"
            }
            onChange={(e) => {
              const file = e.target.files?.[0];

              if (file)
                onUpdate({
                  ...data,
                  src: file
                });
            }}
          />
        </FormControl>
      </Column>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Description"
          }}>
          <Textarea
            name="alt"
            value={data.alt}
            onChange={(e) => {
              onUpdate({
                ...data,
                alt: e.target.value
              });
            }}
            placeholder="description of your image"
          />
        </FormControl>
      </Column>
    </Row>
  );
};

export default ImageWindow;
