import { put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { ModelPage } from "helpers/models/ModelPage/ModelPage";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";

/**
 * @description enable/disable selectable page section.
 */
export default function* toggle({
  payload
}: ReturnType<(typeof theme)["section"]["actions"]["toggle"]["request"]>): Generator<
  TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const { pageId, sections } = payload;
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      pages: cloneDeep(WebsiteWizardReducer.pages),
      website: WebsiteWizardReducer.website
    });

    let { pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    const foundPage = modelWebsite.page.findPageById(pages, pageId);

    if (!foundPage) {
      throw new Error("Failed to find page to update section visibility.");
    }

    const newPage = new ModelPage(foundPage);

    newPage.updateSections(
      newPage.sections.map((section) => {
        const sectionPayload = sections.find((item) => item.sectionName === section.name);
        if (sectionPayload) {
          section.selected = sectionPayload.action === "enable" ? true : false;
          /**
           * @deprecated we will no longer have this attribute.
           */
          section.default = false;
        }

        return section;
      })
    );

    if (website.page.id === newPage.id) {
      modelWebsite.update(modelWebsite, { page: newPage });
    }
    pages = pages.map((page) => (page.id == newPage.id ? newPage.object : page));

    yield put(
      theme.section.actions.toggle.success({
        pages: pages,
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.section.actions.toggle.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
