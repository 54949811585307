import types from "../../../actions/types";
import { takeLatest } from "redux-saga/effects";
import organize from "./organize";
import toggle from "./toggle";
import move from "./move";

export default function* Section() {
  yield takeLatest(types.WEBSITE_SECTION_ORGANIZE_ALL_REQUEST, organize);
  yield takeLatest(types.WEBSITE_TOGGLE_PAGE_SECTION_REQUEST, toggle);
  yield takeLatest(types.WEBSITE_MOVE_PAGE_SECTION_REQUEST, move);
}
