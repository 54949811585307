import { call, CallEffect, put, PutEffect, select, SelectEffect } from "@redux-saga/core/effects";
import websiteClass from "../../../../helpers/api/website.class";
import history from "../../../../routes/history";
import { RootState } from "../../../reducers";
import { ErrorApp } from "../../../../helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import { validateWebsiteData } from "helpers/functions/validateWebsiteData";
import { checkOrganizationRoute } from "helpers/functions/checkOrganizationRoute";
import cloneDeep from "lodash.clonedeep";
import { createPrevColors } from "helpers";
import actions from "redux/actions";
import semver from "semver";

/**
 *  @description get initial theme data.
 * - theme id
 * - theme version
 * - theme url
 * - theme cdn url
 * - website (root object | global.website.json)
 * - pages (list of pages | pages.website.json)
 * - configuration (theme configuration | config.website.json)
 * - validation (theme validation | validation.website.json)
 */
export default function* getAll({
  payload
}: ReturnType<(typeof actions.theme)["data"]["actions"]["getAll"]["request"]>): Generator<
  SelectEffect | CallEffect<any> | PutEffect<any>,
  void,
  any
> {
  try {
    const withOrganization = checkOrganizationRoute();

    const mapStateToProps = ({ Wizard, App }: RootState) => ({
      orgId: App.organization.data.orgId,
      website: cloneDeep(Wizard.website),
      organization: App.organization
    });

    const { orgId, website, organization }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);

    const themeDataResponse: APIResponses["getThemeById"]["success"] | undefined =
      process.env.NODE_ENV === "development"
        ? undefined
        : yield call(websiteClass.getThemeById, {
            themeId: payload.themeId
          });

    /**
     * Set initial values inside theme data.
     */
    modelWebsite.update(modelWebsite, {
      id: organization.data.orgId,
      themeId: payload.themeId,
      version: process.env.NODE_ENV === "development" ? "0.1.0" : themeDataResponse?.data.latestVersion
    });

    // try to get published theme data if in /organization route.
    const publishedWebsiteData =
      withOrganization && process.env.NODE_ENV !== "development"
        ? yield call(websiteClass.websiteGetGlobal, { orgId: orgId })
        : undefined;

    // try to get published theme pages if in /organization route.
    const publishedPages =
      withOrganization && process.env.NODE_ENV !== "development"
        ? yield call(websiteClass.websiteGetPages, { orgId: orgId })
        : undefined;

    /**
     * Update theme version to match the published theme data.
     */
    if (semver.valid(publishedWebsiteData?.version)) {
      modelWebsite.update(modelWebsite, { version: publishedWebsiteData.version });
    }

    const THEME_URLS = {
      themeCDNURL: `${process.env.REACT_APP_WEBSITE_CDN}/themes/${payload.themeId}/${modelWebsite.version}/`,
      themeURL: !withOrganization
        ? `${process.env.REACT_APP_BUILDER_API}/themes/${payload.themeId}/${
            modelWebsite.version
          }/?forceRefresh=true&cache=${new Date().getMilliseconds()}`
        : `${process.env.REACT_APP_BUILDER_API}/themes/${payload.themeId}/${
            modelWebsite.version
          }/?loadOrgData=true&id=${organization.data.orgId}&forceRefresh=true&cache=${new Date().getMilliseconds()}`
    };

    // get theme configuration
    const websiteConfiguration: WebsiteConfig = yield call(websiteClass.websiteGetJson, {
      fileName: "config.website.json",
      themeId: modelWebsite.themeId,
      version: modelWebsite.version
    });

    // get theme validation
    const websiteValidation: Validation = yield call(websiteClass.websiteGetJson, {
      fileName: "validation.website.json",
      themeId: modelWebsite.themeId,
      version: modelWebsite.version
    });

    // get default data of the theme.
    const defaultWebsiteData: WebsiteData = yield call(websiteClass.websiteGetJson, {
      fileName: "global.website.json",
      themeId: modelWebsite.themeId,
      version: modelWebsite.version
    });

    // get default pages
    const defaultPages: WebsitePage[] = yield call(websiteClass.websiteGetJson, {
      fileName: "pages.website.json",
      themeId: modelWebsite.themeId,
      version: modelWebsite.version
    });

    if (publishedWebsiteData && process.env.NODE_ENV !== "development" && withOrganization) {
      if (publishedWebsiteData?.code === 404 || publishedWebsiteData?.error?.code === 404) {
        //if not found set the default theme.
        modelWebsite.update(modelWebsite, defaultWebsiteData);
      } else if (publishedWebsiteData?.code >= 400 || publishedWebsiteData?.error?.code >= 400) {
        // if any other issue than not found throw error.
        throw publishedWebsiteData;
      } else {
        // set previous saved theme.
        modelWebsite.update(modelWebsite, publishedWebsiteData);
      }
    } else {
      //not withOrganization or in development use default data.
      modelWebsite.update(modelWebsite, defaultWebsiteData);
    }

    // make sure website.plugins default & required attribute is set.
    modelWebsite.prefillMetaPixel(modelWebsite);

    let pages: WebsitePage[] = [];

    if (publishedPages && process.env.NODE_ENV !== "development" && withOrganization) {
      if (
        (publishedPages?.code && publishedPages?.code >= 400) ||
        (publishedPages?.error?.code && publishedPages?.error?.code >= 400)
      ) {
        // if any other issue than not found throw error.
        throw publishedPages;
      } else {
        pages = defaultPages.map((defaultPage) => {
          const publishedPage = (publishedPages as unknown as WebsitePage[]).find(
            (publishedPage) => publishedPage.name === defaultPage.name
          );
          if (publishedPage) {
            //custom pages already modified.
            return {
              ...publishedPage,
              // because of page id updates when theme is under development changes.
              id: defaultPage.id,
              sections: publishedPage.sections.map((publishedSection) => {
                const defaultSection = defaultPage.sections.find(
                  (defaultSection) => defaultSection.name === publishedSection.name
                );
                // because of page section id updates when theme is under development changes.
                return {
                  ...publishedSection,
                  id: defaultSection?.id || publishedSection.id
                };
              })
            };
          }
          // default pages
          return defaultPage;
        });
      }
    } else {
      //not withOrganization or in development use default pages data.
      pages = defaultPages;
    }

    // Make sure the first page is always home/index with "/" path.
    pages = modelWebsite.orderPagesByRoutes(modelWebsite, pages);

    const checkThemeData = validateWebsiteData(modelWebsite.object);
    if (!checkThemeData.valid) {
      console.error(checkThemeData.errors);
      throw new ErrorApp({
        exception: "themeException",
        message: "Theme contains invalid data, please contact soltivo team if the error persists."
      });
    }

    const homePageRoute = modelWebsite.routes.list.find((page) => page.path === "/");
    const homePage = pages.find((page) => page.name === homePageRoute?.pageName);

    if (!homePage) {
      throw new ErrorApp({
        exception: "themeException",
        message: "Theme doesn't contain home page."
      });
    }

    modelWebsite.update(modelWebsite, { page: homePage });

    yield put(
      actions.theme.data.actions.getAll.success({
        website: modelWebsite.object,
        pages: pages,
        configuration: websiteConfiguration,
        validation: websiteValidation,
        themeCDNURL: THEME_URLS.themeCDNURL,
        themeURL: THEME_URLS.themeURL
      })
    );

    const prevColors = createPrevColors(modelWebsite.colors);

    yield put(actions.theme.color.actions.updatePrevColors.request({ prevColors: prevColors }));

    yield put(actions.theme.page.actions.open.request({ pageId: modelWebsite.page.id }));
  } catch (error) {
    if (error instanceof ErrorApp) {
      history.push("/422");
    } else {
      history.push("/404");
    }

    yield put(
      actions.theme.data.actions.getAll.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
