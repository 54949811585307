import React from "react";
import { Settings24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./styles.module.scss";
import FormWindow from "./form.window/form.window";
import useEditableData from "helpers/hooks/useEditableData";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface EditableFormProps extends EditableProps<"form"> {}

const EditableForm: React.FC<React.PropsWithChildren<EditableFormProps>> = ({
  dataClass,
  dataPath,
  viewPositionString,
  ...rest
}) => {
  const editable = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();
  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_FORM.key,
      event: e,
      title: editable.data?.action === "plugin/contact" ? "Contact Form" : "Form",
      acceptSizes: ["big"],
      size: "big",
      Component: () => <FormWindow dataClass={dataClass} dataPath={dataPath} />,
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_INPUTS,
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_FORM.pageKeys.EDITABLE_FORM_BUTTONS,
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  return (
    <div className={`${styles.component} ${styles[viewPositionString.x]} ${styles[viewPositionString.y]}`}>
      <div className={styles.content}>
        <div className={styles.actions}>
          <button onClick={onOpenWindow}>
            <Settings24Px height={18} width={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditableForm;
