import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_MOVE_PAGE_SECTION_REQUEST: "WEBSITE_MOVE_PAGE_SECTION_REQUEST",
  WEBSITE_MOVE_PAGE_SECTION_SUCCESS: "WEBSITE_MOVE_PAGE_SECTION_SUCCESS",
  WEBSITE_MOVE_PAGE_SECTION_FAILURE: "WEBSITE_MOVE_PAGE_SECTION_FAILURE",

  WEBSITE_TOGGLE_PAGE_SECTION_REQUEST: "WEBSITE_TOGGLE_PAGE_SECTION_REQUEST",
  WEBSITE_TOGGLE_PAGE_SECTION_SUCCESS: "WEBSITE_TOGGLE_PAGE_SECTION_SUCCESS",
  WEBSITE_TOGGLE_PAGE_SECTION_FAILURE: "WEBSITE_TOGGLE_PAGE_SECTION_FAILURE",

  WEBSITE_SECTION_ORGANIZE_ALL_REQUEST: "WEBSITE_SECTION_ORGANIZE_ALL_REQUEST",
  WEBSITE_SECTION_ORGANIZE_ALL_SUCCESS: "WEBSITE_SECTION_ORGANIZE_ALL_SUCCESS",
  WEBSITE_SECTION_ORGANIZE_ALL_FAILURE: "WEBSITE_SECTION_ORGANIZE_ALL_FAILURE"
};

const actions = {
  /**
   * @description move section index inside a page
   */
  move: {
    request: (payload: { from: number[]; to: number[] }) => ({
      type: types.WEBSITE_MOVE_PAGE_SECTION_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"]; pages: WizardState["pages"] }) => ({
      type: types.WEBSITE_MOVE_PAGE_SECTION_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_MOVE_PAGE_SECTION_FAILURE,
      payload
    })
  },
  /**
   * @descrition switch on/off (selected => true/false) list of sections by name.
   */
  toggle: {
    request: (payload: {
      pageId: WebsitePage["id"];
      sections: {
        sectionName: WebsiteSection["name"];
        action: "enable" | "disable";
      }[];
    }) => ({
      type: types.WEBSITE_TOGGLE_PAGE_SECTION_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"]; pages: WizardState["pages"] }) => ({
      type: types.WEBSITE_TOGGLE_PAGE_SECTION_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_TOGGLE_PAGE_SECTION_FAILURE,
      payload
    })
  },
  /**
   * @description organize section groups.
   * sections = ["about", "contact", "about_me", "video", "about_video"]
   * >>>
   * updated = ["about", "about_me", "about_video", "contact", "video"]
   */
  organize: {
    request: (payload: { website: WebsiteData }) => ({
      type: types.WEBSITE_SECTION_ORGANIZE_ALL_REQUEST,
      payload
    }),
    success: (payload: { pages: WebsitePage[]; website: WebsiteData }) => ({
      type: types.WEBSITE_SECTION_ORGANIZE_ALL_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_SECTION_ORGANIZE_ALL_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
