import styles from "./styles.module.scss";
import { Button } from "@soltivo/draw-a-line";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { PluginWindowState } from "../../plugin.window";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface CategoriesViewProps extends EditableWindowProps<"plugin"> {
  state: PluginWindowState;
  setState: React.Dispatch<React.SetStateAction<PluginWindowState>>;
}

const CategoriesView = ({ state, setState, dataClass, dataPath }: CategoriesViewProps) => {
  const { data, validation } = useEditableData({ dataClass, dataPath });

  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    plugins: WebsiteWizardReducer.plugins
  });

  const { plugins } = useSelector(mapStateToProps);

  const { useFooter, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

  const currentWindowPage = windowMenu?.pagination.pages.find(
    (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES
  );

  const Footer = () => {
    const { onMoveToPage, onClose, getWindowMenuById } = WindowMenu.useWindowMenu();

    const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

    const currentWindowPage = windowMenu?.pagination.pages.find(
      (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES
    );

    if (!windowMenu || !currentWindowPage?.visible || !validation) {
      return <></>;
    }

    const disabledNext = !state.category;

    return (
      <Fragment>
        <Button onClick={() => onClose(windowMenu.id)} outline variant="primary">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (disabledNext) return;

            if (validation.type === "booking/service") {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
                pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES
              });
            } else {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
                pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY
              });
            }
          }}
          disabled={disabledNext}>
          Next
        </Button>
      </Fragment>
    );
  };

  useFooter(
    {
      id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
      component: () => <Footer />
    },
    [state]
  );

  if (!windowMenu || !currentWindowPage?.visible || !data || !validation) {
    return <></>;
  }

  return (
    <div className={`${styles.component}`}>
      <ul className={`${styles.items} ${windowMenu.size === "medium" ? styles.medium__size : ""}`}>
        {plugins.booking.categories.data.length
          ? plugins.booking.categories.data.map((category, index) => {
              if (state.search) {
                const regex = new RegExp(`(${state.search})`, "g");
                if (!regex.test(category.name)) {
                  return null;
                }
              }

              return (
                <li
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      search: "",
                      category: state.category?.id === category.id ? undefined : category
                    }));
                  }}
                  className={`${styles.plugin__item} ${state.category?.id === category.id ? styles.active : ""}`}
                  key={index}
                  tabIndex={3}>
                  <span>{category.name}</span>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default CategoriesView;
