import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Button, Color, Column, FormControl, InputText, Row } from "@soltivo/draw-a-line";
import { GLOBAL_VARS } from "helpers/global";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import actions from "redux/actions";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface SocialMediaProps {
  media: WebsiteSocialMedia;
}
const SocialMedia: React.FC<React.PropsWithChildren<SocialMediaProps>> = ({ media }) => {
  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website
  });

  const { website } = useSelector(mapStateToProps);

  const currentMedia = website.contact.socialMedia.find((sm) => sm.id === media.id) as WebsiteSocialMedia;

  const svgUploadRef = useRef<HTMLInputElement>(null);

  const { useFooter, onClose } = WindowMenu.useWindowMenu();

  useFooter(
    {
      id: WINDOW_MENU_KEYS.SOCIAL_MEDIA.key,
      component: () => (
        <>
          {currentMedia ? (
            <Button
              data-title-position="bottom-left"
              data-title="Delete social media"
              onClick={() => {
                dispatch(actions.theme.contact.actions.deleteSocialMedia.request({ id: currentMedia.id }));
                onClose(WINDOW_MENU_KEYS.SOCIAL_MEDIA.key);
              }}
              variant="valencia-500"
              outline
              style={{
                margin: "0 auto"
              }}>
              Delete
            </Button>
          ) : null}
        </>
      )
    },
    []
  );

  if (!currentMedia) {
    return (
      <Row className={styles.social__media}>
        <p>Social media does not exist.</p>
      </Row>
    );
  }

  return (
    <Row className={styles.social__media}>
      <Column size={"2"}>
        <FormControl
          labelProps={{
            value: "Icon"
          }}>
          <div className={styles.icon__wrapper}>
            <span
              onClick={() => {
                svgUploadRef.current?.click();
              }}
              className={styles.icon}
              dangerouslySetInnerHTML={{ __html: currentMedia.svgHTML }}
            />
          </div>

          <input
            style={{
              display: "none"
            }}
            ref={svgUploadRef}
            type={"file"}
            accept="image/svg+xml"
            onChange={async (e) => {
              const file = e.target.files?.[0];
              dispatch(
                actions.theme.contact.actions.changeSocialMediaAttribute.request({
                  id: currentMedia.id,
                  name: "svgHTML",
                  value: file
                })
              );
            }}></input>
        </FormControl>
      </Column>
      <Column size={"10"}>
        <FormControl
          labelProps={{
            value: "Name"
          }}
          footerProps={{
            value: "Social media name, eg. facebook, twitter etc."
          }}>
          <InputText
            onChange={(e) => {
              dispatch(
                actions.theme.contact.actions.changeSocialMediaAttribute.request({
                  id: currentMedia.id,
                  name: "name",
                  value: e.target.value
                })
              );
            }}
            defaultValue={currentMedia.name}
            placeholder="Social media name"
          />
        </FormControl>
      </Column>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Link"
          }}
          footerProps={{
            value: "Social media link, eg. https://facebook.com/{name}."
          }}>
          <InputText
            type="url"
            onChange={(e) => {
              dispatch(
                actions.theme.contact.actions.changeSocialMediaAttribute.request({
                  id: currentMedia.id,
                  name: "href",
                  value: e.target.value
                })
              );
            }}
            defaultValue={currentMedia.href}
            onDoubleClick={() => {
              if (GLOBAL_VARS.validaton.regex.httpsUrl.test(String(currentMedia.href))) {
                window.open(String(currentMedia.href), "_blank");
              }
            }}
            onClick={(e) => {
              e.currentTarget.select();
            }}
            style={{
              color: Color["bluemine"]["bluemine-500"],
              textDecoration: "underline"
            }}
            placeholder="Media link"
          />
        </FormControl>
      </Column>
    </Row>
  );
};

export default SocialMedia;
