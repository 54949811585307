import { call, CallEffect, put, PutEffect } from "@redux-saga/core/effects";
import { soltivoHelper } from "@soltivo/draw-a-line";
import adminClass from "../../../../helpers/api/admin.class";
import authClass from "../../../../helpers/api/auth.class";
import history from "../../../../routes/history";
import { createErrorApp, ErrorApp } from "../../../../helpers/classes/ErrorApp";
import actions from "redux/actions";
import { Organization, User } from "@soltivo/types";
import orgClass from "helpers/api/org.class";
import { delay } from "redux-saga/effects";
import { matchPath } from "react-router-dom";

/**
 * @description get initial informations needed to load the builder
 * - Authetication
 * - organization id
 */
export default function* init({
  payload
}: ReturnType<(typeof actions.wizard)["app"]["actions"]["init"]["request"]>): Generator<
  CallEffect<any> | PutEffect<any>,
  void,
  any
> {
  try {
    const builderPath = matchPath<{ themeId: string }>(window.location.pathname, {
      path: ["/:themeId", "/:themeId/organization"],
      exact: true
    });

    const themeId = builderPath?.params.themeId || "";

    if (!themeId) {
      throw new Error("Cannot load theme in this url.");
    }

    //Check if user is authenticated.
    const cognitorUser = yield call(authClass.currentUserPoolUser);

    yield delay(1000); // this is just for the loading.wrapper to show info about what's being loading.

    yield put(
      actions.wizard.app.actions.init.partialSuccess({
        cognitorUser: {
          data: JSON.parse(JSON.stringify(cognitorUser)),
          loading: false
        }
      })
    );

    const {
      accessToken: { jwtToken: token },
      idToken: { jwtToken: idToken }
    } = cognitorUser.signInUserSession;

    const orgId = soltivoHelper.getCookie("orgId");

    if (!orgId) {
      throw new ErrorApp({
        exception: "orgNotFoundException",
        code: "orgNotFoundException",
        message: "Organization not found, you will be redirected to the platform to login."
      });
    }

    localStorage.setItem("token", token);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("orgId", orgId);

    const { data: organization }: { data: Organization } = yield call(orgClass.getById, { orgId: orgId });

    yield delay(1000); // this is just for the loading.wrapper to show info about what's being loading.

    yield put(
      actions.wizard.app.actions.init.partialSuccess({
        organization: {
          data: organization,
          loading: false
        }
      })
    );

    const { data: profileInfo }: { data: User } = yield call(adminClass.profileInfo);

    yield delay(1000); // this is just for the loading.wrapper to show info about what's being loading.
    yield put(
      actions.wizard.app.actions.init.partialSuccess({
        user: {
          data: profileInfo,
          loading: false
        }
      })
    );

    if (profileInfo.roleId !== "0") {
      throw new ErrorApp({
        exception: "userNotAllowedException",
        code: "userNotAllowedException",
        message: "You do not have the permission to access this resource."
      });
    }

    localStorage.setItem("SOLTIVO_THEME_ID", themeId);

    yield put(
      actions.wizard.app.actions.init.success({
        organization: {
          loading: false,
          data: organization
        },
        user: {
          loading: false,
          data: profileInfo
        },
        cognitorUser: {
          loading: false,
          data: JSON.parse(JSON.stringify(cognitorUser))
        }
      })
    );

    yield delay(1000); // this is just for the loading.wrapper to show info about what's being loading.
    // get theme initial information
    yield put(actions.theme.data.actions.getAll.request({ themeId: themeId }));

    // get domain information
    yield put(actions.api.domain.actions.get.request());

    // load all categories and services.
    yield put(actions.api.booking.actions.getAll.request());
  } catch (error) {
    if (typeof error === "string" && error === "No current user") {
      localStorage.removeItem("token");
      localStorage.removeItem("idToken");
      localStorage.removeItem("orgId");
      history.push("/401");
      yield put(
        actions.wizard.app.actions.init.failure({
          error: new ErrorApp(
            createErrorApp(
              "Authetication failed, You must be logged in Soltivo platform to access the website builder.",
              { exception: "AuthException" }
            )
          ).toObject()
        })
      );
    } else if (error instanceof ErrorApp) {
      if (error.code === "orgNotFoundException") {
        localStorage.removeItem("token");
        localStorage.removeItem("idToken");
        localStorage.removeItem("orgId");
        history.push("/401");
        yield put(
          actions.wizard.app.actions.init.failure({
            error: error.toObject()
          })
        );
      } else {
        history.push("/403");
        yield put(
          actions.wizard.app.actions.init.failure({
            error: new ErrorApp(error).toObject()
          })
        );
      }
    } else {
      history.push("/404");
      yield put(
        actions.wizard.app.actions.init.failure({
          error: new ErrorApp(error).toObject()
        })
      );
    }
  }
}
