import types from "../../../actions/types";
import { take, delay, put, select, TakeEffect, PutEffect, SelectEffect, CallEffect } from "redux-saga/effects";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import uniqBy from "lodash.uniqby";
import { RootState } from "redux/reducers";
import cloneDeep from "lodash.clonedeep";
import actions from "redux/actions";

/**
 * @description record changes being made to website data.
 */
export default function* recordChanges({
  payload
}: ReturnType<(typeof actions.theme)["data"]["actions"]["recordChanges"]["request"]>): Generator<
  TakeEffect | SelectEffect | CallEffect | PutEffect<any>,
  void,
  any
> {
  try {
    // this is always running but it's being blocked here until takes any of the actions bellow.
    yield take([
      types.WEBSITE_UPDATE_SUCCESS,
      types.WEBSITE_CHANGE_THEME_COLOR_SUCCESS,
      types.WEBSITE_CHANGE_THEME_FONT_SUCCESS,
      types.WEBSITE_MOVE_PAGE_SECTION_SUCCESS,
      types.WEBSITE_TOGGLE_PAGE_SECTION_SUCCESS,
      types.WEBSITE_TOGGLE_PAGE_SUCCESS,
      types.WEBSITE_UPDATE_GENERAL_SUCCESS
    ]);

    yield delay(10000);

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      editor: WebsiteWizardReducer.editor,
      undos: cloneDeep(WebsiteWizardReducer.undos),
      website: cloneDeep(WebsiteWizardReducer.website),
      pages: cloneDeep(WebsiteWizardReducer.pages)
    });

    const { editor, undos, pages, website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    if (!editor) {
      return;
    }

    let newUndos = undos.prev;

    if (newUndos.length >= GLOBAL_VARS.undo.hold) {
      newUndos.shift();
    }

    newUndos = uniqBy(
      [
        ...newUndos,
        {
          website,
          pages
        }
      ],
      (data) => {
        return JSON.stringify(data);
      }
    );

    yield put(
      actions.theme.data.actions.recordChanges.success({
        undos: {
          next: [],
          prev: newUndos
        }
      })
    );
  } catch (error) {
    yield put(
      actions.theme.data.actions.recordChanges.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
