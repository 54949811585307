import { GLOBAL_VARS } from "helpers/global";

export const ValidateTypeUrl = (validation: ValidateTypeUrl, payload: string) => {
  if (typeof payload !== "string") {
    throw "Url must be string.";
  } else if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(payload) && payload !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER) {
    throw `Url must start with https://, \n eg. "https://myurlexample.com".`;
  } else if (validation.rules.audioSources) {
    const srcRegexes = Object.values(GLOBAL_VARS.editables["audio"].availableSources).map((i) => i.regexUrl);
    const findSource = srcRegexes.find((rex) => rex.test(payload));
    if (!findSource) {
      throw `Available sources: ${Object.values(GLOBAL_VARS.editables["audio"].availableSources)
        .map((source) => source.name)
        .join(", ")}.`;
    }
  } else if (validation.rules.includes?.length) {
    const findInclude = validation.rules.includes.find((textToInclude) => payload.includes(textToInclude));
    if (!findInclude) {
      throw `Url does not include any the folowing: ${validation.rules.includes.join(", ")}.`;
    }
  }
};
