import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_CHANGE_THEME_FONT_REQUEST: "WEBSITE_CHANGE_THEME_FONT_REQUEST",
  WEBSITE_CHANGE_THEME_FONT_SUCCESS: "WEBSITE_CHANGE_THEME_FONT_SUCCESS",
  WEBSITE_CHANGE_THEME_FONT_FAILURE: "WEBSITE_CHANGE_THEME_FONT_FAILURE"
};

const actions = {
  /**
   * @description change font of a theme.
   */
  update: {
    request: (payload: { fontName: string }) => ({
      type: types.WEBSITE_CHANGE_THEME_FONT_REQUEST,
      payload
    }),
    success: (payload: Pick<WizardState, "website">) => ({
      type: types.WEBSITE_CHANGE_THEME_FONT_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_CHANGE_THEME_FONT_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
