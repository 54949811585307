import { Button, InputSelectHybrid, InputText } from "@soltivo/draw-a-line";
import { Close24Px, Trash } from "@soltivo/draw-a-line/core/components/icons";
import { GLOBAL_VARS } from "helpers/global";
import { nanoid } from "nanoid";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { ModelWebsite } from "helpers/models";
import ContentHeader from "../../../../../../components/content.header/content.header";
import InputCreateLink from "components/input.create.link/input.create.link";
import SelectLink from "components/select.link/select.link";
import VisibleSwitch from "components/visible.switch/visible.switch";
import styles from "./styles.module.scss";
import actions from "redux/actions";

const MenuView = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    orgId: App.organization.data.orgId,
    pages: Wizard.pages
  });

  const { website } = useSelector(mapStateToProps);

  const INITIAL_STATE = {
    /**
     * @description display inputs to create new group
     */
    newGroup: false,
    /**
     * @description display inputs to create new sub link
     */
    newSubLink: false,
    viewGroupById: "",
    viewLinkByIndex: -1,
    /**
     * @description sublink view
     */
    sublink: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @description on change visibility of sitemap on footer.
   */
  const onChangeVisibility = (payload: { name: string; value: unknown }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!copyWebsite.footer.data.siteMap) {
      return;
    }
    dispatch(
      actions.theme.footer.actions.update.request({
        footer: { siteMap: { ...copyWebsite.footer.data.siteMap, [payload.name]: payload.value } }
      })
    );
  };

  /**
   * @description creates a group with one link.
   */
  const onCreateGroup = (payload: { value: string }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!copyWebsite.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: [
            ...copyWebsite.routes.siteMap.groups,
            {
              id: nanoid(),
              value: payload.value,
              items: [
                {
                  href: "/",
                  rel: "noreferrer",
                  target: "_self",
                  title: payload.value,
                  value: payload.value
                }
              ]
            }
          ]
        }
      })
    );
  };

  /**
   * @description on delete group with one group.items.length === 1
   */
  const onDeleteGroup = (payload: { groupId: string }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!website.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: copyWebsite.routes.siteMap.groups.filter((group) => group.id !== payload.groupId)
        }
      })
    );

    setState(INITIAL_STATE);
  };

  /**
   * @description on delete sublink
   */
  const onDeleteSublink = (payload: { groupId: string; subLinkIndex: number }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!website.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: copyWebsite.routes.siteMap.groups.map((group) => {
            if (group.id === payload.groupId) {
              group = {
                ...group,
                items: group.items.filter((link, index) => index !== payload.subLinkIndex)
              };
            }
            return group;
          })
        }
      })
    );

    setState((state) => ({
      ...state,
      viewLinkByIndex: 0,
      sublink: INITIAL_STATE.sublink
    }));
  };

  /**
   * @description on change link values, for both sublink & group.items[length === 0].link
   */
  const onChangeLink = (payload: { name: string; value: string; groupId: string; linkIndex: number }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!copyWebsite.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: copyWebsite.routes.siteMap.groups.map((group) => {
            if (group.id === payload.groupId) {
              group.items = group.items.map((link, index) => {
                if (index === payload.linkIndex) {
                  if (payload.name === "value") {
                    // change dropdown value to be equal to first link item.
                    if (group.items.length === 1) {
                      group.value = payload.value;
                    }

                    link = {
                      ...link,
                      title: payload.value,
                      value: payload.value
                    };
                  } else {
                    link = {
                      ...link,
                      [payload.name]: payload.value
                    };
                  }
                }

                return link;
              });
            }

            return group;
          })
        }
      })
    );
  };

  /**
   * @description if link contains more than one sublink.
   */
  const onChangeGroupValue = (payload: { value: string; groupId: string }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!copyWebsite.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: copyWebsite.routes.siteMap.groups.map((group) => {
            if (group.id === payload.groupId) {
              group.value = payload.value;
            }

            return group;
          })
        }
      })
    );
  };

  /**
   * @description on create new sublink (items.length > 1)
   */
  const onCreateSubLink = (payload: { value: string; groupId: string }) => {
    const copyWebsite = new ModelWebsite(website).object;
    if (!copyWebsite.footer.data.siteMap) {
      return;
    }

    dispatch(
      actions.theme.route.actions.updateSiteMap.request({
        siteMap: {
          ...copyWebsite.routes.siteMap,
          groups: copyWebsite.routes.siteMap.groups.map((group) => {
            if (group.id === payload.groupId) {
              group = {
                ...group,
                items: [
                  ...group.items,
                  {
                    href: "/",
                    rel: "noreferrer",
                    target: "_self",
                    title: payload.value,
                    value: payload.value
                  }
                ]
              };
            }

            return group;
          })
        }
      })
    );
  };

  if (!website.footer.data.siteMap) {
    return null;
  }

  const menu = website.footer.data.siteMap;

  const disabled = !website.footer.data.siteMap.visible;
  /**
   * @description selected group.
   */
  const group = website.routes.siteMap.groups.find((group) => state.viewGroupById === group.id);
  /**
   * @description sublink inside group
   */
  const link = state.viewLinkByIndex >= 0 ? group?.items[state.viewLinkByIndex] : undefined;

  return (
    <div className={`${styles.component} ${disabled ? styles.disabled : ""}`}>
      <ContentHeader
        title={
          state.sublink && link && group
            ? `${group.value} > ${link.value}`
            : link && group
            ? link.value
            : "Menu parameters"
        }
        onBack={
          state.sublink && link && group
            ? // for sublink
              () => {
                setState((state) => ({
                  ...state,
                  viewLinkByIndex: 0,
                  sublink: INITIAL_STATE.sublink
                }));
              }
            : group && link
            ? // For link
              () => {
                setState(INITIAL_STATE);
              }
            : undefined
        }>
        {state.sublink && link && group ? (
          //  Delete sublink
          <Button
            outline
            variant="valencia-500"
            padding={false}
            onClick={() => {
              onDeleteSublink({ groupId: group.id, subLinkIndex: state.viewLinkByIndex });
            }}>
            <Trash /> Remove
          </Button>
        ) : group && link ? (
          // Delete link/group
          <Button
            outline
            variant="valencia-500"
            padding={false}
            onClick={() => {
              onDeleteGroup({ groupId: group.id });
            }}>
            <Trash /> Remove
          </Button>
        ) : (
          <VisibleSwitch
            checked={menu.visible}
            onChange={(e) => {
              onChangeVisibility({ name: "visible", value: e.target.checked });
              setState(INITIAL_STATE);
            }}
          />
        )}
      </ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        {/* If no group is open */}
        {!group ? (
          <div style={{ display: "grid", placeItems: "start", gridColumn: "1/3" }}>
            <Button
              disabled={disabled}
              outline
              padding={false}
              variant={state.newGroup ? "valencia-500" : "primary"}
              onClick={() => {
                setState((state) => ({
                  ...state,
                  newGroup: !state.newGroup
                }));
              }}>
              {state.newGroup ? (
                <Close24Px width={24} height={24} />
              ) : (
                <svg
                  style={{
                    marginRight: 8
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 16 10"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.4375 7.75H4.25C3.1875 7.75 2.29688 7.39062 1.57812 6.67188C0.859375 5.95312 0.5 5.0625 0.5 4C0.5 2.9375 0.859375 2.04688 1.57812 1.32812C2.29688 0.609375 3.1875 0.25 4.25 0.25H7.4375V1.375H4.25C3.5 1.375 2.875 1.625 2.375 2.125C1.875 2.625 1.625 3.25 1.625 4C1.625 4.75 1.875 5.375 2.375 5.875C2.875 6.375 3.5 6.625 4.25 6.625H7.4375V7.75ZM5.075 4.5625V3.4375H10.8875V4.5625H5.075ZM15.5 4H14.375C14.375 3.25 14.125 2.625 13.625 2.125C13.125 1.625 12.5 1.375 11.75 1.375H8.5625V0.25H11.75C12.8125 0.25 13.7031 0.609375 14.4219 1.32812C15.1406 2.04688 15.5 2.9375 15.5 4ZM12.1063 10V7.75H9.85625V6.625H12.1063V4.375H13.2313V6.625H15.4813V7.75H13.2313V10H12.1063Z"
                    fill="currentColor"
                  />
                </svg>
              )}
              {state.newGroup ? "Cancel New Link" : "New Link"}
            </Button>
          </div>
        ) : null}

        {/* Create new link using input and press enter. */}
        {state.newGroup && !group ? (
          <div style={{ display: "grid", placeItems: "start", gridColumn: "1/2" }}>
            <InputCreateLink
              name="value"
              placeholder="New link text content"
              onEnter={(e) => {
                if (e.currentTarget instanceof HTMLInputElement) {
                  onCreateGroup({ value: e.currentTarget.value });
                  setState((state) => ({
                    ...state,
                    newGroup: false
                  }));
                }
              }}
            />
          </div>
        ) : null}

        <div style={{ display: "grid", gridColumn: "1/3" }}>
          {group && group.items.length > 1 && !state.sublink ? (
            // change sub link parent dropdown content text
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 20
              }}>
              <div
                style={{
                  display: "grid"
                }}>
                <label htmlFor="value">Dropdown Text</label>
                <InputText
                  id={"value"}
                  name="value"
                  disabled={!website.footer.data.siteMap.visible}
                  onChange={(e) => {
                    onChangeGroupValue({ value: e.target.value, groupId: group.id });
                  }}
                  value={group.value}
                  placeholder="Text content"
                />
              </div>
            </div>
          ) : // change link
          group && link ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 20
              }}>
              <div
                style={{
                  display: "grid"
                }}>
                <label htmlFor="value">Text</label>
                <InputText
                  id={"value"}
                  name="value"
                  disabled={!website.footer.data.siteMap.visible}
                  onChange={(e) => {
                    onChangeLink({
                      name: e.target.name,
                      value: e.target.value,
                      groupId: group.id,
                      linkIndex: state.viewLinkByIndex
                    });
                  }}
                  value={link.value}
                  placeholder="Text content"
                />
              </div>

              <div
                style={{
                  display: "grid"
                }}>
                <label htmlFor="href">Link</label>
                <SelectLink
                  name="href"
                  disabled={!website.footer.data.siteMap.visible}
                  url={link.href}
                  onChange={({ name, value }) => {
                    onChangeLink({ name: name, value: value, groupId: group.id, linkIndex: state.viewLinkByIndex });
                  }}
                />
              </div>

              <div
                style={{
                  display: "grid"
                }}>
                <label htmlFor="title">On Click</label>
                <InputSelectHybrid
                  selectProps={{
                    name: "target",
                    value: link.target,
                    onChange: (e) => {
                      onChangeLink({
                        name: e.target.name,
                        value: e.target.value,
                        groupId: group.id,
                        linkIndex: state.viewLinkByIndex
                      });
                    },
                    placeholder: "Select behavior",
                    style: {
                      textTransform: "capitalize"
                    },
                    disabled: !website.footer.data.siteMap.visible
                  }}>
                  {GLOBAL_VARS.editables.link.targets.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {GLOBAL_VARS.editables.link.targetLabels[index]}
                      </option>
                    );
                  })}
                </InputSelectHybrid>
              </div>
            </div>
          ) : (
            // List of links/(groups)
            <ul className={styles.links}>
              {website.routes.siteMap.groups.map((group) => {
                return (
                  <li
                    key={group.id}
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        viewGroupById: group.id,
                        viewLinkByIndex: 0
                      }));
                    }}>
                    {group.items.length > 1 ? group.value : group.items[0].value}{" "}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 14L12 10L8 6L8 14Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* Add sublinks  */}
        {group && !state.sublink ? (
          <Fragment>
            <div style={{ display: "grid", placeItems: "start" }}>
              <span className={styles.sublink__title}>Sublinks</span>
            </div>
            <div style={{ display: "grid", placeItems: "end" }}>
              <Button
                outline
                padding={false}
                variant={state.newSubLink ? "valencia-500" : "primary"}
                onClick={() => {
                  setState((state) => ({
                    ...state,
                    newSubLink: !state.newSubLink
                  }));
                }}>
                {state.newSubLink ? (
                  <Close24Px width={24} height={24} />
                ) : (
                  <svg
                    style={{
                      marginRight: 8
                    }}
                    width="24"
                    height="24"
                    viewBox="0 0 16 10"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.4375 7.75H4.25C3.1875 7.75 2.29688 7.39062 1.57812 6.67188C0.859375 5.95312 0.5 5.0625 0.5 4C0.5 2.9375 0.859375 2.04688 1.57812 1.32812C2.29688 0.609375 3.1875 0.25 4.25 0.25H7.4375V1.375H4.25C3.5 1.375 2.875 1.625 2.375 2.125C1.875 2.625 1.625 3.25 1.625 4C1.625 4.75 1.875 5.375 2.375 5.875C2.875 6.375 3.5 6.625 4.25 6.625H7.4375V7.75ZM5.075 4.5625V3.4375H10.8875V4.5625H5.075ZM15.5 4H14.375C14.375 3.25 14.125 2.625 13.625 2.125C13.125 1.625 12.5 1.375 11.75 1.375H8.5625V0.25H11.75C12.8125 0.25 13.7031 0.609375 14.4219 1.32812C15.1406 2.04688 15.5 2.9375 15.5 4ZM12.1063 10V7.75H9.85625V6.625H12.1063V4.375H13.2313V6.625H15.4813V7.75H13.2313V10H12.1063Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                {state.newSubLink ? "Cancel Sublink" : "Add Sublink"}
              </Button>
            </div>
          </Fragment>
        ) : null}

        {/* Create new sublink using input and press enter. */}
        {state.newSubLink && group && !state.sublink ? (
          <div style={{ display: "grid", placeItems: "start", gridColumn: "1/2" }}>
            <InputCreateLink
              name="value"
              placeholder="New sublink text content"
              onEnter={(e) => {
                if (e.currentTarget instanceof HTMLInputElement) {
                  onCreateSubLink({ value: e.currentTarget.value, groupId: group.id });
                  setState((state) => ({
                    ...state,
                    newSubLink: false
                  }));
                }
              }}
            />
          </div>
        ) : null}

        {/* List of sub links inside group  */}
        {group && group.items.length > 1 && !state.sublink ? (
          <ul
            className={styles.links}
            style={{
              gridColumn: "1/3"
            }}>
            {website.routes.siteMap.groups.flatMap((_group) => {
              if (_group.id !== group.id) {
                return null;
              }

              return group.items.map((link, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setState((state) => ({
                        ...state,
                        viewGroupById: _group.id,
                        viewLinkByIndex: index,
                        sublink: true
                      }));
                    }}>
                    {link.value}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 14L12 10L8 6L8 14Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                );
              });
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default MenuView;
