import types from "../../../actions/types";
import { takeLatest } from "redux-saga/effects";
import changeInformationAttribute from "./changeInformationAttribute";
import changeSocialMediaAttribute from "./changeSocialMediaAttribute";
import createSocialMedia from "./createSocialMedia";
import deleteSocialMedia from "./deleteSocialMedia";

export default function* Contact() {
  yield takeLatest(types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_REQUEST, changeInformationAttribute);
  yield takeLatest(types.WEBSITE_CREATE_CONTACT_SM_REQUEST, createSocialMedia);
  yield takeLatest(types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_REQUEST, changeSocialMediaAttribute);
  yield takeLatest(types.WEBSITE_DELETE_CONTACT_SM_REQUEST, deleteSocialMedia);
}
