import { createErrorApp } from "helpers/classes/ErrorApp";

export const ValidateClassGroup = (validation: ValidateClassGroup, payload: any[]) => {
  if (!payload.length) {
    throw createErrorApp(`Group cannot be empty.`);
  }
  if (validation.items?.rules?.maxLength) {
    if (payload.length > validation.items.rules?.maxLength) {
      throw createErrorApp(`Group cannot have more than ${validation.items.rules?.maxLength}.`);
    }
  }
  if (validation.items?.rules?.minLength) {
    if (payload.length < validation.items.rules?.minLength) {
      throw createErrorApp(`Group cannot have less than ${validation.items.rules?.minLength}.`);
    }
  }
};
