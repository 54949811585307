import React from "react";
import { VideoSwitch } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./styles.module.scss";
import VideoBackgroundWindow from "./video.background.window/video.background.window";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import WindowMenu from "components/window.menu";
import useEditableData from "helpers/hooks/useEditableData";
interface EditableBackgroundVideoProps extends EditableProps<"background-video"> {}

const EditableBackgroundVideo: React.FC<React.PropsWithChildren<EditableBackgroundVideoProps>> = ({
  dataClass,
  dataPath,
  ...rest
}) => {
  const editable = useEditableData({ dataClass, dataPath });

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();
  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_BACKGROUND_VIDEO.key,
      event: e,
      title: "Background",
      Component: () => (
        <>
          <VideoBackgroundWindow dataPath={dataPath} dataClass={dataClass} />
        </>
      )
    });
  };

  if (!editable.data || !editable.validation) {
    return null;
  }

  return (
    <div className={styles.component}>
      <div className={styles.actions}>
        <button onClick={onOpenWindow}>
          <VideoSwitch />
        </button>
      </div>
    </div>
  );
};

export default EditableBackgroundVideo;
