import { FC, Fragment, useEffect, useState } from "react";
import { RootState } from "redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";
import actions from "redux/actions";
import PluginWindowView from "./views";

export type PluginWindowState = {
  pluginUpdate: boolean;
  category: WebsitePluginServiceCategory | undefined;
  service: WebsitePluginService | undefined;
  search: string;
  loading: boolean;
};

export interface PluginWindowProps extends EditableWindowProps<"plugin"> {}

const PluginWindow: FC<React.PropsWithChildren<PluginWindowProps>> = ({ dataClass, dataPath }) => {
  const { data, validation } = useEditableData({ dataClass, dataPath });

  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    plugins: WebsiteWizardReducer.plugins
  });

  const { plugins } = useSelector(mapStateToProps);

  const [state, setState] = useState<PluginWindowState>({
    category: undefined,
    service: undefined,
    search: "",
    pluginUpdate: false,
    loading: false
  });

  const { onMoveToPage, update, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

  useEffect(() => {
    // call get booking only if there are no categories.
    if (!plugins.booking.categories.data.length) {
      dispatch(actions.api.booking.actions.getAll.request());
    }
  }, [plugins.booking.categories.data]);

  const loading = plugins.booking.categories.loading || plugins.booking.services.loading || state.loading;

  useEffect(() => {
    update((windowState) => ({
      ...windowState,
      windows: windowState.windows.map((wm) => {
        if (wm.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key) {
          wm.loading = loading;
        }
        return wm;
      })
    }));
  }, [loading]);

  const currentPage = windowMenu?.pagination.pages.find((windowPage) => windowPage.visible === true);

  useEffect(() => {
    if (!currentPage) return;
    // make sure if user is on booking/category service page the state contains categoryId.
    if (!state.category && currentPage?.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES) {
      onMoveToPage({
        id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
        pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES
      });
    }
  }, [state.category, currentPage]);

  const viewProps = {
    dataClass,
    dataPath,
    setState,
    state
  };

  if (!windowMenu || !currentPage || !data || !validation) {
    return <></>;
  }

  return (
    <Fragment>
      {(() => {
        switch (currentPage.id) {
          case WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES:
            return <PluginWindowView.Categories {...viewProps} />;

          case WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY:
            return <PluginWindowView.Category {...viewProps} />;

          case WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES:
            return <PluginWindowView.Services {...viewProps} />;

          case WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICE:
            return <PluginWindowView.Service {...viewProps} />;

          default:
            return <></>;
        }
      })()}
    </Fragment>
  );
};

export default PluginWindow;
