export const ValidateTypeNumber = (validation: ValidateTypeNumber, payload: number) => {
  if (typeof payload !== "number") {
    throw `Value must be a number.`;
  } else {
    if (typeof validation.rules?.max === "number") {
      if (payload > validation.rules.max) {
        throw `Number cannot be greater than ${validation.rules.max}.`;
      }
    }
    if (typeof validation.rules?.min === "number") {
      if (payload < validation.rules.min) {
        throw `Number cannot be less than ${validation.rules.min}.`;
      }
    }
  }
};
