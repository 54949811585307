import CategoriesView from "./categories/categories.view";
import CategoryView from "./category/category.view";
import ServiceView from "./service/service.view";
import ServicesView from "./services/services.view";

const PluginWindowView = {
  Categories: CategoriesView,
  Category: CategoryView,
  Services: ServicesView,
  Service: ServiceView
};

export default PluginWindowView;
