import { Button, InputSelectHybrid, InputText } from "@soltivo/draw-a-line";
import { Link24Px, LinkOff24Px } from "@soltivo/draw-a-line/core/components/icons";
import { bookingUrl } from "helpers";
import { GLOBAL_VARS } from "helpers/global";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

interface SelectLinkProps {
  name: string;
  disabled: boolean;
  url: string;
  onChange: (payload: { name: string; value: any }) => void;
}

const SelectLink: React.FC<React.PropsWithChildren<SelectLinkProps>> = ({ name, disabled, url, onChange }) => {
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    orgId: App.organization.data.orgId,
    pages: Wizard.pages
  });

  const { website, orgId, pages } = useSelector(mapStateToProps);

  const [state, setState] = useState({
    customHref: !(
      url === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER ||
      website.routes?.list.find((item) => {
        return url === item.path;
      }) ||
      url.startsWith("/")
    )
  });

  return (
    <Fragment>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 24px",
          gap: 10
        }}>
        {state.customHref ? (
          <InputText
            name={name}
            type={"url"}
            disabled={disabled}
            value={
              url === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
                ? bookingUrl({ orgId: orgId, name: website.name, favicon: website.logo.favicon.src })
                : url
            }
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            placeholder="eg. https://example.com"
          />
        ) : (
          <InputSelectHybrid
            selectProps={{
              name: name,
              value: url,
              disabled: disabled,
              onChange: (e) => onChange({ name: e.target.name, value: e.target.value }),
              placeholder: "Select a Link",
              style: {
                textTransform: "capitalize"
              }
            }}>
            <optgroup label="Pages">
              {website.routes?.list.map((item, index) => {
                const page = pages.find((p) => p.name === item.pageName);
                const pageName = page?.name || "";

                if (!pageName) return null;

                return (
                  <option key={item.path} value={item.path}>
                    {pageName}
                  </option>
                );
              })}
            </optgroup>

            <optgroup label="Page sections">
              {website.routes?.list.map((item, index) => {
                const page = pages.find((p) => p.name === item.pageName);
                const pageName = page?.name || "";

                if (!pageName) return null;

                return (
                  page?.sections.map((section, sectionIndex) => {
                    return (
                      <option key={section.targetId} value={`${item.path}#${section.targetId}`}>
                        {pageName.toLowerCase()} {`➡`} {section.name.toLowerCase()}
                      </option>
                    );
                  }) || null
                );
              })}
            </optgroup>

            <optgroup label="Plugins">
              <option value={GLOBAL_VARS.BOOKING_URL_PLACEHOLDER}>booking</option>
            </optgroup>
          </InputSelectHybrid>
        )}
        <div
          style={{
            display: "grid",
            placeItems: "center"
          }}>
          <Button
            disabled={disabled}
            data-title={state.customHref ? "Click to select a link" : "Click to use text as a link"}
            data-title-position="top-left"
            outline
            padding={false}
            variant="geyser-500"
            onClick={() => setState((g) => ({ ...g, customHref: !g.customHref }))}>
            {" "}
            {state.customHref ? <LinkOff24Px width={24} height={24} /> : <Link24Px width={24} height={24} />}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectLink;
