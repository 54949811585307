import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_GET_THEME_DATA_REQUEST: "WEBSITE_GET_THEME_DATA_REQUEST",
  WEBSITE_GET_THEME_DATA_SUCCESS: "WEBSITE_GET_THEME_DATA_SUCCESS",
  WEBSITE_GET_THEME_DATA_FAILURE: "WEBSITE_GET_THEME_DATA_FAILURE",

  WEBSITE_RECORD_UNDO_REQUEST: "WEBSITE_RECORD_UNDO_REQUEST",
  WEBSITE_RECORD_UNDO_SUCCESS: "WEBSITE_RECORD_UNDO_SUCCESS",
  WEBSITE_RECORD_UNDO_FAILURE: "WEBSITE_RECORD_UNDO_FAILURE",

  WEBSITE_RECOVER_CHANGE_REQUEST: "WEBSITE_RECOVER_CHANGE_REQUEST",
  WEBSITE_RECOVER_CHANGE_SUCCESS: "WEBSITE_RECOVER_CHANGE_SUCCESS",
  WEBSITE_RECOVER_CHANGE_FAILURE: "WEBSITE_RECOVER_CHANGE_FAILURE",

  WEBSITE_PUBLISH_REQUEST: "WEBSITE_PUBLISH_REQUEST",
  WEBSITE_PUBLISH_SUCCESS: "WEBSITE_PUBLISH_SUCCESS",
  WEBSITE_PUBLISH_FAILURE: "WEBSITE_PUBLISH_FAILURE",

  WEBSITE_UPDATE_NAME_REQUEST: "WEBSITE_UPDATE_NAME_REQUEST",
  WEBSITE_UPDATE_NAME_SUCCESS: "WEBSITE_UPDATE_NAME_SUCCESS",
  WEBSITE_UPDATE_NAME_FAILURE: "WEBSITE_UPDATE_NAME_FAILURE"
};

const actions = {
  /**
   * @description get initial theme data.
   * - theme id
   * - theme version
   * - theme url
   * - theme cdn url
   * - website (root object | global.website.json)
   * - pages (list of pages | pages.website.json)
   * - configuration (theme configuration | config.website.json)
   * - validation (theme validation | validation.website.json)
   */
  getAll: {
    request: (payload: {
      /**
       * theme id.
       */
      themeId: string;
    }) => ({
      type: types.WEBSITE_GET_THEME_DATA_REQUEST,
      payload: payload
    }),
    success: (payload: {
      website: WizardState["website"];
      pages: WizardState["pages"];
      configuration: WizardState["configuration"];
      validation: WizardState["validation"];
      themeCDNURL: WizardState["themeCDNURL"];
      themeURL: WizardState["themeURL"];
    }) => ({
      type: types.WEBSITE_GET_THEME_DATA_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_GET_THEME_DATA_FAILURE,
      payload
    })
  },
  /**
   * @description record data to being changed.
   */
  recordChanges: {
    request: () => ({
      type: types.WEBSITE_RECORD_UNDO_REQUEST,
      payload: undefined
    }),
    success: (payload: { undos: WizardState["undos"] }) => ({
      type: types.WEBSITE_RECORD_UNDO_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_RECORD_UNDO_FAILURE,
      payload
    })
  },
  /**
   * @description time travel through changes (undo | redo) stored.
   */
  recovery: {
    request: (payload: { action: "undo" | "redo" }) => ({
      type: types.WEBSITE_RECOVER_CHANGE_REQUEST,
      payload
    }),
    success: (payload: {
      website: WizardState["website"];
      pages: WizardState["pages"];
      undos: WizardState["undos"];
    }) => ({
      type: types.WEBSITE_RECOVER_CHANGE_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_RECOVER_CHANGE_FAILURE,
      payload
    })
  },
  /**
   * @description publish current theme data.
   * - website (root object | global.website.json)
   * - pages (list of pages | pages.website.json)
   * - configuration (theme configuration | config.website.json)
   * - validation (theme validation | validation.website.json)
   */
  publish: {
    request: () => ({
      type: types.WEBSITE_PUBLISH_REQUEST,
      payload: undefined
    }),
    success: () => ({
      type: types.WEBSITE_PUBLISH_SUCCESS,
      payload: undefined
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_PUBLISH_FAILURE,
      payload
    })
  },
  /**
   * @description update name attributes of a theme
   */
  updateName: {
    request: (payload: { name: string }) => ({
      type: types.WEBSITE_UPDATE_NAME_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_NAME_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_NAME_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
