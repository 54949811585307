import React from "react";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import styles from "./styles.module.scss";
import { Minus, Plus, Settings24Px } from "@soltivo/draw-a-line/core/components/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ObjectWindow from "./object.window/object.window";
import { nanoid } from "nanoid";
import { pushToArrayPosition } from "helpers";
import { ModelWebsite } from "helpers/models";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";
import actions from "redux/actions";

interface EditableObjectProps extends EditableProps<"object"> {
  isGroup: boolean;
  onClearEditables: () => void;
}

const EditableObject: React.FC<React.PropsWithChildren<EditableObjectProps>> = ({
  viewPositionString,
  viewPosition,
  onClearEditables,
  editableObjectValidation,
  isGroup,
  dataPath,
  dataClass,
  target,
  iframeWebsite
}) => {
  const editableRect: DOMRect | undefined = target?.getBoundingClientRect();

  const sizeType = editableRect
    ? editableRect.width <= 200 || editableRect.height <= 200
      ? "small"
      : editableRect.width <= 500 || editableRect.height <= 500
      ? "medium"
      : "big"
    : "small";

  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard }: RootState) => ({
    website: Wizard.website
  });

  const { website } = useSelector(mapStateToProps);
  const modelWebsite = new ModelWebsite(website);

  const types = dataPath.split("/");
  const lastType = types[types.length - 1];
  const parentArrayDataPath = dataPath.replace(/(\/[a-zA-Z0-9_\-:]+)$/i, "");

  const { data, validation } = useEditableData({ dataClass, dataPath });

  const parentData: WebsiteObject[] = modelWebsite.getEditableData({
    website: modelWebsite.object,
    dataPath: parentArrayDataPath
  });

  const objectIndex = /[a-zA-Z0-9_-]:[a-zA-Z0-9_-]/g.test(lastType)
    ? parentData.findIndex((i) => i.id === data?.id)
    : parseInt(lastType);

  const onAdd = () => {
    if (!isGroup) return;

    dispatch(
      actions.theme.editable.actions.update.request({
        dataPath: parentArrayDataPath,
        dataClass: "group",
        value: pushToArrayPosition(parentData, objectIndex + 1, {
          ...data,
          id: nanoid()
        })
      })
    );

    onClearEditables();
  };

  const onDelete = () => {
    if (!isGroup) return;
    if (parentData.length === 1) {
      toastHelper.toastStartContent(
        "info",
        "if you do not want to display any item, you should disabled this section."
      );
      return;
    }

    dispatch(
      actions.theme.editable.actions.update.request({
        dataPath: parentArrayDataPath,
        dataClass: "group",
        value: parentData.filter((object, index: number) => index !== objectIndex)
      })
    );

    onClearEditables();
  };

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_OBJECT.key,
      event: e,
      title: editableObjectValidation.label,
      Component: () => <ObjectWindow dataPath={dataPath} dataClass={dataClass} />
    });
  };

  if (!data || !validation) {
    return null;
  }

  return (
    <div
      data-label={`${editableObjectValidation.label}`}
      className={`${styles.component} ${styles[viewPositionString.y]} ${styles[sizeType]}`}>
      <div className={styles.content}>
        <div className={styles.actions}>
          {isGroup && (
            <button className={styles.minus} onClick={onDelete}>
              <Minus height={18} width={18} />
            </button>
          )}

          <button onClick={onOpenWindow}>
            <Settings24Px height={18} width={18} />
          </button>

          {isGroup && (
            <button className={styles.plus} onClick={onAdd}>
              <Plus height={18} width={18} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableObject;
