import booking from "./booking";
import domain from "./domain";
import unsplash from "./unsplash";

const types = {
  ...booking.types,
  ...domain.types,
  ...unsplash.types
};

export { booking, domain, unsplash, types };
