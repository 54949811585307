import { AddImage } from "@soltivo/draw-a-line/core/components/icons";
import React, { FC, useRef } from "react";
import styles from "./input.logo.module.scss";

export interface InputLogoProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value?: string | File;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  CustomIcon?: JSX.Element;
  className?: string;
  accept: string;
  inputStyleType: "block" | "inline";
}

const InputLogo: FC<React.PropsWithChildren<InputLogoProps>> = ({
  inputStyleType = "block",
  value,
  //
  name,
  onChange,
  disabled,
  CustomIcon,
  className,
  style,
  accept = "image/*",
  ...rest
}) => {
  const logoInputRef = useRef<HTMLInputElement>(null);
  const logoInputParentRef = useRef<HTMLDivElement>(null);

  const defaultSize =
    inputStyleType === "block"
      ? {
          minWidth: style?.maxWidth || "144px",
          maxWidth: style?.minWidth || "80px",
          minHeight: style?.minHeight || "80px",
          maxHeight: style?.maxHeight || "80px"
        }
      : {};

  const onClick = () => {
    if (disabled) {
      return;
    } else {
      logoInputRef.current?.click();
    }
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter" && !disabled) {
      logoInputRef.current?.click();
    }
  };

  return (
    <div
      {...rest}
      ref={logoInputParentRef}
      className={`${styles.input__logo} ${styles[inputStyleType]} ${!value ? styles.empty : ""} ${
        disabled ? styles.disabled : ""
      } ${className ? className : ""}`}
      tabIndex={1}
      onKeyDown={inputStyleType === "block" ? onKeyDown : undefined}
      onClick={inputStyleType === "block" ? onClick : undefined}
      style={{
        ...style,
        ...defaultSize
      }}>
      {inputStyleType === "inline" ? (
        <>
          {value ? <img src={value instanceof File ? URL.createObjectURL(value) : value} alt="Upload a file" /> : null}
          <button onClick={onClick}>Browser files</button>
          <span className={!value ? styles.empty : ""}>
            <span>{value instanceof File ? value.name : value || "Empty"} </span>
          </span>
        </>
      ) : null}

      {inputStyleType === "block" ? (
        <>
          {value ? (
            <img
              style={{
                ...defaultSize
              }}
              src={value instanceof File ? URL.createObjectURL(value) : value}
              alt="Upload a file"
            />
          ) : null}
          {CustomIcon ? CustomIcon : <AddImage />}
        </>
      ) : null}

      <input type="file" name={name} onChange={disabled ? undefined : onChange} ref={logoInputRef} accept={accept} />
    </div>
  );
};

export default InputLogo;
