import { put, select } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import cloneDeep from "lodash.clonedeep";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { theme } from "redux/actions";

export default function* pluginStore({
  payload
}: ReturnType<(typeof theme)["editable"]["actions"]["pluginStore"]["request"]>) {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      plugins: cloneDeep(WebsiteWizardReducer.plugins)
    });

    let { plugins }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    if (payload.type === "booking/category") {
      plugins = {
        ...plugins,
        booking: {
          ...plugins.booking,
          categories: {
            ...plugins.booking.categories,
            data: plugins.booking.categories.data.map((item) => {
              if (item.id === payload.value.id) {
                return {
                  ...item,
                  ...(payload.value as unknown as WebsitePlugin<"booking/category">)
                };
              }
              return item;
            }),
            toUpdate: [
              ...plugins.booking.categories.toUpdate.filter((item) => item.id !== payload.value.id),
              payload.value as unknown as WebsitePlugin<"booking/category">
            ]
          }
        }
      };

      yield put(
        theme.editable.actions.pluginStore.success({
          plugins: plugins
        })
      );
    } else if (payload.type === "booking/service") {
      for (let i = 0; i < plugins.booking.services.data.length; i++) {
        const item = plugins.booking.services.data[i];
        if (item.id === payload.value.id) {
          plugins.booking.services.data[i] = {
            ...item,
            ...(payload.value as unknown as WebsitePlugin<"booking/service">)
          };

          plugins.booking.services.toUpdate = [
            ...plugins.booking.services.toUpdate.filter((item) => item.id !== payload.value.id),
            payload.value as unknown as WebsitePlugin<"booking/service">
          ];
        }
      }

      yield put(
        theme.editable.actions.pluginStore.success({
          plugins: plugins
        })
      );
    } else {
      throw new Error(`Plugin "${payload.type}" is unknown.`);
    }
  } catch (error) {
    yield put(
      theme.editable.actions.pluginStore.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
