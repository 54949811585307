import { InputSelectHybrid, InputSwitch, InputText } from "@soltivo/draw-a-line";
import { GLOBAL_VARS } from "helpers/global";
import { FC, useState } from "react";
import ContentHeader from "components/content.header/content.header";
import styles from "./styles.module.scss";
import { FormWindowProps } from "../../form.window";
import useEditableData from "helpers/hooks/useEditableData";

type InputsViewProps = FormWindowProps;

const InputsView: FC<React.PropsWithChildren<InputsViewProps>> = (props) => {
  const editable = useEditableData({
    dataClass: props.dataClass,
    dataPath: props.dataPath
  });

  const INITIAL_STATE = {
    inputIndex: -1
  };

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @description current open input.
   */
  const currentInput = state.inputIndex >= 0 ? editable.data?.inputs[state.inputIndex] : undefined;

  const requiredAttributes = GLOBAL_VARS.editables.form.inputs.requiredAttributes;

  const isRequiredInput = requiredAttributes.includes(currentInput?.name || "");

  // /**
  //  * @description create a new input
  //  */
  // const onCreateInput = (payload: { value: string }) => {};

  // /**
  //  * @description delete an input
  //  */
  // const onDeleteInput = (payload: { inputIndex: number }) => {};

  /**
   * @description on change input values.
   */
  const onChangeInput = (payload: { name: string; value: string | boolean }) => {
    if (!props.dataClass || !props.dataPath || !editable.data) {
      return;
    }

    const editableData = {
      ...editable.data,
      inputs: editable.data.inputs.map((input, index) => {
        if (index === state.inputIndex) {
          return {
            ...input,
            [payload.name]: payload.value
          };
        }
        return input;
      })
    } as WebsiteForm;

    editable.onUpdate(editableData);
  };

  if (!editable.data) {
    return null;
  }
  return (
    <div className={styles.component}>
      <ContentHeader
        title={currentInput ? `${currentInput.label}` : "Input Fields"}
        onBack={
          currentInput
            ? // when update currentInput opene
              () => {
                setState(INITIAL_STATE);
              }
            : undefined
        }></ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div style={{ display: "grid", gridColumn: "1/3" }}>
          {
            // change input
            currentInput ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 20
                }}>
                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="label">Label</label>
                  <InputText
                    id={"label"}
                    name="label"
                    onChange={(e) => {
                      onChangeInput({
                        name: e.target.name,
                        value: e.target.value
                      });
                    }}
                    value={currentInput.label}
                    placeholder="Label text"
                  />
                </div>

                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="placeholder">Placeholder</label>
                  <InputText
                    id={"placeholder"}
                    name="placeholder"
                    onChange={(e) => {
                      onChangeInput({
                        name: e.target.name,
                        value: e.target.value
                      });
                    }}
                    value={currentInput.placeholder}
                    placeholder="Placeholder"
                  />
                </div>

                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="tagName">Input</label>
                  <InputSelectHybrid
                    selectProps={{
                      name: "tagName",
                      value: currentInput.tagName,
                      onChange: (e) => {
                        onChangeInput({
                          name: e.target.name,
                          value: e.target.value
                        });
                      },
                      placeholder: "Input",
                      style: {
                        textTransform: "capitalize"
                      }
                    }}>
                    {GLOBAL_VARS.editables.form.inputs.tags.map((item, index) => {
                      return (
                        <option key={index} value={item.tag}>
                          {item.label}
                        </option>
                      );
                    })}
                  </InputSelectHybrid>
                </div>

                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="type">Type</label>
                  <InputSelectHybrid
                    selectProps={{
                      name: "type",
                      value: currentInput.type,
                      onChange: (e) => {
                        onChangeInput({
                          name: e.target.name,
                          value: e.target.value
                        });
                      },
                      placeholder: "Input type",
                      style: {
                        textTransform: "capitalize"
                      }
                    }}>
                    {GLOBAL_VARS.editables.form.inputs.types.map((item, index) => {
                      return (
                        <option key={index} value={item.type}>
                          {item.label}
                        </option>
                      );
                    })}
                  </InputSelectHybrid>
                </div>
                <div
                  style={{
                    display: "grid"
                  }}>
                  <label htmlFor="require">Required</label>

                  <div>
                    <InputSwitch
                      checked={currentInput.required}
                      disabled={isRequiredInput}
                      onChange={(e) => {
                        onChangeInput({
                          name: "required",
                          value: e.target.checked
                        });
                      }}></InputSwitch>
                  </div>
                </div>
              </div>
            ) : (
              // list of inputs
              <ul className={styles.links}>
                {editable.data.inputs.map((inputItem, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setState((state) => ({
                          ...state,
                          inputIndex: index
                        }));
                      }}>
                      {inputItem.label || inputItem.placeholder || "No label/placeholder"}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 14L12 10L8 6L8 14Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                  );
                })}
              </ul>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default InputsView;
