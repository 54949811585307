import { all } from "redux-saga/effects";
import Theme from "./theme";
import Wizard from "./wizard";
import API from "./api";

/**
 * @description combine sagas.
 */
export default function* Sagas() {
  yield all([Wizard(), Theme(), API()]);
}
