import { Button, Column, FormControl, InputPhone, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import { Color } from "@soltivo/draw-a-line/core/helpers/colors.styles";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { GLOBAL_VARS } from "helpers/global";
import { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/actions";
import { RootState } from "redux/reducers";
import styles from "./styles.module.scss";
import { formatPhoneNumber } from "helpers/functions/formatPhoneNumber";

const InformationTwo = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    validation: Wizard.validation,
    organization: App.organization
  });
  const formRef = createRef<HTMLFormElement>();

  const { website, validation, organization } = useSelector(mapStateToProps);

  const { onFooterUpdate, onMoveToPage } = WindowMenu.useWindowMenu();
  useEffect(() => {
    onFooterUpdate(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key, () => {
      return (
        <>
          <Button
            variant="primary"
            outline
            padding={false}
            style={{
              marginRight: 24
            }}
            onClick={() => {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
                pageId: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_1
              });
            }}>
            <span className="material-symbols-outlined">navigate_before</span> Back
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
                pageId: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_CUSTOMIZATION
              });
            }}>
            Next <span className="material-symbols-outlined">navigate_next</span>
          </Button>
        </>
      );
    });
  }, []);

  const onSubmit = () => {
    //prefill organization name
    dispatch(
      actions.theme.data.actions.updateName.request({
        name: organization.data.name
      })
    );

    const contactInfoKeys = Object.keys(website.contact.information);
    const used: unknown[] = [];
    const allPhoneNumbers = organization.data.locations.map((i) => {
      return formatPhoneNumber(i.phoneNumber);
    });
    const allEmails = organization.data.locations.map((i) => i.email);
    const allAddresses = organization.data.locations.map((i) => i.address);

    const onAutoFillPattern = (reg: RegExp, key: string, items: unknown[], used: unknown[]) => {
      if (reg.test(key.toLowerCase())) {
        //
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (!used.includes(item)) {
            dispatch(
              actions.theme.contact.actions.changeInformationAttribute.request(
                {
                  name: key,
                  value: item
                },
                {
                  noDelay: true
                }
              )
            );
            const input = formRef.current?.elements.namedItem(key);
            if (input instanceof HTMLTextAreaElement || input instanceof HTMLInputElement) {
              input.value = `${item}`;
            }
            used.push(item);
            break;
          }
        }
      }
    };

    // prefill contact information keys
    for (let i = 0; i < contactInfoKeys.length; i++) {
      const key = contactInfoKeys[i];
      onAutoFillPattern(/phone/g, key, allPhoneNumbers, used);
      onAutoFillPattern(/email/g, key, allEmails, used);
      onAutoFillPattern(/address/g, key, allAddresses, used);
    }
  };

  useEffect(() => {
    onSubmit();
  }, [organization]);

  return (
    <form
      ref={formRef}
      className={styles.component}
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <Row>
        <Column size={"6"}>
          <FormControl
            labelProps={{
              value: "Organization name"
            }}>
            <InputText
              defaultValue={organization.data.name}
              onChange={(e) => {
                dispatch(
                  actions.theme.data.actions.updateName.request({
                    name: e.target.value
                  })
                );
              }}
              placeholder="Organization name"
            />
          </FormControl>
        </Column>

        {/* Create all inputs by validation type */}
        {website.contact.information
          ? Object.keys(website.contact.information).map((key, index) => {
              if (key === "id" || key === "label") return null;
              /**
               * @description input information
               */
              const _validation = validation.contact.attributes.information.attributes[key];
              // If no validation found we should not show an input for it.
              if (!_validation) return null;

              const value = website.contact.information[key];

              switch (_validation.typeof) {
                case "string":
                  return (
                    <Column key={index} size={"6"}>
                      <FormControl
                        labelProps={{
                          value: _validation.label
                        }}>
                        <Textarea
                          name={key}
                          defaultValue={String(value)}
                          onChange={(e) => {
                            dispatch(
                              actions.theme.contact.actions.changeInformationAttribute.request({
                                name: key,
                                value: e.target.value
                              })
                            );
                          }}
                          placeholder={_validation.label}
                        />
                      </FormControl>
                    </Column>
                  );
                case "phone-number":
                  return (
                    <Column key={index} size={"6"}>
                      <FormControl
                        labelProps={{
                          value: _validation.label
                        }}>
                        <InputPhone
                          styling="default"
                          autoFormat
                          inputStyle={{
                            width: "100%"
                          }}
                          inputProps={{
                            name: key
                          }}
                          value={String(value)}
                          onChange={(value) => {
                            value = formatPhoneNumber(value);

                            dispatch(
                              actions.theme.contact.actions.changeInformationAttribute.request({
                                name: key,
                                value: value
                              })
                            );
                          }}
                          placeholder={_validation.label}
                        />
                      </FormControl>
                    </Column>
                  );

                case "email":
                  return (
                    <Column key={index} size={"6"}>
                      <FormControl
                        labelProps={{
                          value: _validation.label
                        }}>
                        <InputText
                          name={key}
                          defaultValue={String(value)}
                          onChange={(e) => {
                            dispatch(
                              actions.theme.contact.actions.changeInformationAttribute.request({
                                name: key,
                                value: e.target.value
                              })
                            );
                          }}
                          type="email"
                          placeholder={_validation.label}
                        />
                      </FormControl>
                    </Column>
                  );

                case "url":
                  return (
                    <Column key={index} size={"6"}>
                      <FormControl
                        labelProps={{
                          value: _validation.label
                        }}>
                        <InputText
                          name={key}
                          defaultValue={String(value)}
                          onDoubleClick={() => {
                            if (GLOBAL_VARS.validaton.regex.httpsUrl.test(String(value))) {
                              window.open(String(value), "_blank");
                            }
                          }}
                          style={{
                            color: Color["bluemine"]["bluemine-500"],
                            textDecoration: "underline"
                          }}
                          onClick={(e) => {
                            e.currentTarget.select();
                          }}
                          onChange={(e) => {
                            dispatch(
                              actions.theme.contact.actions.changeInformationAttribute.request({
                                name: key,
                                value: e.target.value
                              })
                            );
                          }}
                          type="url"
                          placeholder={_validation.label}
                        />
                      </FormControl>
                    </Column>
                  );
              }
            })
          : null}
      </Row>
    </form>
  );
};

export default InformationTwo;
