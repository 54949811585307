import styles from "./select.icon.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { useClickOutsideRef } from "@soltivo/draw-a-line";
import { DOMGetIframeWebsite } from "helpers/functions/DOMGetIframeWebsite";
import googleSymbols from "assets/json/google.symbols.json";
import { createPortal } from "react-dom";

interface SelectIconProps {
  name: string;
  onSelect: (payload: { value: WebsiteIcon; name: string }) => void;
  value: WebsiteIcon;
}

const SelectIcon: FC<React.PropsWithChildren<SelectIconProps>> = ({ name, onSelect, value }) => {
  const [state, setState] = useState({
    search: "",
    dropdown: true
  });

  const selectGoogleSymbolsRef = useRef<HTMLUListElement>(null);
  const drodownRef = useRef<HTMLUListElement>(null);

  const rect = selectGoogleSymbolsRef.current?.getBoundingClientRect();

  const dropdownRect = drodownRef.current?.getBoundingClientRect();

  useEffect(() => {
    if (state.dropdown === false) {
      return;
    }
    setState((state) => ({ ...state, dropdown: false }));
  }, [rect?.bottom]);

  const { iframeWebsite } = DOMGetIframeWebsite();
  useClickOutsideRef(
    iframeWebsite?.contentWindow ? [window, iframeWebsite?.contentWindow] : [window],
    () => {
      setState((state) => ({ ...state, dropdown: false }));
    },
    selectGoogleSymbolsRef
  );

  return (
    <ul ref={selectGoogleSymbolsRef} className={`${styles.select__google__symbols}`}>
      {/* Current value */}
      <li
        onClick={() => {
          setState((state) => ({ ...state, dropdown: !state.dropdown }));
        }}>
        <i
          style={{
            fontVariationSettings: `'FILL' ${value.fill}`
          }}
          className={`material-symbols-${value.format || "outlined"}`}>
          {value.name}{" "}
        </i>{" "}
        <span>{value.name.replace(/_/g, " ")}</span>
        <i className={`${styles.dropdown} material-symbols-${"outlined"}`}>
          {state.dropdown ? "arrow_drop_up" : "arrow_drop_down"}
        </i>{" "}
      </li>

      {rect
        ? createPortal(
            <ul
              ref={drodownRef}
              className={styles.dropdown__list}
              style={{
                position: "fixed",
                width: rect.width,
                top: (() => {
                  if (!dropdownRect) {
                    return rect.bottom;
                  }

                  const dropdownBottom = rect.bottom + dropdownRect.height;

                  return dropdownBottom > window.screen.height ? rect.top - dropdownRect.height : rect.bottom;
                })(),
                left: (() => {
                  if (!dropdownRect) {
                    return rect.left;
                  }
                  return rect.left > window.screen.width ? rect.left - dropdownRect.width : rect.left;
                })(),
                opacity: state.dropdown ? 1 : 0,
                pointerEvents: state.dropdown ? "all" : "none"
              }}>
              {/* Search input */}
              <div className={`${styles.search__input}`} tabIndex={1}>
                <input
                  placeholder="Search icon"
                  onChange={(e) => {
                    setState((state) => ({ ...state, search: e.target.value }));
                  }}
                  value={state.search}
                  tabIndex={-1}
                />
                <i className={`material-symbols-${"outlined"}`}>search</i>
              </div>

              <div className={styles.list}>
                {/* List of icons */}
                {(googleSymbols as GoogleSymbolsAPIResult).icons
                  .filter((icon) => new RegExp(`(${state.search})`, "g").test(icon.name.replace(/_/g, " ")))
                  .map((iconGoogle, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          onSelect({
                            value: {
                              ...value,
                              name: iconGoogle.name
                            },
                            name
                          });
                          setState((state) => ({ ...state, dropdown: false }));
                        }}>
                        <i
                          style={{
                            fontVariationSettings: `'FILL' ${value.fill}`
                          }}
                          className={`material-symbols-${value.format || "outlined"}`}>
                          {iconGoogle.name}{" "}
                        </i>{" "}
                        <span>{iconGoogle.name.replace(/_/g, " ")}</span>
                      </li>
                    );
                  }) || <p>Cannot find icons at the time, try again later.</p>}
              </div>
            </ul>,
            document.body
          )
        : null}
      {/* Dropdown */}
    </ul>
  );
};

export default SelectIcon;
