import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_REQUEST: "WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_REQUEST",
  WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_SUCCESS: "WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_SUCCESS",
  WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_FAILURE: "WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_FAILURE",

  WEBSITE_CREATE_CONTACT_SM_REQUEST: "WEBSITE_CREATE_CONTACT_SM_REQUEST",
  WEBSITE_CREATE_CONTACT_SM_SUCCESS: "WEBSITE_CREATE_CONTACT_SM_SUCCESS",
  WEBSITE_CREATE_CONTACT_SM_FAILURE: "WEBSITE_CREATE_CONTACT_SM_FAILURE",

  WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_REQUEST: "WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_REQUEST",
  WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_SUCCESS: "WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_SUCCESS",
  WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_FAILURE: "WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_FAILURE",

  WEBSITE_DELETE_CONTACT_SM_REQUEST: "WEBSITE_DELETE_CONTACT_SM_REQUEST",
  WEBSITE_DELETE_CONTACT_SM_SUCCESS: "WEBSITE_DELETE_CONTACT_SM_SUCCESS",
  WEBSITE_DELETE_CONTACT_SM_FAILURE: "WEBSITE_DELETE_CONTACT_SM_FAILURE"
};

const actions = {
  /**
   * @description update contact information
   */
  changeInformationAttribute: {
    request: (
      payload: { name: string; value: unknown },
      options?: Partial<{
        noDelay: boolean;
      }>
    ) => ({
      type: types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_REQUEST,
      payload,
      options
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_CONTACT_INFORMATION_ATTRIBUTE_FAILURE,
      payload
    })
  },
  /**
   * @description create contact social media
   */
  createSocialMedia: {
    request: () => ({
      type: types.WEBSITE_CREATE_CONTACT_SM_REQUEST,
      payload: undefined
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_CREATE_CONTACT_SM_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_CREATE_CONTACT_SM_FAILURE,
      payload
    })
  },
  /**
   * @description update contact social media
   */
  changeSocialMediaAttribute: {
    request: (payload: { id: string; name: "name" | "svgHTML" | "href"; value: unknown }) => ({
      type: types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_CONTACT_SM_ATTRIBUTE_FAILURE,
      payload
    })
  },
  /**
   * @description delete contact social media
   */
  deleteSocialMedia: {
    request: (payload: { id: string }) => ({
      type: types.WEBSITE_DELETE_CONTACT_SM_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_DELETE_CONTACT_SM_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_DELETE_CONTACT_SM_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
