import { CallEffect, put, PutEffect, select, call, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { soltivoHelper } from "@soltivo/draw-a-line";
// import { ErrorResponse } from "../../../../helpers/classes/ErrorResponse";
import { createErrorApp, ErrorApp } from "helpers/classes/ErrorApp";
import { processImageSize } from "helpers";
import _cloneDeep from "lodash.clonedeep";
import { ModelWebsite } from "helpers/models";
import { GLOBAL_VARS } from "helpers/global";
import { theme } from "redux/actions";

/**
 * @description update actual page (aka Reducer.pageId) page seo
 */
export default function* updateSEO({
  payload
}: ReturnType<(typeof theme)["page"]["actions"]["updateSEO"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  const codeException = "SeoException";

  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      pages: WebsiteWizardReducer.pages,
      pageId: WebsiteWizardReducer.pageId
    });

    const { website, pages, pageId }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);

    const page = modelWebsite.page.findPageById(pages, pageId);

    if (!page) {
      throw createErrorApp("Cannot modify page not found.", {
        code: codeException
      });
    }

    const { seo } = payload;
    type SeoType = ReturnType<() => keyof typeof seo>;

    const keysToBeChanged = Object.keys(seo);

    for (let i = 0; i < keysToBeChanged.length; i++) {
      const seoAttribute = keysToBeChanged[i] as SeoType;

      if (seoAttribute === "title") {
        const value = seo[seoAttribute];

        if (typeof value !== "string") {
          throw createErrorApp("Title must be a valid text string.", {
            code: codeException
          });
        } else if (value) {
          if (value.length > 70) {
            throw createErrorApp("Title cannot have more than 70 characters.", {
              code: codeException
            });
          }
        }
      } else if (seoAttribute === "description") {
        const value = seo[seoAttribute];

        if (typeof value !== "string") {
          throw createErrorApp("Description must be a valid text string.", {
            code: codeException
          });
        } else if (value) {
          if (value.length > 160) {
            throw createErrorApp("Description cannot have more than 160 characters.", {
              code: codeException
            });
          }
        }
      } else if (seoAttribute === "thumbnail") {
        if (seo[seoAttribute]) {
          // base64 thumbnail from file
          if (seo[seoAttribute] instanceof File) {
            const value = seo[seoAttribute] as File;
            if (value.size > GLOBAL_VARS.defaultFileSize) {
              throw createErrorApp("You must add a image with less than 5MB as thumbnail.", {
                code: codeException
              });
            }

            // Proccess & Create base 64 image.
            const processedImage = yield processImageSize(value, {
              dimensions: {
                width: 1200,
                height: 627,
                fill: true
              }
            });

            if (processedImage.size > GLOBAL_VARS.secondaryFileSize) {
              throw createErrorApp("Image size cannot be greater than 2MB", {
                code: codeException
              });
            }

            const image: string = yield call(soltivoHelper.fileToBase64, processedImage);
            seo[seoAttribute] = image;
          } else {
            if (typeof seo[seoAttribute] !== "string") {
              throw createErrorApp("Thumbnail must be a valid image string.", {
                code: codeException
              });
            } else if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(seo[seoAttribute] as string)) {
              throw createErrorApp("Thumbnail must be a valid url.", {
                code: codeException
              });
            }
          }
        }
      }
    }

    const pageModifiedSeo = seo as {
      title?: string;
      description?: string;
      thumbnail?: string;
    };

    let newPages = _cloneDeep(pages);
    newPages = pages.map((_page) => {
      let page = _cloneDeep(_page);
      if (page.id === pageId) {
        page = { ...page, ...pageModifiedSeo };
        if (modelWebsite.page.id === page.id) {
          modelWebsite.update(modelWebsite, { page: { ...modelWebsite.page, ...pageModifiedSeo } });
        }

        return page;
      }
      return page;
    });

    yield put(
      theme.page.actions.updateSEO.success({
        website: modelWebsite.object,
        pages: newPages
      })
    );
  } catch (error) {
    yield put(
      theme.page.actions.updateSEO.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
