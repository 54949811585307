import React from "react";
import styles from "./styles.module.scss";
import { Column, FormControl, InputPassword, InputSwitch, Row } from "@soltivo/draw-a-line";
import { GLOBAL_VARS } from "helpers/global";
import { Info24Px } from "@soltivo/draw-a-line/core/components/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import actions from "redux/actions";

interface MetaPixelProps {
  pluginKey: WebsitePluginName;
}
const MetaPixel: React.FC<React.PropsWithChildren<MetaPixelProps>> = ({ pluginKey }) => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);

  return (
    <div className={styles.component}>
      {pluginKey === "metaPixel" ? (
        <Row>
          <Column size={"10"}>
            <FormControl
              labelProps={{
                value: "Pixel ID"
              }}>
              <InputPassword
                placeholder="Paste in your Meta Pixel id"
                value={website.plugins?.metaPixel.attributes.pixelId}
                onChange={(e) => {
                  dispatch(
                    actions.wizard.state.actions.change.request({
                      website: {
                        ...website,
                        plugins: {
                          metaPixel: {
                            ...website.plugins?.metaPixel,
                            attributes: {
                              ...website.plugins?.metaPixel?.attributes,
                              pixelId: e.target.value
                            }
                          }
                        }
                      }
                    })
                  );
                }}
              />
            </FormControl>
          </Column>
          <Column size={"2"}>
            <FormControl
              labelProps={{
                value: "Enabled"
              }}>
              <div
                style={{
                  marginTop: 10
                }}>
                <InputSwitch
                  checked={website.plugins?.metaPixel.enabled}
                  onChange={(e) => {
                    dispatch(
                      actions.wizard.state.actions.change.request({
                        website: {
                          ...website,
                          plugins: {
                            metaPixel: {
                              ...website.plugins?.metaPixel,
                              enabled: !website.plugins?.metaPixel.enabled
                            }
                          }
                        }
                      })
                    );
                  }}
                />
              </div>
            </FormControl>
          </Column>

          <Column size={"12"}>
            <a
              href={GLOBAL_VARS.plugins.metaPixel.learnMore}
              target={"_blank"}
              rel="external noreferrer"
              className={styles.pixel__id__info}>
              <Info24Px />
              How to setup my Meta Pixel ID ?
            </a>
          </Column>
        </Row>
      ) : null}
    </div>
  );
};

export default MetaPixel;
