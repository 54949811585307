import { Column, Row } from "@soltivo/draw-a-line";
import ContentHeader from "components/content.header/content.header";
import { FC } from "react";
import styles from "./styles.module.scss";
import FontsComponent from "blocks/fonts/fonts";

interface FontsProps {}

const Fonts: FC<React.PropsWithChildren<FontsProps>> = () => {
  return (
    <Row className={styles.component}>
      <Column size={"12"}>
        <ContentHeader title="Text Format"></ContentHeader>
      </Column>
      <Column size={"12"}>
        <FontsComponent />
      </Column>
    </Row>
  );
};

export default Fonts;
