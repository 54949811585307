import React, { FC } from "react";
import styles from "./styles.module.scss";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import { Minus, Plus, TagHeart } from "@soltivo/draw-a-line/core/components/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import PluginWindow from "./plugin.window/plugin.window";
import { pushToArrayPosition } from "helpers";
import { ModelWebsite } from "helpers/models";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import actions from "redux/actions";
import useEditableData from "helpers/hooks/useEditableData";

interface EditablePluginProps extends EditableProps<"plugin"> {
  isGroup: boolean;
}
const EditablePlugin: FC<React.PropsWithChildren<EditablePluginProps>> = ({
  viewPositionString,
  target,
  isGroup,
  dataPath,
  dataClass
}) => {
  const editableRect: DOMRect | undefined = target?.getBoundingClientRect();

  const sizeType = editableRect
    ? editableRect.width <= 200 || editableRect.height <= 200
      ? "small"
      : editableRect.width <= 500 || editableRect.height <= 500
      ? "medium"
      : "big"
    : "small";

  const dispatch = useDispatch();

  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProps);
  const modelWebsite = new ModelWebsite(website);

  const types = dataPath.split("/");
  const lastType = types[types.length - 1];
  const parentArrayDataClass = dataPath.replace(/(\/[a-zA-Z0-9_\-:]+)$/i, "");

  const { data: _data, validation } = useEditableData({ dataClass, dataPath });
  const data = _data as WebsitePluginService | WebsitePluginServiceCategory | undefined;

  const parentData: (WebsitePluginService | WebsitePluginServiceCategory)[] = modelWebsite.getEditableData({
    website: modelWebsite.object,
    dataPath: parentArrayDataClass
  });

  const objectIndex = /[a-zA-Z0-9_-]:[a-zA-Z0-9_-]/g.test(lastType)
    ? parentData.findIndex((i) => i.id === data?.id)
    : parseInt(lastType);

  const label =
    validation?.type === "booking/category"
      ? "Service category"
      : validation?.type === "booking/service"
      ? "Service"
      : "";

  /**
   * @description add a new block at the end
   */
  const onAdd = () => {
    if (!isGroup) return;
    const parentArrayDataClass = dataPath.replace(/(\/[a-zA-Z0-9:]+)$/i, "");

    dispatch(
      actions.theme.editable.actions.update.request({
        dataPath: parentArrayDataClass,
        dataClass: dataClass,
        value: pushToArrayPosition(parentData, objectIndex + 1, data)
      })
    );
  };

  /**
   * @description delete a block
   */
  const onDelete = () => {
    if (!isGroup) return;
    if (parentData.length === 1) {
      toastHelper.toastStartContent(
        "info",
        "if you do not want to display any item, you should disabled this section."
      );
      return;
    }

    const parentArrayDataClass = dataPath.replace(/(\/[a-zA-Z0-9]+)$/i, "");

    dispatch(
      actions.theme.editable.actions.update.request({
        dataPath: parentArrayDataClass,
        dataClass: dataClass,
        value: parentData.filter((object, index: number) => index !== objectIndex)
      })
    );
  };

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenWindow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onStartWindowMenu({
      id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
      event: e,
      title: label,
      Component: () => <PluginWindow dataPath={dataPath} dataClass={dataClass} />,
      size: "medium",
      acceptSizes: ["medium"],
      pagination: {
        enabled: true,
        pages: [
          {
            id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES,
            title: "Categories",
            visible: true
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY,
            title: "Categories",
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES,
            title: "Services",
            visible: false
          },
          {
            id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICE,
            title: "Services",
            visible: false
          }
        ],
        style: "fixed__bottom"
      }
    });
  };

  if (!_data || !validation) {
    return null;
  }

  return (
    <div data-label={label} className={`${styles.component} ${styles[viewPositionString.y]} ${styles[sizeType]}`}>
      <div className={styles.content}>
        <div className={styles.actions}>
          {isGroup && (
            <button className={styles.minus} onClick={onDelete}>
              <Minus height={18} width={18} />
            </button>
          )}

          <button onClick={onOpenWindow}>
            <TagHeart height={18} width={18} />
          </button>

          {isGroup && (
            <button className={styles.plus} onClick={onAdd}>
              <Plus height={18} width={18} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditablePlugin;
