import { errorWizard } from "helpers/classes/ErrorApp";
import { useEffect, useState } from "react";
/**
 * @description responsable to send data from the builder to the theme using postMessage api.
 */
const useSendData = (payload: {
  isThemeReadyToLoad: boolean;
  iframeIsLoaded: boolean;
  iframeWebsite: HTMLIFrameElement | undefined;
  themeURL: string;
  website: WebsiteData | undefined;
  pages: WebsitePage[];
}) => {
  const { iframeIsLoaded, isThemeReadyToLoad, iframeWebsite, pages, themeURL, website } = payload;
  const [sentFirstMessage, setSentFirstMessage] = useState(false);

  /**
   * @description for update useEffect when sending new message to the iframe.
   */
  const hookDependency = (() => {
    if (typeof website === "object" && Array.isArray(pages)) {
      return JSON.stringify(website) + JSON.stringify(pages);
    } else {
      return undefined;
    }
  })();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!website || !iframeIsLoaded || !isThemeReadyToLoad) {
      return;
    }

    const websiteJSON = JSON.stringify(website);
    const copy = JSON.parse(websiteJSON) as WebsiteData;
    const page = website?.page;

    //Fetch actual page route.
    const routePath = copy.routes?.list.find((item) => item.pageName === page?.name)?.path;

    if (!page) {
      errorWizard("Cannot send message without website.page.");
      return;
    } else if (!page?.id) {
      errorWizard("Cannot send a message without website.page.id.");
      return;
    } else if (!routePath) {
      errorWizard(`Cannot send a message without website.routes.list[page.name].path.`);
      return;
    }

    const onSendMessage = () => {
      iframeWebsite?.contentWindow?.postMessage(
        {
          brain: "wizard",
          website: copy,
          routePath: routePath
        },
        themeURL
      );
    };

    if (!sentFirstMessage) {
      timeout = setTimeout(onSendMessage, 3000);
    } else {
      onSendMessage();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [hookDependency, sentFirstMessage, iframeIsLoaded, isThemeReadyToLoad]);

  useEffect(() => {
    const onListenMessage = (e: MessageEvent<any>) => {
      const { data } = e;
      if (data === "SoltivoThemeMessageReceived") {
        setSentFirstMessage(true);
      }
    };

    window.addEventListener("message", onListenMessage, true);

    return () => {
      window.removeEventListener("message", onListenMessage, true);
    };
  }, []);

  return {
    sentFirstMessage
  };
};

export default useSendData;
