import { Column, FormControl, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import InputImage from "components/input.image/input.image";
import Unsplash from "components/unsplash/unsplash";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "redux/actions";
import { RootState } from "redux/reducers";
import styles from "./page.seo.module.scss";

const PageSeoWindow = () => {
  const dispatch = useDispatch();
  const mapStateToProp = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });

  const { website } = useSelector(mapStateToProp);

  const onChange = (options: { value: any; name: "title" | "description" | "thumbnail" }) => {
    dispatch(
      theme.page.actions.updateSEO.request({
        seo: {
          [options.name]: options.value
        }
      })
    );
  };

  const { windowMenuState, onMoveToPage } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((wm) => wm.id === WINDOW_MENU_KEYS.PAGE_SEO.key);

  return (
    <div className={styles.page__seo}>
      {windowMenu?.pagination.pages.map((windowPage) => {
        if (windowPage.visible !== true) {
          return null;
        }

        switch (windowPage.id) {
          case WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_INPUTS:
            return (
              <Row
                key={windowPage.id}
                style={{
                  flexDirection: windowMenu.size === "big" ? "row-reverse" : undefined
                }}>
                <Column size={windowMenu.size === "big" ? "6" : "12"}>
                  <Row>
                    <Column size={"12"}>
                      <FormControl
                        labelProps={{
                          value: "Title"
                        }}
                        footerProps={{
                          value: `${website.page.title.length}/70`
                        }}>
                        <InputText
                          name="title"
                          value={website.page.title}
                          onChange={(e) => {
                            onChange({ value: e.target.value, name: "title" });
                          }}
                          placeholder="Page title"
                        />
                      </FormControl>
                    </Column>

                    <Column size={"12"}>
                      <FormControl
                        labelProps={{
                          value: "Description"
                        }}
                        footerProps={{
                          value: `${website.page.description.length}/160`
                        }}>
                        <Textarea
                          name="description"
                          value={website.page.description}
                          style={{
                            minHeight: windowMenu.size === "big" ? 150 : "auto"
                          }}
                          onChange={(e) => {
                            onChange({ value: e.target.value, name: "description" });
                          }}
                          placeholder="Page description"
                        />
                      </FormControl>
                    </Column>
                  </Row>
                </Column>

                <Column size={windowMenu.size === "big" ? "6" : "12"}>
                  <FormControl
                    labelProps={{
                      value: "Thumbnail"
                    }}>
                    <InputImage
                      name="thumbnail"
                      value={website.page.thumbnail}
                      style={{
                        maxWidth: "100%",
                        height: windowMenu.size === "medium" ? "240px" : "240px",
                        maxHeight: windowMenu.size === "medium" ? "240px" : "240px"
                      }}
                      onLibrary={() => {
                        onMoveToPage({
                          id: WINDOW_MENU_KEYS.PAGE_SEO.key,
                          pageId: WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_UNSPLASH
                        });
                      }}
                      onChange={(e) => {
                        if (e.target.files?.[0]) {
                          onChange({ value: e.target.files[0], name: "thumbnail" });
                        }
                      }}
                      placeholder="Page thumbnail"
                    />
                  </FormControl>
                </Column>
              </Row>
            );
          case WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_UNSPLASH:
            return (
              <Unsplash
                key={windowPage.id}
                columns={windowMenu.size === "medium" ? 2 : 3}
                menu={true}
                onSelectImage={(imageUrl) => {
                  onChange({ value: imageUrl, name: "thumbnail" });
                  onMoveToPage({
                    id: WINDOW_MENU_KEYS.PAGE_SEO.key,
                    pageId: WINDOW_MENU_KEYS.PAGE_SEO.pageKeys.PAGE_SEO_INPUTS
                  });
                }}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
export default PageSeoWindow;
