import { Button } from "@soltivo/draw-a-line";
import { ReactComponent as SteveWithPen } from "@soltivo/draw-a-line/core/assets/illutrations/SteveWithPen.svg";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { useEffect } from "react";
import styles from "./styles.module.scss";

const Welcome = () => {
  const { onFooterUpdate, onClose, onMoveToPage } = WindowMenu.useWindowMenu();

  useEffect(() => {
    onFooterUpdate(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key, () => {
      return (
        <>
          <Button
            variant="primary"
            outline
            padding={false}
            style={{
              marginRight: 24
            }}
            onClick={() => {
              onClose(WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key);
            }}>
            Skip
          </Button>
          <Button
            onClick={() => {
              onMoveToPage({
                id: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.key,
                pageId: WINDOW_MENU_KEYS.CONFIGURATION_FLOW.pageKeys.CONFIGURATION_FLOW_INFORMATION_STEP_1
              });
            }}
            variant="primary">
            Next <span className="material-symbols-outlined">navigate_next</span>
          </Button>
        </>
      );
    });
  }, []);

  return (
    <div className={styles.component}>
      <div className={styles.vertical_center}>
        <SteveWithPen width={180} height={180} />
      </div>

      <div className={styles.vertical_center}>
        <p className={styles.description}>Let’s configure your website in under 5 minutes ? </p>
      </div>
    </div>
  );
};

export default Welcome;
