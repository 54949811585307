import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import MenuLink from "components/menu.link/menu.link";
import styles from "./styles.module.scss";
import Basics from "./views/basics/basics";
import Colors from "./views/colors/colors";
import Contact from "./views/contact/contact";
import Fonts from "./views/fonts/fonts";
import Logos from "./views/logos/logos";
import Plugins from "./views/plugins/plugins";

const Menu = () => {
  const { windowMenuState, onMoveToPage } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((item) => item.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.key);
  const currentPage = windowMenu?.pagination.pages.find((page) => page.visible === true);

  if (!currentPage || !windowMenu) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <div className={styles.sidebar}>
        <ul>
          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_BASICS
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_BASICS}>
              Basics
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_CONTACT_INFO
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_CONTACT_INFO}>
              Contacts
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_LOGOS
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_LOGOS}>
              Logos
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_COLORS
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_COLORS}>
              Color Palette
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_FONTS
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_FONTS}>
              Text Format
            </MenuLink>
          </li>

          <li>
            <MenuLink
              onClick={() => {
                onMoveToPage({
                  id: windowMenu.id,
                  pageId: WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_PLUGINS
                });
              }}
              active={currentPage?.id === WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_PLUGINS}>
              Plugins
            </MenuLink>
          </li>
        </ul>
      </div>

      <div className={styles.content}>
        {(() => {
          switch (currentPage.id) {
            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_BASICS:
              return <Basics />;

            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_CONTACT_INFO:
              return <Contact />;

            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_LOGOS:
              return <Logos />;

            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_COLORS:
              return <Colors />;

            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_FONTS:
              return <Fonts />;

            case WINDOW_MENU_KEYS.CONFIGURATION_MENU.pageKeys.CONFIGURATION_MENU_PLUGINS:
              return <Plugins />;

            default:
              return <></>;
          }
        })()}
      </div>
    </div>
  );
};

export default Menu;
