import { Column, FormControl, Row, Textarea } from "@soltivo/draw-a-line";
import InputImage from "components/input.image/input.image";
import { FC } from "react";
import Unsplash from "components/unsplash/unsplash";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import WindowMenu from "components/window.menu";
import useEditableData from "helpers/hooks/useEditableData";

interface ImageBackgroundWindowProps extends EditableWindowProps<"background-image"> {}

const ImageBackgroundWindow: FC<React.PropsWithChildren<ImageBackgroundWindowProps>> = ({ dataClass, dataPath }) => {
  const { onStartWindowMenu, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_BACKGROUND_IMAGE.key);

  const editable = useEditableData({ dataClass, dataPath });

  if (!windowMenu || !editable.data || !editable.validation) {
    return null;
  }

  return (
    <Row>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Background image"
          }}>
          <InputImage
            name="src"
            style={{
              maxWidth: "100%",
              width: "100%",
              height: windowMenu.size === "medium" ? "240px" : "140px",
              maxHeight: windowMenu.size === "medium" ? "240px" : "140px"
            }}
            value={editable.data.src}
            onLibrary={(e) => {
              onStartWindowMenu({
                id: WINDOW_MENU_KEYS.UNSPLASH.key,
                event: e,
                size: "medium",
                acceptSizes: ["medium"],
                title: "Select an image",
                Component: () => {
                  return (
                    <Unsplash
                      columns={2}
                      menu={true}
                      onSelectImage={(imageUrl) => {
                        editable.onUpdate({
                          ...editable.data,
                          src: imageUrl
                        });
                      }}
                    />
                  );
                }
              });
            }}
            accept={
              editable.validation.attributes.src?.rules?.mimes
                ? editable.validation.attributes.src?.rules.mimes.map((item) => `image/${item}`).join(",")
                : "image/png,image/jpeg,image/jpg,image/webp"
            }
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file)
                editable.onUpdate({
                  ...editable.data,
                  src: file
                });
            }}
          />
        </FormControl>
      </Column>

      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Description"
          }}>
          <Textarea
            name="alt"
            value={editable.data.alt}
            onChange={(e) => {
              editable.onUpdate({
                ...editable.data,
                alt: e.target.value
              });
            }}
            placeholder="value"
          />
        </FormControl>
      </Column>
    </Row>
  );
};

export default ImageBackgroundWindow;
