import React, { FC, useState } from "react";
import { Link24Px, LinkOff24Px } from "@soltivo/draw-a-line/core/components/icons";
import { Button, Column, FormControl, InputSelectHybrid, InputText, Row } from "@soltivo/draw-a-line";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { GLOBAL_VARS } from "helpers/global";
import { bookingUrl } from "helpers";
import useEditableData from "helpers/hooks/useEditableData";

interface ButtonWindowProps extends EditableWindowProps<"button"> {}
const ButtonWindow: FC<React.PropsWithChildren<ButtonWindowProps>> = ({ dataClass, dataPath }) => {
  const { data, onUpdate, validation } = useEditableData({ dataClass, dataPath });

  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    pages: Wizard.pages,
    orgId: App.organization.data.orgId
  });
  const { website, pages, orgId }: ReturnType<typeof mapStateToProps> = useSelector(mapStateToProps);

  const [state, setState] = useState({
    // checks if href should be previewed as custom url or input select.
    customHref:
      data?.action === "window_open"
        ? !(
            data?.href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER ||
            website.routes?.list.find((item) => {
              return data?.href === item.path;
            }) ||
            data?.href.startsWith("/")
          )
        : false
  });

  if (!data || !validation) {
    return null;
  }

  const onChange = (name: string, value: string) => {
    if (validation.classof === "button") {
      if (validation.attributes.action?.rules.readonly && name === "action") {
        return;
      }
      onUpdate({ ...data, [name]: value });
    }
  };

  return (
    <Row>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Text"
          }}>
          <InputText
            name="value"
            value={data.value}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            placeholder="value"
          />
        </FormControl>
      </Column>

      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "On Click"
          }}>
          <InputSelectHybrid
            selectProps={{
              name: "action",
              value: data.action,
              onChange: (e) => onChange("action", e.target.value),
              placeholder: "Select action",
              disabled: validation.attributes.action?.rules.readonly,
              style: {
                textTransform: "capitalize"
              }
            }}>
            {GLOBAL_VARS.editables.button.actions.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {GLOBAL_VARS.editables.button.actionLabels[index]}
                </option>
              );
            })}
          </InputSelectHybrid>
        </FormControl>
      </Column>

      {data.action === "window_open" ? (
        <Column size={"12"}>
          <Row>
            <Column size="10">
              <FormControl
                labelProps={{
                  value: "Link"
                }}>
                {state.customHref ? (
                  <InputText
                    name="href"
                    type={"url"}
                    value={
                      data.href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
                        ? bookingUrl({ orgId: orgId, name: website.name, favicon: website.logo.favicon.src })
                        : data.href
                    }
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    placeholder="eg. https://example.com"
                  />
                ) : (
                  <InputSelectHybrid
                    selectProps={{
                      name: "href",
                      value: data.href,
                      onChange: (e) => onChange(e.target.name, e.target.value),
                      placeholder: "Select a Link",
                      style: {
                        textTransform: "capitalize"
                      }
                    }}>
                    <optgroup label="Pages">
                      {website.routes?.list.map((item, index) => {
                        const page = pages.find((p) => p.name === item.pageName);
                        const pageName = page?.name || "";

                        if (!pageName) return null;

                        return (
                          <option key={item.path} value={item.path}>
                            {pageName}
                          </option>
                        );
                      })}
                    </optgroup>

                    <optgroup label="Page sections">
                      {website.routes?.list.map((item, index) => {
                        const page = pages.find((p) => p.name === item.pageName);
                        const pageName = page?.name || "";

                        if (!pageName) return null;

                        return (
                          page?.sections.map((section, sectionIndex) => {
                            return (
                              <option key={section.targetId} value={`${item.path}#${section.targetId}`}>
                                {pageName.toLowerCase()} {`➡`} {section.name.toLowerCase()}
                              </option>
                            );
                          }) || null
                        );
                      })}
                    </optgroup>

                    <optgroup label="Plugins">
                      <option value={GLOBAL_VARS.BOOKING_URL_PLACEHOLDER}>booking</option>
                    </optgroup>
                  </InputSelectHybrid>
                )}
              </FormControl>
            </Column>

            <Column size="2">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%"
                }}>
                <Button
                  data-title={state.customHref ? "Click to select a link" : "Click to use text as a link"}
                  data-title-position="top-left"
                  outline
                  padding={false}
                  variant="geyser-500"
                  onClick={() => setState((g) => ({ ...g, customHref: !g.customHref }))}>
                  {" "}
                  {state.customHref ? <LinkOff24Px /> : <Link24Px />}
                </Button>
              </div>
            </Column>
          </Row>
        </Column>
      ) : null}
    </Row>
  );
};

export default ButtonWindow;
