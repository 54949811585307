import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_LOGO_REQUEST: "WEBSITE_UPDATE_LOGO_REQUEST",
  WEBSITE_UPDATE_LOGO_SUCCESS: "WEBSITE_UPDATE_LOGO_SUCCESS",
  WEBSITE_UPDATE_LOGO_FAILURE: "WEBSITE_UPDATE_LOGO_FAILURE"
};

const actions = {
  /**
   * @description update root logo object
   */
  update: {
    request: (payload: { logo: Partial<Record<keyof WebsiteData["logo"], File | string>> }) => ({
      type: types.WEBSITE_UPDATE_LOGO_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_LOGO_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_LOGO_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
