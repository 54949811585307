import { ArrowDropDown24Px } from "@soltivo/draw-a-line/core/components/icons";
import styles from "./value.module.scss";

export interface ValueProps {
  value: string;
  dropped: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const Value = ({ dropped, value, onClick }: ValueProps) => {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
      className={`${styles.value} ${dropped ? styles.dropped : ""}`}
      role="button">
      <span>{value}</span> <ArrowDropDown24Px />
    </span>
  );
};

export default Value;
