import { parsePhoneNumber } from "awesome-phonenumber";

export const formatPhoneNumber = (value: string) => {
  const pn = parsePhoneNumber(value.startsWith("+") ? value : `+${value}`);
  const json = pn.toJSON();
  const contryCode = pn.getCountryCode();
  if (typeof json?.number?.national === "string" && contryCode) {
    return `+${contryCode} ${json?.number?.national}`;
  }
  return value;
};
