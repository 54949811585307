import { CallEffect, put, PutEffect, select, call, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { soltivoHelper, toastHelper } from "@soltivo/draw-a-line";
import { delay } from "redux-saga/effects";
import websiteClass from "../../../../helpers/api/website.class";
import apiV2 from "../../../../helpers/api/api.v2";
import { ErrorApp } from "helpers/classes/ErrorApp";
import { getLastDataValue, processImageSize } from "helpers";
import { ModelWebsite, ModelValidation } from "helpers/models";
import actions from "redux/actions";

/**
 * @description update general attributes of a theme
 */
export default function* update({
  payload,
  options
}: ReturnType<(typeof actions.theme)["general"]["actions"]["update"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const { value, attribute } = payload;

    let _value = value;

    if (options?.delay) {
      yield delay(options.delay);
    }

    const mapStateToProps = ({ Wizard }: RootState) => ({
      website: Wizard.website,
      validation: Wizard.validation
    });

    const { validation, website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);
    const modelValidation = new ModelValidation(validation);

    if (!modelValidation.general.attributes[attribute]) {
      return;
    }

    const validationAttribute = getLastDataValue(validation.general.attributes, `${attribute}`);

    if (!validationAttribute) {
      return;
    }

    modelValidation.validate(validationAttribute, _value);

    if (_value instanceof File) {
      if (/(favicon)/g.test(attribute)) {
        const processedImage = yield processImageSize(_value, {
          dimensions: {
            width: 32,
            height: 32,
            fill: true
          }
        });

        const image = yield call(soltivoHelper.fileToBase64, processedImage);
        _value = image;
      }

      if (typeof _value !== "string" && _value instanceof File) {
        toastHelper.toastStartContent("info", `Uploading ${attribute}...`);
        const { data: assignedUrl } = yield call(websiteClass.websiteUploadFile, _value);
        const { fileLink } = yield call(apiV2.postAssignedUrl, assignedUrl, _value);
        toastHelper.toastStartContent("success", `File for ${attribute} uploaded successfully`);
        _value = fileLink;
      }
    }

    modelWebsite.updateRootPath({
      dataClass: undefined,
      dataPath: `global/general/${attribute}`,
      value: _value,
      website: modelWebsite.object
    });

    yield put(
      actions.theme.general.actions.update.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      actions.theme.general.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
