import { CallEffect, delay, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import { createErrorApp, ErrorApp } from "helpers/classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import { theme } from "redux/actions";

/**
 * @description update name attributes of a theme
 */
export default function* updateName({
  payload
}: ReturnType<(typeof theme)["data"]["actions"]["updateName"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  const codeException = "updateNameException";
  try {
    yield delay(1000);

    const { name } = payload;

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website
    });

    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    if (typeof name !== "string") {
      throw createErrorApp("Website name must be typeof text string.", {
        code: codeException
      });
    } else if (!name) {
      throw createErrorApp("Website name is required.", {
        code: codeException
      });
    }

    // website.name = name;
    modelWebsite.update(modelWebsite, { name: name });

    yield put(
      theme.data.actions.updateName.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.data.actions.updateName.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
