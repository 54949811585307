import styles from "./visible.switch.module.scss";
import { InputSwitch } from "@soltivo/draw-a-line";
import { InputSwitchProps } from "@soltivo/draw-a-line/core/components/inputs/input.switch/input.switch";
import { FC } from "react";

interface VisibleSwitchProps {
  checked: boolean;
  onChange: InputSwitchProps["onChange"];
}

const VisibleSwitch: FC<React.PropsWithChildren<VisibleSwitchProps>> = ({ checked, onChange }) => {
  return (
    <div className={`${styles.visible__switch}`}>
      <label>Visible</label> <InputSwitch onChange={onChange} checked={checked} />
    </div>
  );
};

export default VisibleSwitch;
