import React from "react";
import { ReactComponent as SteveSad } from "@soltivo/draw-a-line/core/assets/illutrations/SteveSad.svg";
import styles from "./error.boundary.module.scss";

class ErrorBoundary extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  state: { hasError: boolean; error: unknown } = { hasError: false, error: "" };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.error__boundary}>
          <div className={styles.container}>
            <SteveSad />
            <h2>Something went wrong!</h2>
            <p>
              Application crashed, please if you have the time report this issue to us so it won't happen in the future.
            </p>

            <code
              style={{
                color: "red",
                fontSize: "10px",
                borderRadius: "4px",
                background: "#222",
                padding: "10px"
              }}>
              {this.state.error instanceof Error
                ? this.state.error.message
                : typeof this.state.error === "string"
                ? this.state.error
                : typeof this.state.error === "object"
                ? JSON.stringify(this.state.error)
                : ""}
            </code>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
