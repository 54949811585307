import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_SITE_MAP_REQUEST: "WEBSITE_UPDATE_SITE_MAP_REQUEST",
  WEBSITE_UPDATE_SITE_MAP_SUCCESS: "WEBSITE_UPDATE_SITE_MAP_SUCCESS",
  WEBSITE_UPDATE_SITE_MAP_FAILURE: "WEBSITE_UPDATE_SITE_MAP_FAILURE",

  WEBSITE_UPDATE_PAGE_ROUTE_REQUEST: "WEBSITE_UPDATE_PAGE_ROUTE_REQUEST",
  WEBSITE_UPDATE_PAGE_ROUTE_SUCCESS: "WEBSITE_UPDATE_PAGE_ROUTE_SUCCESS",
  WEBSITE_UPDATE_PAGE_ROUTE_FAILURE: "WEBSITE_UPDATE_PAGE_ROUTE_FAILURE"
};

const actions = {
  /**
   * @description update website site map
   */
  updateSiteMap: {
    request: (payload: { siteMap: WebsiteData["routes"]["siteMap"] }) => ({
      type: types.WEBSITE_UPDATE_SITE_MAP_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_SITE_MAP_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_SITE_MAP_FAILURE,
      payload
    })
  },
  /**
   * @descrition update current page route
   */
  updatePageRoute: {
    request: (payload: { pageRoute: WebsiteRoutes["list"][0]; type: "custom" | "home" }) => ({
      type: types.WEBSITE_UPDATE_PAGE_ROUTE_REQUEST,
      payload
    }),
    success: (payload: { pages: WizardState["pages"]; website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_PAGE_ROUTE_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_PAGE_ROUTE_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
