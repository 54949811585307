export const ValidateTypeIcon = (validation: ValidateTypeIcon, payload: string) => {
  if (typeof payload !== "string") {
    throw `Icon must be string JSON.`;
  }

  const isJson = (() => {
    try {
      return typeof JSON.parse(payload) === "object" ? true : false;
    } catch (e) {
      return false;
    }
  })();

  if (isJson === false) {
    throw `Icon must be string JSON.`;
  }

  const data: WebsiteIcon = JSON.parse(payload);

  if (typeof data.fill !== "number") {
    throw `Icon fill must be number.`;
  }

  if (typeof data.format !== "string") {
    throw `Icon format must be string.`;
  }

  if (typeof data.grade !== "number") {
    throw `Icon grade must be number.`;
  }

  if (typeof data.name !== "string") {
    throw `Icon name must be string.`;
  }

  if (typeof data.size !== "number") {
    throw `Icon size must be number.`;
  }

  if (typeof data.source !== "string") {
    throw `Icon source must be string.`;
  }

  if (typeof data.weight !== "number") {
    throw `Icon weight must be number.`;
  }
};
