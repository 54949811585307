import React, { FC, useState } from "react";
import { Link24Px, LinkOff24Px } from "@soltivo/draw-a-line/core/components/icons";
import { Button, Column, FormControl, InputSelectHybrid, InputText, Row } from "@soltivo/draw-a-line";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { GLOBAL_VARS } from "helpers/global";
import { bookingUrl } from "helpers";
import useEditableData from "helpers/hooks/useEditableData";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";

interface LinkWindowProps extends EditableWindowProps<"link"> {}

const LinkWindow: FC<React.PropsWithChildren<LinkWindowProps>> = ({ dataClass, dataPath }) => {
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    pages: Wizard.pages,
    orgId: App.organization.data.orgId
  });
  const { website, pages, orgId }: ReturnType<typeof mapStateToProps> = useSelector(mapStateToProps);

  const { getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_LINK.key);

  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  const [state, setState] = useState({
    // checks if href should be previewed as custom url or input select.
    customHref: !(
      data?.href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER ||
      website.routes?.list.find((item) => {
        return data?.href === item.path;
      }) ||
      data?.href.startsWith("/")
    )
  });

  const onChange = (name: string, value: string) => {
    if (validation?.classof === "link") {
      onUpdate(name === "value" ? { ...data, title: value, value: value } : { ...data, [name]: value });
    }
  };

  if (!windowMenu || !data || !validation) {
    return null;
  }

  return (
    <Row>
      <Column size="12">
        <FormControl
          labelProps={{
            value: "Text"
          }}>
          <InputText
            name="value"
            value={data.value}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            placeholder="Text"
          />
        </FormControl>
      </Column>

      <Column size="10">
        <FormControl
          labelProps={{
            value: "Link"
          }}>
          {state.customHref ? (
            <InputText
              name="href"
              type={"url"}
              value={
                data.href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
                  ? bookingUrl({ orgId: orgId, name: website.name, favicon: website.logo.favicon.src })
                  : data.href
              }
              onChange={(e) => onChange(e.target.name, e.target.value)}
              placeholder="eg. https://example.com"
            />
          ) : (
            <InputSelectHybrid
              selectProps={{
                name: "href",
                value: data.href,
                onChange: (e) => onChange(e.target.name, e.target.value),
                placeholder: "Select a Link",
                style: {
                  textTransform: "capitalize"
                }
              }}>
              <optgroup label="Pages">
                {website.routes?.list.map((item, index) => {
                  const page = pages.find((p) => p.name === item.pageName);
                  const pageName = page?.name || "";

                  if (!pageName) return null;

                  return (
                    <option key={item.path} value={item.path}>
                      {pageName}
                    </option>
                  );
                })}
              </optgroup>

              <optgroup label="Page sections">
                {website.routes?.list.map((item, index) => {
                  const page = pages.find((p) => p.name === item.pageName);
                  const pageName = page?.name || "";

                  if (!pageName) return null;

                  return (
                    page?.sections.map((section, sectionIndex) => {
                      return (
                        <option key={section.targetId} value={`${item.path}#${section.targetId}`}>
                          {pageName.toLowerCase()} {`➡`} {section.name.toLowerCase()}
                        </option>
                      );
                    }) || null
                  );
                })}
              </optgroup>

              <optgroup label="Plugins">
                <option value={GLOBAL_VARS.BOOKING_URL_PLACEHOLDER}>booking</option>
              </optgroup>
            </InputSelectHybrid>
          )}
        </FormControl>
      </Column>

      <Column size="2">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%"
          }}>
          <Button
            data-title={state.customHref ? "Click to select a link" : "Click to use text as a link"}
            data-title-position="top-left"
            outline
            padding={false}
            variant="geyser-500"
            onClick={() => setState((g) => ({ ...g, customHref: !g.customHref }))}>
            {" "}
            {state.customHref ? <LinkOff24Px width={24} height={24} /> : <Link24Px width={24} height={24} />}
          </Button>
        </div>
      </Column>

      <Column size="12">
        <FormControl
          labelProps={{
            value: "On Click"
          }}>
          <InputSelectHybrid
            selectProps={{
              name: "target",
              value: data.target,
              onChange: (e) => onChange(e.target.name, e.target.value),
              placeholder: "Select behavior",
              style: {
                textTransform: "capitalize"
              },
              disabled: validation.attributes.target?.rules.disabled
            }}>
            {GLOBAL_VARS.editables.link.targets.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {GLOBAL_VARS.editables.link.targetLabels[index]}
                </option>
              );
            })}
          </InputSelectHybrid>
        </FormControl>
      </Column>
    </Row>
  );
};

export default LinkWindow;
