import { IndexedDB } from "helpers/functions/indexedDB";
import { useState } from "react";

export const useBuilderIndexedDB = (payload: { storeKey: "theme"; version?: number }) => {
  const [state] = useState(() => {
    return new IndexedDB({ databaseName: "builder", ...payload });
  });

  return state;
};
