import types from "../../../actions/types";
import { takeLatest } from "redux-saga/effects";
import open from "./open";
import toggle from "./toggle";
import updateSEO from "./updateSEO";

export default function* Page() {
  yield takeLatest(types.WEBSITE_OPEN_PAGE_REQUEST, open);
  yield takeLatest(types.WEBSITE_TOGGLE_PAGE_REQUEST, toggle);
  yield takeLatest(types.WEBSITE_UPDATE_PAGE_SEO_REQUEST, updateSEO);
}
