import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import MenuLink from "components/menu.link/menu.link";
import styles from "./styles.module.scss";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import FooterWindowView from "./views";

const FooterWindow = () => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website
  });
  const { website } = useSelector(mapStateToProps);

  const { windowMenuState, onMoveToPage } = WindowMenu.useWindowMenu();

  const windowMenu = windowMenuState.windows.find((item) => item.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.key);
  const currentPage = windowMenu?.pagination.pages.find((page) => page.visible === true);

  if (!currentPage || !windowMenu) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <div className={styles.sidebar}>
        <ul>
          {website.footer.data.logo && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_LOGO
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_LOGO}>
                Logo
              </MenuLink>
            </li>
          )}

          {website.footer.data.siteMap && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SITEMAP
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SITEMAP}>
                Menu
              </MenuLink>
            </li>
          )}

          {website.footer.data.links && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_BUTTON
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_BUTTON}>
                Button (CTA)
              </MenuLink>
            </li>
          )}

          {website.footer.data.copyright && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_COPYRIGHT
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_COPYRIGHT}>
                Copyright
              </MenuLink>
            </li>
          )}

          {website.footer.data.socialMedia && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SM
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SM}>
                Social Media
              </MenuLink>
            </li>
          )}

          {website.footer.data.contactInformation && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_CONTACTS
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_CONTACTS}>
                Contacts
              </MenuLink>
            </li>
          )}

          {website.footer.data.text && (
            <li>
              <MenuLink
                onClick={() => {
                  onMoveToPage({
                    id: windowMenu.id,
                    pageId: WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_TEXT
                  });
                }}
                active={currentPage.id === WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_TEXT}>
                Text
              </MenuLink>
            </li>
          )}
        </ul>
      </div>

      <div className={styles.content}>
        {(() => {
          switch (currentPage.id) {
            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_LOGO:
              return <FooterWindowView.Logo />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SITEMAP:
              return <FooterWindowView.Menu />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_BUTTON:
              return <FooterWindowView.Button />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_COPYRIGHT:
              return <FooterWindowView.Copyright />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_SM:
              return <FooterWindowView.SocialMedia />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_CONTACTS:
              return <FooterWindowView.ContactInformation />;

            case WINDOW_MENU_KEYS.EDITABLE_FOOTER.pageKeys.EDITABLE_FOOTER_TEXT:
              return <FooterWindowView.Text />;

            default:
              return <></>;
          }
        })()}
      </div>
    </div>
  );
};

export default FooterWindow;
