import { changeWindowMenuState } from "./functions/changeWindowMenuState";
import { queryWindowMenuElementsById } from "./functions/queryWindowMenuElementsById";
import { useWindowMenu } from "./hooks/useWindowMenu";
import Component from "./component";
import { resize } from "./functions/resize";
import Wrapper from "./wrapper";
import { fixContainerPosition } from "./functions/fixContainerPosition";
import { exists } from "./functions/exists";
import { INITIAL_STATE, INITIAL_WINDOW_STATE, WindowMenuContext } from "./context";
import { getWindowMenuObject } from "./functions/getWindowMenuObject";

const WindowMenu = {
  resize,
  exists,
  changeWindowMenuState,
  queryWindowMenuElementsById,
  getWindowMenuObject,
  useWindowMenu,
  fixContainerPosition,
  Component: Component,
  Wrapper: Wrapper,
  Context: WindowMenuContext,
  INITIAL_STATE,
  INITIAL_WINDOW_STATE
};

export default WindowMenu;
