import { call, CallEffect, put, PutEffect } from "@redux-saga/core/effects";
import websiteClass from "../../../../helpers/api/website.class";
import actions from "redux/actions";
import { ErrorApp } from "helpers/classes/ErrorApp";

/**
 * @description get organization purchased domain
 */
export default function* get({
  payload
}: ReturnType<(typeof actions.api)["domain"]["actions"]["get"]["request"]>): Generator<
  CallEffect<any> | PutEffect<any>,
  void,
  any
> {
  try {
    const { data }: { data: DomainInfo } = yield call(websiteClass.websiteGetDomainInfo);

    yield put(
      actions.api.domain.actions.get.success({
        domainInfo: {
          data: data,
          loading: false,
          updatingPagePath: false
        }
      })
    );
  } catch (error) {
    yield put(
      actions.api.domain.actions.get.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
