import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./app";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import authConfig from "./helpers/aws/aws-confg";
import { Amplify } from "aws-amplify";
import { ToastContainer, Popup } from "@soltivo/draw-a-line";
import history from "./routes/history";
import "./assets/styles/index.styles.scss";

Amplify.configure(authConfig);

const container = document.getElementById("root") as unknown as HTMLElement;

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
        <ToastContainer />
        <Popup />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
