import ButtonView from "./button/button.view";
import ContactInformation from "./contact.information/contact.information";
import Copyright from "./copyright/copyright";
import Logo from "./logo/logo";
import MenuView from "./menu/menu";
import SocialMedia from "./social.media/social.media";
import Text from "./text/text";

const FooterWindowView = {
  Button: ButtonView,
  ContactInformation: ContactInformation,
  Copyright: Copyright,
  Logo: Logo,
  Menu: MenuView,
  SocialMedia: SocialMedia,
  Text: Text
};

export default FooterWindowView;
