import { createErrorApp } from "helpers/classes/ErrorApp";

export const ValidateClassIcon = (validation: ValidateClassIcon, payload: WebsiteIcon) => {
  if (typeof payload.fill !== "number") {
    throw createErrorApp(`Icon fill must be number.`);
  }

  if (typeof payload.format !== "string") {
    throw createErrorApp(`Icon format must be string.`);
  }

  if (typeof payload.grade !== "number") {
    throw createErrorApp(`Icon grade must be number.`);
  }

  if (typeof payload.name !== "string") {
    throw createErrorApp(`Icon name must be string.`);
  }

  if (typeof payload.size !== "number") {
    throw createErrorApp(`Icon size must be number.`);
  }

  if (typeof payload.source !== "string") {
    throw createErrorApp(`Icon source must be string.`);
  }

  if (typeof payload.weight !== "number") {
    throw createErrorApp(`Icon weight must be number.`);
  }
};
