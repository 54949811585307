import { createErrorApp } from "helpers/classes/ErrorApp";

/**
 * @description validation menu class.
 */
export const ValidateClassMenu = (validation: Validation["menu"], payload: WebsiteData["menu"]) => {
  if (typeof payload.data !== "object") {
    throw createErrorApp(`Menu is missign data object.`);
  }

  // const validationDataAttributes = validation.attributes?.data?.attributes;

  if (typeof payload.data.links !== "undefined") {
  }
};
