import { WizardState } from "redux/reducers/wizard/reducer";

const types = {
  WEBSITE_UPDATE_FOOTER_REQUEST: "WEBSITE_UPDATE_FOOTER_REQUEST",
  WEBSITE_UPDATE_FOOTER_SUCCESS: "WEBSITE_UPDATE_FOOTER_SUCCESS",
  WEBSITE_UPDATE_FOOTER_FAILURE: "WEBSITE_UPDATE_FOOTER_FAILURE"
};

const actions = {
  /**
   * @description update root footer object
   */
  update: {
    request: (payload: { footer: Partial<WebsiteFooter["data"]> }) => ({
      type: types.WEBSITE_UPDATE_FOOTER_REQUEST,
      payload
    }),
    success: (payload: { website: WizardState["website"] }) => ({
      type: types.WEBSITE_UPDATE_FOOTER_SUCCESS,
      payload
    }),

    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_UPDATE_FOOTER_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
