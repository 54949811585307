import { WizardState } from "redux/reducers/wizard/reducer";
const types = {
  WEBSITE_GET_BOOKING_REQUEST: "WEBSITE_GET_BOOKING_REQUEST",
  WEBSITE_GET_BOOKING_SUCCESS: "WEBSITE_GET_BOOKING_SUCCESS",
  WEBSITE_GET_BOOKING_FAILURE: "WEBSITE_GET_BOOKING_FAILURE",

  WEBSITE_PLUGIN_UPDATE_REQUEST: "WEBSITE_PLUGIN_UPDATE_REQUEST",
  WEBSITE_PLUGIN_UPDATE_SUCCESS: "WEBSITE_PLUGIN_UPDATE_SUCCESS",
  WEBSITE_PLUGIN_UPDATE_FAILURE: "WEBSITE_PLUGIN_UPDATE_FAILURE"
};

const actions = {
  /**
   * @description get category of services and services for booking.
   */
  getAll: {
    request: () => ({
      type: types.WEBSITE_GET_BOOKING_REQUEST,
      payload: undefined
    }),
    success: (payload: { categories: WebsitePluginServiceCategory[]; services: WebsitePluginService[] }) => ({
      type: types.WEBSITE_GET_BOOKING_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_GET_BOOKING_FAILURE,
      payload
    })
  },
  update: {
    request: () => ({
      type: types.WEBSITE_PLUGIN_UPDATE_REQUEST,
      payload: undefined
    }),
    success: (payload: { plugins: WizardState["plugins"] }) => ({
      type: types.WEBSITE_PLUGIN_UPDATE_SUCCESS,
      payload
    }),
    failure: (payload: Pick<WizardState, "error">) => ({
      type: types.WEBSITE_PLUGIN_UPDATE_FAILURE,
      payload
    })
  }
};

export default {
  types,
  actions
};
