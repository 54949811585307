import { Button } from "@soltivo/draw-a-line";
import { Home24Px, Pencil } from "@soltivo/draw-a-line/core/components/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { theme } from "redux/actions";
import { WizardState } from "redux/reducers/wizard/reducer";
import styles from "./domain.input.module.scss";

interface DomainInputProps {
  website: Partial<WebsiteData>;
  domainInfo: WizardState["domainInfo"];
  page: WebsitePage | undefined;
  error: WizardState["error"];
  validation: WizardState["validation"];
}

const DomainInput: FC<React.PropsWithChildren<DomainInputProps>> = ({
  domainInfo,
  page,
  website,
  error,
  validation
}) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  const pageRoute = website.routes?.list.find((r) => r.pageName === page?.name);
  const allowedAsHomePage = validation.routes.attributes.list.items.rules.index?.find(
    (pageName) => pageName === page?.name
  );

  const [form, setForm] = useState(() => {
    if (website.routes) {
      return {
        pageRoute: pageRoute
      };
    }
  });

  const onChangePath = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.startsWith("/")) {
      e.target.value = `/${e.target.value}`;
    }

    if (form?.pageRoute) {
      setForm({
        pageRoute: {
          ...form.pageRoute,
          path: e.target.value
        }
      });
    }
  };

  const onSubmit = (type: "custom" | "home") => {
    if (form?.pageRoute) {
      switch (type) {
        case "custom":
          dispatch(theme.route.actions.updatePageRoute.request({ pageRoute: form.pageRoute, type: type }));
          break;
        case "home":
          dispatch(
            theme.route.actions.updatePageRoute.request({
              pageRoute: {
                ...form.pageRoute,
                path: "/"
              },
              type: type
            })
          );
          break;
      }
    }
  };

  useEffect(() => {
    setForm(() => {
      if (website.routes) {
        return {
          pageRoute: pageRoute
        };
      }
    });
  }, [page?.id, error?.code === "PageRouteException"]);

  useEffect(() => {
    if (domainInfo.updatingPagePath) {
      setEditing(false);
    }
  }, [domainInfo.updatingPagePath]);

  return (
    <div className={styles.domain__input}>
      {domainInfo.loading ? (
        <p>
          <span>Loading domain </span>
        </p>
      ) : (
        <>
          {!editing ? (
            <a
              href={`https://${domainInfo.data?.domainName}${pageRoute?.path || "/"}`}
              target="_blank"
              rel="noreferrer">
              <span>https://</span>
              {domainInfo.data?.domainName
                ? domainInfo.data?.domainName + pageRoute?.path || "/"
                : "your-domain.mysoltivo.com" + pageRoute?.path || "/"}
            </a>
          ) : (
            <div className={styles.domain__update__group}>
              <span>https://</span>
              {domainInfo.data?.domainName ? domainInfo.data?.domainName : "your-domain.mysoltivo.com"}
              <input
                className={styles.domain__update}
                type={"text"}
                value={form?.pageRoute?.path || "/"}
                onChange={onChangePath}
              />
            </div>
          )}

          <div className={styles.button__group}>
            {!editing ? (
              <>
                {pageRoute?.path !== "/" ? (
                  <>
                    {domainInfo.updatingPagePath ? null : allowedAsHomePage ? (
                      <Button
                        onClick={() => {
                          onSubmit("home");
                        }}
                        title="Set page as home page"
                        variant="geyser-300"
                        padding={false}
                        outline
                        loading={domainInfo.updatingPagePath}>
                        <Home24Px width={24} height={24} />
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        setEditing(!editing);
                      }}
                      title="Change page route"
                      variant="geyser-300"
                      padding={false}
                      outline
                      loading={domainInfo.updatingPagePath}>
                      <Pencil />
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <Button padding={false} variant="primary" outline onClick={() => onSubmit("custom")}>
                Done
              </Button>
            )}
          </div>
        </>
      )}
      {domainInfo.data === null && !domainInfo.loading ? (
        <Button
          className={styles.purchase__button}
          onClick={() => {
            window.open(
              process.env.NODE_ENV === "development"
                ? "https://mysoltivo.local/settings/search-domain"
                : process.env.NODE_ENV === "production"
                ? "https://mysoltivo.com/settings/search-domain"
                : "https://mysoltivo.dev/settings/search-domain",
              "_blank"
            );
          }}
          outline
          variant="primary"
          border={false}
          padding={false}>
          Purchase a domain name
        </Button>
      ) : null}
    </div>
  );
};

export default DomainInput;
