import { all } from "redux-saga/effects";
import Color from "./color";
import Contact from "./contact";
import Data from "./data";
import Editable from "./editable";
import Font from "./font";
import Footer from "./footer";
import General from "./general";
import Logo from "./logo";
import Menu from "./menu";
import Page from "./page";
import Route from "./route";
import Section from "./section";

export default function* Theme() {
  yield all([
    Section(),
    Color(),
    Font(),
    Logo(),
    Data(),
    Editable(),
    Route(),
    General(),
    Menu(),
    Footer(),
    Contact(),
    Page()
  ]);
}
