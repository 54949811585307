import { put, select } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
import cloneDeep from "lodash.clonedeep";
import websiteClass from "../../../../helpers/api/website.class";
import { all, call } from "redux-saga/effects";
import { ErrorApp } from "helpers/classes/ErrorApp";
import actions from "redux/actions";

export default function* update({
  payload
}: ReturnType<(typeof actions.api)["booking"]["actions"]["update"]["request"]>): Generator<any, void, any> {
  try {
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      plugins: cloneDeep(WebsiteWizardReducer.plugins)
    });

    const { plugins }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    // call api to update each category/service
    const categoriesApiCall = plugins.booking.categories.toUpdate
      .filter((category) => {
        // make sure current api contains category using id.
        const pluginExistsInApi = plugins.booking.categories.data.find(
          (dataCategory) => dataCategory.id === category.id
        );
        return pluginExistsInApi;
      })
      .map((category) => {
        return call(websiteClass.websiteUpdateBookingCategory, {
          categoryId: category.id,
          description: category.description,
          name: category.name
        });
      });

    yield all(categoriesApiCall);
    plugins.booking.categories.toUpdate = [];
    // call api to update each category/service
    const servicesApiCall = plugins.booking.services.toUpdate
      .filter((service) => {
        return call(async () => {
          // make sure current api contains service using id.
          const pluginExistsInApi = plugins.booking.services.data.find((dataService) => dataService.id === service.id);
          return pluginExistsInApi;
        });
      })
      .map((service) => {
        const { categoryId, ...restService } = service;
        return call(websiteClass.websiteUpdateService, {
          serviceId: service.id,
          service: {
            ...restService,
            category: categoryId
          }
        });
      });
    yield all(servicesApiCall);

    plugins.booking.services.toUpdate = [];
    yield put(actions.api.booking.actions.update.success({ plugins }));
  } catch (error) {
    yield put(
      actions.api.booking.actions.update.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
