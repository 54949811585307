import React, { useState, useEffect, useRef } from "react";
import { RootState } from "../../../redux/reducers";
import { GLOBAL_VARS } from "helpers/global";
import { errorWizard } from "../../classes/ErrorApp";
import { ModelWebsite } from "helpers/models";
import {
  EditableImage,
  EditableText,
  EditableBackgroundImage,
  EditableVideo,
  EditableBackgroundVideo,
  EditableObject,
  EditablePlugin,
  EditableWrapper,
  EditableHeader,
  EditableFooter,
  EditableLink,
  EditableButton,
  EditableAudio,
  EditableMap,
  EditableIcon
} from "blocks/editables";
import { positionViewString } from "helpers/functions";
import EditableForm from "blocks/editables/editable.form/editable.form";

interface UseEditableV1Props {
  iframeWebsite?: HTMLIFrameElement;
  website: RootState["Wizard"]["website"];
  validation: RootState["Wizard"]["validation"];
  pageId: WebsitePage["id"];
  plugins: RootState["Wizard"]["plugins"];
  error: RootState["Wizard"]["error"];
  device: Device;
}

const useEditableV1 = ({ iframeWebsite, website, pageId, validation, plugins, device, error }: UseEditableV1Props) => {
  const [state, setState] = useState<{
    EditableElements: React.MemoExoticComponent<() => JSX.Element> | null;
    textFocus: boolean;
  }>({
    EditableElements: null,
    textFocus: false
  });

  const { EditableElements, textFocus } = state;

  const testEditables = useRef<WebsiteDataPath[]>([]);
  /**
   * @description remove all editables from the DOM.
   */
  const onClearEditables = () => {
    setState((state) => ({
      ...state,
      EditableElements: null
    }));
    testEditables.current = [];
  };

  /**
   * @description unhide text inside iframe when editing
   */
  const removeHiddenText = () => {
    const editableHidden = iframeWebsite?.contentDocument?.querySelectorAll(".editable-hidden");
    if (!editableHidden?.length) {
      return;
    }
    for (let i = 0; i < editableHidden.length; i++) {
      if (editableHidden[i].classList.contains("editable-hidden")) {
        editableHidden[i].classList.remove("editable-hidden");
      }
    }

    setState((state) => ({
      ...state,
      textFocus: false
    }));
  };

  const onRemoveEditableElement = (options: { textFocus: boolean; dataPath?: string; show?: boolean }) => {
    if (options.textFocus || !EditableElements) {
      return;
    }
    onClearEditables();
  };

  const onMouseMove = (e: MouseEvent) => {
    const editableList: {
      dataClass: WebsiteDataClass;
      dataPath?: WebsiteDataPath;
      Component: JSX.Element;
    }[] = [];

    //targets to be displayed, filtered from targets [data-class].
    const triggeredTargets: HTMLElement[] = [];

    // do not allow to add targets if iframe window and document isn't defined.
    if (!iframeWebsite || !iframeWebsite?.contentWindow || !iframeWebsite.contentDocument) {
      return;
    }

    // get all targets.
    const targets = iframeWebsite.contentDocument.querySelectorAll<HTMLElement>("[data-class]");

    if (!targets?.length) {
      return;
    }

    for (let i = 0; i < targets.length; i++) {
      const target = targets[i];

      if (!website?.page?.sections?.length || !target) {
        return;
      }

      const elementRect = target.getBoundingClientRect();

      //get mouse position
      const mousePosition = {
        x: e.x,
        y: e.y
      };

      //get element square position.
      const elementPosition = {
        x: {
          from: elementRect.x,
          to: elementRect.x + elementRect.width
        },
        y: {
          from: elementRect.y,
          to: elementRect.y + elementRect.height
        }
      };

      //rather show or not the element.
      let show = false;
      const atX = mousePosition.x >= elementPosition.x.from && mousePosition.x <= elementPosition.x.to;
      const atY = mousePosition.y >= elementPosition.y.from && mousePosition.y <= elementPosition.y.to;

      //set show or not element based on mouse position over the element.
      if (atX && atY) {
        show = true;
      } else {
        show = false;
      }

      removeHiddenText();

      if (show) {
        // Attempt to check if element is hidden in someway.

        let hidden = false;
        let testTarget: HTMLElement = target;

        while (testTarget.tagName.toLowerCase() !== "html") {
          const copt = iframeWebsite.contentWindow.getComputedStyle(testTarget);
          hidden = Boolean(copt?.display === "none" || copt?.opacity === "0" || copt?.visibility === "hidden");
          if (hidden) {
            break;
          }
          testTarget = testTarget.parentElement as HTMLElement;
        }

        if (hidden === false) {
          triggeredTargets.push(target);
        }
      }
    }

    for (let i = 0; i < triggeredTargets.length; i++) {
      const target = triggeredTargets[i];

      if (textFocus || !website?.page?.sections?.length || !target) return;

      const dataClass = target.getAttribute("data-class") as ModifyConfig["dataClass"];

      const dataPath = target.getAttribute("data-path");

      if (dataClass && dataPath) {
        // const positionY = e.clientY;
        // const positionX = e.clientX;

        // const greaterThanScreenSizeHeight = positionY >= (iframeWebsite.contentWindow.innerHeight || 0) / 2;
        // const greaterThanScreenSizeWidth = positionX >= (iframeWebsite.contentWindow.innerWidth || 0) / 2;

        const viewPosition: EditableViewPosition = {
          top: true, // greaterThanScreenSizeHeight,
          bottom: false, //!greaterThanScreenSizeHeight,
          left: false, //greaterThanScreenSizeWidth,
          right: true // !greaterThanScreenSizeWidth
        };

        const viewPositionString: ViewPositionString = positionViewString(viewPosition);
        const modelWebsite = new ModelWebsite(website);
        switch (dataClass) {
          case "text":
            try {
              const editableObjectValidation: ValidateClassText | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "text") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableText
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                          onFocus={(e) => {
                            setState((state) => ({
                              ...state,
                              textFocus: true
                            }));
                          }}
                          onBlur={() => {
                            setState((state) => ({
                              ...state,
                              textFocus: false
                            }));
                          }}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "link":
            try {
              const editableObjectValidation: ValidateClassLink | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "link") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableLink
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (e) {
              console.error(error);
            }
            break;

          case "button":
            try {
              const editableObjectValidation: ValidateClassButton | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "button") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableButton
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (e) {
              console.error(e);
            }
            break;

          case "image":
            try {
              const editableObjectValidation: ValidateClassImage | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "image") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableImage
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "background-image":
            try {
              const editableObjectValidation: ValidateClassBackgroundImage | undefined =
                modelWebsite.getEditableValidation({
                  pageId: pageId,
                  validation: validation,
                  dataPath: dataPath,
                  dataClass: dataClass
                });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "background-image") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableBackgroundImage
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "video":
            try {
              const editableObjectValidation: ValidateClassVideo | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "video") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableVideo
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "audio":
            try {
              const editableObjectValidation: ValidateClassAudio | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for AUDIO if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "audio") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableAudio
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "background-video":
            try {
              const editableObjectValidation: ValidateClassBackgroundVideo | undefined =
                modelWebsite.getEditableValidation({
                  pageId: pageId,
                  validation: validation,
                  dataPath: dataPath,
                  dataClass: dataClass
                });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "background-video") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableBackgroundVideo
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "form":
            try {
              const editableObjectValidation: ValidateClassForm | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "form") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableForm
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "map":
            try {
              const editableObjectValidation = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "map") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditableMap
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "icon":
            try {
              const editableObjectValidation = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "icon") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return <EditableIcon dataClass={dataClass} dataPath={dataPath} />;
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }
            break;

          case "object":
            try {
              const editableObjectValidation: (ValidateClassObject & ValidateTypeObject) | undefined =
                modelWebsite.getEditableValidation({
                  pageId: pageId,
                  validation: validation,
                  dataPath: dataPath,
                  dataClass: dataClass
                });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "object" && editableObjectValidation?.typeof !== "object") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              // check if element is wrapped in data-class="group"
              let isGroup = false;
              const allGroups = iframeWebsite.contentDocument.querySelectorAll("[data-class='group']");

              if (allGroups?.length) {
                for (let i = 0; i < allGroups?.length; i++) {
                  const dataGroupEl = allGroups[i];

                  if (dataGroupEl.querySelector(`[data-path="${dataPath}"]`)) {
                    isGroup = true;
                    break;
                  }
                }
              }

              let offsetTarget = target;
              let testTarget = target;

              while (testTarget?.parentElement) {
                if (
                  testTarget.parentElement?.getAttribute("data-path") === dataPath &&
                  testTarget.parentElement?.getAttribute("data-class") === dataClass
                ) {
                  offsetTarget = testTarget.parentElement;
                }
                testTarget = testTarget.parentElement;
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={offsetTarget}>
                    {(props) => (
                      <EditableObject
                        wrapperProps={props}
                        iframeWebsite={iframeWebsite}
                        target={target}
                        dataClass={dataClass}
                        dataPath={dataPath}
                        viewPositionString={viewPositionString}
                        viewPosition={viewPosition}
                        editableObjectValidation={editableObjectValidation}
                        onClearEditables={onClearEditables}
                        isGroup={isGroup}
                      />
                    )}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }

            break;

          case "plugin":
            try {
              const editableObjectValidation: ValidateClassPlugin | undefined = modelWebsite.getEditableValidation({
                pageId: pageId,
                validation: validation,
                dataPath: dataPath,
                dataClass: dataClass
              });

              // check validation for object if exists and if it's of object class.
              if (editableObjectValidation?.classof !== "plugin") {
                console.error("[Wizard error]: Attempt to open object element without valid validation.");
                break;
              }

              //validate plugin.
              if (!GLOBAL_VARS.editables.plugins.availablePlugins.includes(editableObjectValidation.type)) {
                errorWizard(
                  `Invalid plugin name for ${dataPath}, plugin ${editableObjectValidation.type} does not exist.`
                );
                return;
              }

              // check if element is wrapped in data-class="group"
              let isGroup = false;
              const allGroups = iframeWebsite.contentDocument.querySelectorAll("[data-class='group']");

              if (allGroups?.length) {
                for (let i = 0; i < allGroups?.length; i++) {
                  const dataGroupEl = allGroups[i];

                  if (dataGroupEl.querySelector(`[data-path="${dataPath}"]`)) {
                    isGroup = true;
                    break;
                  }
                }
              }

              editableList.push({
                dataClass: dataClass,
                dataPath: dataPath,
                Component: (
                  <EditableWrapper
                    device={device}
                    dataPath={dataPath}
                    dataClass={dataClass}
                    iframeWebsite={iframeWebsite}
                    target={target}>
                    {(props) => {
                      return (
                        <EditablePlugin
                          wrapperProps={props}
                          iframeWebsite={iframeWebsite}
                          target={target}
                          dataClass={dataClass}
                          dataPath={dataPath}
                          viewPositionString={viewPositionString}
                          viewPosition={viewPosition}
                          editableObjectValidation={editableObjectValidation}
                          isGroup={isGroup}
                        />
                      );
                    }}
                  </EditableWrapper>
                )
              });
            } catch (error) {
              console.error(error);
            }

            break;
          default:
            break;
        }
      } else if (dataClass) {
        // const positionY = e.clientY;

        // const greaterThanScreenSizeHeight = window.innerHeight - positionY <= target.offsetHeight;

        // let viewPosition: EditableViewPosition = {
        //     top: greaterThanScreenSizeHeight,
        //     bottom: !greaterThanScreenSizeHeight,
        //     right: false,
        //     left: false,
        // };

        switch (dataClass) {
          case "menu":
            editableList.push({
              dataClass: dataClass,
              Component: (
                <EditableWrapper device={device} dataClass={dataClass} iframeWebsite={iframeWebsite} target={target}>
                  {() => {
                    return <EditableHeader iframeWebsite={iframeWebsite} editable={target} dataClass={dataClass} />;
                  }}
                </EditableWrapper>
              )
            });
            break;

          case "footer":
            editableList.push({
              dataClass: dataClass,
              Component: (
                <EditableWrapper device={device} dataClass={dataClass} iframeWebsite={iframeWebsite} target={target}>
                  {() => {
                    return <EditableFooter iframeWebsite={iframeWebsite} editable={target} dataClass={dataClass} />;
                  }}
                </EditableWrapper>
              )
            });
            break;
        }
      }
    }

    if (editableList.length) {
      const globals: WebsiteDataClass[] = ["menu", "footer"];
      const editableNonGlobals = editableList.filter((item) => !globals.includes(item.dataClass)) as Required<
        ReturnType<() => (typeof editableList)[0]>
      >[];
      const editableGlobals = editableList.filter((item) => globals.includes(item.dataClass)) as Omit<
        ReturnType<() => (typeof editableList)[0]>,
        "dataPath"
      >[];
      const testNonGlobals = editableNonGlobals.map((item) => item.dataPath);
      const testGlobals = editableGlobals.map((item) => item.dataClass);

      const testAll = [...testGlobals, ...testNonGlobals];

      if (JSON.stringify(testEditables.current) !== JSON.stringify(testAll)) {
        testEditables.current = testAll;
        const EditableElements = React.memo(() => {
          return (
            <>
              {editableList.map((e, index) => {
                const Element = () => e.Component;
                // 🚩 We may want to improve the key here.
                return <Element key={e.dataPath || `${e.dataClass}_${index}`} />;
              })}
            </>
          );
        });
        setState((state) => ({
          ...state,
          EditableElements: EditableElements
        }));
      }
    } else {
      if (EditableElements !== null) {
        onClearEditables();
      }
    }
  };

  useEffect(() => {
    if (!iframeWebsite?.contentDocument || !iframeWebsite?.contentWindow) {
      return;
    }

    const onMovingMouse = (e: MouseEvent) => {
      if (textFocus || !website?.page?.sections?.length) {
        return;
      }
      onMouseMove(e);
    };

    const onKill = (e: Event) => {
      onClearEditables();
      removeHiddenText();
    };

    //improvement of editables.
    iframeWebsite.contentDocument.addEventListener("mousemove", onMovingMouse);
    // iframeWebsite.contentDocument.addEventListener("scroll", onKill);
    // iframeWebsite.contentDocument.addEventListener("resize", onKill);
    window.addEventListener("resize", onKill);
    return () => {
      if (!iframeWebsite?.contentDocument || !iframeWebsite?.contentWindow) {
        return;
      }

      iframeWebsite.contentDocument.removeEventListener("mousemove", onMovingMouse);
      // iframeWebsite.contentDocument.removeEventListener("scroll", onKill);
      // iframeWebsite.contentDocument.removeEventListener("resize", onKill);
      window.removeEventListener("resize", onKill);
    };
  });

  useEffect(() => {
    //this block is watching for sidebar have a .show class, if it does it means it's displaying class
    // when this happens we do not want to allow the user to modify anything on the right side.
    const interval = setInterval(() => {
      const sidebarOpen = document.querySelector(".wizard-sidebar.show");
      if (sidebarOpen) {
        onRemoveEditableElement({ textFocus });
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // clear editable elements when device changes.
    return () => {
      if (device.type) {
        onClearEditables();
        removeHiddenText();
      }
    };
  }, [device.type]);

  useEffect(() => {
    return () => {
      //clear editable elements when page changes.
      onClearEditables();
      removeHiddenText();
    };
  }, [website.page.id]);

  return state;
};

export default useEditableV1;
