import { CallEffect, delay, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { RootState } from "../../../reducers";
// import { ErrorResponse } from "../../../../helpers/classes/ErrorResponse";
import { createErrorApp, ErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";
import { ModelWebsite } from "helpers/models";
import cloneDeep from "lodash.clonedeep";
import { theme } from "redux/actions";

/**
 * @description update a page route.
 */
export default function* updatePageRoute({
  payload
}: ReturnType<(typeof theme)["route"]["actions"]["updatePageRoute"]["request"]>): Generator<
  Promise<File> | CallEffect | CallEffect<true> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  const codeException = "PageRouteException";

  try {
    yield delay(3000);

    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website,
      pages: cloneDeep(WebsiteWizardReducer.pages),
      validation: WebsiteWizardReducer.validation
    });

    const { website, validation }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    let { pages }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);

    const modelWebsite = new ModelWebsite(website);
    // const modelValiadation = new ModelValidation(validation);

    const { pageRoute, type } = payload;

    const actualPageRoute = modelWebsite.routes?.list.find((i) => i.pageName === pageRoute.pageName);

    if (!modelWebsite.routes) {
      throw createErrorApp("Website does not contain routes data information.", {
        code: codeException
      });
    } else if (!actualPageRoute) {
      return;
    }

    switch (type) {
      case "custom":
        if (!pageRoute.path) {
          throw createErrorApp(`Route path is required.`, {
            code: codeException
          });
        } else if (pageRoute.path.toLowerCase() === "/api") {
          throw createErrorApp(`You cannot use this route.`, {
            code: codeException
          });
        } else if (/\s/g.test(pageRoute.path)) {
          throw createErrorApp(`Route path cannot have spaces.`, {
            code: codeException
          });
        } else if (actualPageRoute?.path === "/") {
          throw createErrorApp(`Home page cannot be changed.`, {
            code: codeException
          });
        } else {
          // check for duplications.
          if (
            modelWebsite.routes?.list.find(
              (item) => item.pageName !== pageRoute.pageName && item.path === pageRoute.path
            )
          ) {
            throw createErrorApp(
              pageRoute.path === "/" ? `You cannot use this route.` : `Routes cannot have same path.`,
              {
                code: codeException
              }
            );
          }
        }
        break;

      case "home":
        if (pageRoute.path !== "/") {
          throw createErrorApp(`Attempt to set wrong home page path.`, {
            code: codeException
          });
        } else if (!validation.routes.attributes.list.items.rules.index) {
          throw createErrorApp(`Home page cannot be changed for this theme.`, {
            code: codeException
          });
        } else if (
          !validation.routes.attributes.list.items.rules.index.find((pageName) => pageName === pageRoute.pageName)
        ) {
          throw createErrorApp(`This page cannot be set as home page.`, {
            code: codeException
          });
        }
        break;
    }

    let websiteCopy = modelWebsite.toJSON(modelWebsite); // JSON.stringify(website);
    let pagesCopy = JSON.stringify(pages);
    if (type === "home") {
      /**
       * This block will replace the current home page to a new route path.
       */

      const pageObject = pages.find((p) => p.name === pageRoute?.pageName);

      //create new path route for the actual home page.
      const newPath = `/${pageObject?.name?.toLowerCase().replace(/\s/g, "-")}`;
      // check if new path already exists
      const exists = modelWebsite.routes?.list.filter((i) => i.path === newPath);

      const path = exists?.length ? `/${newPath}_${exists.length + 1}` : newPath;
      // Fetch href & path attribute on JSON string and replace with the right path route.
      websiteCopy = websiteCopy.replace(
        GLOBAL_VARS.validaton.regex.extractFromJson("href", pageRoute.path),
        `"href":"${path}"`
      );
      websiteCopy = websiteCopy.replace(
        GLOBAL_VARS.validaton.regex.extractFromJson("path", pageRoute.path),
        `"path":"${path}"`
      );

      const websiteJSON = modelWebsite.parseJSON(websiteCopy);
      if (websiteJSON) {
        modelWebsite.update(modelWebsite, websiteJSON);
      }

      pagesCopy = pagesCopy.replace(
        GLOBAL_VARS.validaton.regex.extractFromJson("href", pageRoute.path),
        `"href":"${path}"`
      );
      pages = JSON.parse(pagesCopy);
    }

    // Fetch href & path attribute on JSON string and replace with the right path route.
    websiteCopy = websiteCopy.replace(
      GLOBAL_VARS.validaton.regex.extractFromJson("href", actualPageRoute.path),
      `"href":"${pageRoute.path}"`
    );
    websiteCopy = websiteCopy.replace(
      GLOBAL_VARS.validaton.regex.extractFromJson("path", actualPageRoute.path),
      `"path":"${pageRoute.path}"`
    );

    const websiteJSON = modelWebsite.parseJSON(websiteCopy);
    if (websiteJSON) {
      modelWebsite.update(modelWebsite, websiteJSON);
    }

    pagesCopy = pagesCopy.replace(
      GLOBAL_VARS.validaton.regex.extractFromJson("href", actualPageRoute.path),
      `"href":"${pageRoute.path}"`
    );
    pages = JSON.parse(pagesCopy);

    yield put(
      theme.route.actions.updatePageRoute.success({
        pages: pages,
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.route.actions.updatePageRoute.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
