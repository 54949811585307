import {
  Button,
  Color,
  Column,
  FormControl,
  InputRangePercentage,
  InputSwitch,
  Row,
  Textarea
} from "@soltivo/draw-a-line";
import { Info24Px, Trash } from "@soltivo/draw-a-line/core/components/icons";
import InputImage from "components/input.image/input.image";
import { GLOBAL_VARS } from "helpers/global";
import { FC } from "react";
import Unsplash from "components/unsplash/unsplash";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";

interface AudioWindowProps extends EditableWindowProps<"audio"> {}

const AudioWindow: FC<React.PropsWithChildren<AudioWindowProps>> = ({ dataClass, dataPath }) => {
  const { onStartWindowMenu, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_AUDIO.key);

  const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

  /**
   * @description change inputs
   */
  const onChange = (name: keyof WebsiteAudio, value: any) => {
    onUpdate({
      ...data,
      [name]: value
    });
  };

  if (!windowMenu || !data || !validation) {
    return null;
  }

  return (
    <Row>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                <span>Url</span>

                <span data-title-position="bottom-left" data-title={`Available: Youtube, Sound Cloud & Mix Cloud.`}>
                  <Info24Px width={16} height={16} color={Color["bluemine"]["bluemine-500"]} />
                </span>
              </div>
            )
          }}>
          <Textarea
            onDoubleClick={() => {
              if (GLOBAL_VARS.validaton.regex.httpsUrl.test(data.src)) window.open(data.src, "_blank");
            }}
            onClick={(e) => {
              e.currentTarget.select();
            }}
            name="src"
            placeholder={`Example: https://youtube.com?watch={key}`}
            value={data.src}
            onChange={(e) => onChange("src", e.target.value)}
            style={{
              resize: "none",
              color: Color["bluemine"]["bluemine-500"],
              textDecoration: "underline",
              cursor: "pointer"
            }}
          />
        </FormControl>
      </Column>
      <Column size={"12"}>
        <hr
          style={{
            margin: "10px 0",
            border: `1px solid ${Color["border"]["stroke"]}`
          }}
        />
      </Column>
      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}>
                <span>Thumbnail</span>{" "}
                {data.thumbnail ? (
                  <Button
                    onClick={() => {
                      onChange("thumbnail", "");
                    }}
                    data-title-position="bottom-left"
                    data-title={`Use default audio thumbnail.`}
                    border={false}
                    padding={false}
                    outline
                    customButton={{
                      textColor: "primary",
                      backgroundColor: "white"
                    }}>
                    <Trash />
                  </Button>
                ) : null}
              </div>
            )
          }}>
          <InputImage
            name="thumbnail"
            style={{
              maxWidth: "100%",
              height: windowMenu?.size === "medium" ? "240px" : "140px",
              maxHeight: windowMenu?.size === "medium" ? "240px" : "140px"
            }}
            value={data.thumbnail}
            onLibrary={(e) => {
              onStartWindowMenu({
                id: WINDOW_MENU_KEYS.UNSPLASH.key,
                event: e,
                size: "medium",
                acceptSizes: ["medium"],
                title: "Select an image",
                Component: () => {
                  return (
                    <Unsplash
                      columns={2}
                      menu={true}
                      onSelectImage={(imageUrl) => {
                        onChange("thumbnail", imageUrl);
                      }}
                    />
                  );
                }
              });
            }}
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) onChange("thumbnail", file);
            }}
          />
        </FormControl>
      </Column>

      <Column size={"12"}>
        <hr
          style={{
            margin: "10px 0",
            border: `1px solid ${Color["border"]["stroke"]}`
          }}
        />
      </Column>

      <Column size={"12"}>
        <FormControl
          labelProps={{
            value: "Volume"
          }}>
          <InputRangePercentage
            variant="primary"
            onChange={({ name, value: percent }) => {
              if (validation.attributes.volume?.rules.disabled) {
                return;
              }
              const value = (percent / 100) * 2;

              onChange("volume", value > 2 ? 2 : value < 0 ? 0 : value);
            }}
            name={"volume"}
            style={{
              opacity: validation.attributes.volume?.rules.disabled ? "0.5" : "1",
              pointerEvents: validation.attributes.volume?.rules.disabled ? "none" : "all"
            }}
            value={Math.round((data.volume / 2) * 100)}
          />
        </FormControl>
      </Column>

      <Column size={"12"}>
        <hr
          style={{
            margin: "10px 0",
            border: `1px solid ${Color["border"]["stroke"]}`
          }}
        />
      </Column>

      <Column size={"6"}>
        <FormControl
          labelProps={{
            value: "Controls"
          }}>
          <div>
            <InputSwitch
              checked={data.controls}
              disabled={validation.attributes.controls?.rules.disabled}
              placeholder={`Show controls`}
              onChange={(e) => {
                onChange("controls", e.target.checked);
              }}
            />
          </div>
        </FormControl>
      </Column>

      <Column size={"6"}>
        <FormControl
          labelProps={{
            value: "light"
          }}
          footerProps={{
            value: "Show audio thumbnail.",
            align: "left"
          }}>
          <div>
            <InputSwitch
              checked={data.light}
              disabled={validation.attributes.light?.rules.disabled}
              placeholder={`Show audio thumbnail`}
              onChange={(e) => {
                onChange("light", e.target.checked);
              }}
            />
          </div>
        </FormControl>
      </Column>

      <Column size={"6"}>
        <FormControl
          labelProps={{
            value: "Repeat"
          }}>
          <div>
            <InputSwitch
              checked={data.loop}
              disabled={validation.attributes.loop?.rules.disabled}
              placeholder={`Repeat audio in loop`}
              onChange={(e) => {
                onChange("loop", e.target.checked);
              }}
            />
          </div>
        </FormControl>
      </Column>

      <Column size={"6"}>
        <FormControl
          labelProps={{
            value: "Muted"
          }}>
          <div>
            <InputSwitch
              checked={data.muted}
              disabled={validation.attributes.muted?.rules.disabled}
              placeholder={"Remove track audio."}
              onChange={(e) => {
                onChange("muted", e.target.checked);
              }}
            />
          </div>
        </FormControl>
      </Column>

      <Column size={"6"}>
        <FormControl
          labelProps={{
            value: "Auto play"
          }}>
          <div>
            <InputSwitch
              checked={data.playing}
              disabled={validation.attributes.playing?.rules.disabled}
              placeholder={"Automatically play audio."}
              onChange={(e) => {
                onChange("playing", e.target.checked);
              }}
            />
          </div>
        </FormControl>
      </Column>

      <Column size={"12"}>
        <hr
          style={{
            margin: "10px 0",
            border: `1px solid ${Color["border"]["stroke"]}`
          }}
        />
      </Column>
    </Row>
  );
};

export default AudioWindow;
