import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import styles from "./styles.module.scss";
import { SketchPicker } from "react-color";
import { Color } from "@soltivo/draw-a-line";
import actions from "redux/actions";
import tinycolor2 from "tinycolor2";

interface ColorsProps {}

const Colors: FC<React.PropsWithChildren<ColorsProps>> = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard }: RootState) => ({
    colors: Wizard.website.colors
  });

  const { colors } = useSelector(mapStateToProps);

  const { onStartWindowMenu } = WindowMenu.useWindowMenu();

  const onOpenColorPicker = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, colorKey: string) => {
    onStartWindowMenu({
      id: `${WINDOW_MENU_KEYS.COLOR_PICKER.key}_${colorKey}`,
      event: e,
      title: `${colorKey.replace(/([A-Z])/g, " $1").replace(/(-|_)/g, " ")} color`,
      size: "medium",
      acceptSizes: ["medium"],
      width: 320,
      Component: () => {
        const mapStateToProps = ({ Wizard }: RootState) => ({
          colors: Wizard.website.colors,
          prevColors: Wizard.prevColors
        });

        const { colors, prevColors } = useSelector(mapStateToProps);
        return (
          <div
            style={{
              width: "100%",
              position: "relative"
            }}>
            <SketchPicker
              presetColors={[]}
              color={colors[colorKey]}
              onChange={(color) => {
                dispatch(
                  actions.theme.color.actions.update.request({
                    inputName: colorKey,
                    color: tinycolor2(color.rgb).toHex8String()
                  })
                );
              }}
              styles={{
                default: {
                  color: {
                    display: "none"
                  },
                  hue: {
                    borderRadius: "4px",
                    margin: "8px 0",
                    border: `1px solid ${Color["border"]["stroke"]}`
                  },
                  saturation: {
                    borderRadius: "4px",
                    border: `2px solid ${Color["border"]["stroke"]}`
                  },
                  alpha: {
                    borderRadius: "4px",
                    margin: "0 0 8px 0",
                    border: `1px solid ${Color["border"]["stroke"]}`
                  },
                  picker: {
                    borderRadius: "4px",
                    fontFamily: "Poppins, sans-serif",
                    boxShadow: "none",
                    background: Color["background"]["bg-body"],
                    width: "100%",
                    padding: 0
                  }
                }
              }}
            />

            <p className={styles.label}>History:</p>

            <div className={styles.prevColors}>
              {Object.keys(prevColors).map((key) => {
                if (key !== colorKey) return null;

                return prevColors[key]?.slice(0, 7).map((prevColor, index) => (
                  <div key={index} className={`${styles.item} `}>
                    <span
                      onClick={() => {
                        // onChangeColor(prevColor);
                      }}
                      className={styles.square}
                      style={{ backgroundColor: prevColor }}></span>
                  </div>
                ));
              })}
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className={styles.component}>
      {colors
        ? Object.keys(colors).map((colorKey, index) => {
            if (!colors?.[colorKey]) return null;
            return (
              <div key={index} className={`${styles.item} `}>
                <span
                  onClick={(e) => {
                    onOpenColorPicker(e, colorKey);
                  }}
                  className={styles.square}
                  style={{ backgroundColor: colors[colorKey] }}></span>
                <span>{colorKey.replace(/([A-Z])/g, " $1").replace(/(-|_)/g, " ")}</span>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Colors;
