import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

/**
 * @description Authentication API calls.
 */
export class AuthClass {
  constructor() {
    if (process.env.NODE_ENV !== "production") {
      Hub.listen(/.*/, (data) => {
        console.log(data);
      });
    }
  }

  /**
   * @description current authenticated CognitoUser with bypassCache
   */
  public currentAuthenticatedUser(): Promise<CognitoUser> {
    return Auth.currentAuthenticatedUser({
      bypassCache: true
    });
  }

  /**
   * @description current authenticated CognitoUser
   */
  public currentUserPoolUser(): Promise<CognitoUser> {
    return Auth.currentUserPoolUser();
  }

  /**
   * @description refresh session token
   */
  public async refreshSessionToken(calback?: () => void): Promise<undefined> {
    const cognitoUser = await this.currentAuthenticatedUser();

    return new Promise(async (resolve, reject) => {
      const signInUserSession = cognitoUser.getSignInUserSession();

      if (!signInUserSession) {
        return reject(new Error("Failed to refresh session token without current session."));
      }

      const refreshToken = signInUserSession.getRefreshToken();

      cognitoUser.refreshSession(
        refreshToken,
        (err, session: { accessToken: any; idToken: any; refreshToken: any } | null) => {
          if (err) {
            return reject(err);
          }

          if (!session) {
            return reject(new Error("Failed to create a new session token."));
          }
          const { accessToken, idToken } = session;
          localStorage.setItem("token", accessToken.jwtToken);
          localStorage.setItem("idToken", idToken.jwtToken);
          if (calback) {
            calback();
          }
          resolve(undefined);
        }
      );
    });
  }
}

export default new AuthClass();
