import styles from "./styles.module.scss";
import { Button, Checkbox, Column, FormControl, InputText, Row, Textarea } from "@soltivo/draw-a-line";
import { Fragment } from "react";
import { PluginWindowState } from "../../plugin.window";
import { useDispatch } from "react-redux";
import WindowMenu from "components/window.menu";
import { WINDOW_MENU_KEYS } from "components/window.menu/constants";
import useEditableData from "helpers/hooks/useEditableData";
import actions from "redux/actions";

interface CategoryViewProps extends EditableWindowProps<"plugin"> {
  state: PluginWindowState;
  setState: React.Dispatch<React.SetStateAction<PluginWindowState>>;
}

const CategoryView = ({ state, setState, dataClass, dataPath }: CategoryViewProps) => {
  const dispatch = useDispatch();

  const { data, validation } = useEditableData({ dataClass, dataPath });

  const { useFooter, getWindowMenuById } = WindowMenu.useWindowMenu();

  const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

  const currentWindowPage = windowMenu?.pagination.pages.find(
    (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY
  );

  const Footer = () => {
    const { data, validation, onUpdate } = useEditableData({ dataClass, dataPath });

    const { onMoveToPage, onClose, getWindowMenuById } = WindowMenu.useWindowMenu();

    const windowMenu = getWindowMenuById(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);

    const currentWindowPage = windowMenu?.pagination.pages.find(
      (page) => page.id === WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORY
    );

    if (!windowMenu || !currentWindowPage?.visible || !validation || !data) {
      return <></>;
    }

    const disabledNext = !state.category;

    return (
      <Fragment>
        <Button
          onClick={() => {
            onMoveToPage({
              id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
              pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_CATEGORIES
            });
          }}
          outline
          variant="primary">
          Back
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (disabledNext) return;
            if (state.category) {
              // update editable element
              if (validation.type === "booking/category") {
                onUpdate(state.category);
              }
              // store changes to be saved inside api on publish
              if (state.pluginUpdate) {
                dispatch(
                  actions.theme.editable.actions.pluginStore.request({
                    type: validation.type,
                    value: state.category
                  })
                );
              }

              if (validation.type === "booking/service") {
                onMoveToPage({
                  id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
                  pageId: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.pageKeys.BOOKING_SERVICES
                });
              } else {
                onClose(WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key);
              }
            }
          }}
          disabled={disabledNext}>
          {validation.type === "booking/category" ? "Insert" : "Next"}
        </Button>
      </Fragment>
    );
  };

  useFooter(
    {
      id: WINDOW_MENU_KEYS.EDITABLE_PLUGIN.key,
      component: () => <Footer />
    },
    [state]
  );

  if (!windowMenu || !currentWindowPage?.visible || !data || !validation) {
    return <></>;
  }

  return (
    <div className={styles.component}>
      <form name="categoryForm">
        <Row>
          <Column size={"12"}>
            <FormControl
              labelProps={{
                value: "Title"
              }}>
              <InputText
                name="name"
                placeholder="Title"
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    category: state.category
                      ? {
                          ...state.category,
                          name: e.target.value
                        }
                      : undefined
                  }))
                }
                value={state.category?.name || ""}></InputText>
            </FormControl>
          </Column>

          <Column size={"12"}>
            <FormControl
              labelProps={{
                value: "Description"
              }}>
              <Textarea
                name="description"
                placeholder="Description"
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    category: state.category
                      ? {
                          ...state.category,
                          description: e.target.value
                        }
                      : undefined
                  }))
                }
                value={state.category?.description || ""}></Textarea>
            </FormControl>
          </Column>

          <Column size={"12"}>
            <div className={styles.plugin__update__checkbox}>
              <Checkbox
                name="pluginUpdate"
                checked={state.pluginUpdate}
                onChange={(e) => setState((state) => ({ ...state, pluginUpdate: e.target.checked }))}
              />
              <p>Update the category on my listing</p>
            </div>
          </Column>
        </Row>
      </form>
    </div>
  );
};

export default CategoryView;
