import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassButton = (validation: ValidateClassButton, payload: WebsiteButton) => {
  if (typeof payload.value !== "string") {
    throw createErrorApp(`Button value is required.`);
  }

  if (payload.value === "") {
    throw createErrorApp(`Button value cannot be empty.`);
  }

  if (typeof payload.action !== "string") {
    throw createErrorApp(`Button action is required.`);
  }

  if (!GLOBAL_VARS.editables.button.actions.includes(payload.action)) {
    throw createErrorApp(`Button action is unknown.`);
  }

  if (!payload.type) {
    throw createErrorApp(`Button type is required.`);
  }

  if (!GLOBAL_VARS.editables.button.types.includes(payload.type)) {
    throw createErrorApp(`Button type is unknown.`);
  }

  if (payload.action === "window_open") {
    if (typeof payload.href !== "string") {
      throw createErrorApp(`Button window_open should have href defined as string.`);
    }

    if (
      !payload.href.startsWith("/") &&
      !new RegExp(GLOBAL_VARS.validaton.regex.httpsUrl).test(payload.href) &&
      payload.href !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
    ) {
      throw createErrorApp(`Button window_open should be a valid https website url or valid page on your website.`);
    }
  }

  if (validation.attributes.value?.rules.maxLength) {
    if (payload.value.length > validation.attributes.value?.rules.maxLength) {
      throw createErrorApp(
        `Button value length cannot have more than ${validation.attributes.value?.rules.maxLength} caracters.`
      );
    }
  }
  if (validation.attributes.value?.rules.minLength) {
    if (payload.value.length < validation.attributes.value?.rules.minLength) {
      throw createErrorApp(
        `Button value length cannot less more than ${validation.attributes.value?.rules.minLength} caracters.`
      );
    }
  }

  if (validation.attributes.type?.rules.acceptOnly) {
    if (!validation.attributes.type?.rules.acceptOnly.includes(payload.type)) {
      throw createErrorApp(
        `Button does not accept this type, accepted types: ${validation.attributes.type?.rules.acceptOnly.join(", ")}`
      );
    }
  }
};
