import { createErrorApp } from "helpers/classes/ErrorApp";

/**
 * @description validation footer class.
 */
export const ValidateClassFooter = (validation: Validation["footer"], payload: WebsiteData["footer"]) => {
  if (typeof payload.data !== "object") {
    throw createErrorApp(`Footer is missign data object.`);
  }

  // const validationDataAttributes = validation?.attributes?.data?.attributes;

  if (typeof payload.data.links !== "undefined") {
  }
};
