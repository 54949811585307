import { ErrorItem } from "@soltivo/types";
import { GLOBAL_VARS } from "helpers/global";

type ErrorResponsePayload = Partial<Omit<Error, "name">> &
  Partial<{
    exception: string;
    code: string | number;
    /**
     * @description this  comes sometimes from the server ...
     */
    error: {
      code: number;
      errors: Array<ErrorItem>;
      message: string;
    };
    fields: Array<ErrorItem>;
  }>;

/**
 * @description create wizard error object.
 */
class ErrorApp extends Error {
  constructor(payload: unknown) {
    super(payload as unknown as any);

    if (payload instanceof ErrorApp) {
      this.code = payload.code;
      this.exception = payload.exception;
      this.fields = payload.fields;
      this.message = payload.message;
      this.name = payload.name;
      this.stack = payload.stack;
    } else if (payload instanceof Error) {
      this.message = typeof payload.message === "string" ? payload.message : this.message;
    } else if (typeof payload === "string") {
      this.message = typeof payload === "string" ? payload : this.message;
    } else if (typeof payload === "object") {
      const validAttributes = payload as Partial<ErrorResponsePayload>;
      this.exception = typeof validAttributes?.exception === "string" ? validAttributes.exception : this.exception;
      this.code =
        typeof validAttributes.error === "object" && typeof validAttributes.error.code === "number"
          ? `${validAttributes.error.code}`
          : typeof validAttributes?.code === "string"
          ? validAttributes.code
          : typeof validAttributes?.code === "number"
          ? `${validAttributes?.code}`
          : this.code;
      this.message = typeof validAttributes?.message === "string" ? validAttributes.message : this.message;
      this.fields =
        typeof validAttributes.error === "object"
          ? Array.isArray(validAttributes.error.errors)
            ? validAttributes.error.errors
            : undefined
          : undefined;
    } else {
      this.exception = "AppUnknownException";
      this.message = GLOBAL_VARS.api.defaultErrorString;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorApp);
    }
  }

  name = "ErrorApp";
  exception = "AppException";
  message = GLOBAL_VARS.api.defaultErrorString;
  stack: string | undefined = "";
  code: string | undefined = "";
  fields: Array<ErrorItem> | undefined = undefined;

  public toObject() {
    return JSON.parse(
      JSON.stringify({
        name: this.name,
        message: this.message,
        code: this.code,
        exception: this.exception,
        fields: this.fields
      })
    );
  }

  public toString() {
    return JSON.stringify({
      name: this.name,
      message: this.message,
      code: this.code,
      exception: this.exception,
      fields: this.fields
    });
  }
}

// const createErrorApp = (error: ErrorResponsePayload) => {
//   return new ErrorApp(error);
// };

/**
 * @description create instance of ErrorApp
 */
const createErrorApp = (message: string, options?: Omit<ErrorResponsePayload, "message">): ErrorApp => {
  return new ErrorApp({
    message: message,
    ...options
  });
};

/**
 * @description wizard console log error for development of themes.
 */
const errorWizard = (
  message: string,
  options?: {
    code?: string;
  }
) => {
  if (process.env.NODE_ENV === "development") {
    if (options?.code) {
      console.log(
        `%c[Developer Error]: %c${message} \n%c[Code]: ${options.code}`,
        // Developer Error
        "font-weight:bold; color: rgb(65,105,225);",
        // Message color
        "color: rgb(65,105,225);",
        // Code color
        "color: rgb(255,0,0);"
      );
    } else {
      console.log(
        `%c[Developer Error]: %c${message}`,
        "font-weight:bold; color: rgb(65,105,225);",
        "color: rgb(65,105,225);"
      );
    }
  }
};

export { ErrorApp, createErrorApp, errorWizard };
