import { Button, InputSelectHybrid, InputText } from "@soltivo/draw-a-line";
import { Link24Px, LinkOff24Px } from "@soltivo/draw-a-line/core/components/icons";
import { bookingUrl } from "helpers";
import { GLOBAL_VARS } from "helpers/global";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ContentHeader from "components/content.header/content.header";
import VisibleSwitch from "components/visible.switch/visible.switch";
import actions from "redux/actions";

const ButtonView = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ Wizard, App }: RootState) => ({
    website: Wizard.website,
    orgId: App.organization.data.orgId,
    pages: Wizard.pages
  });
  const { website, pages, orgId } = useSelector(mapStateToProps);

  const [state, setState] = useState({
    // checks if href should be previewed as custom url or input select.
    customHref: !(
      website.menu.data.links?.items[0].href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER ||
      website.routes?.list.find((item) => {
        return website.menu.data.links?.items[0].href === item.path;
      }) ||
      website.menu.data.links?.items[0].href.startsWith("/")
    )
  });

  const onChange = (payload: { name: string; value: unknown }) => {
    if (!website.menu.data.links) {
      return;
    }

    const link = website.menu.data.links.items[0];

    if (payload.name === "value") {
      dispatch(
        actions.theme.menu.actions.update.request({
          menu: {
            links: {
              ...website.menu.data.links,
              items: [{ ...link, value: payload.value as string, title: payload.value as string }]
            }
          }
        })
      );
    } else if (payload.name === "href") {
      dispatch(
        actions.theme.menu.actions.update.request({
          menu: {
            links: {
              ...website.menu.data.links,
              items: [{ ...link, href: payload.value as string }]
            }
          }
        })
      );
    } else if (payload.name === "target") {
      dispatch(
        actions.theme.menu.actions.update.request({
          menu: {
            links: {
              ...website.menu.data.links,
              items: [{ ...link, target: payload.value as WebsiteLinkTarget }]
            }
          }
        })
      );
    } else {
      dispatch(
        actions.theme.menu.actions.update.request({
          menu: { links: { ...website.menu.data.links, [payload.name]: payload.value } }
        })
      );
    }
  };

  if (!website.menu.data.links) {
    return null;
  }

  const link = website.menu.data.links?.items[0];

  return (
    <div>
      <ContentHeader title="Button (Call to action)">
        <VisibleSwitch
          checked={website.menu.data.links.visible}
          onChange={(e) => {
            onChange({ name: "visible", value: e.target.checked });
          }}
        />
      </ContentHeader>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20
        }}>
        <div
          style={{
            display: "grid"
          }}>
          <label htmlFor="title">Text</label>
          <InputText
            id={"title"}
            name="value"
            disabled={!website.menu.data.links.visible}
            onChange={(e) => {
              onChange({ name: e.target.name, value: e.target.value });
            }}
            value={link.value}
            placeholder="Text content"
          />
        </div>

        <div
          style={{
            display: "grid"
          }}>
          <label htmlFor="href">Link</label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 24px",
              gap: 10
            }}>
            {state.customHref ? (
              <InputText
                name="href"
                type={"url"}
                disabled={!website.menu.data.links.visible}
                value={
                  link.href === GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
                    ? bookingUrl({ orgId: orgId, name: website.name, favicon: website.logo.favicon.src })
                    : link.href
                }
                onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
                placeholder="eg. https://example.com"
              />
            ) : (
              <InputSelectHybrid
                selectProps={{
                  name: "href",
                  value: link.href,
                  disabled: !website.menu.data.links.visible,
                  onChange: (e) => onChange({ name: e.target.name, value: e.target.value }),
                  placeholder: "Select a Link",
                  style: {
                    textTransform: "capitalize"
                  }
                }}>
                <optgroup label="Pages">
                  {website.routes?.list.map((item, index) => {
                    const page = pages.find((p) => p.name === item.pageName);
                    const pageName = page?.name || "";

                    if (!pageName) return null;

                    return (
                      <option key={item.path} value={item.path}>
                        {pageName}
                      </option>
                    );
                  })}
                </optgroup>

                <optgroup label="Page sections">
                  {website.routes?.list.map((item, index) => {
                    const page = pages.find((p) => p.name === item.pageName);
                    const pageName = page?.name || "";

                    if (!pageName) return null;

                    return (
                      page?.sections.map((section, sectionIndex) => {
                        return (
                          <option key={section.targetId} value={`${item.path}#${section.targetId}`}>
                            {pageName.toLowerCase()} {`➡`} {section.name.toLowerCase()}
                          </option>
                        );
                      }) || null
                    );
                  })}
                </optgroup>

                <optgroup label="Plugins">
                  <option value={GLOBAL_VARS.BOOKING_URL_PLACEHOLDER}>booking</option>
                </optgroup>
              </InputSelectHybrid>
            )}
            <div
              style={{
                display: "grid",
                placeItems: "center"
              }}>
              <Button
                disabled={!website.menu.data.links.visible}
                data-title={state.customHref ? "Click to select a link" : "Click to use text as a link"}
                data-title-position="top-left"
                outline
                padding={false}
                variant="geyser-500"
                onClick={() => setState((g) => ({ ...g, customHref: !g.customHref }))}>
                {" "}
                {state.customHref ? <LinkOff24Px width={24} height={24} /> : <Link24Px width={24} height={24} />}
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "grid"
          }}>
          <label htmlFor="title">On Click</label>
          <InputSelectHybrid
            selectProps={{
              name: "target",
              value: link.target,
              onChange: (e) => onChange({ name: e.target.name, value: e.target.value }),
              placeholder: "Select behavior",
              style: {
                textTransform: "capitalize"
              },
              disabled: !website.menu.data.links.visible
            }}>
            {GLOBAL_VARS.editables.link.targets.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {GLOBAL_VARS.editables.link.targetLabels[index]}
                </option>
              );
            })}
          </InputSelectHybrid>
        </div>
      </div>
    </div>
  );
};

export default ButtonView;
