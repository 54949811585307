import types from "../../../actions/types";
import { takeEvery, takeLatest } from "redux-saga/effects";
import getAll from "./getAll";
import publish from "./publish";
import updateName from "./updateName";
import recordChanges from "./recordChanges";
import recovery from "./recovery";

export default function* Data() {
  yield takeLatest(types.WEBSITE_GET_THEME_DATA_REQUEST, getAll);
  yield takeLatest(types.WEBSITE_PUBLISH_REQUEST, publish);
  yield takeLatest(types.WEBSITE_UPDATE_NAME_REQUEST, updateName);
  yield takeEvery(types.WEBSITE_RECORD_UNDO_REQUEST, recordChanges);
  yield takeLatest(types.WEBSITE_RECOVER_CHANGE_REQUEST, recovery);
}
