import { createErrorApp } from "helpers/classes/ErrorApp";
import { GLOBAL_VARS } from "helpers/global";

export const ValidateClassForm = (validation: ValidateClassForm, payload: WebsiteForm) => {
  if (typeof payload.action !== "string") {
    throw createErrorApp(`Form is missing action.`);
  }

  if (payload.action !== "plugin/contact") {
    throw createErrorApp(`Form should have action of plugin/contact.`);
  }

  if (!Array.isArray(payload.inputs)) {
    throw createErrorApp(`Form is missing or have invalid list of inputs.`);
  } else {
    for (let i = 0; i < payload.inputs.length; i++) {
      if (typeof payload.inputs[i].label !== "string") {
        throw createErrorApp(`Form input at ${i + 1} order, is missing label.`);
      }

      if (typeof payload.inputs[i].name !== "string") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing name.`);
      }

      if (typeof payload.inputs[i].disabled !== "boolean") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing disabled.`);
      }

      if (typeof payload.inputs[i].placeholder !== "string") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing placeholder.`);
      }
      if (typeof payload.inputs[i].required !== "boolean") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing required.`);
      }
      if (typeof payload.inputs[i].tagName !== "string") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing tag name.`);
      }

      if (typeof payload.inputs[i].type !== "string") {
        throw createErrorApp(`Form input named ${payload.inputs[i].label} is missing type.`);
      }
    }

    const inputNames = payload.inputs.map((input) => input.name);

    for (let i = 0; i < GLOBAL_VARS.editables.form.inputs.requiredAttributes.length; i++) {
      const required = GLOBAL_VARS.editables.form.inputs.requiredAttributes[i];

      if (!inputNames.includes(required)) {
        throw createErrorApp(`Form inputs is missing required attribute ${required}.`);
      }
    }
  }

  if (typeof payload.buttons !== "object") {
    throw createErrorApp(`Form is missing or have invalid buttons.`);
  } else {
    const buttons = Object.values(payload.buttons);

    if (!buttons.length) {
      throw createErrorApp(`Form should have at least one button (cta).`);
    }

    for (let i = 0; i < buttons.length; i++) {
      if (typeof buttons[i].value !== "string") {
        throw createErrorApp(`Form button at ${i + 1} order, is missing value.`);
      }

      if (typeof buttons[i].disabled !== "boolean") {
        throw createErrorApp(`Form button named ${buttons[i].value} is missing disabled.`);
      }

      if (typeof buttons[i].tabIndex !== "number") {
        throw createErrorApp(`Form button named ${buttons[i].value} is missing tab index.`);
      }

      if (typeof buttons[i].type !== "string") {
        throw createErrorApp(`Form button named ${buttons[i].value} is missing type.`);
      }
    }
  }
};
