import _get from "lodash.get";

/**
 * @description transform data-path "attribute:value" to array index [number] for parsing the object.
 */
export const createObjectAttributePath = (object: any, path: string) => {
  while (/([0-9a-zA-Z_-]+:[0-9a-zA-Z_-]+)/.test(path)) {
    const found = path.match(/([0-9a-zA-Z_-]+:[0-9a-zA-Z_-]+)/g) as RegExpMatchArray;
    const fields = found[0].split(":");
    const maxPath = path.split(/.([0-9a-zA-Z_-]+:[0-9a-zA-Z_-]+)/, 1)[0];
    const result = _get(object, maxPath);

    if (Array.isArray(result)) {
      const index = result.findIndex((item: Record<string, any>) => {
        return item[fields[0]] === fields[1];
      });

      path = path.replace(/([0-9a-zA-Z_-]+:[0-9a-zA-Z_-]+)/, `[${index}]`);
    } else if (typeof result === "object") {
      // is it a validation object for group ?
      if (result.classof === "group") {
        path = path.replace(/([0-9a-zA-Z_-]+:[0-9a-zA-Z_-]+)/, `items`);
      }
    }
  }

  return path;
};
