import React from "react";
import { Route, Switch } from "react-router-dom";
import BrainWizardThemes from "pages/brain.wizard.themes/brain.wizard.themes";
import LoadingWrapper from "pages/loading.wrapper/loading.wrapper";
import ErrorPage from "../pages/error.page/error.page";
import NotFound from "../pages/not.found/not.found";

const Routes = () => {
  return (
    <Switch>
      <Route exact path={["/401", "/403", "/404", "/422"]} render={() => <ErrorPage />} />

      <Route
        exact
        path={`/:themeId`}
        render={() => (
          <LoadingWrapper>
            <BrainWizardThemes />{" "}
          </LoadingWrapper>
        )}
      />
      <Route
        exact
        path={`/:themeId/organization`}
        render={() => (
          <LoadingWrapper>
            <BrainWizardThemes />{" "}
          </LoadingWrapper>
        )}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
