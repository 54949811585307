import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "redux/actions";
import { RootState } from "redux/reducers";

/**
 * @description creates a copy of website & pages for undo behavior.
 */
const useUndo = (isThemeReadyToLoad: boolean) => {
  const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
    website: WebsiteWizardReducer.website,
    pages: WebsiteWizardReducer.pages
  });
  const { website, pages } = useSelector(mapStateToProps);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isThemeReadyToLoad) {
      return;
    }

    dispatch(theme.data.actions.recordChanges.request());
  }, [isThemeReadyToLoad, dispatch, website, pages]);
};

export default useUndo;
