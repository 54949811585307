import { GLOBAL_VARS } from "helpers/global";
import { errorWizard, ErrorApp } from "../classes/ErrorApp";
import { validateObjectKeys } from "./validateObjectKeys";
import { validateWebsitePage } from "./validateWebsitePage";

/**
 * @description validate WebsiteData interface structure.
 */
export const validateWebsiteData = (website: WebsiteData) => {
  const errors: ErrorApp[] = [];

  if (typeof website === "object") {
    const requiredKeys: (keyof WebsiteData)[] = [
      "version",
      "colors",
      "contact",
      "font",
      "footer",
      "general",
      "logo",
      "menu",
      "name",
      "page",
      // "policies",
      "routes",
      "themeId",
      "plugins"
    ];
    const validKeys: (keyof WebsiteData)[] = ["id"];

    const { invalidKeys, notFoundKeys } = validateObjectKeys(website, {
      requiredKeys: requiredKeys,
      validKeys: validKeys
    });

    if (invalidKeys.length) {
      const code = "Website";
      const message = `website contains invalid attributes ${invalidKeys.join("|")}.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }
    if (notFoundKeys.length) {
      const code = "Website";
      const message = `website is missing required attributes ${notFoundKeys.join("|")}.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.themeId !== "string") {
      const code = "WebsiteThemeId";
      const message = `Website themeId must be string.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    } else if (!website.themeId) {
      const code = "WebsiteThemeId";
      const message = `Website themeId is required.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.id !== "string" && typeof website.id !== "undefined") {
      const code = "WebsiteName";
      const message = "website id must be string|undefined.";
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.version !== "string") {
      const code = "WebsiteVersion";
      const message = `Website version must be string.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.name !== "string") {
      const code = "WebsiteName";
      const message = `Website name must be string.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.colors === "object") {
      const code = "WebsiteColors";
      const colorKeys = Object.keys(website.colors);

      if (!colorKeys.length) {
        const message = "Website color is must have at least one color.";
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else {
        for (let i = 0; i < colorKeys.length; i++) {
          const key = colorKeys[i];

          if (typeof website.colors[key] !== "string") {
            const message = `Website color ${key} must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!/(#[0-9A-Za-z]{3,8})/.test(website.colors[key])) {
            const message = `Website color ${key} must be valid HEX string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      }
    } else {
      const code = "WebsiteColors";
      const message = `Website colors must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.contact === "object") {
      const code = "WebsiteContact";
      if (!website?.contact.information) {
        const message = "Website contact information is required.";
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (typeof website?.contact.information !== "object") {
        const message = "Website contact information must be object.";
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else {
        const informationKeys = Object.keys(website.contact.information);
        for (let i = 0; i < informationKeys.length; i++) {
          const key = informationKeys[i];
          const value = website.contact.information[key];
          if (typeof value !== "boolean" && typeof value !== "string" && typeof value !== "number") {
            const message = `Website contact information ${key} must be either boolean|string|number.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      }

      if (!website?.contact.socialMedia) {
        const message = "Website contact socialMedia is required.";
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!Array.isArray(website.contact.socialMedia)) {
        const message = "Website contact socialMedia must be array.";
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (website.contact.socialMedia.length) {
        const socialMedia = website.contact.socialMedia;

        for (let i = 0; i < socialMedia.length; i++) {
          const social = socialMedia[i];

          if (typeof social.id !== "string") {
            const message = `Website contact socialMedia id in index ${i} must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!social.id) {
            const message = `Website contact socialMedia id in index ${i} is required.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof social.name !== "string") {
            const message = `Website contact socialMedia name in index ${i} must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!social.name) {
            const message = `Website contact socialMedia name in index ${i} is required.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof social.href !== "string") {
            const message = `Website contact socialMedia name in index ${i} must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!social.href) {
            const message = `Website contact socialMedia name in index ${i} is required.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(social.href)) {
            const message = `Website contact socialMedia href in index ${i} must be a valid secure URL.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof social.svgHTML !== "string") {
            const message = `Website contact socialMedia svgHTML in index ${i} must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else if (!social.svgHTML) {
            const message = `Website contact socialMedia svgHTML in index ${i} is required.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      }
    } else {
      const code = "WebsiteContact";
      const message = `Website contact must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.font === "object") {
      const code = "WebsiteFont";
      if (typeof website.font.cssRule !== "string") {
        const message = `Website font cssRule must be string.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!website.font.cssRule) {
        const message = `Website font is cssRule is required.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }

      if (typeof website.font.href !== "string") {
        const message = `Website font href must be string.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!website.font.href) {
        const message = `Website font href is required.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!GLOBAL_VARS.validaton.regex.httpsUrl.test(website.font.href)) {
        const message = `Website font href must be a valid secure URL.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }

      if (typeof website.font.id !== "string") {
        const message = `Website font id must be string.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!website.font.id) {
        const message = `Website font id is required.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }

      if (typeof website.font.name !== "string") {
        const message = `Website font name must be string.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (!website.font.name) {
        const message = `Website font name is required.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }
    } else {
      const code = "WebsiteFont";
      const message = `Website font must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.footer === "object") {
      const code = "WebsiteFooter";

      if (typeof website.footer.data !== "object") {
        const message = `Website footer data must be string.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else if (typeof website.footer.data === "object") {
        const {
          contactInformation,
          copyright,
          //
          links,
          logo,
          siteMap,
          socialMedia,
          text
        } = website.footer.data;

        if (links) {
          if (typeof links.id !== "string") {
            const message = `Website footer links id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof links.visible !== "boolean") {
            const message = `Website footer links visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof links.title !== "string") {
            const message = `Website footer links title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (!Array.isArray(links.items)) {
            const message = `Website footer links title must be array.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else {
            for (let i = 0; i < links.items.length; i++) {
              const item = links.items[i];
              let notValid = false;
              if (typeof item !== "object") {
                notValid = true;
              } else if (typeof item.rel !== "string") {
                notValid = true;
              } else if (typeof item.target !== "string") {
                notValid = true;
              } else if (typeof item.href !== "string") {
                notValid = true;
              } else if (typeof item.title !== "string") {
                notValid = true;
              } else if (typeof item.value !== "string") {
                notValid = true;
              }

              if (notValid) {
                const message = `Website footer links in ${i} index must be WebsiteLink.`;
                errors.push(
                  new ErrorApp({
                    exception: "ThemeException",
                    message: message,
                    code: code
                  })
                );
              }
            }
          }
        }

        if (copyright) {
          if (typeof copyright !== "object") {
            const message = `Website footer copyright must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof copyright.value !== "string") {
            const message = `Website footer copyright value must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (contactInformation) {
          if (typeof contactInformation !== "object") {
            const message = `Website footer contactInformation must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof contactInformation.id !== "string") {
            const message = `Website footer contactInformation id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof contactInformation.visible !== "boolean") {
            const message = `Website footer contactInformation visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof contactInformation.title !== "string") {
            const message = `Website footer contactInformation title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (siteMap) {
          if (typeof siteMap !== "object") {
            const message = `Website footer siteMap must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.id !== "string") {
            const message = `Website footer siteMap id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.visible !== "boolean") {
            const message = `Website footer siteMap visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.title !== "string") {
            const message = `Website footer siteMap title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (socialMedia) {
          if (typeof socialMedia !== "object") {
            const message = `Website footer socialMedia must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof socialMedia.id !== "string") {
            const message = `Website footer socialMedia id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof socialMedia.visible !== "boolean") {
            const message = `Website footer socialMedia visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof socialMedia.title !== "string") {
            const message = `Website footer socialMedia title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (text) {
          if (typeof text !== "object") {
            const message = `Website footer text must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof text.id !== "string") {
            const message = `Website footer text id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof text.visible !== "boolean") {
            const message = `Website footer text visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof text.title !== "string") {
            const message = `Website footer text title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof text.value !== "string") {
            const message = `Website footer text value must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (logo) {
          if (typeof logo.logoKey !== "string") {
            const message = `Website footer logo logoKey must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof logo.visible !== "boolean") {
            const message = `Website footer logo visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      } else {
        const code = "WebsiteFooter";
        const message = `Website footer data must be object.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }
    } else {
      const code = "WebsiteFooter";
      const message = `Website footer must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.menu === "object") {
      const code = "WebsiteMenu";
      if (typeof website.menu.data === "object") {
        const { links, logo, siteMap } = website.menu.data;

        if (links) {
          if (typeof links.id !== "string") {
            const message = `Website menu links id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof links.visible !== "boolean") {
            const message = `Website menu links visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof links.title !== "string") {
            const message = `Website menu links title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (!Array.isArray(links.items)) {
            const message = `Website menu links title must be array.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          } else {
            for (let i = 0; i < links.items.length; i++) {
              const item = links.items[i];
              let notValid = false;
              if (typeof item !== "object") {
                notValid = true;
              } else if (typeof item.rel !== "string") {
                notValid = true;
              } else if (typeof item.target !== "string") {
                notValid = true;
              } else if (typeof item.href !== "string") {
                notValid = true;
              } else if (typeof item.title !== "string") {
                notValid = true;
              } else if (typeof item.value !== "string") {
                notValid = true;
              }

              if (notValid) {
                const message = `Website menu links in ${i} index must be WebsiteLink.`;
                errors.push(
                  new ErrorApp({
                    exception: "ThemeException",
                    message: message,
                    code: code
                  })
                );
              }
            }
          }
        }

        if (logo) {
          if (typeof logo.logoKey !== "string") {
            const message = `Website menu logo logoKey must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof logo.visible !== "boolean") {
            const message = `Website menu logo visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }

        if (siteMap) {
          if (typeof siteMap !== "object") {
            const message = `Website menu siteMap must be object.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.id !== "string") {
            const message = `Website menu siteMap id must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.visible !== "boolean") {
            const message = `Website menu siteMap visible must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }

          if (typeof siteMap.title !== "string") {
            const message = `Website menu siteMap title must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      } else {
        const code = "WebsiteMenu";
        const message = `Website menu  data must be object.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }
    } else {
      const code = "WebsiteMenu";
      const message = `Website menu must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.general === "object") {
      const code = "WebsiteGeneral";
      const keys = Object.keys(website.general);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = website.general[key];
        if (typeof value !== "string" && typeof value !== "boolean" && typeof value !== "number") {
          const message = `Website general must be string|boolean|number|WebsiteCurrency.`;
          errors.push(
            new ErrorApp({
              exception: "ThemeException",
              message: message,
              code: code
            })
          );
        }

        if (typeof value === "object") {
          // const { notFoundKeys } = validateObjectKeys(value, {
          //   requiredKeys: ["code", "decimal_digits", "name", "name_plural", "rounding", "symbol", "symbol_native"],
          //   validKeys: []
          // });
          // for (let i = 0; i < notFoundKeys.length; i++) {
          //   const key = notFoundKeys[i];
          //   const message = `Website general ${key} is object, we only accept WebsiteCurrency objects.`;
          //   errors.push(
          //     new WizardError({
          //       object: {
          //         message: message,
          //         code: code
          //       }
          //     })
          //   );
          // }
        }
      }
    } else {
      const code = "WebsiteGeneral";
      const message = `Website general must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.logo === "object") {
      const code = "WebsiteLogo";
      const { notFoundKeys } = validateObjectKeys(website.logo, {
        requiredKeys: ["favicon", "primary", "secondary"],
        validKeys: []
      });

      for (let i = 0; i < notFoundKeys.length; i++) {
        const key = notFoundKeys[i];

        const message = `Website logo ${key} must be WebsiteLogo.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      }
    } else {
      const code = "WebsiteLogo";
      const message = `Website logo must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    // if (Array.isArray(website.policies)) {
    //   const code = "WebsitePolicies";
    //   for (let i = 0; i < website.policies.length; i++) {
    //     const item = website.policies[i];

    //     if (typeof item.href !== "string") {
    //       const message = `Website policies href in ${i} index must be string.`;
    //       errors.push(
    //         new WizardError({
    //           object: {
    //             message: message,
    //             code: code
    //           }
    //         })
    //       );
    //     }
    //     if (typeof item.id !== "string") {
    //       const message = `Website policies id in ${i} index must be string.`;
    //       errors.push(
    //         new WizardError({
    //           object: {
    //             message: message,
    //             code: code
    //           }
    //         })
    //       );
    //     }
    //     if (typeof item.name !== "string") {
    //       const message = `Website policies name in ${i} index must be string.`;
    //       errors.push(
    //         new WizardError({
    //           object: {
    //             message: message,
    //             code: code
    //           }
    //         })
    //       );
    //     }
    //   }
    // } else {
    //   const code = "WebsitePolicies";
    //   const message = `Website policies must be array.`;
    //   errors.push(
    //     new WizardError({
    //       object: {
    //         message: message,
    //         code: code
    //       }
    //     })
    //   );
    // }

    if (typeof website.routes === "object") {
      const code = "WebsiteRoutes";
      if (!Array.isArray(website.routes.list)) {
        const message = `Website routes list must be array.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else {
        for (let i = 0; i < website.routes.list.length; i++) {
          const item = website.routes.list[i];

          if (typeof item.pageName !== "string" && typeof item.pageName !== "undefined") {
            const message = `Website routes list pageName in ${i} index must be string|undefined.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
          if (typeof item.default !== "boolean") {
            const message = `Website routes list default in ${i} index must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
          if (typeof item.selected !== "boolean") {
            const message = `Website routes list selected in ${i} index must be boolean.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
          if (typeof item.path !== "string") {
            const message = `Website routes list path in ${i} index must be string.`;
            errors.push(
              new ErrorApp({
                exception: "ThemeException",
                message: message,
                code: code
              })
            );
          }
        }
      }

      if (typeof website.routes.siteMap !== "object") {
        const message = `Website routes siteMap must be object.`;
        errors.push(
          new ErrorApp({
            exception: "ThemeException",
            message: message,
            code: code
          })
        );
      } else {
        if (!Array.isArray(website.routes.siteMap.groups)) {
          const message = `Website routes siteMap groups must be array.`;
          errors.push(
            new ErrorApp({
              exception: "ThemeException",
              message: message,
              code: code
            })
          );
        } else {
          for (let i = 0; i < website.routes.siteMap.groups.length; i++) {
            const item = website.routes.siteMap.groups[i];

            if (typeof item.id !== "string") {
              const message = `Website routes siteMap groups id in ${i} index must be string.`;
              errors.push(
                new ErrorApp({
                  exception: "ThemeException",
                  message: message,
                  code: code
                })
              );
            }

            if (typeof item.value !== "string") {
              const message = `Website routes siteMap groups value in ${i} index must be string.`;
              errors.push(
                new ErrorApp({
                  exception: "ThemeException",
                  message: message,
                  code: code
                })
              );
            }

            if (!Array.isArray(item.items)) {
              const message = `Website routes siteMap groups items in ${i} index must be array.`;
              errors.push(
                new ErrorApp({
                  exception: "ThemeException",
                  message: message,
                  code: code
                })
              );
            } else {
              for (let i = 0; i < item.items.length; i++) {
                const link = item.items[i];
                let notValid = false;
                if (typeof link !== "object") {
                  notValid = true;
                } else if (typeof link.rel !== "string") {
                  notValid = true;
                } else if (typeof link.target !== "string") {
                  notValid = true;
                } else if (typeof link.href !== "string") {
                  notValid = true;
                } else if (typeof link.title !== "string") {
                  notValid = true;
                } else if (typeof link.value !== "string") {
                  notValid = true;
                }

                if (notValid) {
                  const message = `Website routes siteMap groups items in ${i} index must be WebsiteLink.`;
                  errors.push(
                    new ErrorApp({
                      exception: "ThemeException",
                      message: message,
                      code: code
                    })
                  );
                }
              }
            }
          }
        }
      }
    } else {
      const code = "WebsiteRoutes";
      const message = `Website routes must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }

    if (typeof website.page === "object") {
      const { errors: pageErrors } = validateWebsitePage(website.page);
      for (let i = 0; i < pageErrors.length; i++) {
        errors.push(pageErrors[i]);
      }
    } else {
      const code = "WebsitePage";
      const message = `Website page must be object.`;
      errors.push(
        new ErrorApp({
          exception: "ThemeException",
          message: message,
          code: code
        })
      );
    }
  } else {
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: "website must be object.",
        code: "Website"
      })
    );
  }

  errors.forEach((error) => errorWizard(error.message, { code: error.code }));

  return {
    valid: !errors.length ? true : false,
    errors
  };
};
