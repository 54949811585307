import { CallEffect, put, PutEffect, select, SelectEffect, TakeEffect } from "@redux-saga/core/effects";
import { createErrorApp, ErrorApp } from "../../../../helpers/classes/ErrorApp";
import { RootState } from "redux/reducers";
import { GLOBAL_VARS } from "../../../../helpers/global";
import { ModelWebsite } from "helpers/models";
import { theme } from "redux/actions";

/**
 * @description update website site map
 */
export default function* updateSiteMap({
  payload
}: ReturnType<(typeof theme)["route"]["actions"]["updateSiteMap"]["request"]>): Generator<
  CallEffect<Promise<File>> | CallEffect | Promise<string | undefined> | TakeEffect | SelectEffect | PutEffect<any>,
  void,
  any
> {
  try {
    const exceptionCode = "siteMapException";
    const { siteMap } = payload;
    const mapStateToProps = ({ Wizard: WebsiteWizardReducer }: RootState) => ({
      website: WebsiteWizardReducer.website
    });

    const { website }: ReturnType<typeof mapStateToProps> = yield select(mapStateToProps);
    const modelWebsite = new ModelWebsite(website);

    const errors: string[] = [];

    if (modelWebsite.routes?.siteMap) {
      const links = siteMap.groups.flatMap((item) => item.items);
      for (let i = 0; i < links.length; i++) {
        const item = links[i];
        if (!item.value) {
          errors.push(`Link value is required.`);
        } else if (!item.href) {
          errors.push(`Link is required.`);
        } else if (
          !GLOBAL_VARS.validaton.regex.httpsUrl.test(item.href) &&
          !item.href.startsWith("/") &&
          item.href !== GLOBAL_VARS.BOOKING_URL_PLACEHOLDER
        ) {
          errors.push(`Link is invalid, select a page, section or add a custom url.`);
        } else if (item.target && !GLOBAL_VARS.editables.link.targets.includes(item.target)) {
          errors.push(
            `Link behavior is invalid, available options are ${GLOBAL_VARS.editables.link.targets.join(", ")}.`
          );
        } else if (item.rel && !GLOBAL_VARS.editables.link.rels.includes(item.rel)) {
          errors.push(
            `Link relationship is invalid, available options are ${GLOBAL_VARS.editables.link.rels.join(", ")}.`
          );
        }
      }
      // website.routes.siteMap = siteMap;
      modelWebsite.update(modelWebsite, {
        routes: {
          ...modelWebsite.routes,
          siteMap: siteMap
        }
      });
    }

    if (errors.length) {
      throw createErrorApp(errors[0], {
        code: exceptionCode
      });
    }

    yield put(
      theme.route.actions.updateSiteMap.success({
        website: modelWebsite.object
      })
    );
  } catch (error) {
    yield put(
      theme.route.actions.updateSiteMap.failure({
        error: new ErrorApp(error).toObject()
      })
    );
  }
}
