import { errorWizard, ErrorApp } from "../classes/ErrorApp";
import { validateObjectKeys } from "./validateObjectKeys";

/**
 * @description validate WebsiteSection interface structure.
 */

export const validateWebsiteSection = (section: WebsiteSection) => {
  const code = "WebsiteSection";
  const errors: ErrorApp[] = [];
  const requiredKeys: (keyof WebsiteSection)[] = ["data", "default", "id", "name", "selected", "targetId"];
  const validKeys: (keyof WebsiteSection)[] = [];
  const { invalidKeys, notFoundKeys } = validateObjectKeys(section, {
    requiredKeys: requiredKeys,
    validKeys: validKeys
  });

  if (invalidKeys.length) {
    const message = `section named ${section.name} contains invalid attributes ${invalidKeys.join("|")}.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }
  if (notFoundKeys.length) {
    const message = `section named ${section.name} is missing required attributes ${notFoundKeys.join("|")}.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.id !== "string") {
    const message = `section named ${section.name} id must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.name !== "string") {
    const message = `section named ${section.name} name must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.data !== "object") {
    const message = `section named ${section.name} data must be object.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.selected !== "boolean") {
    const message = `section named ${section.name} selected must be boolean.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.default !== "boolean") {
    const message = `section named ${section.name} default must be boolean.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  if (typeof section.targetId !== "string") {
    const message = `section named ${section.name} targetId must be string.`;
    errors.push(
      new ErrorApp({
        exception: "ThemeException",
        message: message,
        code: code
      })
    );
  }

  errors.forEach((error) => errorWizard(error.message, { code: error.code }));

  return {
    valid: !errors.length ? true : false,
    errors
  };
};
